import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from './resource';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: resources,
    lng: "vi",
    fallbackLng: "vi",
    interpolation: {
      escapeValue: false
    },
    debug: false,
    ns: ['login', 'button', 'menu', 'user', 'message', 'branch', 'product', 'client', 'float_menu']
  });

export default i18n;