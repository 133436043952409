import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL, ClientType, Permission, TransferStatus, GlobalConst } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, CustomSelect, ImageUpload, InputText, ColumnContainer, Autocomplete, Checkbox } from '../../Components';
import { People, Save, KeyboardArrowLeft, Clear } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getTransferMoMoById, saveTransferMoMo, approveTransferMoMo, cancelTransferMoMo, updateConfirmation } from '../../Redux/TransferMoMo/TransferMoMoReducer';
import { getClientSelectItems } from '../../Redux/Client/ClientReducer';
import { getPaymentAccountSelectItems } from '../../Redux/PaymentAccount/PaymentAccountReducer';
import { getTransferSettingMoMoSelectItems } from '../../Redux/TransferSettingMoMo/TransferSettingMoMoReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { TransferMoMoDTO, SelectItemDTO, CurrentUserDTO } from '../../DTO';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';
import { getUserSelectItems } from '../../Redux/User/UserReducer';
let moment = require('moment');

interface ITransferMoMoDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  currentUser: CurrentUserDTO;
  transferMoMo: TransferMoMoDTO;
  clientSelectItems: SelectItemDTO[];
  paymentAccountSelectItems: SelectItemDTO[];
  transferSettingMoMoSelectItems: SelectItemDTO[];
  branchSelectItems: SelectItemDTO[];
  userSelectItems: SelectItemDTO[];

  setActiveRoute: ActionCreator<any>,
  getTransferMoMoById: ActionCreator<any>,
  saveTransferMoMo: ActionCreator<any>,
  getClientSelectItems: ActionCreator<any>,
  getPaymentAccountSelectItems: ActionCreator<any>,
  getTransferMoMoSettingSelectItems: ActionCreator<any>,
  approveTransferMoMo: ActionCreator<any>,
  cancelTransferMoMo: ActionCreator<any>,
  updateConfirmation: ActionCreator<any>,
  getBranchSelectItems: ActionCreator<any>,
  getUserSelectItems: ActionCreator<any>,
}

interface ITransferMoMoDetailStates {
  isSaving?: boolean;

  transferMoMo: TransferMoMoDTO;
  showValidation?: boolean;
  referenceFile?: any;
  confirmationFile?: any;
}

function mapStateToProps(state: any) {
  return ({
    transferMoMo: state.transferMoMo.editingTransferMoMo,
    clientSelectItems: state.client.clientSelectItems,
    paymentAccountSelectItems: state.paymentAccount.paymentAccountSelectItems,
    transferSettingMoMoSelectItems: state.transferSettingMoMo.transferSettingMoMoSelectItems,
    currentUser: state.user.currentUser,
    branchSelectItems: state.branch.branchSelectItems,
    userSelectItems: state.user.userSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getTransferMoMoById: (id: number) => dispatch(getTransferMoMoById(id)),
    saveTransferMoMo: (transferMoMo: TransferMoMoDTO, submit: boolean, reference: any, confirmation: any) => dispatch(saveTransferMoMo(transferMoMo, submit, reference, confirmation)),
    getClientSelectItems: () => dispatch(getClientSelectItems()),
    getPaymentAccountSelectItems: () => dispatch(getPaymentAccountSelectItems()),
    getTransferMoMoSettingSelectItems: () => dispatch(getTransferSettingMoMoSelectItems()),
    approveTransferMoMo: (id: number) => dispatch(approveTransferMoMo(id)),
    cancelTransferMoMo: (id: number) => dispatch(cancelTransferMoMo(id)),
    updateConfirmation: (id: number, confirmation: any) => dispatch(updateConfirmation(id, confirmation)),
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
    getUserSelectItems: () => dispatch(getUserSelectItems()),
  })
}

class TransferMoMoDetail extends React.Component<ITransferMoMoDetailProps, ITransferMoMoDetailStates> {

  constructor(props: ITransferMoMoDetailProps) {
    super(props);
    
    this.state = {
      transferMoMo: {...new TransferMoMoDTO(), ...props.transferMoMo},
    }
  }

  componentDidMount() {
    !this.props.clientSelectItems?.length && this.props.getClientSelectItems();
    !this.props.paymentAccountSelectItems?.length && this.props.getPaymentAccountSelectItems();
    !this.props.transferSettingMoMoSelectItems?.length && this.props.getTransferMoMoSettingSelectItems();
    !this.props.branchSelectItems?.length && this.props.getBranchSelectItems();
    !this.props.userSelectItems?.length && this.props.getUserSelectItems();

    const { setActiveRoute, match, getTransferMoMoById, t } = this.props;

    setActiveRoute([{path: URL.TransferMoMo, name: this.props.t('menu:transferMoMo')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);
    if (match.params.id > 0) {
      getTransferMoMoById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.TransferMoMo);
          } else {
            this.setState({ transferMoMo: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes, clientSelectItems, paymentAccountSelectItems, currentUser, branchSelectItems, userSelectItems } = this.props;
    let { showValidation, transferMoMo: { id, number, clientId, createdUser, toAccountName, toAccountNumber, detail, amount, fee, commission, discount, paymentAccountId, referenceFileName, confirmationFileName, status, transferDate, note, branchId, commissionUserId, payByCash, receiveAccountId } } = this.state;

    const types = [
      { value: 1, text: t('client:type_single')},
      { value: 2, text: t('client:type_multiple')},
      { value: 3, text: t('client:type_branch')},
    ];

    const statusText = [
      { value: 0, text: t('transferMoMo:status_draft')},
      { value: 1, text: t('transferMoMo:status_pending_approve')},
      { value: 2, text: t('transferMoMo:status_approved')},
      { value: 3, text: t('transferMoMo:status_cancel')},
    ]

    let statusStr = statusText.find(s => s.value === status)?.text || '';
    let tDate = transferDate ? moment(transferDate, GlobalConst.DATE_TIME_FORMAT) : moment(new Date());
    let now = moment(new Date());
    let readOnly = (status === TransferStatus.Approved || status === TransferStatus.Canceled) && (tDate.year() * 12 + tDate.month() < now.year() * 12 + now.month());

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={People}
          iconColor={'green'}
          title={t('common:information')}>

          {id && currentUser.functionIds.includes(Permission.ApproveTransferMoMos) && status === TransferStatus.PendingApprove ? 
            <ColumnContainer columnWidth={[{xs:12, sm:12}]}>
              <div className={classes.right}>
                <Button color='primary' customClass={classes.marginRight} round onClick={this._onApprove.bind(this)} disabled={this.state.isSaving}>
                  <Save className={classes.icons} /> {t('button:approve')}
                </Button>
                <Button color='danger' customClass={classes.marginRight} round onClick={this._onCancel.bind(this)} disabled={this.state.isSaving}>
                  <Clear className={classes.icons} /> {t('button:cancel')}
                </Button>
              </div>
            </ColumnContainer>
          : null}

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transfer:number')} </FormLabel>
              <InputText values={number} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferMoMo:status')} </FormLabel>
              <InputText values={statusStr} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferMoMo:date')} </FormLabel>
              {!readOnly ? 
                <Datetime
                  dateFormat={GlobalConst.DATE_FORMAT}
                  timeFormat={"HH:mm"}
                  inputProps={{ placeholder: t('transferMoMo:date') }}
                  onChange={this._onDateChange.bind(this)}
                  value={transferDate}
                /> : <InputText disabled values={transferDate} /> }
            </ColumnContainer>
            
            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferMoMo:user')} </FormLabel>
              <InputText disabled values={id === 0 ? currentUser.name : createdUser} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferMoMo:client')} </FormLabel>
              <>
                <Autocomplete
                  value={clientId}
                  options={clientSelectItems}
                  onChange={this._onClientChange.bind(this)}
                  getOptionLabel={(option: SelectItemDTO) => `${option.text} - ${option.details.phone}`}
                />
                {showValidation && !clientId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <> </>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('client:type')} </FormLabel>
              <InputText disabled values={types.find(t => t.value === clientSelectItems?.find(c => c.value === clientId)?.details.type)?.text || ''} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('client:phone')} </FormLabel>
              <InputText disabled values={clientSelectItems?.find(c => c.value === clientId)?.details.phone || ''} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferMoMo:to_account_name')} </FormLabel>
              <>
                <InputText 
                  name="toAccountName" 
                  values={toAccountName} 
                  onChange={this._onTextChange.bind(this)} 
                  disabled={readOnly} 
                />
                {showValidation && !toAccountName && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferMoMo:to_account_number')} </FormLabel>
              <>
                <InputText name='toAccountNumber' values={toAccountNumber} onChange={this._onTextChange.bind(this)} disabled={readOnly} />
                {showValidation && !toAccountNumber && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferMoMo:amount')} </FormLabel>
              <>
                <InputText 
                  name="amount"
                  numberOnly
                  thousandSeparator
                  values={amount} 
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly} />
                {showValidation && !amount && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferMoMo:detail')} </FormLabel>
              <InputText 
                name='detail'
                values={detail}
                onChange={this._onTextChange.bind(this)}
                disabled={readOnly}
              />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferMoMo:fee')} </FormLabel>
              <InputText numberOnly thousandSeparator values={fee} disabled />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferMoMo:commission')} </FormLabel>
              <InputText disabled numberOnly thousandSeparator values={commission} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferMoMo:discount')} </FormLabel>
              <InputText 
                numberOnly 
                thousandSeparator 
                name='discount' 
                values={discount}
                onChange={this._onNumberChange.bind(this)} 
                disabled={readOnly} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferMoMo:total')} </FormLabel>
              <InputText 
                disabled 
                numberOnly 
                thousandSeparator 
                values={parseFloat('0' + amount.toString()) + parseFloat('0' + fee.toString()) - parseFloat('0' + discount.toString())} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('paymentAccount:name')} </FormLabel>
              <>
                <CustomSelect 
                  name={'paymentAccountId'}
                  value={paymentAccountId}
                  onChange={this._onNumberChange.bind(this)}
                  items={paymentAccountSelectItems}
                  label={t('paymentAccount:name')}
                  disabled={readOnly} />
                {showValidation && !paymentAccountId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferMoMo:note')} </FormLabel>
              <InputText 
                name='note' 
                values={note} 
                onChange={this._onTextChange.bind(this)} 
                disabled={readOnly} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferMoMo:branch')} </FormLabel>
              <>
                <Autocomplete
                  name='branchId'
                  value={branchId}
                  options={branchSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                />
                {showValidation && !branchId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferMoMo:commission_user')} </FormLabel>
              <>
                <Autocomplete
                  name='commissionUserId'
                  value={commissionUserId}
                  options={userSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                />
                {showValidation && !commissionUserId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferMoMo:reference')} </FormLabel>
              <ImageUpload 
                image={id && referenceFileName ? `/Upload/TransferMoMoRef/${id}/${referenceFileName}` : null}
                onImageChange={(file: any) => this.setState({ referenceFile: file })}
                onImageRemoved={() => { let transferMoMo = this.state.transferMoMo; transferMoMo.referenceFileName = ''; this.setState({ transferMoMo: transferMoMo }) }}
                hideButtons={readOnly} />
            </ColumnContainer>
            
            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferMoMo:confirmation')} </FormLabel>
              <ImageUpload 
                image={id && confirmationFileName ? `/Upload/TransferMoMoConfirmation/${id}/${confirmationFileName}` : null}
                onImageChange={(file: any) => this.setState({ confirmationFile: file })}
                onImageRemoved={() => { let transferMoMo = this.state.transferMoMo; transferMoMo.confirmationFileName = ''; this.setState({ transferMoMo: transferMoMo }) }}
                hideButtons={readOnly} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferMoMo:pay_by_cash')} </FormLabel>
              <Checkbox
                checked={payByCash}
                name='payByCash'
                onClick={this._onCheckboxChange.bind(this)}
              />
            </ColumnContainer>

            {payByCash ? null : <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferMoMo:receive_account')} </FormLabel>
              <>
                <CustomSelect 
                  name={'receiveAccountId'}
                  value={receiveAccountId}
                  onChange={this._onNumberChange.bind(this)}
                  items={paymentAccountSelectItems}
                  label={t('transferMoMo:receive_account')}
                  disabled={readOnly} />
                {showValidation && !receiveAccountId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>}
          </ColumnContainer>

          <GridContainer justify="center">
            {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button> : null}
            {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button> : null}
            {status === TransferStatus.Draft ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_submit')}
            </Button> : null}
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.TransferMoMo)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onCheckboxChange(event: any) {
    event.persist && event.persist();
    const { target: { name, checked } } = event;
    let transfer = this.state.transferMoMo;
    this.setState({ transferMoMo: {...transfer, [name]: checked }});
  }

  private _onClientChange(value: number) {
    this.setState({ transferMoMo: {...this.state.transferMoMo, clientId: value}}, () => this._updateCommissionFee());
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ transferMoMo: {...this.state.transferMoMo, [name]: value}});
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ transferMoMo: { ...this.state.transferMoMo, [name]: value } }, () => { name === 'amount' && this._updateCommissionFee(); });
  }

  private _updateCommissionFee() {
    let transferMoMo = this.state.transferMoMo;
    let clientId = transferMoMo.clientId;
    let amount = transferMoMo.amount
    let setting = this.props.transferSettingMoMoSelectItems.find(s => s.details.fromAmount <= amount && s.details.toAmount > amount);
    let client = this.props.clientSelectItems?.find(c => c.value === clientId);
    this.setState({ transferMoMo: {...transferMoMo, commission: setting?.details.commission ?? 0, fee: [ClientType.Multiple, ClientType.Branch].includes(client?.details.type) ? (setting?.details.multipleSalePrice ?? 0) : (setting?.details.salePrice ?? 0)  }});
  }

  private _onDateChange(value: string | Moment) {
    let transferMoMo = this.state.transferMoMo;
    transferMoMo.transferDate = moment(value).format(GlobalConst.DATE_TIME_FORMAT);
    this.setState({ transferMoMo: transferMoMo });
  }

  private _onValidate() {
    let { transferMoMo: { clientId, toAccountName, toAccountNumber, amount, paymentAccountId, branchId, commissionUserId, payByCash, receiveAccountId } } = this.state;

    return clientId && toAccountName && toAccountNumber && amount && paymentAccountId && branchId && commissionUserId && (payByCash || receiveAccountId);
  }

  private _onSave(submit: boolean = false, close: boolean = false) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { referenceFile, confirmationFile, transferMoMo: { clientId, transferDate, toAccountName, toAccountNumber, detail, amount, fee, commission, discount, paymentAccountId, note, branchId, commissionUserId, referenceFileName, confirmationFileName, payByCash, receiveAccountId } } = this.state;
    let transferMoMo = new TransferMoMoDTO();
    transferMoMo.id = parseInt(this.props.match.params.id);
    transferMoMo.clientId = clientId;
    transferMoMo.toAccountName = toAccountName;
    transferMoMo.toAccountNumber = toAccountNumber;
    transferMoMo.detail = detail;
    transferMoMo.amount = amount;
    transferMoMo.fee = fee;
    transferMoMo.commission = commission;
    transferMoMo.discount = discount;
    transferMoMo.paymentAccountId = paymentAccountId;
    transferMoMo.note = note;
    transferMoMo.transferDate = transferDate;
    transferMoMo.branchId = branchId;
    transferMoMo.commissionUserId = commissionUserId;
    transferMoMo.referenceFileName = referenceFileName;
    transferMoMo.confirmationFileName = confirmationFileName;
    transferMoMo.payByCash = payByCash;
    transferMoMo.receiveAccountId = payByCash ? null : receiveAccountId;

    this.props.saveTransferMoMo(transferMoMo, submit, referenceFile, confirmationFile).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        transferMoMo.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close || submit) {
          history.push(URL.TransferMoMo);
        } else if (parseInt(match.params.id) === 0) {
          this.setState({ transferMoMo: response.data });
          history.push(URL.TransferMoMoDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

  // private _onUpdateConfirmation() {
  //   const { t } = this.props;
  //   if (this.state.confirmationFile) {
  //     this.props.updateConfirmation(this.state.transferMoMo.id, this.state.confirmationFile).then((response: any) => {
  //       if (response && !response.errorCode) {
  //         toast.success(t('message:save_success'));
  //       } else {
  //         showError(t, response.errorCode, response.errorMessage);
  //       }
  //     });
  //   } else {
  //     toast.warn(t('transferMoMo:select_confirmation'));
  //   }
  // }

  private _onApprove() {
    const { t } = this.props;
    this.setState({ isSaving: true });

    this.props.approveTransferMoMo(this.state.transferMoMo.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.TransferMoMo);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

  private _onCancel() {
    const { t } = this.props;
    this.setState({ isSaving: true });
    
    this.props.cancelTransferMoMo(this.state.transferMoMo.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.TransferMoMo);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(TransferMoMoDetail)));