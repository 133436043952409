import { FilterDTO, RoleDTO } from '../../DTO';

const ROLE_REQUEST: string = 'ROLE_REQUEST';

const ROLE_GET_LIST_SUCCESS: string = 'ROLE_GET_LIST_SUCCESS';
const ROLE_GET_LIST_FAIL: string = 'ROLE_GET_LIST_FAIL';

const ROLE_SET_EDITING_ROLE: string = 'ROLE_SET_EDITING_ROLE';

const ROLE_GET_BY_ID_SUCCESS: string = 'ROLE_GET_BY_ID_SUCCESS';
const ROLE_GET_BY_ID_FAIL: string = 'ROLE_GET_BY_ID_FAIL';

const ROLE_SAVE_SUCCESS: string = 'ROLE_SAVE_SUCCESS';
const ROLE_SAVE_FAIL: string = 'ROLE_SAVE_FAIL';

const ROLE_DELETE_SUCCESS: string = 'ROLE_DELETE_SUCCESS';
const ROLE_DELETE_FAIL: string = 'ROLE_DELETE_FAIL';

const ROLE_GET_NAMES_SUCCESS: string = 'ROLE_GET_NAMES_SUCCESS';
const ROLE_GET_NAMES_FAIL: string = 'ROLE_GET_NAMES_FAIL';

const initialState = {
  editingRole: {},
  roleSelectItems: [],
}

export function roleReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case ROLE_SET_EDITING_ROLE:
      return Object.assign({}, state, {
        editingRole: action.payload
      });

    case ROLE_GET_NAMES_SUCCESS:
      return Object.assign({}, state, {
        roleSelectItems: action.response?.data ?? []
      })

    default:
      return state;

  }

}

export function getListRole(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [ROLE_REQUEST, ROLE_GET_LIST_SUCCESS, ROLE_GET_LIST_FAIL],
      EndPoint: `Roles${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingRole(role: RoleDTO) {
  return {
    type: ROLE_SET_EDITING_ROLE,
    payload: role
  }
}

export function getRoleById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [ROLE_REQUEST, ROLE_GET_BY_ID_SUCCESS, ROLE_GET_BY_ID_FAIL],
      EndPoint: `Roles\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveRole(role: RoleDTO) {
  let initRequest: RequestInit = {
    method: role.id ? 'PATCH' : 'POST',
    body: JSON.stringify(role),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [ROLE_REQUEST, ROLE_SAVE_SUCCESS, ROLE_SAVE_FAIL],
      EndPoint: 'Roles',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteRole(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [ROLE_REQUEST, ROLE_DELETE_SUCCESS, ROLE_DELETE_FAIL],
      EndPoint: 'Roles',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function getRoleSelectItems() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [ROLE_REQUEST, ROLE_GET_NAMES_SUCCESS, ROLE_GET_NAMES_FAIL],
      EndPoint: 'Roles/selectList',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}