import { GlobalConst } from '../Consts';
let moment = require('moment');

export class GenerateAppKeyDTO {
  public secretKey: string;
  public masterDate: string;
  public regInfoDate: string;
  public normalizeDate: string;
  public personalInfoDate: string;
  public c6Date: string;
  public checkMobicardDate: string;
  public checkMobiGoldDate: string;
  public macAddress: string[];
  public macAddressStr: string;

  constructor() {
    this.secretKey = '';
    this.masterDate = moment().add(3, 'months').format(GlobalConst.DATE_FORMAT);
    this.regInfoDate = '';
    this.normalizeDate = '';
    this.personalInfoDate = '';
    this.c6Date = '';
    this.checkMobicardDate = '';
    this.checkMobiGoldDate = '';
    this.macAddress = [];
    this.macAddressStr = '';
  }
}