import React from 'react';
import { IconCard, DataList, Button, CustomInput, ColumnContainer, Autocomplete } from '../../Components';
import { Assignment, Edit, Replay, Add, Delete, Search, GetApp, OpenInNew } from '@material-ui/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ActionCreator } from 'redux';
import { connect } from 'react-redux';
import { getListClient, deleteClient, setEditingClient, exportClient } from '../../Redux/Client/ClientReducer';
import { IconButton, withStyles, InputAdornment, FormLabel } from '@material-ui/core';
import { URL, GlobalConst } from '../../Consts';
import history from '../../history';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { FilterDTO, ClientDTO, SelectItemDTO, FilterItemDTO } from '../../DTO';
import styles from '../../styles';
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from 'react-toastify';
import { currency, showError } from '../../Utils';
import _ from 'lodash';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';

interface IClientProps extends WithTranslation {
  classes: any;

  branchSelectItems: SelectItemDTO[],

  getClientList: ActionCreator<any>,
  setActiveRoute: ActionCreator<any>,
  setEditingClient: ActionCreator<any>,
  deleteClient: ActionCreator<any>,
  getBranchSelectItems: ActionCreator<any>,
  exportClient: ActionCreator<any>,
}

interface IClientStates {
  data: ClientDTO[],
  page: number;
  pageSize: number;
  totalCount: number;

  searchString: string;
  showDeleteConfirm?: boolean;
  deletingId?: number;

  branchId: number;
  isExporting: boolean;
}

function mapStateToProps(state: any) {
  return ({
    branchSelectItems: state.branch.branchSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    getClientList: (filterDTO: FilterDTO) => dispatch(getListClient(filterDTO)),
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    setEditingClient: (client: ClientDTO) => dispatch(setEditingClient(client)),
    deleteClient: (id: number) => dispatch(deleteClient(id)),
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
    exportClient: (filterDTO: FilterDTO) => dispatch(exportClient(filterDTO)),
  })
}

class Client extends React.Component<IClientProps, IClientStates> {

  constructor(props: IClientProps){
    super(props);

    this.state = {
      data: [],
      page: 0,
      pageSize: 20,
      totalCount: 0,
      searchString: '',
      branchId: 0,
      isExporting: false,
    }

    this._getClientWithDebounce = _.debounce(this._getClientWithDebounce.bind(this), GlobalConst.DEBOUNCE_TIME);
  }

  componentDidMount() {
    !this.props.branchSelectItems?.length && this.props.getBranchSelectItems();
    
    this.props.setActiveRoute([{path: URL.Client, name: this.props.t('menu:client')}]);
  }

  render() {
    const { t, classes, branchSelectItems } = this.props;
    let { data, page, pageSize, totalCount, branchId, isExporting } = this.state;
    
    let emptyOption = { value: 0, text: t('common:all') } as SelectItemDTO;

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={Assignment}
          iconColor={'green'}
          title={t('client:client')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>
            <CustomInput
              labelText={t('common:search')}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                value: this.state.searchString,
                name: 'searchString',
                onChange: this._onSearchStringChange.bind(this)
              }}
            />

            <div className={classes.right}>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onEdit.bind(this, new ClientDTO())}>
                <Add className={classes.icons} /> {t('button:add')}
              </Button>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onRefresh.bind(this)}>
                <Replay className={classes.icons} /> {t('button:refresh')}
              </Button>
              <Button color='primary' customClass={classes.marginRight} disabled={isExporting} round onClick={this._onExport.bind(this)}>
                <GetApp className={classes.icons} /> {t('button:excel')}
              </Button>
            </div>
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs:12, sm:6, md:4}]}>
            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('client:branch')} </FormLabel>
              <Autocomplete
                value={branchId}
                options={[emptyOption, ...(branchSelectItems ?? [])]}
                onChange={(value: number) => { this.setState({ branchId: value }, () => this._search()) }}
              />
            </ColumnContainer>
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs:12}]}>
            <DataList 
              currentPage={page}
              pageSize={pageSize}
              totalPage={Math.ceil(totalCount / pageSize)}
              data={this._mapData(data)}
              columns={this._getColumns()}
              fetchData={this._onLoadData.bind(this)}
              onPageSizeChange={(ps: number) => {this.setState({ pageSize: ps, page: 0 }, () => this._search())}}
              onChangePage={this._onChangePage.bind(this)}
            />
          </ColumnContainer>
          
          {this.state.showDeleteConfirm && <SweetAlert
            warning
            //style={{ display: "block", marginTop: "-100px" }}
            title={t('message:confirm_delete')}
            onConfirm={this._onDelete.bind(this)}
            onCancel={() => this.setState({ showDeleteConfirm: false})}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={t('button:delete')}
            cancelBtnText={t('button:cancel')}
            showCancel
          >
          </SweetAlert>}

        </IconCard>
      </ColumnContainer>
    )
  }

  private _getFilter(tableState: any = null): FilterDTO {
    let { pageSize, searchString, branchId } = this.state;

    let filter: FilterDTO = new FilterDTO(tableState);
    if (!tableState) {
      filter.page = 0;
    }
    filter.pageSize = pageSize;
    filter.searchString = searchString;
    filter.filters = [
      { key: 'branch', value: branchId } as FilterItemDTO,
    ];

    return filter;
  }

  _getClientWithDebounce(filterDTO: FilterDTO) {
    this.props.getClientList(filterDTO).then((response: any) => {
      if (response && !response.errorCode) {
        this.setState({ data: response.data, totalCount: response.totalCount });
      } else {
        showError(this.props.t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _onSearchStringChange(event: any) {
    event.persist && event.persist();
    const { target: { value } } = event;
    this.setState({ searchString: value || '', page: 0 }, () => this._search());
  }

  private _search() {
    this._getClientWithDebounce(this._getFilter());    
  }

  private _getColumns() {
    const { t } = this.props;
    return [
      { Header: t('client:name'), accessor: "name", sortable: false, filterable: false },
      { Header: t('client:phone'), accessor: "phone", sortable: false, filterable: false },
      { Header: t('client:address'), accessor: "address", sortable: false, filterable: false },
      { Header: t('client:username'), accessor: "userName", sortable: false, filterable: false },
      { Header: t('client:branch'), accessor: "branchName", sortable: false, filterable: false },
      { Header: t('client:created_date'), accessor: "createdDate", sortable: false, filterable: false },
      { Header: t('client:type'), accessor: "type", sortable: false, filterable: false },
      { Header: t('client:loan'), accessor: "loanAmount", sortable: false, filterable: false },
      { Header: '', accessor: "actions", sortable: false, filterable: false }
    ]
  }

  private _onEdit(client: ClientDTO) {
    this.props.setEditingClient(client);
    history.push(URL.ClientDetail.replace(':id', client.id.toString()));
  }

  private _onDelete() {
    const { t } = this.props;

    this.setState({ showDeleteConfirm: false });
    this.props.deleteClient(this.state.deletingId).then((response: any) => {
      if (response && !response.errorCode) {
        toast.success(t('message:delete_success'));
        this._onRefresh();
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    });
  }

  private _mapData(data: ClientDTO[]) {
    const { t } = this.props;
    if (!data) return [];

    const types = [
      { value: 1, text: t('client:type_single')},
      { value: 2, text: t('client:type_multiple')},
      { value: 3, text: t('client:type_branch')},
    ];

    return data.map((c: ClientDTO) => {
      return {
        ...c,
        type: types.find(t => t.value === c.type)?.text || '',
        branchName: this.props.branchSelectItems.find(b => b.value === c.branchId)?.text ?? '',
        loanAmount: currency(c.loanAmount),
        actions: (
          <div className="actions-right">
            <IconButton className='small-action' onClick={this._onEdit.bind(this, c)}> <Edit /> </IconButton>
            <IconButton className='small-action' onClick={() => window.open(URL.ClientDetail.replace(':id', c.id.toString()), '_blank')}> <OpenInNew /> </IconButton>
            <IconButton className='small-action' onClick={() => this.setState({ showDeleteConfirm: true, deletingId: c.id })}> <Delete color='error' /> </IconButton>
          </div>
        )
      }
    });
  }

  private _onLoadData(tableState: any, instance: any) {
    this._getClientWithDebounce(this._getFilter(tableState));
  }

  private _onRefresh() {
    this._getClientWithDebounce(this._getFilter());
  }

  private _onExport() {
    this.setState({ isExporting: true });
    this.props.exportClient(this._getFilter()).then((response: any) => {
      this.setState({ isExporting: false });
      if (response && !response.errorCode) {
        window.open(response.data, '_blank');
      } else {
        showError(this.props.t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _onChangePage(pageIndex: any) {
    this.setState({ page: pageIndex});
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(Client)));