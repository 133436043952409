export class SupplierDTO {
  public id: number;
  public number: string;
  public name: string;
  public phone: string;
  public address: string;
  public email: string;
  public website: string;
  public contactName: string;
  public contactPhone: string;
  public bankId: number | null;
  public bankNumber: string;
  public group: number;
  public note: string;
  public amount: number;

  constructor() {
    this.id = 0;
    this.number = '';
    this.name = '';
    this.phone = '';
    this.address = '';
    this.email = '';
    this.website = '';
    this.contactName = '';
    this.contactPhone = '';
    this.bankId = 0;
    this.bankNumber = '';
    this.group = 0;
    this.note = '';
    this.amount = 0;
  }
}