import { createStore, applyMiddleware, compose, Store } from "redux";
import rootReducer from "./RootReducer";
import thunk from "redux-thunk";
import customAPI from './CustomAPI';

export default function configureStore(initialState: any, history: any): Store<any> {
  const middlewares = [thunk, customAPI()]
  const customCreateStore = compose(
    applyMiddleware(...middlewares),
  )(createStore);

  const store: Store<any> = customCreateStore(rootReducer, initialState);

  return store;
}