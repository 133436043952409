import * as React from 'react';
import classNames from "classnames";
import headerLinksStyle from './HeaderLinksStyle';
import { withStyles, IconButton, Hidden, ClickAwayListener, Paper, MenuList, MenuItem, Grow } from '@material-ui/core';
import { Manager, Target, Popper } from "react-popper";
import avatar from "../../Assets/images/avatar.jpg";
import { logout } from '../../Redux/User/UserReducer';
import { ActionCreator } from 'redux';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { CurrentUserDTO } from '../../DTO';
import { URL } from '../../Consts';
import history from '../../history';

interface IHeaderLinksProps extends WithTranslation {
  classes: any;

  currentUser: CurrentUserDTO;

  logout: ActionCreator<any>;
}

interface IHeaderLinksStates {
  open: boolean;
}

function mapStateToProps(state: any) {
  return ({
    currentUser: state.user.currentUser,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    logout: () => dispatch(logout()),
  })
}

class HeaderLinks extends React.Component<IHeaderLinksProps, IHeaderLinksStates> {

  constructor(props: IHeaderLinksProps) {
    super(props);

    this.state = {
      open: false
    }
  }

  render() {
    const { classes, t, currentUser } = this.props;
    let { open } = this.state;
    
    const dropdownItem =
      classes.dropdownItem +
      " " +
      classNames({
        [classes.dropdownItemRTL]: false
      });
    const wrapper = classNames({
      [classes.wrapperRTL]: false
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true
    });
    
    return (
      <div className={wrapper}>
        {/* <CustomInput
          rtlActive={false}
          formControlProps={{
            className: classes.top + " " + classes.search
          }}
          inputProps={{
            placeholder: "Search",
            inputProps: {
              "aria-label": "Search",
              className: classes.searchInput
            }
          }}
        />
        <SearchButton
          color="white"
          aria-label="edit"
          customClass={searchButton}
        >
          <Search className={classes.searchIcon} />
        </SearchButton> */}
        {/* <IconButton
          color="inherit"
          aria-label="Dashboard"
          classes={{
            label: ""
          }}
        >
          <Dashboard />
          <Hidden mdUp>
            <p className={classes.linkText}>
              Dashboard
            </p>
          </Hidden>
        </IconButton> */}
        <ClickAwayListener onClickAway={this._handleClose}>
          <Manager className={managerClasses}>
            <Target>
              {currentUser?.name ?? ''}
              <IconButton
                color="inherit"
                aria-label="Notifications"
                aria-owns={open ? "menu-list" : undefined}
                aria-haspopup="true"
                onClick={this._handleClick.bind(this)}
                className={false ? classes.buttonLinkRTL:classes.buttonLink}
                classes={{
                  label: false ? classes.labelRTL:""
                }}
              >
                {/* <div className={photo}> */}
                  <img src={currentUser?.avatar ? `/Upload/UserAvatar/${currentUser.avatar}` : avatar} className={classes.avatarImg} alt="..." />
                {/* </div> */}
                {/* <Notifications className={false ? classes.links + " " + classes.linksRTL:classes.links} /> */}
                <Hidden mdUp>
                  <p onClick={this._handleClick.bind(this)} className={classes.linkText}>
                    {currentUser?.name ?? ''}
                  </p>
                </Hidden>
              </IconButton>
            </Target>
            <Popper
              placement="bottom-start"
              eventsEnabled={open}
              className={
                classNames({ [classes.popperClose]: !open }) +
                " " +
                classes.pooperResponsive
              }>
              <Grow
                in={open}
                // id="menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={this._userInfo.bind(this)}
                      className={dropdownItem}
                    >
                      {t('user:personal')}
                    </MenuItem>
                    <MenuItem
                      onClick={this._handleClose}
                      className={dropdownItem}
                      disabled
                    >
                      {t('user:setting')}
                    </MenuItem>
                    <MenuItem
                      onClick={this._logout.bind(this)}
                      className={dropdownItem}
                    >
                      {t('button:logout')}
                    </MenuItem>
                  </MenuList>
                </Paper>
              </Grow>
            </Popper>
          </Manager>     
        </ClickAwayListener>
      </div>
    )
  }

  private _handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  private _handleClose = () => {
    this.setState({ open: false });
  };

  private _userInfo() {
    history.push(URL.UserInfo);
    this.setState({ open: false });
  }

  private _logout() {
    this.props.logout().then(() => {
      window.location.reload();
    });
    this.setState({ open: false });
  };

}


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(headerLinksStyle)(HeaderLinks)));