import { FilterDTO, BranchDTO } from '../../DTO';

const BRANCH_REQUEST: string = 'BRANCH_REQUEST';

const BRANCH_GET_LIST_SUCCESS: string = 'BRANCHGET_LIST_SUCCESS';
const BRANCH_GET_LIST_FAIL: string = 'BRANCH_GET_LIST_FAIL';

const BRANCH_SET_EDITING_BRANCH: string = 'BRANCH_SET_EDITING_BRANCH';

const BRANCH_GET_BY_ID_SUCCESS: string = 'BRANCH_GET_BY_ID_SUCCESS';
const BRANCH_GET_BY_ID_FAIL: string = 'BRANCH_GET_BY_ID_FAIL';

const BRANCH_SAVE_SUCCESS: string = 'BRANCH_SAVE_SUCCESS';
const BRANCH_SAVE_FAIL: string = 'BRANCH_SAVE_FAIL';

const BRANCH_DELETE_SUCCESS: string = 'BRANCH_DELETE_SUCCESS';
const BRANCH_DELETE_FAIL: string = 'BRANCH_DELETE_FAIL';

const BRANCH_GET_NAMES_SUCCESS: string = 'BRANCH_GET_NAMES_SUCCESS';
const BRANCH_GET_NAMES_FAIL: string = 'BRANCH_GET_NAMES_SUCCESS';

const initialState = {
  editingBranch: {},
  branchSelectItems: [],
}

export function branchReducer(state = initialState, action: any) {

  switch (action.type) {

    case BRANCH_SET_EDITING_BRANCH:
      return Object.assign({}, state, {
        editingBranch: action.payload
      });

    case BRANCH_GET_NAMES_SUCCESS:
      return Object.assign({}, state, {
        branchSelectItems: action.response?.data ? action.response.data : []
      })

    default:
      return state;

  }

}

export function getListBranch(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [BRANCH_REQUEST, BRANCH_GET_LIST_SUCCESS, BRANCH_GET_LIST_FAIL],
      EndPoint: `Branches${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingBranch(branch: BranchDTO) {
  return {
    type: BRANCH_SET_EDITING_BRANCH,
    payload: branch
  }
}

export function getBranchById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [BRANCH_REQUEST, BRANCH_GET_BY_ID_SUCCESS, BRANCH_GET_BY_ID_FAIL],
      EndPoint: `Branches\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveBranch(branch: BranchDTO) {
  let initRequest: RequestInit = {
    method: branch.id ? 'PATCH' : 'POST',
    body: JSON.stringify(branch),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [BRANCH_REQUEST, BRANCH_SAVE_SUCCESS, BRANCH_SAVE_FAIL],
      EndPoint: 'Branches',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteBranch(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [BRANCH_REQUEST, BRANCH_DELETE_SUCCESS, BRANCH_DELETE_FAIL],
      EndPoint: 'Branches',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function getBranchSelectItems() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [BRANCH_REQUEST, BRANCH_GET_NAMES_SUCCESS, BRANCH_GET_NAMES_FAIL],
      EndPoint: 'Branches/selectList',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}