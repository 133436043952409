import { FilterDTO, CashCollectionSettingDTO } from '../../DTO';

const TRANSFER_SETTING_REQUEST: string = 'TRANSFER_SETTING_REQUEST';

const TRANSFER_SETTING_GET_LIST_SUCCESS: string = 'TRANSFER_SETTING_GET_LIST_SUCCESS';
const TRANSFER_SETTING_GET_LIST_FAIL: string = 'TRANSFER_SETTING_GET_LIST_FAIL';

const TRANSFER_SETTING_SET_EDITING_TRANSFER_SETTING: string = 'TRANSFER_SETTING_SET_EDITING_TRANSFER_SETTING';

const TRANSFER_SETTING_GET_BY_ID_SUCCESS: string = 'TRANSFER_SETTING_GET_BY_ID_SUCCESS';
const TRANSFER_SETTING_GET_BY_ID_FAIL: string = 'TRANSFER_SETTING_GET_BY_ID_FAIL';

const TRANSFER_SETTING_SAVE_SUCCESS: string = 'TRANSFER_SETTING_SAVE_SUCCESS';
const TRANSFER_SETTING_SAVE_FAIL: string = 'TRANSFER_SETTING_SAVE_FAIL';

const TRANSFER_SETTING_DELETE_SUCCESS: string = 'TRANSFER_SETTING_DELETE_SUCCESS';
const TRANSFER_SETTING_DELETE_FAIL: string = 'TRANSFER_SETTING_DELETE_FAIL';

const TRANSFER_SETTING_GET_NAMES_SUCCESS: string = 'TRANSFER_SETTING_GET_NAMES_SUCCESS';
const TRANSFER_SETTING_GET_NAMES_FAIL: string = 'TRANSFER_SETTING_GET_NAMES_FAIL';

const initialState = {
  editingCashCollectionSetting: {},
  cashCollectionSettingSelectItems: [],
}

export function cashCollectionSettingReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case TRANSFER_SETTING_SET_EDITING_TRANSFER_SETTING:
      return Object.assign({}, state, {
        editingCashCollectionSetting: action.payload
      });

    case TRANSFER_SETTING_GET_NAMES_SUCCESS:
      return Object.assign({}, state, {
        cashCollectionSettingSelectItems: action.response?.data ?? []
      });

    default:
      return state;

  }

}

export function getListCashCollectionSetting(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_SETTING_REQUEST, TRANSFER_SETTING_GET_LIST_SUCCESS, TRANSFER_SETTING_GET_LIST_FAIL],
      EndPoint: `CashCollectionSettings${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingCashCollectionSetting(cashCollectionSetting: CashCollectionSettingDTO) {
  return {
    type: TRANSFER_SETTING_SET_EDITING_TRANSFER_SETTING,
    payload: cashCollectionSetting
  }
}

export function getCashCollectionSettingById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_SETTING_REQUEST, TRANSFER_SETTING_GET_BY_ID_SUCCESS, TRANSFER_SETTING_GET_BY_ID_FAIL],
      EndPoint: `CashCollectionSettings\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveCashCollectionSetting(cashCollectionSetting: CashCollectionSettingDTO) {
  let initRequest: RequestInit = {
    method: cashCollectionSetting.id ? 'PATCH' : 'POST',
    body: JSON.stringify(cashCollectionSetting),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_SETTING_REQUEST, TRANSFER_SETTING_SAVE_SUCCESS, TRANSFER_SETTING_SAVE_FAIL],
      EndPoint: 'CashCollectionSettings',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteCashCollectionSetting(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_SETTING_REQUEST, TRANSFER_SETTING_DELETE_SUCCESS, TRANSFER_SETTING_DELETE_FAIL],
      EndPoint: 'CashCollectionSettings',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function getCashCollectionSettingSelectItems() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_SETTING_REQUEST, TRANSFER_SETTING_GET_NAMES_SUCCESS, TRANSFER_SETTING_GET_NAMES_FAIL],
      EndPoint: 'CashCollectionSettings/selectList',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}