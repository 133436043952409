import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL, GlobalConst } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, GridItem, IconCard, CustomInput, Button, Danger, DataList } from '../../Components';
import { Assignment, People, Save, KeyboardArrowLeft, Add, Delete } from '@material-ui/icons';
import { FormLabel, withStyles, TextField, IconButton } from '@material-ui/core';
import styles from '../../styles';
import { OrderDTO, ProductDTO, OrderItemDTO } from '../../DTO';
import { getOrderById, saveOrder } from '../../Redux/Order/OrderReducer';
import { showError, currency } from '../../Utils';
import { toast } from 'react-toastify';
import Datetime from 'react-datetime';
import history from '../../history';
import { Moment } from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getAllProduct } from '../../Redux/Product/ProductReducer';
let moment = require('moment');

interface IOrderDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  order: OrderDTO;
  allProducts: ProductDTO[],

  setActiveRoute: ActionCreator<any>,
  getOrderById: ActionCreator<any>,
  saveOrder: ActionCreator<any>,
  getAllProduct: ActionCreator<any>,
}

interface IOrderDetailStates {
  isSaving?: boolean;

  order: OrderDTO;
  showValidation?: boolean;
  
  items: OrderItemDTO[],
}

function mapStateToProps(state: any) {
  return ({
    order: state.order.editingOrder,
    allProducts: state.product.allProducts,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getOrderById: (id: number) => dispatch(getOrderById(id)),
    saveOrder: (order: OrderDTO) => dispatch(saveOrder(order)),
    getAllProduct: () => dispatch(getAllProduct()),
  })
}

class OrderDetail extends React.Component<IOrderDetailProps, IOrderDetailStates> {

  constructor(props: IOrderDetailProps) {
    super(props);
    this.state = {
      order: {...new OrderDTO(), ...props.order},
      items: [],
    }
  }

  componentDidMount() {
    const { setActiveRoute, match, getOrderById, t } = this.props;

    setActiveRoute([{path: URL.Order, name: this.props.t('menu:order')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getOrderById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.Order);
          } else {
            this.setState({ order: response.data });
          }
        } else {
          showError(t, response.errorCode, response.errorMessage);
        }
      });
    }

    if (!this.props.allProducts?.length) {
      this.props.getAllProduct().then((response: any) => {
        if (response.data.errorCode) {
          showError(t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes } = this.props;
    let { showValidation, order: { dateRelease }, items } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <IconCard
            icon={People}
            iconColor={'green'}
            title={t('common:information')}>

            <GridContainer>
              <GridItem xs={12} sm={6}>
                <GridContainer>
                  <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('order:number')}
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={9}>
                    <CustomInput
                      inputProps={{
                        value: this.state.order.number,
                        name: 'number',
                        onChange: this._onTextChange.bind(this),
                        disabled: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem xs={12} sm={6}>
                <GridContainer>
                  <GridItem xs={12} sm={3}>
                    <FormLabel className={classes.labelHorizontal} required>
                      {t('order:date_release')}
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={9}>
                    <Datetime
                      dateFormat={GlobalConst.DATE_FORMAT}
                      timeFormat={false}
                      inputProps={{ placeholder: t('order:date_release') }}
                      onChange={this._onDateChange.bind(this)}
                      value={this.state.order.dateRelease}
                    />
                    {showValidation && !dateRelease && <Danger>{t('message:required')}</Danger>}
                  </GridItem>
                </GridContainer>
              </GridItem>

            </GridContainer>
          </IconCard>

          <IconCard 
            icon={Assignment}
            iconColor={'green'}
            title={t('order:item')}>

            <GridContainer>
              <GridItem xs={12} sm={12}>
                <div className={classes.right}>
                  <Button color='primary' customClass={classes.marginRight} round onClick={this._onAddOrderItem.bind(this)}>
                    <Add className={classes.icons} /> {t('order:add_item')}
                  </Button>                  
                </div>
              </GridItem>
            </GridContainer>

            <GridContainer>

              <GridItem xs={12}>
                <DataList 
                  currentPage={0}
                  pageSize={9999}
                  totalPage={1}
                  data={this._mapData(items)}
                  columns={this._getColumns()}
                />
              </GridItem>

            </GridContainer>

            <GridContainer justify="center">
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false)} disabled={this.state.isSaving}>
                <Save className={classes.icons} /> {t('button:save')}
              </Button>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true)} disabled={this.state.isSaving}>
                <Save className={classes.icons} /> {t('button:save_close')}
              </Button>
              <Button customClass={classes.marginRight} round onClick={() => history.push(URL.Order)}>
                <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
              </Button>
            </GridContainer>

          </IconCard>

        </GridItem>
      </GridContainer>
    )
  }

  private _getColumns() {
    const { t } = this.props;
    return [
      { Header: t('product:name'), accessor: "productName", sortable: false, filterable: false },
      { Header: t('order:item_quantity'), accessor: 'quantity', sortable: false, filterable: false },
      { Header: t('order:item_price'), accessor: 'price', sortable: false, filterable: false },
      { Header: t('order:total'), accessor: 'total', sortable: false, filterable: false },
      { Header: '', accessor: "actions", sortable: false, filterable: false }
    ]
  }

  private _mapData(data: OrderItemDTO[]) {
    if (!data) return [];

    // Append last row
    return data.map((i: OrderItemDTO, idx: number) => {
      return {
        productName: <Autocomplete
          id="combo-box-demo"
          value={this.props.allProducts?.find(p => p.id === i.productId)}
          options={this.props.allProducts || []}
          onChange={this._onChangeOrderProductItem.bind(this, idx)}
          defaultValue={this.props.allProducts.find(p => p.id === i.productId)}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} margin="normal" />}
          disableClearable
        />,
        quantity: <CustomInput
          inputProps={{
            value: this.state.items[idx].quantity,
            name: 'quantity',
            type: 'number',
            onChange: this._onChangeProduct.bind(this, idx),
          }} />,
        price: currency(i.price),
        total: currency(i.price * this.state.items[idx].quantity),
        actions: (
          <div className="actions-right">
            <IconButton className='small-action' onClick={this._onDeleteProduct.bind(this, idx)} > <Delete color='error' /> </IconButton>
          </div>
        )
      }
    });
  }

  private _onChangeProduct(idx: number, event: any) {
    event.persist();

    let items = this.state.items;
    items[idx].quantity = event.target.value;
    this.setState({ items: items });
  }

  private _onDeleteProduct(idx: number) {
    let items = this.state.items;
    items.splice(idx, 1);
    
    this.setState({ items: items });
  }

  private _onChangeOrderProductItem(idx: number, event: any, values: ProductDTO | null) {
    let items = this.state.items;
    items[idx].productId = values?.id || 0;
    items[idx].price = values?.salePrice || 0;

    this.setState({ items: items })
  }

  private _onAddOrderItem() {
    const { t } = this.props;

    let items = this.state.items || [];
    if (items && items.length >= 100) {
      toast.warn(t('order:max_item_reached'));
      return;
    }

    items.push(new OrderItemDTO());
    this.setState({ items: items });
  }

  private _onTextChange(event: any) {
    event.persist();
    const { target: { name, value } } = event;
    let order = this.state.order;
    this.setState({ order: {...order, [name]: value }});    
  }

  public _onDateChange(value: string | Moment) {
    let order = this.state.order;
    order.dateRelease = moment(value).format(GlobalConst.DATE_FORMAT);
    this.setState({ order: order });
  }

  private _onValidate() {
    let { order: { dateRelease } } = this.state;

    return dateRelease;
  }

  private _onSave(close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { order: { number } } = this.state;
    let order = new OrderDTO();
    order.id = parseInt(this.props.match.params.id);
    order.number = number;

    this.props.saveOrder(order).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        order.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close) {
          history.push(URL.Order);
        } else if (parseInt(match.params.id) === 0) {
          history.push(URL.OrderDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(OrderDetail)));