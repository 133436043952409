import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer } from '../../Components';
import { Assignment, Save, KeyboardArrowLeft } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getCashCollectionProductById, saveCashCollectionProduct } from '../../Redux/CashCollectionProduct/CashCollectionProductReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { CashCollectionProductDTO } from '../../DTO';

interface ICashCollectionProductDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  cashCollectionProduct: CashCollectionProductDTO;

  setActiveRoute: ActionCreator<any>,
  getCashCollectionProductById: ActionCreator<any>,
  saveCashCollectionProduct: ActionCreator<any>,
}

interface ICashCollectionProductDetailStates {
  isSaving?: boolean;

  cashCollectionProduct: CashCollectionProductDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    cashCollectionProduct: state.cashCollectionProduct.editingCashCollectionProduct,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getCashCollectionProductById: (id: number) => dispatch(getCashCollectionProductById(id)),
    saveCashCollectionProduct: (cashCollectionProduct: CashCollectionProductDTO) => dispatch(saveCashCollectionProduct(cashCollectionProduct)),
  })
}

class CashCollectionProductDetail extends React.Component<ICashCollectionProductDetailProps, ICashCollectionProductDetailStates> {

  constructor(props: ICashCollectionProductDetailProps) {
    super(props);
    this.state = {
      cashCollectionProduct: {...new CashCollectionProductDTO(), ...props.cashCollectionProduct}
    }
  }

  componentDidMount() {
    const { setActiveRoute, match, getCashCollectionProductById, t } = this.props;

    setActiveRoute([{path: URL.CashCollectionProduct, name: this.props.t('menu:cashCollectionProduct')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getCashCollectionProductById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.CashCollectionProduct);
          } else {
            this.setState({ cashCollectionProduct: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes } = this.props;
    let { showValidation, cashCollectionProduct: { name, number } } = this.state;

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={Assignment}
          iconColor={'green'}
          title={t('common:information')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('cashCollectionProduct:number')} </FormLabel>
              <>
                <InputText name='number' values={number} onChange={this._onTextChange.bind(this)} />
                {showValidation && !number && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('cashCollectionProduct:name')} </FormLabel>
              <>
                <InputText name='name' values={name} onChange={this._onTextChange.bind(this)} />
                {showValidation && !name && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

          </ColumnContainer>

          <GridContainer justify="center">
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button>
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button>
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.CashCollectionProduct)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ cashCollectionProduct: {...this.state.cashCollectionProduct, [name]: value }});    
  }

  private _onValidate() {
    let { cashCollectionProduct: { name, number } } = this.state;

    return name && number;
  }

  private _onSave(close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { cashCollectionProduct: { name, number } } = this.state;
    let cashCollectionProduct = new CashCollectionProductDTO();
    cashCollectionProduct.id = parseInt(this.props.match.params.id);
    cashCollectionProduct.name = name;
    cashCollectionProduct.number = number;

    this.props.saveCashCollectionProduct(cashCollectionProduct).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        cashCollectionProduct.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close) {
          history.push(URL.CashCollectionProduct);
        } else if (parseInt(match.params.id) === 0) {
          history.push(URL.CashCollectionProductDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(CashCollectionProductDetail)));