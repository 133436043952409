import { FilterDTO, AutoNumberDTO } from '../../DTO';

const AUTO_NUMBER_REQUEST: string = 'AUTO_NUMBER_REQUEST';

const AUTO_NUMBER_GET_LIST_SUCCESS: string = 'AUTO_NUMBER_GET_LIST_SUCCESS';
const AUTO_NUMBER_GET_LIST_FAIL: string = 'AUTO_NUMBER_GET_LIST_FAIL';

const AUTO_NUMBER_SET_EDITING_AUTO_NUMBER: string = 'AUTO_NUMBER_SET_EDITING_AUTO_NUMBER';

const AUTO_NUMBER_GET_BY_ID_SUCCESS: string = 'AUTO_NUMBER_GET_BY_ID_SUCCESS';
const AUTO_NUMBER_GET_BY_ID_FAIL: string = 'AUTO_NUMBER_GET_BY_ID_FAIL';

const AUTO_NUMBER_SAVE_SUCCESS: string = 'AUTO_NUMBER_SAVE_SUCCESS';
const AUTO_NUMBER_SAVE_FAIL: string = 'AUTO_NUMBER_SAVE_FAIL';

const AUTO_NUMBER_DELETE_SUCCESS: string = 'AUTO_NUMBER_DELETE_SUCCESS';
const AUTO_NUMBER_DELETE_FAIL: string = 'AUTO_NUMBER_DELETE_FAIL';

const initialState = {
  editingAutoNumber: {},
}

export function autoNumberReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case AUTO_NUMBER_SET_EDITING_AUTO_NUMBER:
      return Object.assign({}, state, {
        editingAutoNumber: action.payload
      });

    default:
      return state;

  }

}

export function getListAutoNumber(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [AUTO_NUMBER_REQUEST, AUTO_NUMBER_GET_LIST_SUCCESS, AUTO_NUMBER_GET_LIST_FAIL],
      EndPoint: `AutoNumbers${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingAutoNumber(autoNumber: AutoNumberDTO) {
  return {
    type: AUTO_NUMBER_SET_EDITING_AUTO_NUMBER,
    payload: autoNumber
  }
}

export function getAutoNumberById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [AUTO_NUMBER_REQUEST, AUTO_NUMBER_GET_BY_ID_SUCCESS, AUTO_NUMBER_GET_BY_ID_FAIL],
      EndPoint: `AutoNumbers\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveAutoNumber(autoNumber: AutoNumberDTO) {
  let initRequest: RequestInit = {
    method: autoNumber.id ? 'PATCH' : 'POST',
    body: JSON.stringify(autoNumber),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [AUTO_NUMBER_REQUEST, AUTO_NUMBER_SAVE_SUCCESS, AUTO_NUMBER_SAVE_FAIL],
      EndPoint: 'AutoNumbers',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteAutoNumber(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [AUTO_NUMBER_REQUEST, AUTO_NUMBER_DELETE_SUCCESS, AUTO_NUMBER_DELETE_FAIL],
      EndPoint: 'AutoNumbers',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}