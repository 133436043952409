import { GlobalConst } from "../Consts";
let moment = require('moment');

export class OrderDTO {
  public id: number;
  public number: string;
  public dateRelease: string;

  constructor() {
    this.id = 0;
    this.number = '';
    this.dateRelease = moment().format(GlobalConst.DATE_FORMAT);
  }
}

export class OrderItemDTO {
  public id: number;
  public productId: number;
  public quantity: number;
  public price: number;

  constructor() {
    this.id = 0;
    this.productId = 0;
    this.quantity = 1;
    this.price = 0;
  }
}