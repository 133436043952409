import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer } from '../../Components';
import { People, Save, KeyboardArrowLeft } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getTransferSettingById, saveTransferSetting } from '../../Redux/TransferSetting/TransferSettingReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { TransferSettingDTO } from '../../DTO';

interface ITransferSettingDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  transferSetting: TransferSettingDTO;

  setActiveRoute: ActionCreator<any>,
  getTransferSettingById: ActionCreator<any>,
  saveTransferSetting: ActionCreator<any>,
}

interface ITransferSettingDetailStates {
  isSaving?: boolean;

  transferSetting: TransferSettingDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    transferSetting: state.transferSetting.editingTransferSetting,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getTransferSettingById: (id: number) => dispatch(getTransferSettingById(id)),
    saveTransferSetting: (transferSetting: TransferSettingDTO) => dispatch(saveTransferSetting(transferSetting)),
  })
}

class TransferSettingDetail extends React.Component<ITransferSettingDetailProps, ITransferSettingDetailStates> {

  constructor(props: ITransferSettingDetailProps) {
    super(props);
    this.state = {
      transferSetting: {...new TransferSettingDTO(), ...props.transferSetting}
    }
  }

  componentDidMount() {
    const { setActiveRoute, match, getTransferSettingById, t } = this.props;

    setActiveRoute([{path: URL.TransferSetting, name: this.props.t('menu:transferSetting')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getTransferSettingById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.TransferSetting);
          } else {
            this.setState({ transferSetting: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes } = this.props;
    let { showValidation, transferSetting: { name, number, fromAmount, toAmount, salePrice, multipleSalePrice, commission, note } } = this.state;

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={People}
          iconColor={'green'}
          title={t('common:information')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferSetting:name')} </FormLabel>
              <>
                <InputText name='name' values={name} onChange={this._onTextChange.bind(this)} />
                {showValidation && !name && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>
            
            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferSetting:number')} </FormLabel>
              <>
                <InputText name='number' values={number} onChange={this._onTextChange.bind(this)} />
                {showValidation && !number && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferSetting:from_amount')} </FormLabel>
              <>
                <InputText name='fromAmount' values={fromAmount} numberOnly thousandSeparator onChange={this._onNumberChange.bind(this)} />
                {showValidation && !fromAmount && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferSetting:to_amount')} </FormLabel>
              <>
                <InputText name='toAmount' values={toAmount} numberOnly thousandSeparator onChange={this._onNumberChange.bind(this)} />
                {showValidation && !toAmount && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferSetting:sale_price')} </FormLabel>
              <>
                <InputText name='salePrice' values={salePrice} numberOnly thousandSeparator onChange={this._onNumberChange.bind(this)} />
                {showValidation && !salePrice && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferSetting:multiple_sale_price')} </FormLabel>
              <>
                <InputText name='multipleSalePrice' values={multipleSalePrice} numberOnly thousandSeparator onChange={this._onNumberChange.bind(this)} />
                {showValidation && !multipleSalePrice && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferSetting:commission')} </FormLabel>
              <>
                <InputText name='commission' values={commission} numberOnly thousandSeparator onChange={this._onNumberChange.bind(this)} />
                {showValidation && !commission && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferSetting:note')} </FormLabel>
              <InputText name='note' values={note} onChange={this._onTextChange.bind(this)} />
            </ColumnContainer>
          </ColumnContainer>

          <GridContainer justify="center">
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button>
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button>
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.TransferSetting)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ transferSetting: {...this.state.transferSetting, [name]: value }});    
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ transferSetting: {...this.state.transferSetting, [name]: value }});    
  }

  private _onValidate() {
    let { transferSetting: { name, number, fromAmount, toAmount, salePrice, multipleSalePrice, commission } } = this.state;

    return name && number && fromAmount && toAmount && salePrice && multipleSalePrice && commission;
  }

  private _onSave(close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { transferSetting: { name, number, fromAmount, toAmount, salePrice, multipleSalePrice, commission, note } } = this.state;
    let transferSetting = new TransferSettingDTO();
    transferSetting.id = parseInt(this.props.match.params.id);
    transferSetting.name = name;
    transferSetting.number = number;
    transferSetting.fromAmount = fromAmount;
    transferSetting.toAmount = toAmount;
    transferSetting.salePrice = salePrice;
    transferSetting.multipleSalePrice = multipleSalePrice;
    transferSetting.commission = commission;
    transferSetting.note = note;

    this.props.saveTransferSetting(transferSetting).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        transferSetting.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close) {
          history.push(URL.TransferSetting);
        } else if (parseInt(match.params.id) === 0) {
          history.push(URL.TransferSettingDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(TransferSettingDetail)));