import { FilterDTO, CashCollectionDTO, cashCollectionToFormData } from '../../DTO';

const CASH_COLLECTION_REQUEST: string = 'CASH_COLLECTION_REQUEST';

const CASH_COLLECTION_GET_LIST_SUCCESS: string = 'CASH_COLLECTION_GET_LIST_SUCCESS';
const CASH_COLLECTION_GET_LIST_FAIL: string = 'CASH_COLLECTION_GET_LIST_FAIL';

const CASH_COLLECTION_SET_EDITING_CASH_COLLECTION: string = 'CASH_COLLECTION_SET_EDITING_CASH_COLLECTION';

const CASH_COLLECTION_GET_BY_ID_SUCCESS: string = 'CASH_COLLECTION_GET_BY_ID_SUCCESS';
const CASH_COLLECTION_GET_BY_ID_FAIL: string = 'CASH_COLLECTION_GET_BY_ID_FAIL';

const CASH_COLLECTION_SAVE_SUCCESS: string = 'CASH_COLLECTION_SAVE_SUCCESS';
const CASH_COLLECTION_SAVE_FAIL: string = 'CASH_COLLECTION_SAVE_FAIL';

const CASH_COLLECTION_DELETE_SUCCESS: string = 'CASH_COLLECTION_DELETE_SUCCESS';
const CASH_COLLECTION_DELETE_FAIL: string = 'CASH_COLLECTION_DELETE_FAIL';

const CASH_COLLECTION_APPROVE_SUCCESS: string = 'CASH_COLLECTION_APPROVE_SUCCESS';
const CASH_COLLECTION_APPROVE_FAIL: string = 'CASH_COLLECTION_APPROVE_FAIL';

const CASH_COLLECTION_CANCEL_SUCCESS: string = 'CASH_COLLECTION_CANCEL_SUCCESS';
const CASH_COLLECTION_CANCEL_FAIL: string = 'CASH_COLLECTION_CANCEL_FAIL';

const CASH_COLLECTION_EXPORT_LIST_SUCCESS: string = 'CASH_COLLECTION_EXPORT_LIST_SUCCESS';
const CASH_COLLECTION_EXPORT_LIST_FAIL: string = 'CASH_COLLECTION_EXPORT_LIST_FAIL';

const CASH_COLLECTION_PRINT_SUCCESS: string = 'CASH_COLLECTION_PRINT_SUCCESS';
const CASH_COLLECTION_PRINT_FAIL: string  = 'CASH_COLLECTION_PRINT_FAIL';

const initialState = {
  editingCashCollection: {},
}

export function cashCollectionReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case CASH_COLLECTION_SET_EDITING_CASH_COLLECTION:
      return Object.assign({}, state, {
        editingCashCollection: action.payload
      });

    default:
      return state;

  }

}

export function getListCashCollection(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CASH_COLLECTION_REQUEST, CASH_COLLECTION_GET_LIST_SUCCESS, CASH_COLLECTION_GET_LIST_FAIL],
      EndPoint: `CashCollections${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingCashCollection(cashCollection: CashCollectionDTO) {
  return {
    type: CASH_COLLECTION_SET_EDITING_CASH_COLLECTION,
    payload: cashCollection
  }
}

export function getCashCollectionById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CASH_COLLECTION_REQUEST, CASH_COLLECTION_GET_BY_ID_SUCCESS, CASH_COLLECTION_GET_BY_ID_FAIL],
      EndPoint: `CashCollections\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveCashCollection(cashCollection: CashCollectionDTO, submit: boolean = false, reference: any, confirmation: any) {
  let formData = cashCollectionToFormData(cashCollection);
  if (reference) {
    formData.append('reference', reference);
  }
  if (confirmation) {
    formData.append('confirmation', confirmation);
  }

  let initRequest: RequestInit = {
    method: cashCollection.id ? 'PATCH' : 'POST',
    body: formData,
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CASH_COLLECTION_REQUEST, CASH_COLLECTION_SAVE_SUCCESS, CASH_COLLECTION_SAVE_FAIL],
      EndPoint: `CashCollections\\${submit}`,
      AuthenticationRequire: true,
      Config: initRequest,
      IsUpload: true
    }
  }
}

export function deleteCashCollection(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CASH_COLLECTION_REQUEST, CASH_COLLECTION_DELETE_SUCCESS, CASH_COLLECTION_DELETE_FAIL],
      EndPoint: 'CashCollections',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function approveCashCollection(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CASH_COLLECTION_REQUEST, CASH_COLLECTION_APPROVE_SUCCESS, CASH_COLLECTION_APPROVE_FAIL],
      EndPoint: 'CashCollections\\Approve',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function cancelCashCollection(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CASH_COLLECTION_REQUEST, CASH_COLLECTION_CANCEL_SUCCESS, CASH_COLLECTION_CANCEL_FAIL],
      EndPoint: 'CashCollections\\Cancel',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function exportCashCollection(filterDTO: FilterDTO) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CASH_COLLECTION_REQUEST, CASH_COLLECTION_EXPORT_LIST_SUCCESS, CASH_COLLECTION_EXPORT_LIST_FAIL],
      EndPoint: `CashCollections\\export${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function printCashCollection(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CASH_COLLECTION_REQUEST, CASH_COLLECTION_PRINT_SUCCESS, CASH_COLLECTION_PRINT_FAIL],
      EndPoint: `CashCollections\\print\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest,
      ResponseHtml : true
    }
  }
}