import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL, TransferStatus, GlobalConst, Permission } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer, Autocomplete, Checkbox } from '../../Components';
import { SwapHoriz, Save, KeyboardArrowLeft, Clear } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getIncomeById, saveIncome, approveIncome, cancelIncome } from '../../Redux/Income/IncomeReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { IncomeDTO, SelectItemDTO, CurrentUserDTO } from '../../DTO';
import { getPaymentAccountSelectItems } from '../../Redux/PaymentAccount/PaymentAccountReducer';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';
import { getUserSelectItems } from '../../Redux/User/UserReducer';
let moment = require('moment');

interface IIncomeDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  currentUser: CurrentUserDTO;
  income: IncomeDTO;
  paymentAccountSelectItems: SelectItemDTO[];
  branchSelectItems: SelectItemDTO[];
  userSelectItems: SelectItemDTO[];

  setActiveRoute: ActionCreator<any>,
  getIncomeById: ActionCreator<any>,
  saveIncome: ActionCreator<any>,
  approveIncome: ActionCreator<any>,
  cancelIncome: ActionCreator<any>,
  getPaymentAccountSelectItems: ActionCreator<any>,
  getBranchSelectItems: ActionCreator<any>,
  getUserSelectItems: ActionCreator<any>,
}

interface IIncomeDetailStates {
  isSaving?: boolean;

  income: IncomeDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    income: state.income.editingIncome,
    paymentAccountSelectItems: state.paymentAccount.paymentAccountSelectItems,
    branchSelectItems: state.branch.branchSelectItems,
    userSelectItems: state.user.userSelectItems,
    currentUser: state.user.currentUser,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getIncomeById: (id: number) => dispatch(getIncomeById(id)),
    saveIncome: (income: IncomeDTO, submit: boolean) => dispatch(saveIncome(income, submit)),
    approveIncome: (id: number) => dispatch(approveIncome(id)),
    cancelIncome: (id: number) => dispatch(cancelIncome(id)),
    getPaymentAccountSelectItems: () => dispatch(getPaymentAccountSelectItems()),
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
    getUserSelectItems: () => dispatch(getUserSelectItems()),
  })
}

class IncomeDetail extends React.Component<IIncomeDetailProps, IIncomeDetailStates> {

  constructor(props: IIncomeDetailProps) {
    super(props);
    this.state = {
      income: {...new IncomeDTO(), ...props.income}
    }
  }

  componentDidMount() {
    !this.props.paymentAccountSelectItems?.length && this.props.getPaymentAccountSelectItems();
    !this.props.branchSelectItems?.length && this.props.getBranchSelectItems();
    !this.props.userSelectItems?.length && this.props.getUserSelectItems();

    const { setActiveRoute, match, getIncomeById, t } = this.props;

    setActiveRoute([{path: URL.Income, name: this.props.t('menu:income')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getIncomeById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.Income);
          } else {
            this.setState({ income: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes, currentUser, userSelectItems, branchSelectItems, paymentAccountSelectItems } = this.props;
    let { showValidation, income: { id, number, date, status, createdBy, fromBranchId, branchId, commissionUserId, isCash, amount, paymentAccountId, detail } } = this.state;

    const statusText = [
      { value: TransferStatus.Draft, text: t('income:status_draft')},
      { value: TransferStatus.PendingApprove, text: t('income:status_pending_approve')},
      { value: TransferStatus.Approved, text: t('income:status_approved')},
      { value: TransferStatus.Canceled, text: t('income:status_cancelled')},
    ]

    let statusStr = statusText.find(s => s.value === status)?.text || '';
    let tDate = date ? moment(date, GlobalConst.DATE_TIME_FORMAT) : moment(new Date());
    let now = moment(new Date());
    let readOnly = (status === TransferStatus.Approved || status === TransferStatus.Canceled) && (tDate.year() * 12 + tDate.month() < now.year() * 12 + now.month());

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={SwapHoriz}
          iconColor={'green'}
          title={t('common:information')}>

          {id && currentUser.functionIds.includes(Permission.ApproveIncomes) && status === TransferStatus.PendingApprove ? 
            <ColumnContainer columnWidth={[{xs:12, sm:12}]}>
              <div className={classes.right}>
                <Button color='primary' customClass={classes.marginRight} round onClick={this._onApprove.bind(this)} disabled={this.state.isSaving}>
                  <Save className={classes.icons} /> {t('button:approve')}
                </Button>
                <Button color='danger' customClass={classes.marginRight} round onClick={this._onCancel.bind(this)} disabled={this.state.isSaving}>
                  <Clear className={classes.icons} /> {t('button:cancel')}
                </Button>
              </div>
            </ColumnContainer>
          : null }

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('income:number')} </FormLabel>
              <InputText values={number} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('income:status')} </FormLabel>
              <InputText values={statusStr} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('income:date')} </FormLabel>
              {!readOnly ? 
                <Datetime
                  dateFormat={GlobalConst.DATE_FORMAT}
                  timeFormat={"HH:mm"}
                  inputProps={{ placeholder: t('income:date') }}
                  onChange={this._onDateChange.bind(this)}
                  value={date}
                /> : <InputText disabled values={date} /> }
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('income:created_by')} </FormLabel>
              <InputText disabled values={id === 0 ? currentUser.name : (userSelectItems.find(u => u.value === createdBy.toString())?.text ?? '')} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('income:from_branch')} </FormLabel>
              <>
                <Autocomplete
                  name='fromBranchId'
                  value={fromBranchId}
                  options={branchSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !fromBranchId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('income:is_cash')} </FormLabel>
              <Checkbox
                checked={isCash}
                name='isCash'
                onClick={this._onCheckboxChange.bind(this)}
                disabled={readOnly}
              />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('income:amount')} </FormLabel>
              <>
                <InputText 
                  name="amount"
                  numberOnly
                  thousandSeparator
                  values={amount} 
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly} />
                {showValidation && !amount && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('income:detail')} </FormLabel>
              <>
                <InputText name='detail' values={detail} onChange={this._onTextChange.bind(this)} />
              </>
            </ColumnContainer>

            {!isCash ? <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('income:payment_account')} </FormLabel>
              <>
                <Autocomplete
                  name='paymentAccountId'
                  value={paymentAccountId}
                  options={paymentAccountSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !isCash && !paymentAccountId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer> : null}

            {!isCash ? <> </> : null}

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('income:branch')} </FormLabel>
              <>
                <Autocomplete
                  name='branchId'
                  value={branchId}
                  options={branchSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !branchId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('income:commission_user')} </FormLabel>
              <>
                <Autocomplete
                  name='commissionUserId'
                  value={commissionUserId}
                  options={userSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !commissionUserId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

          </ColumnContainer>

          <GridContainer justify="center">
            {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button> : null}
            {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button> : null}
            {status === TransferStatus.Draft ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_submit')}
            </Button> : null}
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.Income)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ income: {...this.state.income, [name]: value }});    
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ income: {...this.state.income, [name]: value }});    
  }

  private _onDateChange(value: string | Moment) {
    let income = this.state.income;
    income.date = moment(value).format(GlobalConst.DATE_TIME_FORMAT);
    this.setState({ income: income });
  }

  private _onCheckboxChange(event: any) {
    event.persist && event.persist();
    const { target: { name, checked } } = event;
    let income = this.state.income;
    this.setState({ income: {...income, [name]: checked }});
  }

  private _onValidate() {
    let { income: { date, amount, isCash, paymentAccountId, fromBranchId, branchId, commissionUserId } } = this.state;

    return date && amount && (isCash || paymentAccountId) && fromBranchId && branchId && commissionUserId;
  }

  private _onSave(submit: boolean = false, close: boolean = false) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { income: { date, amount, detail, isCash, paymentAccountId, fromBranchId, branchId, commissionUserId } } = this.state;
    let income = new IncomeDTO();
    income.id = parseInt(this.props.match.params.id);
    income.date = date;
    income.amount = amount;
    income.detail = detail;
    income.isCash = isCash;
    if (!isCash) {
      income.paymentAccountId = paymentAccountId;
    }
    income.fromBranchId = fromBranchId;
    income.branchId = branchId;
    income.commissionUserId = commissionUserId;

    this.props.saveIncome(income, submit).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        income.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close || submit) {
          history.push(URL.Income);
        } else if (parseInt(match.params.id) === 0) {
          this.setState({ income: response.data });
          history.push(URL.IncomeDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

  private _onApprove() {
    const { t } = this.props;
    this.setState({ isSaving: true });

    this.props.approveIncome(this.state.income.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.Income);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

  private _onCancel() {
    const { t } = this.props;
    this.setState({ isSaving: true });
    
    this.props.cancelIncome(this.state.income.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.Income);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(IncomeDetail)));