import React from "react";

// material-ui components
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, withStyles } from '@material-ui/core'

// material-ui-icons
import { ExpandMore } from '@material-ui/icons';

import accordionStyle from "./AccordionStyle";

interface IAccordionProps {
  classes: any;
  active: number;
  collapses: any[];
}

interface IAccordionStates {
  active: number;
}

class Accordion extends React.Component<IAccordionProps, IAccordionStates> {

  public static defaultProps = {
    active: -1
  }

  constructor(props: IAccordionProps) {
    super(props);
    this.state = {
      active: props.active
    };
  }

  private _handleChange(value: number, event: any, expanded: boolean) {
    this.setState({ active: expanded ? value : -1 });
  }

  // handleChange = panel => (event, expanded) => {
  //   this.setState({
  //     active: expanded ? panel : -1
  //   });
  // };

  render() {
    const { classes, collapses } = this.props;
    return (
      <div className={classes.root}>
        {collapses.map((prop, key) => {
          return (
            <ExpansionPanel
              expanded={this.state.active === key}
              onChange={this._handleChange.bind(this, key)}
              key={key}
              classes={{
                root: classes.expansionPanel,
                expanded: classes.expansionPanelExpanded
              }}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                classes={{
                  root: classes.expansionPanelSummary,
                  expanded: classes.expansionPanelSummaryExpaned,
                  content: classes.expansionPanelSummaryContent,
                  expandIcon: classes.expansionPanelSummaryExpandIcon,
                  // expandIconExpanded: classes.expansionPanelSummaryExpandIconExpanded
                }}
              >
                <h4 className={classes.title}>{prop.title}</h4>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                {prop.content}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
      </div>
    );
  }
}

export default withStyles(accordionStyle)(Accordion);
