import React from 'react';
import { Checkbox as CheckboxCore, withStyles } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import styles from '../../styles';

interface ICheckboxProps {
  classes?: any;

  name?: string,
  checked: boolean,
  onClick: any;
  disabled?: boolean;
}

function Checkbox(props: ICheckboxProps) {
  return (
    <CheckboxCore
      tabIndex={-1}
      checked={props.checked}
      name={props.name}
      onClick={props.onClick}
      checkedIcon={
        <Check className={props.classes.checkedIcon} />
      }
      icon={<Check className={props.classes.uncheckedIcon} />}
      className={props.classes.checkbox}
      classes={{
        checked: props.classes.checked
      }}
      disabled={props.disabled}
    />
  )
}

export default withStyles(styles)(Checkbox);