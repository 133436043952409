import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL, TransferStatus, Permission, GlobalConst, ClientType } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer, Autocomplete, Checkbox, CustomSelect } from '../../Components';
import { People, Save, KeyboardArrowLeft, Clear } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getSellTelecomServiceById, saveSellTelecomService, approveSellTelecomService, cancelSellTelecomService } from '../../Redux/SellTelecomService/SellTelecomServiceReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { SellTelecomServiceDTO, SelectItemDTO, CurrentUserDTO } from '../../DTO';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';
import { getUserSelectItems } from '../../Redux/User/UserReducer';
import { getTelecomServiceSelectItems } from '../../Redux/TelecomService/TelecomServiceReducer';
import { getClientSelectItems } from '../../Redux/Client/ClientReducer';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
import { getSupplierSelectItems } from '../../Redux/Supplier/SupplierReducer';
import { getPaymentAccountSelectItems } from '../../Redux/PaymentAccount/PaymentAccountReducer';
let moment = require('moment');

interface ISellTelecomServiceDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  sellTelecomService: SellTelecomServiceDTO;
  currentUser: CurrentUserDTO;
  clientSelectItems: SelectItemDTO[];
  branchSelectItems: SelectItemDTO[];
  userSelectItems: SelectItemDTO[];
  telecomServiceSelectItems: SelectItemDTO[];
  supplierSelectItems: SelectItemDTO[];
  paymentAccountSelectItems: SelectItemDTO[];

  setActiveRoute: ActionCreator<any>,
  getSellTelecomServiceById: ActionCreator<any>,
  saveSellTelecomService: ActionCreator<any>,
  approveSellTelecomService: ActionCreator<any>,
  cancelSellTelecomService: ActionCreator<any>,
  getClientSelectItems: ActionCreator<any>,
  getBranchSelectItems: ActionCreator<any>,
  getUserSelectItems: ActionCreator<any>,
  getTelecomServiceSelectItems: ActionCreator<any>,
  getSupplierSelectItems: ActionCreator<any>,
  getPaymentAccountSelectItems: ActionCreator<any>,
}

interface ISellTelecomServiceDetailStates {
  isSaving?: boolean;

  sellTelecomService: SellTelecomServiceDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    sellTelecomService: state.sellTelecomService.editingSellTelecomService,
    clientSelectItems: state.client.clientSelectItems,
    telecomServiceSelectItems: state.telecomService.telecomServiceSelectItems,
    currentUser: state.user.currentUser,
    branchSelectItems: state.branch.branchSelectItems,
    userSelectItems: state.user.userSelectItems,
    supplierSelectItems: state.supplier.supplierSelectItems,
    paymentAccountSelectItems: state.paymentAccount.paymentAccountSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getSellTelecomServiceById: (id: number) => dispatch(getSellTelecomServiceById(id)),
    saveSellTelecomService: (sellTelecomService: SellTelecomServiceDTO, submit: boolean) => dispatch(saveSellTelecomService(sellTelecomService, submit)),
    approveSellTelecomService: (id: number) => dispatch(approveSellTelecomService(id)),
    cancelSellTelecomService: (id: number) => dispatch(cancelSellTelecomService(id)),
    getClientSelectItems: () => dispatch(getClientSelectItems()),
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
    getUserSelectItems: () => dispatch(getUserSelectItems()),
    getTelecomServiceSelectItems: () => dispatch(getTelecomServiceSelectItems()),
    getSupplierSelectItems: () => dispatch(getSupplierSelectItems()),
    getPaymentAccountSelectItems: () => dispatch(getPaymentAccountSelectItems()),
  })
}

class SellTelecomServiceDetail extends React.Component<ISellTelecomServiceDetailProps, ISellTelecomServiceDetailStates> {

  constructor(props: ISellTelecomServiceDetailProps) {
    super(props);
    this.state = {
      sellTelecomService: {...new SellTelecomServiceDTO(), ...props.sellTelecomService}
    }
  }

  componentDidMount() {
    !this.props.clientSelectItems?.length && this.props.getClientSelectItems();
    !this.props.branchSelectItems?.length && this.props.getBranchSelectItems();
    !this.props.userSelectItems?.length && this.props.getUserSelectItems();
    !this.props.telecomServiceSelectItems?.length && this.props.getTelecomServiceSelectItems();
    !this.props.supplierSelectItems?.length && this.props.getSupplierSelectItems();
    !this.props.paymentAccountSelectItems?.length && this.props.getPaymentAccountSelectItems();

    const { setActiveRoute, match, getSellTelecomServiceById, t } = this.props;

    setActiveRoute([{path: URL.SellTelecomService, name: this.props.t('menu:sellTelecomService')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getSellTelecomServiceById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.SellTelecomService);
          } else {
            this.setState({ sellTelecomService: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes, currentUser, userSelectItems, clientSelectItems, branchSelectItems, telecomServiceSelectItems, supplierSelectItems, paymentAccountSelectItems } = this.props;
    let { showValidation, sellTelecomService: { id, number, status, sellDate, serviceId, supplierId, phone, clientId, price, amount, discount, commission, branchId, commissionUserId, createdBy, payByCash, receiveAccountId } } = this.state;

    const types = [
      { value: 1, text: t('client:type_single')},
      { value: 2, text: t('client:type_multiple')},
      { value: 3, text: t('client:type_branch')},
    ];

    const statusText = [
      { value: TransferStatus.Draft, text: t('sellTelecomService:status_draft')},
      { value: TransferStatus.PendingApprove, text: t('sellTelecomService:status_pending_approve')},
      { value: TransferStatus.Approved, text: t('sellTelecomService:status_approved')},
      { value: TransferStatus.Canceled, text: t('sellTelecomService:status_cancel')},
    ]

    let statusStr = statusText.find(s => s.value === status)?.text || '';
    let tDate = sellDate ? moment(sellDate, GlobalConst.DATE_TIME_FORMAT) : moment(new Date());
    let now = moment(new Date());
    let readOnly = (status === TransferStatus.Approved || status === TransferStatus.Canceled) && (tDate.year() * 12 + tDate.month() < now.year() * 12 + now.month());

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={People}
          iconColor={'green'}
          title={t('common:information')}>

          {id && currentUser.functionIds.includes(Permission.ApproveSellTelecomServices) && status === TransferStatus.PendingApprove ? 
            <ColumnContainer columnWidth={[{xs:12, sm:12}]}>
              <div className={classes.right}>
                <Button color='primary' customClass={classes.marginRight} round onClick={this._onApprove.bind(this)} disabled={this.state.isSaving}>
                  <Save className={classes.icons} /> {t('button:approve')}
                </Button>
                <Button color='danger' customClass={classes.marginRight} round onClick={this._onCancel.bind(this)} disabled={this.state.isSaving}>
                  <Clear className={classes.icons} /> {t('button:cancel')}
                </Button>
              </div>
            </ColumnContainer>
          : null }

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('sellTelecomService:number')} </FormLabel>
              <InputText values={number} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('sellTelecomService:status')} </FormLabel>
              <InputText values={statusStr} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('sellTelecomService:date')} </FormLabel>
              {!readOnly ? 
                <Datetime
                  dateFormat={GlobalConst.DATE_FORMAT}
                  timeFormat={"HH:mm"}
                  inputProps={{ placeholder: t('sellTelecomService:date') }}
                  onChange={this._onDateChange.bind(this)}
                  value={sellDate}
                /> : <InputText disabled values={sellDate} /> }
            </ColumnContainer>
            
            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('sellTelecomService:user')} </FormLabel>
              <InputText disabled values={id === 0 ? currentUser.name : userSelectItems.find(u => u.value === createdBy.toString())?.text ?? ''} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('sellTelecomService:service')} </FormLabel>
              <>
                <Autocomplete
                  value={serviceId}
                  options={telecomServiceSelectItems}
                  onChange={this._onServiceChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !serviceId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>
            
            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('sellTelecomService:supplier')} </FormLabel>
              <>
                <Autocomplete
                  value={supplierId}
                  options={supplierSelectItems}
                  onChange={this._onSupplierChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !supplierId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('sellTelecomService:phone')} </FormLabel>
              <>
                <InputText name='phone' values={phone} onChange={this._onTextChange.bind(this)} />
              </>
            </ColumnContainer>

            <> </>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('sellTelecomService:client')} </FormLabel>
              <>
                <Autocomplete
                  value={clientId}
                  options={clientSelectItems}
                  onChange={this._onClientChange.bind(this)}
                  getOptionLabel={(option: SelectItemDTO) => `${option.text} - ${option.details.phone}`}
                  disabled={readOnly}
                />
                {showValidation && !clientId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('client:type')} </FormLabel>
              <InputText disabled values={types.find(t => t.value === clientSelectItems?.find(c => c.value === clientId)?.details.type)?.text || ''} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('sellTelecomService:amount')} </FormLabel>
              <>
                <InputText 
                  name="amount"
                  numberOnly
                  thousandSeparator
                  values={amount} 
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly} />
                {showValidation && !amount && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('sellTelecomService:price')} </FormLabel>
              <>
                <InputText 
                  name="price"
                  numberOnly
                  thousandSeparator
                  values={price} 
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly} />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('sellTelecomService:discount')} </FormLabel>
              <>
                <InputText 
                  name="discount"
                  numberOnly
                  thousandSeparator
                  values={discount} 
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly} />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('sellTelecomService:commission')} </FormLabel>
              <>
                <InputText 
                  name="commission"
                  numberOnly
                  thousandSeparator
                  values={commission} 
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly} />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('sellTelecomService:total')} </FormLabel>
              <InputText 
                disabled 
                numberOnly 
                thousandSeparator 
                values={parseFloat('0' + amount.toString()) - parseFloat('0' + discount.toString())} />
            </ColumnContainer>

            <> </>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('sellTelecomService:branch')} </FormLabel>
              <>
                <Autocomplete
                  name='branchId'
                  value={branchId}
                  options={branchSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                />
                {showValidation && !branchId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('sellTelecomService:commission_user')} </FormLabel>
              <>
                <Autocomplete
                  name='commissionUserId'
                  value={commissionUserId}
                  options={userSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                />
                {showValidation && !commissionUserId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('sellTelecomService:pay_by_cash')} </FormLabel>
              <Checkbox
                checked={payByCash}
                name='payByCash'
                onClick={this._onCheckboxChange.bind(this)}
              />
            </ColumnContainer>

            {payByCash ? null : <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('sellTelecomService:receive_account')} </FormLabel>
              <>
                <CustomSelect 
                  name={'receiveAccountId'}
                  value={receiveAccountId}
                  onChange={this._onNumberChange.bind(this)}
                  items={paymentAccountSelectItems}
                  label={t('sellTelecomService:receive_account')}
                  disabled={readOnly} />
                {showValidation && !receiveAccountId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>}

          </ColumnContainer>

          <GridContainer justify="center">
          {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button> : null}
            {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button> : null}
            {status === TransferStatus.Draft ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_submit')}
            </Button> : null}
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.SellTelecomService)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onCheckboxChange(event: any) {
    event.persist && event.persist();
    const { target: { name, checked } } = event;
    let sellTelecomService = this.state.sellTelecomService;
    this.setState({ sellTelecomService: {...sellTelecomService, [name]: checked }});
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ sellTelecomService: {...this.state.sellTelecomService, [name]: value }});    
  }

  private _onDateChange(value: string | Moment) {
    let sellTelecomService = this.state.sellTelecomService;
    sellTelecomService.sellDate = moment(value).format(GlobalConst.DATE_TIME_FORMAT);
    this.setState({ sellTelecomService: sellTelecomService });
  }

  private _updatePriceAmount() {
    // let sellTelecomService = this.state.sellTelecomService;
    // let service = this.props.telecomServiceSelectItems?.find(s => s.value === sellTelecomService.serviceId);
    // let client = this.props.clientSelectItems?.find(c => c.value === sellTelecomService.clientId);
    // if (service && client) {
    //   sellTelecomService.price = parseFloat(service.details.price.toString());
    //   sellTelecomService.amount = [ClientType.Multiple, ClientType.Branch].includes(client.details.type) ? parseFloat(service.details.multipleSalePrice.toString()) : parseFloat(service.details.salePrice.toString());
    //   this.setState({ sellTelecomService: sellTelecomService });
    // }
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ sellTelecomService: {...this.state.sellTelecomService, [name]: value }});
  }

  private _onServiceChange(value: number) {
    this.setState({ sellTelecomService: { ...this.state.sellTelecomService, serviceId: value } }, () => this._updatePriceAmount());
  }

  private _onSupplierChange(value: number) {
    this.setState({ sellTelecomService: { ...this.state.sellTelecomService, supplierId: value } });
  }

  private _onClientChange(value: number) {
    this.setState({ sellTelecomService: { ...this.state.sellTelecomService, clientId: value } }, () => this._updatePriceAmount());
  }

  private _onValidate() {
    let { sellTelecomService: { serviceId, clientId, supplierId, sellDate, amount, commissionUserId, branchId, payByCash, receiveAccountId } } = this.state;

    return serviceId && clientId && supplierId && sellDate && commissionUserId && branchId && (payByCash || receiveAccountId);
  }

  private _onSave(submit: boolean = false, close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { sellTelecomService: { serviceId, clientId, supplierId, phone, sellDate, price, amount, discount, commission, commissionUserId, branchId, payByCash, receiveAccountId } } = this.state;
    let sellTelecomService = new SellTelecomServiceDTO();
    sellTelecomService.id = parseInt(this.props.match.params.id);
    sellTelecomService.serviceId = serviceId;
    sellTelecomService.supplierId = supplierId;
    sellTelecomService.phone = phone;
    sellTelecomService.clientId = clientId;
    sellTelecomService.sellDate = sellDate;
    sellTelecomService.price = price;
    sellTelecomService.amount = amount;
    sellTelecomService.discount = discount;
    sellTelecomService.commission = commission;
    sellTelecomService.commissionUserId = commissionUserId;
    sellTelecomService.branchId = branchId;
    sellTelecomService.payByCash = payByCash;
    sellTelecomService.receiveAccountId = payByCash ? null : receiveAccountId;

    this.props.saveSellTelecomService(sellTelecomService, submit).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        sellTelecomService.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close || submit) {
          history.push(URL.SellTelecomService);
        } else if (parseInt(match.params.id) === 0) {
          this.setState({ sellTelecomService: response.data });
          history.push(URL.SellTelecomServiceDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

  private _onApprove() {
    const { t } = this.props;
    this.setState({ isSaving: true });

    this.props.approveSellTelecomService(this.state.sellTelecomService.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.SellTelecomService);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

  private _onCancel() {
    const { t } = this.props;
    this.setState({ isSaving: true });
    
    this.props.cancelSellTelecomService(this.state.sellTelecomService.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.SellTelecomService);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(SellTelecomServiceDetail)));