import { FilterDTO, SellTelecomServiceDTO } from '../../DTO';

const SELL_TELECOM_SERVICE_REQUEST: string = 'SELL_TELECOM_SERVICE_REQUEST';

const SELL_TELECOM_SERVICE_GET_LIST_SUCCESS: string = 'SELL_TELECOM_SERVICE_GET_LIST_SUCCESS';
const SELL_TELECOM_SERVICE_GET_LIST_FAIL: string = 'SELL_TELECOM_SERVICE_GET_LIST_FAIL';

const SELL_TELECOM_SERVICE_SET_EDITING_SELL_TELECOM_SERVICE: string = 'SELL_TELECOM_SERVICE_SET_EDITING_SELL_TELECOM_SERVICE';

const SELL_TELECOM_SERVICE_GET_BY_ID_SUCCESS: string = 'SELL_TELECOM_SERVICE_GET_BY_ID_SUCCESS';
const SELL_TELECOM_SERVICE_GET_BY_ID_FAIL: string = 'SELL_TELECOM_SERVICE_GET_BY_ID_FAIL';

const SELL_TELECOM_SERVICE_SAVE_SUCCESS: string = 'SELL_TELECOM_SERVICE_SAVE_SUCCESS';
const SELL_TELECOM_SERVICE_SAVE_FAIL: string = 'SELL_TELECOM_SERVICE_SAVE_FAIL';

const SELL_TELECOM_SERVICE_APPROVE_SUCCESS: string = 'SELL_TELECOM_SERVICE_APPROVE_SUCCESS';
const SELL_TELECOM_SERVICE_APPROVE_FAIL: string = 'SELL_TELECOM_SERVICE_APPROVE_FAIL';

const SELL_TELECOM_SERVICE_CANCEL_SUCCESS: string = 'SELL_TELECOM_SERVICE_CANCEL_SUCCESS';
const SELL_TELECOM_SERVICE_CANCEL_FAIL: string = 'SELL_TELECOM_SERVICE_CANCEL_FAIL';

const SELL_TELECOM_SERVICE_DELETE_SUCCESS: string = 'SELL_TELECOM_SERVICE_DELETE_SUCCESS';
const SELL_TELECOM_SERVICE_DELETE_FAIL: string = 'SELL_TELECOM_SERVICE_DELETE_FAIL';

const SELL_TELECOM_SERVICE_EXPORT_LIST_SUCCESS: string = 'SELL_TELECOM_SERVICE_EXPORT_LIST_SUCCESS';
const SELL_TELECOM_SERVICE_EXPORT_LIST_FAIL: string = 'SELL_TELECOM_SERVICE_EXPORT_LIST_FAIL';

const initialState = {
  editingSellTelecomService: {},
}

export function sellTelecomServiceReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case SELL_TELECOM_SERVICE_SET_EDITING_SELL_TELECOM_SERVICE:
      return Object.assign({}, state, {
        editingSellTelecomService: action.payload
      });

    default:
      return state;

  }

}

export function getListSellTelecomService(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SELL_TELECOM_SERVICE_REQUEST, SELL_TELECOM_SERVICE_GET_LIST_SUCCESS, SELL_TELECOM_SERVICE_GET_LIST_FAIL],
      EndPoint: `SellTelecomServices${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingSellTelecomService(sellTelecomService: SellTelecomServiceDTO) {
  return {
    type: SELL_TELECOM_SERVICE_SET_EDITING_SELL_TELECOM_SERVICE,
    payload: sellTelecomService
  }
}

export function getSellTelecomServiceById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SELL_TELECOM_SERVICE_REQUEST, SELL_TELECOM_SERVICE_GET_BY_ID_SUCCESS, SELL_TELECOM_SERVICE_GET_BY_ID_FAIL],
      EndPoint: `SellTelecomServices\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveSellTelecomService(sellTelecomService: SellTelecomServiceDTO, submit: boolean) {
  let initRequest: RequestInit = {
    method: sellTelecomService.id ? 'PATCH' : 'POST',
    body: JSON.stringify(sellTelecomService),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SELL_TELECOM_SERVICE_REQUEST, SELL_TELECOM_SERVICE_SAVE_SUCCESS, SELL_TELECOM_SERVICE_SAVE_FAIL],
      EndPoint: `SellTelecomServices\\${submit}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function approveSellTelecomService(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SELL_TELECOM_SERVICE_REQUEST, SELL_TELECOM_SERVICE_APPROVE_SUCCESS, SELL_TELECOM_SERVICE_APPROVE_FAIL],
      EndPoint: 'SellTelecomServices\\Approve',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function cancelSellTelecomService(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SELL_TELECOM_SERVICE_REQUEST, SELL_TELECOM_SERVICE_CANCEL_SUCCESS, SELL_TELECOM_SERVICE_CANCEL_FAIL],
      EndPoint: 'SellTelecomServices\\Cancel',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteSellTelecomService(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SELL_TELECOM_SERVICE_REQUEST, SELL_TELECOM_SERVICE_DELETE_SUCCESS, SELL_TELECOM_SERVICE_DELETE_FAIL],
      EndPoint: 'SellTelecomServices',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function exportSellTelecomService(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SELL_TELECOM_SERVICE_REQUEST, SELL_TELECOM_SERVICE_EXPORT_LIST_SUCCESS, SELL_TELECOM_SERVICE_EXPORT_LIST_FAIL],
      EndPoint: `SellTelecomServices\\export${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}