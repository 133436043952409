import React from "react";
import defaultImage from '../../Assets/images/image_placeholder.jpg';
import defaultAvatar from '../../Assets/images/placeholder.jpg';
import { Button } from "../";
import { withTranslation, WithTranslation } from 'react-i18next';

interface ImageUploadProps extends WithTranslation {
  avatar?: boolean;
  image?: any;

  onImageChange?: any;
  onImageRemoved?: any;
  hideButtons?: boolean;
}

interface ImageUploadStates {
  file: any;
  imagePreviewUrl: any;
}

class ImageUpload extends React.Component<ImageUploadProps, ImageUploadStates> {

  constructor(props: ImageUploadProps) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: props.image ?? (this.props.avatar ? defaultAvatar : defaultImage)
    };
    this._handleImageChange = this._handleImageChange.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleClick = this._handleClick.bind(this);
    this._handleRemove = this._handleRemove.bind(this);
  }

  private _handleImageChange(e: any) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
    };
    reader.readAsDataURL(file);

    this.props.onImageChange && this.props.onImageChange(file);
  }

  private _handleSubmit(e: any) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }

  private _handleClick() {
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = this._handleImageChange;
    input.click();
  }

  private _handleRemove() {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    });

    this.props.onImageRemoved && this.props.onImageRemoved();
  }

  componentWillReceiveProps(props: ImageUploadProps) {
    if (props.image !== this.props.image) {
      this.setState({ imagePreviewUrl: props.image ?? (this.props.avatar ? defaultAvatar : defaultImage) })
    }
  }

  render() {
    const { t, image, hideButtons } = this.props;
    return (
      <div className="fileinput text-center">
        <div className={"thumbnail" + (this.props.avatar ? " img-circle" : "")}>
          <img src={this.state.imagePreviewUrl} alt="..." />
        </div>
        {!hideButtons ? <div>
          {this.state.file === null && image === null ? (
            <Button round color="primary" onClick={() => this._handleClick()}>
              {this.props.avatar ? t('button:add_image') : t('button:select_image')}
            </Button>
          ) : (
            <span>
              <Button round color="primary" onClick={() => this._handleClick()}>
                {t('button:change')}
              </Button>
              {this.props.avatar ? <br /> : null}
              <Button color="danger" round onClick={() => this._handleRemove()}>
                <i className="fa fa-times" /> {t('button:remove')}
              </Button>
            </span>
          )}
        </div> : null}
      </div>
    )
  }

}

export default withTranslation()(ImageUpload);