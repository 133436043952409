import { FilterDTO, TransferViettelSettingDTO } from '../../DTO';

const TRANSFER_VIETTEL_SETTING_REQUEST: string = 'TRANSFER_VIETTEL_SETTING_REQUEST';

const TRANSFER_VIETTEL_SETTING_GET_LIST_SUCCESS: string = 'TRANSFER_VIETTEL_SETTING_GET_LIST_SUCCESS';
const TRANSFER_VIETTEL_SETTING_GET_LIST_FAIL: string = 'TRANSFER_VIETTEL_SETTING_GET_LIST_FAIL';

const TRANSFER_VIETTEL_SETTING_SET_EDITING_TRANSFER_VIETTEL_SETTING: string = 'TRANSFER_VIETTEL_SETTING_SET_EDITING_TRANSFER_VIETTEL_SETTING';

const TRANSFER_VIETTEL_SETTING_GET_BY_ID_SUCCESS: string = 'TRANSFER_VIETTEL_SETTING_GET_BY_ID_SUCCESS';
const TRANSFER_VIETTEL_SETTING_GET_BY_ID_FAIL: string = 'TRANSFER_VIETTEL_SETTING_GET_BY_ID_FAIL';

const TRANSFER_VIETTEL_SETTING_SAVE_SUCCESS: string = 'TRANSFER_VIETTEL_SETTING_SAVE_SUCCESS';
const TRANSFER_VIETTEL_SETTING_SAVE_FAIL: string = 'TRANSFER_VIETTEL_SETTING_SAVE_FAIL';

const TRANSFER_VIETTEL_SETTING_DELETE_SUCCESS: string = 'TRANSFER_VIETTEL_SETTING_DELETE_SUCCESS';
const TRANSFER_VIETTEL_SETTING_DELETE_FAIL: string = 'TRANSFER_VIETTEL_SETTING_DELETE_FAIL';

const TRANSFER_VIETTEL_SETTING_GET_NAMES_SUCCESS: string = 'TRANSFER_VIETTEL_SETTING_GET_NAMES_SUCCESS';
const TRANSFER_VIETTEL_SETTING_GET_NAMES_FAIL: string = 'TRANSFER_VIETTEL_SETTING_GET_NAMES_FAIL';

const initialState = {
  editingTransferViettelSetting: {},
  transferViettelSettingSelectItems: [],
}

export function transferViettelSettingReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case TRANSFER_VIETTEL_SETTING_SET_EDITING_TRANSFER_VIETTEL_SETTING:
      return Object.assign({}, state, {
        editingTransferViettelSetting: action.payload
      });

    case TRANSFER_VIETTEL_SETTING_GET_NAMES_SUCCESS:
      return Object.assign({}, state, {
        transferViettelSettingSelectItems: action.response?.data ?? []
      });

    default:
      return state;

  }

}

export function getListTransferViettelSetting(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_VIETTEL_SETTING_REQUEST, TRANSFER_VIETTEL_SETTING_GET_LIST_SUCCESS, TRANSFER_VIETTEL_SETTING_GET_LIST_FAIL],
      EndPoint: `TransferViettelSettings${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingTransferViettelSetting(transferViettelSetting: TransferViettelSettingDTO) {
  return {
    type: TRANSFER_VIETTEL_SETTING_SET_EDITING_TRANSFER_VIETTEL_SETTING,
    payload: transferViettelSetting
  }
}

export function getTransferViettelSettingById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_VIETTEL_SETTING_REQUEST, TRANSFER_VIETTEL_SETTING_GET_BY_ID_SUCCESS, TRANSFER_VIETTEL_SETTING_GET_BY_ID_FAIL],
      EndPoint: `TransferViettelSettings\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveTransferViettelSetting(transferViettelSetting: TransferViettelSettingDTO) {
  let initRequest: RequestInit = {
    method: transferViettelSetting.id ? 'PATCH' : 'POST',
    body: JSON.stringify(transferViettelSetting),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_VIETTEL_SETTING_REQUEST, TRANSFER_VIETTEL_SETTING_SAVE_SUCCESS, TRANSFER_VIETTEL_SETTING_SAVE_FAIL],
      EndPoint: 'TransferViettelSettings',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteTransferViettelSetting(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_VIETTEL_SETTING_REQUEST, TRANSFER_VIETTEL_SETTING_DELETE_SUCCESS, TRANSFER_VIETTEL_SETTING_DELETE_FAIL],
      EndPoint: 'TransferViettelSettings',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function getTransferViettelSettingSelectItems() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_VIETTEL_SETTING_REQUEST, TRANSFER_VIETTEL_SETTING_GET_NAMES_SUCCESS, TRANSFER_VIETTEL_SETTING_GET_NAMES_FAIL],
      EndPoint: 'TransferViettelSettings/selectList',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}