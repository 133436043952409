export class CashCollectionProductDTO {
  public id: number;
  public name: string;
  public number: string;

  constructor() {
    this.id = 0;
    this.name = '';
    this.number = '';
  }
}