import { WorkflowDTO } from "./WorkflowDTO";
import { GlobalConst } from "../Consts";

let moment = require('moment');

export class PaySupplierDTO {
  public id: number;
  public number: string;
  public supplierId: number;
  public supplierName: string;
  public date: string;
  public amount: number;
  public payByCash: boolean;
  public paymentAccountId: number | null;
  public branchId: number | null;
  public status: number;
  public workflow: WorkflowDTO;

  constructor() {
    this.id = 0;
    this.number = '';
    this.supplierId = 0;
    this.supplierName = '';
    this.date = moment().format(GlobalConst.DATE_TIME_FORMAT);
    this.amount = 0;
    this.payByCash = true;
    this.paymentAccountId = null;
    this.branchId = null;
    this.status = 0;
    this.workflow = new WorkflowDTO();
  }
}