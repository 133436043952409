import { GlobalConst } from "../Consts";
import { WorkflowStateDTO } from "./WorkflowStateDTO";

let moment = require('moment');

export class WorkflowDTO {
  public id: number;
  public name: string;
  public number: string;
  public objectName: string;
  public isActive: boolean;
  public fromDate: string;
  public toDate: string;
  public workflowStates: WorkflowStateDTO[];

  constructor() {
    this.id = 0;
    this.name = '';
    this.number = '';
    this.objectName = '';
    this.isActive = true;
    this.fromDate = moment().format(GlobalConst.DATE_TIME_FORMAT);
    this.toDate = moment().format(GlobalConst.DATE_TIME_FORMAT);
    this.workflowStates = [];
  }
}