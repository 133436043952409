export class WorkflowButtonDTO {
  public id: number;
  public name: string;
  public textCode: string;
  public icon: string;
  public color: string;
  public class: string;
  public actionName: string;
  public order: number;

  constructor() {
    this.id = 0;
    this.name = '';
    this.textCode = '';
    this.icon = '';
    this.color = '';
    this.class = '';
    this.actionName = '';
    this.order = 0;
  }
}