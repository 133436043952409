import { FilterDTO, BankDTO } from '../../DTO';

const BANK_REQUEST: string = 'BANK_REQUEST';

const BANK_GET_LIST_SUCCESS: string = 'BANK_GET_LIST_SUCCESS';
const BANK_GET_LIST_FAIL: string = 'BANK_GET_LIST_FAIL';

const BANK_SET_EDITING_BANK: string = 'BANK_SET_EDITING_BANK';

const BANK_GET_BY_ID_SUCCESS: string = 'BANK_GET_BY_ID_SUCCESS';
const BANK_GET_BY_ID_FAIL: string = 'BANK_GET_BY_ID_FAIL';

const BANK_SAVE_SUCCESS: string = 'BANK_SAVE_SUCCESS';
const BANK_SAVE_FAIL: string = 'BANK_SAVE_FAIL';

const BANK_DELETE_SUCCESS: string = 'BANK_DELETE_SUCCESS';
const BANK_DELETE_FAIL: string = 'BANK_DELETE_FAIL';

const BANK_GET_NAMES_SUCCESS: string = 'BANK_GET_NAMES_SUCCESS';
const BANK_GET_NAMES_FAIL: string = 'BANK_GET_NAMES_FAIL';

const initialState = {
  editingBank: {},
  bankSelectItems: [],
}

export function bankReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case BANK_SET_EDITING_BANK:
      return Object.assign({}, state, {
        editingBank: action.payload
      });

    case BANK_GET_NAMES_SUCCESS:
      return Object.assign({}, state, {
        bankSelectItems: action.response?.data ?? []
      })

    default:
      return state;

  }

}

export function getListBank(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [BANK_REQUEST, BANK_GET_LIST_SUCCESS, BANK_GET_LIST_FAIL],
      EndPoint: `Banks${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingBank(bank: BankDTO) {
  return {
    type: BANK_SET_EDITING_BANK,
    payload: bank
  }
}

export function getBankById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [BANK_REQUEST, BANK_GET_BY_ID_SUCCESS, BANK_GET_BY_ID_FAIL],
      EndPoint: `Banks\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveBank(bank: BankDTO) {
  let initRequest: RequestInit = {
    method: bank.id ? 'PATCH' : 'POST',
    body: JSON.stringify(bank),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [BANK_REQUEST, BANK_SAVE_SUCCESS, BANK_SAVE_FAIL],
      EndPoint: 'Banks',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteBank(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [BANK_REQUEST, BANK_DELETE_SUCCESS, BANK_DELETE_FAIL],
      EndPoint: 'Banks',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function getBankSelectItems() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [BANK_REQUEST, BANK_GET_NAMES_SUCCESS, BANK_GET_NAMES_FAIL],
      EndPoint: 'Banks/selectList',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}