import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL, GlobalConst, Permission, TransferStatus } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer, Autocomplete } from '../../Components';
import { PhoneIphone, Save, KeyboardArrowLeft, Clear } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getBuySimById, saveBuySim, approveBuySim, cancelBuySim } from '../../Redux/BuySim/BuySimReducer';
import { getSimSelectItems } from '../../Redux/Sim/SimReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { BuySimDTO, SelectItemDTO, CurrentUserDTO } from '../../DTO';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
import { getClientSelectItems } from '../../Redux/Client/ClientReducer';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';
import { getUserSelectItems } from '../../Redux/User/UserReducer';
import { getNetworkProviderSelectItems } from '../../Redux/NetworkProvider/NetworkProviderReducer';
let moment = require('moment');

interface IBuySimDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  buySim: BuySimDTO;
  clientSelectItems: SelectItemDTO[];
  branchSelectItems: SelectItemDTO[];
  userSelectItems: SelectItemDTO[];
  simSelectItems: SelectItemDTO[];
  currentUser: CurrentUserDTO;
  networkProviderSelectItems: SelectItemDTO[];

  setActiveRoute: ActionCreator<any>,
  getBuySimById: ActionCreator<any>,
  saveBuySim: ActionCreator<any>,
  getClientSelectItems: ActionCreator<any>,
  getBranchSelectItems: ActionCreator<any>,
  getUserSelectItems: ActionCreator<any>,
  approveBuySim: ActionCreator<any>,
  cancelBuySim: ActionCreator<any>,
  getSimSelectItems: ActionCreator<any>,
  getNetworkProviderSelectItems: ActionCreator<any>,
}

interface IBuySimDetailStates {
  isSaving?: boolean;

  buySim: BuySimDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    buySim: state.buySim.editingBuySim,
    clientSelectItems: state.client.clientSelectItems,
    branchSelectItems: state.branch.branchSelectItems,
    userSelectItems: state.user.userSelectItems,
    currentUser: state.user.currentUser,
    simSelectItems: state.sim.simSelectItems,
    networkProviderSelectItems: state.networkProvider.networkProviderSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getBuySimById: (id: number) => dispatch(getBuySimById(id)),
    saveBuySim: (buySim: BuySimDTO, submit: boolean) => dispatch(saveBuySim(buySim, submit)),
    getClientSelectItems: () => dispatch(getClientSelectItems()),
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
    getUserSelectItems: () => dispatch(getUserSelectItems()),
    approveBuySim: (id: number) => dispatch(approveBuySim(id)),
    cancelBuySim: (id: number) => dispatch(cancelBuySim(id)),
    getSimSelectItems: () => dispatch(getSimSelectItems()),
    getNetworkProviderSelectItems: () => dispatch(getNetworkProviderSelectItems()),
  })
}

class BuySimDetail extends React.Component<IBuySimDetailProps, IBuySimDetailStates> {

  constructor(props: IBuySimDetailProps) {
    super(props);
    this.state = {
      buySim: {...new BuySimDTO(), ...props.buySim}
    }
  }

  componentDidMount() {
    !this.props.clientSelectItems?.length && this.props.getClientSelectItems();
    !this.props.branchSelectItems?.length && this.props.getBranchSelectItems();
    !this.props.userSelectItems?.length && this.props.getUserSelectItems();
    !this.props.simSelectItems?.length && this.props.getSimSelectItems();
    !this.props.networkProviderSelectItems?.length && this.props.getNetworkProviderSelectItems();

    const { setActiveRoute, match, getBuySimById, t } = this.props;

    setActiveRoute([{path: URL.BuySim, name: this.props.t('menu:buySim')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getBuySimById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.BuySim);
          } else {
            this.setState({ buySim: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes, currentUser, clientSelectItems, branchSelectItems, userSelectItems, simSelectItems, networkProviderSelectItems } = this.props;
    let { showValidation, buySim: { id, number, simId, networkProviderId, buyDate, sellerId, amount, fee, discount, commission, branchId, commissionUserId, status } } = this.state;

    const statusText = [
      { value: TransferStatus.Draft, text: t('buySim:status_draft')},
      { value: TransferStatus.PendingApprove, text: t('buySim:status_pending_approve')},
      { value: TransferStatus.Approved, text: t('buySim:status_approved')},
      { value: TransferStatus.Canceled, text: t('buySim:status_cancel')},
    ]

    let statusStr = statusText.find(s => s.value === status)?.text || '';
    let tDate = buyDate ? moment(buyDate, GlobalConst.DATE_TIME_FORMAT) : moment(new Date());
    let now = moment(new Date());
    let readOnly = (status === TransferStatus.Approved || status === TransferStatus.Canceled) && (tDate.year() * 12 + tDate.month() < now.year() * 12 + now.month());

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={PhoneIphone}
          iconColor={'green'}
          title={t('common:information')}>

          {id && currentUser.functionIds.includes(Permission.ApproveBuySims) && status === TransferStatus.PendingApprove ? 
            <ColumnContainer columnWidth={[{xs:12, sm:12}]}>
              <div className={classes.right}>
                <Button color='primary' customClass={classes.marginRight} round onClick={this._onApprove.bind(this)} disabled={this.state.isSaving}>
                  <Save className={classes.icons} /> {t('button:approve')}
                </Button>
                <Button color='danger' customClass={classes.marginRight} round onClick={this._onCancel.bind(this)} disabled={this.state.isSaving}>
                  <Clear className={classes.icons} /> {t('button:cancel')}
                </Button>
              </div>
            </ColumnContainer>
          : null }

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('buySim:number')} </FormLabel>
              <InputText values={number} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('buySim:status')} </FormLabel>
              <InputText values={statusStr} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('buySim:buy_date')} </FormLabel>
              {!readOnly ? 
                <Datetime
                  dateFormat={GlobalConst.DATE_FORMAT}
                  timeFormat={"HH:mm"}
                  inputProps={{ placeholder: t('buySim:buy_date') }}
                  onChange={this._onDateChange.bind(this)}
                  value={buyDate}
                /> : <InputText disabled values={buyDate} /> }
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('buySim:seller')} </FormLabel>
              <>
                <Autocomplete
                  name='sellerId'
                  value={sellerId}
                  options={clientSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  getOptionLabel={(option: SelectItemDTO) => `${option.text} - ${option.details.phone}`}
                  disabled={readOnly}
                />
                {showValidation && !sellerId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('buySim:sim')} </FormLabel>
              <>
                <Autocomplete
                  name='simId'
                  value={simId}
                  options={simSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !simId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('buySim:network_provider')} </FormLabel>
              <>
                <Autocomplete
                  name='networkProviderId'
                  value={networkProviderId}
                  options={networkProviderSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !networkProviderId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('buySim:amount')} </FormLabel>
              <>
                <InputText 
                  name="amount"
                  numberOnly
                  thousandSeparator
                  values={amount} 
                  disabled={readOnly}
                  onChange={this._onNumberChange.bind(this)} />
                {showValidation && !amount && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('buySim:fee')} </FormLabel>
              <>
                <InputText 
                  name="fee"
                  numberOnly
                  thousandSeparator
                  values={fee} 
                  disabled={readOnly}
                  onChange={this._onNumberChange.bind(this)} />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('buySim:discount')} </FormLabel>
              <>
                <InputText 
                  name="discount"
                  numberOnly
                  thousandSeparator
                  values={discount} 
                  disabled={readOnly}
                  onChange={this._onNumberChange.bind(this)} />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('buySim:commission')} </FormLabel>
              <>
                <InputText 
                  name="commission"
                  numberOnly
                  thousandSeparator
                  values={commission} 
                  disabled={readOnly}
                  onChange={this._onNumberChange.bind(this)} />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('buySim:total')} </FormLabel>
              <>
                <InputText 
                  numberOnly
                  thousandSeparator
                  values={parseFloat(amount.toString()) + parseFloat(fee.toString()) - parseFloat(discount.toString())} 
                  disabled />
              </>
            </ColumnContainer>

            <> </>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('buySim:branch')} </FormLabel>
              <>
                <Autocomplete
                  name='branchId'
                  value={branchId}
                  options={branchSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !branchId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('buySim:commission_user')} </FormLabel>
              <>
                <Autocomplete
                  name='commissionUserId'
                  value={commissionUserId}
                  options={userSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !commissionUserId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

          </ColumnContainer>

          <GridContainer justify="center">
          {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button> : null}
            {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button> : null}
            {status === TransferStatus.Draft ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_submit')}
            </Button> : null}
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.BuySim)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ buySim: {...this.state.buySim, [name]: value }});
  }

  private _onDateChange(value: string | Moment) {
    let buySim = this.state.buySim;
    buySim.buyDate = moment(value).format(GlobalConst.DATE_TIME_FORMAT);
    this.setState({ buySim: buySim });
  }

  private _onValidate() {
    let { buySim: { simId, sellerId, amount, branchId, commissionUserId } } = this.state;

    return simId && sellerId && amount && branchId && commissionUserId;
  }

  private _onSave(submit: boolean = false, close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { buySim: { simId, networkProviderId, buyDate, sellerId, amount, fee, discount, commission, branchId, commissionUserId } } = this.state;
    let buySim = new BuySimDTO();
    buySim.id = parseInt(this.props.match.params.id);
    buySim.simId = simId;
    buySim.buyDate = buyDate;
    buySim.sellerId = sellerId;
    buySim.amount = amount;
    buySim.fee = fee;
    buySim.discount = discount;
    buySim.commission = commission;
    buySim.branchId = branchId;
    buySim.commissionUserId = commissionUserId;
    buySim.networkProviderId = networkProviderId;

    this.props.saveBuySim(buySim, submit).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        buySim.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close || submit) {
          history.push(URL.BuySim);
        } else if (parseInt(match.params.id) === 0) {
          this.setState({ buySim: response.data });
          history.push(URL.BuySimDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

  private _onApprove() {
    const { t } = this.props;
    this.setState({ isSaving: true });

    this.props.approveBuySim(this.state.buySim.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.BuySim);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

  private _onCancel() {
    const { t } = this.props;
    this.setState({ isSaving: true });
    
    this.props.cancelBuySim(this.state.buySim.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.BuySim);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(BuySimDetail)));