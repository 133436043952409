import { FilterDTO, SellProductDTO } from '../../DTO';

const SELL_PRODUCT_REQUEST: string = 'SELL_PRODUCT_REQUEST';

const SELL_PRODUCT_GET_LIST_SUCCESS: string = 'SELL_PRODUCT_GET_LIST_SUCCESS';
const SELL_PRODUCT_GET_LIST_FAIL: string = 'SELL_PRODUCT_GET_LIST_FAIL';

const SELL_PRODUCT_SET_EDITING_SELL_PRODUCT: string = 'SELL_PRODUCT_SET_EDITING_SELL_PRODUCT';

const SELL_PRODUCT_GET_BY_ID_SUCCESS: string = 'SELL_PRODUCT_GET_BY_ID_SUCCESS';
const SELL_PRODUCT_GET_BY_ID_FAIL: string = 'SELL_PRODUCT_GET_BY_ID_FAIL';

const SELL_PRODUCT_SAVE_SUCCESS: string = 'SELL_PRODUCT_SAVE_SUCCESS';
const SELL_PRODUCT_SAVE_FAIL: string = 'SELL_PRODUCT_SAVE_FAIL';

const SELL_PRODUCT_DELETE_SUCCESS: string = 'SELL_PRODUCT_DELETE_SUCCESS';
const SELL_PRODUCT_DELETE_FAIL: string = 'SELL_PRODUCT_DELETE_FAIL';

const initialState = {
  editingSellProducts: {},
}

export function sellProductReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case SELL_PRODUCT_SET_EDITING_SELL_PRODUCT:
      return Object.assign({}, state, {
        editingSellProducts: action.payload
      });

    default:
      return state;

  }

}

export function getListSellProduct(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SELL_PRODUCT_REQUEST, SELL_PRODUCT_GET_LIST_SUCCESS, SELL_PRODUCT_GET_LIST_FAIL],
      EndPoint: `SellProducts${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingSellProduct(inventoryExport: SellProductDTO) {
  return {
    type: SELL_PRODUCT_SET_EDITING_SELL_PRODUCT,
    payload: inventoryExport
  }
}

export function getSellProductById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SELL_PRODUCT_REQUEST, SELL_PRODUCT_GET_BY_ID_SUCCESS, SELL_PRODUCT_GET_BY_ID_FAIL],
      EndPoint: `SellProducts\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveSellProduct(inventoryExport: SellProductDTO, action: string) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: JSON.stringify(inventoryExport),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SELL_PRODUCT_REQUEST, SELL_PRODUCT_SAVE_SUCCESS, SELL_PRODUCT_SAVE_FAIL],
      EndPoint: `SellProducts?action=${action}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteSellProduct(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SELL_PRODUCT_REQUEST, SELL_PRODUCT_DELETE_SUCCESS, SELL_PRODUCT_DELETE_FAIL],
      EndPoint: 'SellProducts',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}