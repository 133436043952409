import React from 'react';
import { IconCard, DataList, Button, CustomInput, ColumnContainer, CustomSelect, Accordion, InputText, Autocomplete } from '../../Components';
import { PhoneIphone, Edit, Replay, Add, Delete, Search, CloudUpload } from '@material-ui/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ActionCreator } from 'redux';
import { connect } from 'react-redux';
import { getListSim, deleteSim, setEditingSim } from '../../Redux/Sim/SimReducer';
import { IconButton, withStyles, InputAdornment, FormLabel } from '@material-ui/core';
import { URL, GlobalConst, SimTypes, SimStatus, NumberTypes } from '../../Consts';
import history from '../../history';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { FilterDTO, SimDTO, SelectItemDTO, FilterItemDTO } from '../../DTO';
import styles from '../../styles';
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from 'react-toastify';
import { showError, currency } from '../../Utils';
import _ from 'lodash';
import { getNetworkProviderSelectItems } from '../../Redux/NetworkProvider/NetworkProviderReducer';
import { getSimStyleSelectItems } from '../../Redux/SimStyle/SimStyleReducer';
import { UploadSim } from '../';

interface ISimProps extends WithTranslation {
  classes: any;

  networkProviderSelectItems: SelectItemDTO[];
  simStyleSelectItems: SelectItemDTO[];

  getSimList: ActionCreator<any>,
  setActiveRoute: ActionCreator<any>,
  setEditingSim: ActionCreator<any>,
  deleteSim: ActionCreator<any>,
  getNetworkProviderSelectItems: ActionCreator<any>,
  getSimStyleSelectItems: ActionCreator<any>,
}

interface ISimStates {
  data: SimDTO[],
  page: number;
  pageSize: number;
  totalCount: number;

  searchString: string;
  isOutOfStock: number;

  showDeleteConfirm?: boolean;
  deletingId?: number;
  openUpload: boolean;

  startWith: string;
  endWith: string;
  networkProviderId?: number;
  style?: number;
}

function mapStateToProps(state: any) {
  return ({
    networkProviderSelectItems: state.networkProvider.networkProviderSelectItems,
    simStyleSelectItems: state.simStyle.simStyleSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    getSimList: (filterDTO: FilterDTO) => dispatch(getListSim(filterDTO)),
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    setEditingSim: (sim: SimDTO) => dispatch(setEditingSim(sim)),
    deleteSim: (id: number) => dispatch(deleteSim(id)),
    getNetworkProviderSelectItems: () => dispatch(getNetworkProviderSelectItems()),
    getSimStyleSelectItems: () => dispatch(getSimStyleSelectItems()),
  })
}

class Sim extends React.Component<ISimProps, ISimStates> {

  constructor(props: ISimProps){
    super(props);

    this.state = {
      data: [],
      page: 0,
      pageSize: 20,
      totalCount: 0,
      searchString: '',
      isOutOfStock: 0,
      openUpload: false,
      startWith: '',
      endWith: ''
    }

    this._getSimWithDebounce = _.debounce(this._getSimWithDebounce.bind(this), GlobalConst.DEBOUNCE_TIME);
  }

  componentDidMount() {
    !this.props.networkProviderSelectItems?.length && this.props.getNetworkProviderSelectItems();
    !this.props.simStyleSelectItems?.length && this.props.getSimStyleSelectItems();
    
    this.props.setActiveRoute([{path: URL.Sim, name: this.props.t('menu:sim')}]);
  }

  render() {
    const { t, classes } = this.props;
    let { data, page, pageSize, totalCount, openUpload } = this.state;
    
    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={PhoneIphone}
          iconColor={'green'}
          title={t('sim:sim')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>
            <CustomInput
              labelText={t('common:search')}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                value: this.state.searchString,
                name: 'searchString',
                onChange: this._onSearchStringChange.bind(this)
              }}
            />

            <div className={classes.right}>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onEdit.bind(this, new SimDTO())}>
                <Add className={classes.icons} /> {t('button:add')}
              </Button>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onRefresh.bind(this)}>
                <Replay className={classes.icons} /> {t('button:refresh')}
              </Button>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onOpenUpload.bind(this, true)}>
                <CloudUpload className={classes.icons} /> {t('button:upload')}
              </Button>
            </div>
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs: 12}]}>
            <Accordion
              active={-1}
              collapses={[
                {
                  title: t('common:advance_search'),
                  content: this._renderAdvancedSearch()
                }
              ]}
            />
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs:12}]}>
            <DataList 
              currentPage={page}
              pageSize={pageSize}
              totalPage={Math.ceil(totalCount / pageSize)}
              data={this._mapData(data)}
              columns={this._getColumns()}
              fetchData={this._onLoadData.bind(this)}
              onPageSizeChange={(ps: number) => {this.setState({ pageSize: ps, page: 0 }, () => this._search())}}
              onChangePage={this._onChangePage.bind(this)}
            />
          </ColumnContainer>
          
          {this.state.showDeleteConfirm && <SweetAlert
            warning
            //style={{ display: "block", marginTop: "-100px" }}
            title={t('message:confirm_delete')}
            onConfirm={this._onDelete.bind(this)}
            onCancel={() => this.setState({ showDeleteConfirm: false})}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={t('button:delete')}
            cancelBtnText={t('button:cancel')}
            showCancel
          >
          </SweetAlert>}

        </IconCard>

        {openUpload ? <UploadSim handleClose={this._onCloseUpload.bind(this)} /> : null}
      </ColumnContainer>
    )
  }

  private _onOpenUpload() {
    this.setState({ openUpload: true });
  }

  private _onCloseUpload(success: boolean = false) {
    this.setState({ openUpload: false });
    if (success) {
      this._onRefresh();
    }
  }

  private _renderAdvancedSearch() {
    const { t, classes, networkProviderSelectItems, simStyleSelectItems } = this.props;
    let { isOutOfStock, startWith, endWith, networkProviderId, style } = this.state;

    let statusSelectOptions = [
      { value: -1, text: t('common:all') } as SelectItemDTO,
      { value: 0, text: t('sim:out_of_stock_false')} as SelectItemDTO,
      { value: 1, text: t('sim:out_of_stock_true')} as SelectItemDTO,
    ]

    return (
      <ColumnContainer columnWidth={[{xs:12, sm:6, md:4}]}>

        <ColumnContainer columnWidth={[{xs:12, sm:4}, {xs:12, sm:8}]}>
          <FormLabel className={classes.labelHorizontal}> {t('sim:status')} </FormLabel>
          <CustomSelect 
            value={isOutOfStock}
            onChange={(value: number) => this.setState({ isOutOfStock: value, page: 0 }, () => this._search()) }
            items={statusSelectOptions}
            label={t('sim:status')} />
        </ColumnContainer>

        <ColumnContainer columnWidth={[{xs:12, sm:4}, {xs:12, sm:8}]}>
          <FormLabel className={classes.labelHorizontal}> {t('sim:start_with')} </FormLabel>
          <InputText name='startWith' values={startWith} onChange={(value: string) => this.setState({ startWith: value }, () => this._search())} />
        </ColumnContainer>

        <ColumnContainer columnWidth={[{xs:12, sm:4}, {xs:12, sm:8}]}>
          <FormLabel className={classes.labelHorizontal}> {t('sim:end_with')} </FormLabel>
          <InputText name='endWith' values={endWith} onChange={(value: string) => this.setState({ endWith: value }, () => this._search())} />
        </ColumnContainer>

        <ColumnContainer columnWidth={[{xs:12, sm:4}, {xs:12, sm:8}]}>
          <FormLabel className={classes.labelHorizontal}> {t('sim:network_provider')} </FormLabel>
          <Autocomplete
            value={networkProviderId}
            includeEmptyOption
            emptyText={t('common:all')}
            options={networkProviderSelectItems}
            onChange={(value: number) => this.setState({ networkProviderId: value, page: 0 }, () => this._search()) }
          />
        </ColumnContainer>

        <ColumnContainer columnWidth={[{xs:12, sm:4}, {xs:12, sm:8}]}>
          <FormLabel className={classes.labelHorizontal}> {t('sim:style')} </FormLabel>
          <CustomSelect 
            value={style}
            onChange={(value: number) => this.setState({ style: value, page: 0 }, () => this._search()) }
            items={simStyleSelectItems}
            label={t('sim:style')} />
        </ColumnContainer>

      </ColumnContainer>
    )
  }

  private _getFilter(tableState: any = null): FilterDTO {
    let { pageSize, searchString, isOutOfStock, startWith, endWith, networkProviderId, style } = this.state;

    let filter: FilterDTO = new FilterDTO(tableState);
    if (!tableState) {
      filter.page = 0;
    }
    filter.pageSize = pageSize;
    filter.searchString = searchString;
    filter.filters = [];
    if (isOutOfStock >= 0) {
      filter.filters.push({ key: 'outOfStock', value: isOutOfStock === 1 } as FilterItemDTO);
    }
    filter.filters.push({ key: 'startWith', value: startWith } as FilterItemDTO);
    filter.filters.push({ key: 'endWith', value: endWith } as FilterItemDTO);
    if (networkProviderId) {
      filter.filters.push({ key: 'networkProviderId', value: networkProviderId } as FilterItemDTO);
    }
    if (style) {
      filter.filters.push({ key: 'style', value: style } as FilterItemDTO);
    }

    return filter;
  }

  _getSimWithDebounce(filterDTO: FilterDTO) {
    this.props.getSimList(filterDTO).then((response: any) => {
      if (response && !response.errorCode) {
        this.setState({ data: response.data, totalCount: response.totalCount });
      } else {
        showError(this.props.t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _onSearchStringChange(event: any) {
    event.persist && event.persist();
    const { target: { value } } = event;
    this.setState({ searchString: value || '', page: 0 }, () => this._search());
  }

  private _search() {
    this._getSimWithDebounce(this._getFilter());    
  }

  private _getColumns() {
    const { t } = this.props;
    return [
      { Header: t('sim:name'), accessor: "name", sortable: false, filterable: false },
      { Header: t('sim:number'), accessor: "number", sortable: false, filterable: false },
      { Header: t('sim:price'), accessor: "price", sortable: false, filterable: false },
      { Header: t('sim:network_provider'), accessor: "networkProvider", sortable: false, filterable: false },
      { Header: t('sim:style'), accessor: "style", sortable: false, filterable: false },
      { Header: t('sim:number_type'), accessor: "numberType", sortable: false, filterable: false },
      { Header: t('sim:type'), accessor: "type", sortable: false, filterable: false },
      { Header: t('sim:out_of_stock_status'), accessor: 'outOfstock', sortable: false, filterable: false },
      { Header: '', accessor: "actions", sortable: false, filterable: false }
    ]
  }

  private _onEdit(sim: SimDTO) {
    this.props.setEditingSim(sim);
    history.push(URL.SimDetail.replace(':id', sim.id.toString()));
  }

  private _onDelete() {
    const { t } = this.props;

    this.setState({ showDeleteConfirm: false });
    this.props.deleteSim(this.state.deletingId).then((response: any) => {
      if (response && !response.errorCode) {
        toast.success(t('message:delete_success'));
        this._onRefresh();
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _mapData(data: SimDTO[]) {
    if (!data) return [];

    const { t, networkProviderSelectItems, simStyleSelectItems } = this.props;
    const numberTypeOptions = NumberTypes.map(s => { return {...s, text: t(s.text)} as SelectItemDTO } );
    const simTypeOptions = SimTypes.map(s => { return {...s, text: t(s.text)} as SelectItemDTO } );
    const simStatusOptions = SimStatus.map(s => { return {...s, text: t(s.text)} as SelectItemDTO } );

    return data.map((u: SimDTO) => {
      return {
        ...u,
        outOfstock: u.isOutOfStock ? t('sim:out_of_stock_true') : t('sim:out_of_stock_false'),
        price: currency(u.price),
        networkProvider: networkProviderSelectItems.find(p => p.value === u.networkProviderId)?.text ?? '',
        style: simStyleSelectItems.find(s => s.value === u.styleId)?.text ?? '',
        numberType: numberTypeOptions.find(t => t.value === u.numberType)?.text ?? '',
        type: simTypeOptions.find(t => t.value === u.type)?.text ?? '',
        status: simStatusOptions.find(s => s.value === u.status)?.text ?? '',
        actions: (
          <div className="actions-right">
            <IconButton className='small-action' onClick={this._onEdit.bind(this, u)}> <Edit /> </IconButton>
            <IconButton className='small-action' onClick={() => this.setState({ showDeleteConfirm: true, deletingId: u.id })}> <Delete color='error' /> </IconButton>
          </div>
        )
      }
    });
  }

  private _onLoadData(tableState: any, instance: any) {
    this._getSimWithDebounce(this._getFilter(tableState));
  }

  private _onRefresh() {
    this._getSimWithDebounce(this._getFilter());
  }

  private _onChangePage(pageIndex: any) {
    this.setState({ page: pageIndex});
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(Sim)));