import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { GlobalConst, LoanStatus, URL, WorkflowStatus } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer, StateButtons, Autocomplete, CustomSelect } from '../../Components';
import { People, Save, KeyboardArrowLeft } from '@material-ui/icons';
import { Checkbox, FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getLoanById, saveLoan } from '../../Redux/Loan/LoanReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { LoanDTO, SelectItemDTO } from '../../DTO';
import { getClientSelectItems } from '../../Redux/Client/ClientReducer';
import { getPaymentAccountSelectItems } from '../../Redux/PaymentAccount/PaymentAccountReducer';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
let moment = require('moment');

interface ILoanDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  loan: LoanDTO;
  clientSelectItems: SelectItemDTO[];
  paymentAccountSelectItems: SelectItemDTO[];

  setActiveRoute: ActionCreator<any>,
  getLoanById: ActionCreator<any>,
  saveLoan: ActionCreator<any>,
  getClientSelectItems: ActionCreator<any>,
  getPaymentAccountSelectItems: ActionCreator<any>,
}

interface ILoanDetailStates {
  isSaving?: boolean;

  loan: LoanDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    loan: state.loan.editingLoan,
    clientSelectItems: state.client.clientSelectItems,
    paymentAccountSelectItems: state.paymentAccount.paymentAccountSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getLoanById: (id: number) => dispatch(getLoanById(id)),
    saveLoan: (loan: LoanDTO, action: string) => dispatch(saveLoan(loan, action)),
    getClientSelectItems: () => dispatch(getClientSelectItems()),
    getPaymentAccountSelectItems: () => dispatch(getPaymentAccountSelectItems()),
  })
}

class LoanDetail extends React.Component<ILoanDetailProps, ILoanDetailStates> {

  constructor(props: ILoanDetailProps) {
    super(props);
    this.state = {
      loan: {...new LoanDTO(), ...props.loan}
    }
  }

  componentDidMount() {
    const { setActiveRoute, match, getLoanById, t } = this.props;

    !this.props.clientSelectItems?.length && this.props.getClientSelectItems();
    !this.props.paymentAccountSelectItems?.length && this.props.getPaymentAccountSelectItems();

    setActiveRoute([{path: URL.Loan, name: this.props.t('menu:loan')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getLoanById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.Loan);
          } else {
            this.setState({ loan: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes, clientSelectItems, paymentAccountSelectItems } = this.props;
    let { showValidation, loan: { id, number, clientId, date, amount, payByCash, paymentAccountId, workflow, status } } = this.state;

    let statusStr = WorkflowStatus.find(s => s.value === status)?.text ?? '';
    if (statusStr) {
      statusStr = t(`status:${statusStr}`);
    }

    let readOnly = (status === LoanStatus.Approved || status === LoanStatus.Canceled);

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={People}
          iconColor={'green'}
          title={t('common:information')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('loan:number')} </FormLabel>
              <InputText values={number} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('loan:status')} </FormLabel>
              <InputText values={statusStr} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('loan:date')} </FormLabel>
              {!readOnly ? 
                <Datetime
                  dateFormat={GlobalConst.DATE_FORMAT}
                  timeFormat={"HH:mm"}
                  inputProps={{ placeholder: t('loan:date') }}
                  onChange={this._onDateChange.bind(this)}
                  value={date}
                /> : <InputText disabled values={date} /> }
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('loan:client')} </FormLabel>
              <>
                <Autocomplete
                  name='clientId'
                  value={clientId}
                  options={clientSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  getOptionLabel={(option: SelectItemDTO) => `${option.text} - ${option.details.phone}`}
                  disabled={readOnly}
                />
                {showValidation && !clientId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('loan:amount')} </FormLabel>
              <>
                <InputText 
                  name="amount"
                  numberOnly
                  thousandSeparator
                  values={amount} 
                  disabled={readOnly}
                  onChange={this._onNumberChange.bind(this)} />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('loan:pay_by_cash')} </FormLabel>
              <Checkbox
                checked={payByCash}
                name='payByCash'
                onClick={this._onCheckboxChange.bind(this)}
              />
            </ColumnContainer>

            {payByCash ? null : <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('loan:payment_account')} </FormLabel>
              <>
                <CustomSelect 
                  name={'paymentAccountId'}
                  value={paymentAccountId}
                  onChange={this._onNumberChange.bind(this)}
                  items={paymentAccountSelectItems}
                  label={t('loan:payment_account')}
                  disabled={readOnly} />
                {showValidation && !paymentAccountId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>}

          </ColumnContainer>

          <GridContainer justify="center">
            {id === 0 ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSaveWF.bind(this, 'save')} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button> : 
            <StateButtons workflowButton={workflow?.workflowStates.find(s => s.status === status)?.workflowButtons ?? []} onSave={this._onSaveWF.bind(this)} /> }
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.Loan)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:back')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onCheckboxChange(event: any) {
    event.persist && event.persist();
    const { target: { name, checked } } = event;
    let loan = this.state.loan;
    this.setState({ loan: {...loan, [name]: checked }});
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ loan: {...this.state.loan, [name]: value }});
  }

  private _onDateChange(value: string | Moment) {
    let loan = this.state.loan;
    loan.date = moment(value).format(GlobalConst.DATE_TIME_FORMAT);
    this.setState({ loan: loan });
  }

  private _onTextChange(event: any) {
    event.persist && event.persist();
    const { target: { name, value } } = event;
    let loan = this.state.loan;
    this.setState({ loan: {...loan, [name]: value }});    
  }

  private _onValidate() {
    let { loan: { clientId, date, amount } } = this.state;

    return clientId && date && amount;
  }

  private _onSaveWF(action: string) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { loan: { clientId, date, amount, payByCash, paymentAccountId } } = this.state;
    let loan = new LoanDTO();
    loan.id = parseInt(this.props.match.params.id);
    loan.clientId = clientId;
    loan.date = date;
    loan.amount = amount;
    loan.payByCash = payByCash;
    loan.paymentAccountId = payByCash ? null : paymentAccountId;

    this.props.saveLoan(loan, action).then((response: any) => {
      this.setState({ isSaving: false });
      if (response.data && !response.data.errorCode) {
        loan.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (parseInt(match.params.id) === 0) {
          history.push(URL.LoanDetail.replace(':id', response.data.id));
        } else if (action !== 'save') { 
          history.push(URL.Loan);
        }
      } else {
        showError(t, response.data.errorCode, response.data.errorMessage);        
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(LoanDetail)));