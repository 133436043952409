import { FilterDTO, ReceiveViettelDTO } from '../../DTO';

const RECEIVE_VIETTEL_REQUEST: string = 'RECEIVE_VIETTEL_REQUEST';

const RECEIVE_VIETTEL_GET_LIST_SUCCESS: string = 'RECEIVE_VIETTEL_GET_LIST_SUCCESS';
const RECEIVE_VIETTEL_GET_LIST_FAIL: string = 'RECEIVE_VIETTEL_GET_LIST_FAIL';

const RECEIVE_VIETTEL_SET_EDITING_RECEIVE_VIETTEL: string = 'RECEIVE_VIETTEL_SET_EDITING_RECEIVE_VIETTEL';

const RECEIVE_VIETTEL_GET_BY_ID_SUCCESS: string = 'RECEIVE_VIETTEL_GET_BY_ID_SUCCESS';
const RECEIVE_VIETTEL_GET_BY_ID_FAIL: string = 'RECEIVE_VIETTEL_GET_BY_ID_FAIL';

const RECEIVE_VIETTEL_SAVE_SUCCESS: string = 'RECEIVE_VIETTEL_SAVE_SUCCESS';
const RECEIVE_VIETTEL_SAVE_FAIL: string = 'RECEIVE_VIETTEL_SAVE_FAIL';

const RECEIVE_VIETTEL_DELETE_SUCCESS: string = 'RECEIVE_VIETTEL_DELETE_SUCCESS';
const RECEIVE_VIETTEL_DELETE_FAIL: string = 'RECEIVE_VIETTEL_DELETE_FAIL';

const RECEIVE_VIETTEL_APPROVE_SUCCESS: string = 'RECEIVE_VIETTEL_APPROVE_SUCCESS';
const RECEIVE_VIETTEL_APPROVE_FAIL: string = 'RECEIVE_VIETTEL_APPROVE_FAIL';

const RECEIVE_VIETTEL_CANCEL_SUCCESS: string = 'RECEIVE_VIETTEL_CANCEL_SUCCESS';
const RECEIVE_VIETTEL_CANCEL_FAIL: string = 'RECEIVE_VIETTEL_CANCEL_FAIL';

const RECEIVE_VIETTEL_EXPORT_LIST_SUCCESS: string = 'RECEIVE_VIETTEL_EXPORT_LIST_SUCCESS';
const RECEIVE_VIETTEL_EXPORT_LIST_FAIL: string = 'RECEIVE_VIETTEL_EXPORT_LIST_FAIL';

const RECEIVE_VIETTEL_PRINT_SUCCESS: string = 'RECEIVE_VIETTEL_PRINT_SUCCESS';
const RECEIVE_VIETTEL_PRINT_FAIL: string = 'RECEIVE_VIETTEL_PRINT_FAIL';

const initialState = {
  editingReceiveViettel: {},
}

export function receiveViettelReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case RECEIVE_VIETTEL_SET_EDITING_RECEIVE_VIETTEL:
      return Object.assign({}, state, {
        editingReceiveViettel: action.payload
      });

    default:
      return state;

  }

}

export function getListReceiveViettel(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [RECEIVE_VIETTEL_REQUEST, RECEIVE_VIETTEL_GET_LIST_SUCCESS, RECEIVE_VIETTEL_GET_LIST_FAIL],
      EndPoint: `ReceiveViettels${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingReceiveViettel(receiveViettel: ReceiveViettelDTO) {
  return {
    type: RECEIVE_VIETTEL_SET_EDITING_RECEIVE_VIETTEL,
    payload: receiveViettel
  }
}

export function getReceiveViettelById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [RECEIVE_VIETTEL_REQUEST, RECEIVE_VIETTEL_GET_BY_ID_SUCCESS, RECEIVE_VIETTEL_GET_BY_ID_FAIL],
      EndPoint: `ReceiveViettels\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveReceiveViettel(receiveViettel: ReceiveViettelDTO, submit: boolean = false) {
  let initRequest: RequestInit = {
    method: receiveViettel.id ? 'PATCH' : 'POST',
    body: JSON.stringify(receiveViettel),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [RECEIVE_VIETTEL_REQUEST, RECEIVE_VIETTEL_SAVE_SUCCESS, RECEIVE_VIETTEL_SAVE_FAIL],
      EndPoint: `ReceiveViettels\\${submit}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteReceiveViettel(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };


  return {
    CallAPI: {
      Actions: [RECEIVE_VIETTEL_REQUEST, RECEIVE_VIETTEL_DELETE_SUCCESS, RECEIVE_VIETTEL_DELETE_FAIL],
      EndPoint: 'ReceiveViettels',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function approveReceiveViettel(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [RECEIVE_VIETTEL_REQUEST, RECEIVE_VIETTEL_APPROVE_SUCCESS, RECEIVE_VIETTEL_APPROVE_FAIL],
      EndPoint: 'ReceiveViettels\\Approve',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function cancelReceiveViettel(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [RECEIVE_VIETTEL_REQUEST, RECEIVE_VIETTEL_CANCEL_SUCCESS, RECEIVE_VIETTEL_CANCEL_FAIL],
      EndPoint: 'ReceiveViettels\\Cancel',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function exportReceiveViettel(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [RECEIVE_VIETTEL_REQUEST, RECEIVE_VIETTEL_EXPORT_LIST_SUCCESS, RECEIVE_VIETTEL_EXPORT_LIST_FAIL],
      EndPoint: `ReceiveViettels\\export${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function printReceiveViettel(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [RECEIVE_VIETTEL_REQUEST, RECEIVE_VIETTEL_PRINT_SUCCESS, RECEIVE_VIETTEL_PRINT_FAIL],
      EndPoint: `ReceiveViettels\\print\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest,
      ResponseHtml : true
    }
  }
}