import React from 'react';
import { IconCard, DataList, Button, CustomInput, ColumnContainer, Accordion, CustomSelect, Autocomplete } from '../../Components';
import { Assignment, Edit, Replay, Add, Delete, Search } from '@material-ui/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ActionCreator } from 'redux';
import { connect } from 'react-redux';
import { getListInventoryExport, deleteInventoryExport, setEditingInventoryExport } from '../../Redux/InventoryExport/InventoryExportReducer';
import { IconButton, withStyles, InputAdornment, FormLabel } from '@material-ui/core';
import { URL, GlobalConst, InventoryExportStatus } from '../../Consts';
import history from '../../history';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { FilterDTO, FilterItemDTO, InventoryExportDTO, SelectItemDTO } from '../../DTO';
import styles from '../../styles';
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from 'react-toastify';
import { currency, showError } from '../../Utils';
import _ from 'lodash';
import { getUserSelectItems } from '../../Redux/User/UserReducer';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
let moment = require('moment');

interface IInventoryExportProps extends WithTranslation {
  classes: any;

  userSelectItems: SelectItemDTO[];
  branchSelectItems: SelectItemDTO[];

  getInventoryExportList: ActionCreator<any>,
  setActiveRoute: ActionCreator<any>,
  setEditingInventoryExport: ActionCreator<any>,
  deleteInventoryExport: ActionCreator<any>,
  getUserSelectItems: ActionCreator<any>,
  getBranchSelectItems: ActionCreator<any>,
}

interface IInventoryExportStates {
  data: InventoryExportDTO[],
  page: number;
  pageSize: number;
  totalCount: number;

  searchString: string;
  showDeleteConfirm?: boolean;
  deletingId?: number;

  dateFrom?: string;
  dateTo?: string;
  fromBranchId: number;
  toBranchId: number;
  userId: number;
  status: number;
}

function mapStateToProps(state: any) {
  return ({
    userSelectItems: state.user.userSelectItems,
    branchSelectItems: state.branch.branchSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    getInventoryExportList: (filterDTO: FilterDTO) => dispatch(getListInventoryExport(filterDTO)),
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    setEditingInventoryExport: (inventoryExport: InventoryExportDTO) => dispatch(setEditingInventoryExport(inventoryExport)),
    deleteInventoryExport: (id: number) => dispatch(deleteInventoryExport(id)),
    getUserSelectItems: () => dispatch(getUserSelectItems()),
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
  })
}

class InventoryExport extends React.Component<IInventoryExportProps, IInventoryExportStates> {

  constructor(props: IInventoryExportProps){
    super(props);

    this.state = {
      data: [],
      page: 0,
      pageSize: 20,
      totalCount: 0,
      searchString: '',
      dateFrom: moment().format(GlobalConst.DATE_FORMAT),
      dateTo: moment().format(GlobalConst.DATE_FORMAT),
      fromBranchId: 0,
      toBranchId: 0,
      userId: 0,
      status: -1,
    }

    this._getInventoryExportWithDebounce = _.debounce(this._getInventoryExportWithDebounce.bind(this), GlobalConst.DEBOUNCE_TIME);
  }

  componentDidMount() {
    !this.props.userSelectItems?.length && this.props.getUserSelectItems();
    !this.props.branchSelectItems?.length && this.props.getBranchSelectItems();
    
    this.props.setActiveRoute([{path: URL.InventoryExport, name: this.props.t('menu:inventoryExport')}]);
  }

  render() {
    const { t, classes } = this.props;
    let { data, page, pageSize, totalCount } = this.state;
    
    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={Assignment}
          iconColor={'green'}
          title={t('inventoryExport:inventoryExport')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>
            <CustomInput
              labelText={t('common:search')}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                value: this.state.searchString,
                name: 'searchString',
                onChange: this._onSearchStringChange.bind(this)
              }}
            />

            <div className={classes.right}>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onEdit.bind(this, new InventoryExportDTO())}>
                <Add className={classes.icons} /> {t('button:add')}
              </Button>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onRefresh.bind(this)}>
                <Replay className={classes.icons} /> {t('button:refresh')}
              </Button>
            </div>
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs: 12}]}>
            <Accordion
              active={-1}
              collapses={[
                {
                  title: t('common:advance_search'),
                  content: this._renderAdvancedSearch()
                }
              ]}
            />
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs:12}]}>
            <DataList 
              currentPage={page}
              pageSize={pageSize}
              totalPage={Math.ceil(totalCount / pageSize)}
              data={this._mapData(data)}
              columns={this._getColumns()}
              fetchData={this._onLoadData.bind(this)}
              onChangePage={this._onChangePage.bind(this)}
            />
          </ColumnContainer>
          
          {this.state.showDeleteConfirm && <SweetAlert
            warning
            //style={{ display: "block", marginTop: "-100px" }}
            title={t('message:confirm_delete')}
            onConfirm={this._onDelete.bind(this)}
            onCancel={() => this.setState({ showDeleteConfirm: false})}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={t('button:delete')}
            cancelBtnText={t('button:cancel')}
            showCancel
          >
          </SweetAlert>}

        </IconCard>
      </ColumnContainer>
    )
  }

  private _renderAdvancedSearch() {
    const { t, classes, branchSelectItems, userSelectItems } = this.props;
    let { dateFrom, dateTo, fromBranchId, toBranchId, userId, status } = this.state;

    let statusSelectOptions = [
      { value: -1, text: t('common:all') } as SelectItemDTO,
      { value: InventoryExportStatus.Draft, text: t('inventoryExport:status_draft')} as SelectItemDTO,
      { value: InventoryExportStatus.PendingApprove, text: t('inventoryExport:status_pending_approval')} as SelectItemDTO,
      { value: InventoryExportStatus.Approved, text: t('inventoryExport:status_approved')} as SelectItemDTO,
      { value: InventoryExportStatus.Canceled, text: t('inventoryExport:status_cancelled')} as SelectItemDTO,
    ]

    return (
      <ColumnContainer columnWidth={[{xs:12, sm:6, md:4}]}>

        <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
          <FormLabel className={classes.labelHorizontal}> {t('inventoryExport:date_from')} </FormLabel>
          <Datetime
            dateFormat={GlobalConst.DATE_FORMAT}
            timeFormat={false}
            inputProps={{ placeholder: t('inventoryExport:date_from') }}
            onChange={(value: string | Moment) => this.setState({ dateFrom: value ? moment(value).format(GlobalConst.DATE_FORMAT) : '', page: 0 }, () => this._search())}
            value={dateFrom}
          />
        </ColumnContainer>

        <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
          <FormLabel className={classes.labelHorizontal}> {t('inventoryExport:date_to')} </FormLabel>
          <Datetime
            dateFormat={GlobalConst.DATE_FORMAT}
            timeFormat={false}
            inputProps={{ placeholder: t('inventoryExport:date_to') }}
            onChange={(value: string | Moment) => this.setState({ dateTo: value ? moment(value).format(GlobalConst.DATE_FORMAT) : '', page: 0 }, () => this._search())}
            value={dateTo}
          />
        </ColumnContainer>

        <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
          <FormLabel className={classes.labelHorizontal}> {t('inventoryExport:status')} </FormLabel>
          <CustomSelect 
            value={status}
            onChange={(value: number) => this.setState({ status: value, page: 0 }, () => this._search()) }
            items={statusSelectOptions}
            label={t('inventoryExport:status')} />
        </ColumnContainer>

        <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
          <FormLabel className={classes.labelHorizontal}> {t('inventoryExport:from_branch')} </FormLabel>
          <Autocomplete
            value={fromBranchId}
            includeEmptyOption
            emptyText={t('common:default')}
            options={branchSelectItems}
            onChange={(value: number) => this.setState({ fromBranchId: value, page: 0 }, () => this._search()) }
          />
        </ColumnContainer>

        <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
          <FormLabel className={classes.labelHorizontal}> {t('inventoryExport:to_branch')} </FormLabel>
          <Autocomplete
            value={toBranchId}
            includeEmptyOption
            emptyText={t('common:default')}
            options={branchSelectItems}
            onChange={(value: number) => this.setState({ toBranchId: value, page: 0 }, () => this._search()) }
          />
        </ColumnContainer>

        <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
          <FormLabel className={classes.labelHorizontal}> {t('inventoryExport:user')} </FormLabel>
          <Autocomplete
            value={userId}
            includeEmptyOption
            emptyText={t('common:all')}
            options={userSelectItems}
            onChange={(value: number) => this.setState({ userId: value, page: 0 }, () => this._search()) }
          />
        </ColumnContainer>

      </ColumnContainer>
    )
  }

  private _getFilter(tableState: any = null): FilterDTO {
    let { pageSize, searchString, dateFrom, dateTo, fromBranchId, toBranchId, status, userId } = this.state;

    let filter: FilterDTO = new FilterDTO(tableState);
    if (!tableState) {
      filter.page = 0;
    }
    filter.pageSize = pageSize;
    filter.searchString = searchString;
    filter.filters = [
      { key: 'dateFrom', value: dateFrom } as FilterItemDTO,
      { key: 'dateTo', value: dateTo } as FilterItemDTO
    ];
    if (fromBranchId) {
      filter.filters.push({ key: 'from_branch', value: fromBranchId } as FilterItemDTO);
    }
    if (toBranchId) {
      filter.filters.push({ key: 'to_branch', value: toBranchId } as FilterItemDTO);
    }
    if (status >= 0) {
      filter.filters.push({ key: 'status', value: status } as FilterItemDTO);
    }
    if (userId) {
      filter.filters.push({ key: 'user', value: userId } as FilterItemDTO);
    }

    return filter;
  }

  _getInventoryExportWithDebounce(filterDTO: FilterDTO) {
    this.props.getInventoryExportList(filterDTO).then((response: any) => {
      if (response && !response.errorCode) {
        this.setState({ data: response.data, totalCount: response.totalCount });
      } else {
        showError(this.props.t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _onSearchStringChange(event: any) {
    event.persist && event.persist();
    const { target: { value } } = event;
    this.setState({ searchString: value || '', page: 0 }, () => this._search());
  }

  private _search() {
    this._getInventoryExportWithDebounce(this._getFilter());    
  }

  private _getColumns() {
    const { t } = this.props;
    return [
      { Header: t('inventoryExport:number'), accessor: "number", sortable: false, filterable: false },
      { Header: t('inventoryExport:date'), accessor: "dateExport", sortable: false, filterable: false },
      { Header: t('inventoryExport:from_branch'), accessor: "fromBranch", sortable: false, filterable: false },
      { Header: t('inventoryExport:to_branch'), accessor: "toBranch", sortable: false, filterable: false },
      { Header: t('inventoryExport:status'), accessor: "status", sortable: false, filterable: false },
      { Header: '', accessor: "actions", sortable: false, filterable: false }
    ]
  }

  private _onEdit(inventoryExport: InventoryExportDTO) {
    this.props.setEditingInventoryExport(inventoryExport);
    history.push(URL.InventoryExportDetail.replace(':id', inventoryExport.id.toString()));
  }

  private _onDelete() {
    const { t } = this.props;

    this.setState({ showDeleteConfirm: false });
    this.props.deleteInventoryExport(this.state.deletingId).then((response: any) => {
      if (response && !response.errorCode) {
        toast.success(t('message:delete_success'));
        this._onRefresh();
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _mapData(data: InventoryExportDTO[]) {
    const {t, branchSelectItems} = this.props;
    if (!data) return [];

    const statusText = [
      { value: InventoryExportStatus.Draft, text: t('inventoryExport:status_draft')},
      { value: InventoryExportStatus.PendingApprove, text: t('inventoryExport:status_pending_approval')},
      { value: InventoryExportStatus.Approved, text: t('inventoryExport:status_approved')},
      { value: InventoryExportStatus.Canceled, text: t('inventoryExport:status_cancelled')},
    ]

    return data.map((u: InventoryExportDTO) => {
      return {
        ...u,
        fromBranch: branchSelectItems.find(b => b.value === u.fromBranchId)?.text ?? '',
        toBranch: branchSelectItems.find(b => b.value === u.toBranchId)?.text ?? '',
        status: statusText.find(s => s.value === u.status)?.text,
        actions: (
          <div className="actions-right">
            <IconButton className='small-action' onClick={this._onEdit.bind(this, u)}> <Edit /> </IconButton>
            <IconButton className='small-action' onClick={() => this.setState({ showDeleteConfirm: true, deletingId: u.id })}> <Delete color='error' /> </IconButton>
          </div>
        )
      }
    });
  }

  private _onLoadData(tableState: any, instance: any) {
    this._getInventoryExportWithDebounce(this._getFilter(tableState));
  }

  private _onRefresh() {
    this._getInventoryExportWithDebounce(this._getFilter());
  }

  private _onChangePage(pageIndex: any) {
    this.setState({ page: pageIndex});
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(InventoryExport)));