import React from 'react';
import { FormControl, Select, withStyles, MenuItem } from "@material-ui/core";
import customSelectStyle from './CustomSelectStyle';
import { SelectItemDTO } from '../../DTO';

function CustomSelect({ ...props }) {
  const { classes, value, onChange, name, items, label, multiple, disabled } = props;
  let needParseInt = items && items.length && items.every((i: any) => parseInt(i.value).toString() === i.value) ;

  let onChangeValue = (event: any) => {
    if (!onChange) {
      return
    }
    event.persist && event.persist();
    if (multiple) {
      onChange(needParseInt ? (event.target?.value ?? []).map((i: any) => parseInt(i.toString())) : event.target?.value ?? [], event.target?.name)
    } else {
      onChange(needParseInt ? parseInt(event.target?.value) : event.target?.value, event.target?.name);
    }
  }

  return (
    <FormControl
      fullWidth
      className={classes.selectFormControl}
    >
      <Select
        MenuProps={{ className: classes.selectMenu }}
        classes={{ select: classes.select }}
        value={value || (multiple ? [] : 0)}
        onChange={onChangeValue}
        name={name}
        multiple={multiple}
        disabled={disabled}
        renderValue={(selected: any) => (multiple ? selected.map((select: string) => items?.find((i: SelectItemDTO) => i.value === select)?.text || '').join(', ') : (items?.find((i: SelectItemDTO) => i.value === selected)?.text || '') )}
      >
        {label && <MenuItem
          disabled
          classes={{ root: classes.selectMenuItem }}
          key={0}
        >
          {label}
        </MenuItem>}
        {items && items.map((i: SelectItemDTO) => {
          return (
            <MenuItem
              classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
              value={i.value}
              key={i.value}
            >
              {i.text}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default withStyles(customSelectStyle)(CustomSelect);