const TENANT_REQUEST: string = 'TENANT_REQUEST';
const TENANT_LIST_NAME_SUCCESS: string = 'TENANT_LIST_NAME_SUCCESS';
const TENANT_LIST_NAME_FAIL: string = 'TENANT_LIST_NAME_FAIL';

const TENANT_GET_INFO_SUCCESS: string = 'TENANT_GET_INFO_SUCCESS';
const TENANT_GET_INFO_FAIL: string = 'TENANT_GET_INFO_FAIL';

const initialState = {
  isLoading: false,
  tenantInfo: {}
}

export function tenantReducer(state = initialState, action: any) {

  switch (action.type) {

    case TENANT_REQUEST:
      return Object.assign({}, state, {
        isLoading: true
      });

    case TENANT_LIST_NAME_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false
      });

    case TENANT_LIST_NAME_FAIL:
      return Object.assign({}, state, {
        isLoading: false
      });

    case TENANT_GET_INFO_SUCCESS:
      return Object.assign({}, state, {
        tenantInfo: action.response.data
      })

    default:
      return state;

  }

}

export function getListTenantNames() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TENANT_REQUEST, TENANT_LIST_NAME_SUCCESS, TENANT_LIST_NAME_FAIL],
      EndPoint: 'tenants/ListName',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function getTenantInfo() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TENANT_REQUEST, TENANT_GET_INFO_SUCCESS, TENANT_GET_INFO_FAIL],
      EndPoint: 'tenants/info',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}