import { GenerateAppKeyDTO } from "../../DTO";

const APP_DOWNLOAD_REQUEST: string = 'APP_DOWNLOAD_REQUEST';


const APP_DOWNLOAD_GENERATE_KEY_SUCCESS: string = 'APP_DOWNLOAD_GENERATE_KEY_SUCCESS';
const APP_DOWNLOAD_GENERATE_KEY_FAIL: string = 'APP_DOWNLOAD_GENERATE_KEY_FAIL';

const initialState = {
}

export function appDownloadReducer(state = initialState, action: any) {

  switch (action.type) {

    default:
      return state;
      
  }

}

export function generateKeys(generateAppKeyDTO: GenerateAppKeyDTO) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: JSON.stringify(generateAppKeyDTO),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [APP_DOWNLOAD_REQUEST, APP_DOWNLOAD_GENERATE_KEY_SUCCESS, APP_DOWNLOAD_GENERATE_KEY_FAIL],
      EndPoint: `Mobiman\\generateKeys`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}