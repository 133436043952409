import { GlobalConst } from "../Consts";

let moment = require('moment');

export class ExpenseDTO {
  public id: number;
  public number: string;
  public date: string;
  public clientId: number;
  public isCash: boolean;
  public receiveBankId: number | null;
  public receiveBankNumber: string;
  public paymentAccountId: number | null;
  public amount: number;
  public detail: string;
  public status: number;
  public approvedById: number;
  public approvedDate: string;
  public branchId: number;
  public commissionUserId: number;
  public createdUser: string;

  constructor() {
    this.id = 0;
    this.number = '';
    this.date = moment().format(GlobalConst.DATE_TIME_FORMAT);
    this.clientId = 0;
    this.isCash = true;
    this.receiveBankId = null;
    this.receiveBankNumber = '';
    this.paymentAccountId = null;
    this.amount = 0;
    this.detail = '';
    this.status = 0;
    this.approvedById = 0;
    this.approvedDate = '';
    this.branchId = 0;
    this.commissionUserId = 0;
    this.createdUser = '';
  }
}