import { FilterDTO, SellSimDTO } from '../../DTO';

const SELL_SIM_REQUEST: string = 'SELL_SIM_REQUEST';

const SELL_SIM_GET_LIST_SUCCESS: string = 'SELL_SIM_GET_LIST_SUCCESS';
const SELL_SIM_GET_LIST_FAIL: string = 'SELL_SIM_GET_LIST_FAIL';

const SELL_SIM_SET_EDITING_SELL_SIM: string = 'SELL_SIM_SET_EDITING_SELL_SIM';

const SELL_SIM_GET_BY_ID_SUCCESS: string = 'SELL_SIM_GET_BY_ID_SUCCESS';
const SELL_SIM_GET_BY_ID_FAIL: string = 'SELL_SIM_GET_BY_ID_FAIL';

const SELL_SIM_SAVE_SUCCESS: string = 'SELL_SIM_SAVE_SUCCESS';
const SELL_SIM_SAVE_FAIL: string = 'SELL_SIM_SAVE_FAIL';

const SELL_SIM_DELETE_SUCCESS: string = 'SELL_SIM_DELETE_SUCCESS';
const SELL_SIM_DELETE_FAIL: string = 'SELL_SIM_DELETE_FAIL';

const SELL_SIM_APPROVE_SUCCESS: string = 'SELL_SIM_APPROVE_SUCCESS';
const SELL_SIM_APPROVE_FAIL: string = 'SELL_SIM_APPROVE_FAIL';

const SELL_SIM_CANCEL_SUCCESS: string = 'SELL_SIM_CANCEL_SUCCESS';
const SELL_SIM_CANCEL_FAIL: string = 'SELL_SIM_CANCEL_FAIL';

const SELL_SIM_EXPORT_LIST_SUCCESS: string = 'SELL_SIM_EXPORT_LIST_SUCCESS';
const SELL_SIM_EXPORT_LIST_FAIL: string = 'SELL_SIM_EXPORT_LIST_FAIL';

const initialState = {
  editingSellSim: {},
}

export function sellSimReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case SELL_SIM_SET_EDITING_SELL_SIM:
      return Object.assign({}, state, {
        editingSellSim: action.payload
      });

    default:
      return state;

  }

}

export function getListSellSim(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SELL_SIM_REQUEST, SELL_SIM_GET_LIST_SUCCESS, SELL_SIM_GET_LIST_FAIL],
      EndPoint: `SellSims${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingSellSim(sellSim: SellSimDTO) {
  return {
    type: SELL_SIM_SET_EDITING_SELL_SIM,
    payload: sellSim
  }
}

export function getSellSimById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SELL_SIM_REQUEST, SELL_SIM_GET_BY_ID_SUCCESS, SELL_SIM_GET_BY_ID_FAIL],
      EndPoint: `SellSims\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveSellSim(sellSim: SellSimDTO, submit: boolean) {
  let initRequest: RequestInit = {
    method: sellSim.id ? 'PATCH' : 'POST',
    body: JSON.stringify(sellSim),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SELL_SIM_REQUEST, SELL_SIM_SAVE_SUCCESS, SELL_SIM_SAVE_FAIL],
      EndPoint: `SellSims\\${submit}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteSellSim(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SELL_SIM_REQUEST, SELL_SIM_DELETE_SUCCESS, SELL_SIM_DELETE_FAIL],
      EndPoint: 'SellSims',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function approveSellSim(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SELL_SIM_REQUEST, SELL_SIM_APPROVE_SUCCESS, SELL_SIM_APPROVE_FAIL],
      EndPoint: 'SellSims\\Approve',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function cancelSellSim(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SELL_SIM_REQUEST, SELL_SIM_CANCEL_SUCCESS, SELL_SIM_CANCEL_FAIL],
      EndPoint: 'SellSims\\Cancel',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function exportSellSim(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SELL_SIM_REQUEST, SELL_SIM_EXPORT_LIST_SUCCESS, SELL_SIM_EXPORT_LIST_FAIL],
      EndPoint: `SellSims\\export${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}