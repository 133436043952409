import { GlobalConst } from "../Consts";

let moment = require('moment');

export class ReceiveViettelDTO {
  public id: number;
  public number: string;
  public time: string;
  public phone: string;
  public identityNumber: string;
  public receiverName: string;
  public secretCode: string;
  public amount: number;
  public fee: number;
  public discount: number;
  public commission: number;
  public status: number;
  public createdUser: string;
  public branchId: number;
  public commissionUserId: number;
  public approvedById: number;
  public approvedDate: string;
  public payByCash: boolean;
  public receiveAccountId: number | null;

  constructor() {
    this.id = 0;
    this.number = '';
    this.time = moment().format(GlobalConst.DATE_TIME_FORMAT);
    this.phone = '';
    this.identityNumber = '';
    this.receiverName = '';
    this.secretCode = '';
    this.amount = 0;
    this.fee = 0;
    this.discount = 0;
    this.commission = 0;
    this.status = 0;
    this.createdUser = '';
    this.branchId = 0;
    this.commissionUserId = 0;
    this.approvedById = 0;
    this.approvedDate = '';
    this.payByCash = true;
    this.receiveAccountId = null;
  }
}