import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Button, withStyles } from '@material-ui/core';
import paginationStyle from './PaginationStyle';

function Pagination({ ...props }) {
  const { classes, pages, color, onClick } = props;

  return (
    <ul className={classes.pagination}>
      {pages.map((prop, key) => {
        const paginationLink = cx({
          [classes.paginationLink]: true,
          [classes[color]]: prop.active,
          [classes.disabled]: prop.disabled
        });
        return (
          <li className={classes.paginationItem} key={key}>
            <Button disabled={!prop.text} onClick={() => !prop.active && onClick(prop.text - 1)} className={paginationLink}>
              {prop.text || '...'}
            </Button>
          </li>
        );
      })}
    </ul>
  );
}

Pagination.defaultProps = {
  color: "primary"
};

Pagination.propTypes = {
  classes: PropTypes.object.isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      disabled: PropTypes.bool,
      text: PropTypes.number,
      onClick: PropTypes.func
    })
  ).isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

export default withStyles(paginationStyle)(Pagination);