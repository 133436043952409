import { FilterDTO, NetworkProviderDTO } from '../../DTO';

const NETWORK_PROVIDER_REQUEST: string = 'NETWORK_PROVIDER_REQUEST';

const NETWORK_PROVIDER_GET_LIST_SUCCESS: string = 'NETWORK_PROVIDER_GET_LIST_SUCCESS';
const NETWORK_PROVIDER_GET_LIST_FAIL: string = 'NETWORK_PROVIDER_GET_LIST_FAIL';

const NETWORK_PROVIDER_SET_EDITING_NETWORK_PROVIDER: string = 'NETWORK_PROVIDER_SET_EDITING_NETWORK_PROVIDER';

const NETWORK_PROVIDER_GET_BY_ID_SUCCESS: string = 'NETWORK_PROVIDER_GET_BY_ID_SUCCESS';
const NETWORK_PROVIDER_GET_BY_ID_FAIL: string = 'NETWORK_PROVIDER_GET_BY_ID_FAIL';

const NETWORK_PROVIDER_SAVE_SUCCESS: string = 'NETWORK_PROVIDER_SAVE_SUCCESS';
const NETWORK_PROVIDER_SAVE_FAIL: string = 'NETWORK_PROVIDER_SAVE_FAIL';

const NETWORK_PROVIDER_DELETE_SUCCESS: string = 'NETWORK_PROVIDER_DELETE_SUCCESS';
const NETWORK_PROVIDER_DELETE_FAIL: string = 'NETWORK_PROVIDER_DELETE_FAIL';

const NETWORK_PROVIDER_GET_NAMES_SUCCESS: string = 'NETWORK_PROVIDER_GET_NAMES_SUCCESS';
const NETWORK_PROVIDER_GET_NAMES_FAIL: string = 'NETWORK_PROVIDER_GET_NAMES_SUCCESS';

const initialState = {
  editingNetworkProvider: {},
  networkProviderSelectItems: [],
}

export function networkProviderReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case NETWORK_PROVIDER_SET_EDITING_NETWORK_PROVIDER:
      return Object.assign({}, state, {
        editingNetworkProvider: action.payload
      });

    case NETWORK_PROVIDER_GET_NAMES_SUCCESS:
      return Object.assign({}, state, {
        networkProviderSelectItems: action.response?.data ?? []
      });

    default:
      return state;

  }

}

export function getListNetworkProvider(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [NETWORK_PROVIDER_REQUEST, NETWORK_PROVIDER_GET_LIST_SUCCESS, NETWORK_PROVIDER_GET_LIST_FAIL],
      EndPoint: `NetworkProviders${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingNetworkProvider(networkProvider: NetworkProviderDTO) {
  return {
    type: NETWORK_PROVIDER_SET_EDITING_NETWORK_PROVIDER,
    payload: networkProvider
  }
}

export function getNetworkProviderById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [NETWORK_PROVIDER_REQUEST, NETWORK_PROVIDER_GET_BY_ID_SUCCESS, NETWORK_PROVIDER_GET_BY_ID_FAIL],
      EndPoint: `NetworkProviders\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveNetworkProvider(networkProvider: NetworkProviderDTO) {
  let initRequest: RequestInit = {
    method: networkProvider.id ? 'PATCH' : 'POST',
    body: JSON.stringify(networkProvider),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [NETWORK_PROVIDER_REQUEST, NETWORK_PROVIDER_SAVE_SUCCESS, NETWORK_PROVIDER_SAVE_FAIL],
      EndPoint: 'NetworkProviders',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteNetworkProvider(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [NETWORK_PROVIDER_REQUEST, NETWORK_PROVIDER_DELETE_SUCCESS, NETWORK_PROVIDER_DELETE_FAIL],
      EndPoint: 'NetworkProviders',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function getNetworkProviderSelectItems() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [NETWORK_PROVIDER_REQUEST, NETWORK_PROVIDER_GET_NAMES_SUCCESS, NETWORK_PROVIDER_GET_NAMES_FAIL],
      EndPoint: 'NetworkProviders/selectList',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}