import { MessageType, MessageStatus } from "../Consts";

export class MessageDTO {
  public id: number;
  public subject: string;
  public content: string;
  public to: string;
  public cc: string;
  public bcc: string;
  public type: number;
  public isHTML: boolean;
  public noTried: number;
  public status: number;

  constructor() {
    this.id = 0;
    this.subject = '';
    this.content = '';
    this.to = '';
    this.cc = '';
    this.bcc = '';
    this.type = MessageType.Email;
    this.isHTML = true;
    this.noTried = 0;
    this.status = MessageStatus.Sending;
  }
}