import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL, GlobalConst } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, CustomSelect, ImageUpload, InputText, ColumnContainer, CustomInput } from '../../Components';
import { Person, Save, KeyboardArrowLeft } from '@material-ui/icons';
import { FormLabel, withStyles, InputAdornment } from '@material-ui/core';
import styles from '../../styles';
import { UserDTO, SelectItemDTO, TenantInfoDTO } from '../../DTO';
import { getUserById, saveUser } from '../../Redux/User/UserReducer';
import { showError, validEmail } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';
import { getRoleSelectItems } from '../../Redux/Role/RoleReducer';
let moment = require('moment');

interface IUserDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  tenantInfo: TenantInfoDTO;
  user: UserDTO;
  branchSelectItems: SelectItemDTO[],
  roleSelectItems: SelectItemDTO[],

  setActiveRoute: ActionCreator<any>,
  getUserById: ActionCreator<any>,
  saveUser: ActionCreator<any>,
  getBranchSelectItems: ActionCreator<any>,
  getRoleSelectItems: ActionCreator<any>,
}

interface IUserDetailStates {
  isSaving?: boolean;

  user: UserDTO;
  showValidation?: boolean;
  avatarFile?: any;
}

function mapStateToProps(state: any) {
  return ({
    tenantInfo: state.tenant.tenantInfo,
    user: state.user.editingUser,
    branchSelectItems: state.branch.branchSelectItems,
    roleSelectItems: state.role.roleSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getUserById: (id: number) => dispatch(getUserById(id)),
    saveUser: (user: UserDTO, avatarFile: any) => dispatch(saveUser(user, avatarFile)),
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
    getRoleSelectItems: () => dispatch(getRoleSelectItems()),
  })
}

class UserDetail extends React.Component<IUserDetailProps, IUserDetailStates> {

  constructor(props: IUserDetailProps) {
    super(props);
    this.state = {
      user: {...new UserDTO(), ...props.user}
    }
  }

  componentDidMount() {
    !this.props.branchSelectItems?.length && this.props.getBranchSelectItems();
    !this.props.roleSelectItems?.length && this.props.getRoleSelectItems();
    
    const { setActiveRoute, match, getUserById, t } = this.props;

    setActiveRoute([{path: URL.User, name: this.props.t('menu:user')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getUserById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.User);
          } else {
            this.setState({ user: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes, match, branchSelectItems, roleSelectItems, tenantInfo } = this.props;
    let { showValidation, user: { loginName, name, number, firstName, lastName, email, password, salary, repeatPassword, branchId, phone, roleIds, avatar, startDate, endDate } } = this.state;

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={Person}
          iconColor={'green'}
          title={t('common:information')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('user:number')} </FormLabel>
              <>
                <InputText name='number' values={number} onChange={this._onTextChange.bind(this)} />
                {showValidation && !loginName && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('user:login_name')} </FormLabel>
              <>
                {match.params.id === '0' ? 
                <CustomInput
                  labelText={''}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {(tenantInfo?.number ?? '').toLowerCase()}.
                      </InputAdornment>
                    ),
                    value: loginName,
                    name: 'loginName',
                    onChange: this._onUsernameChange.bind(this)
                  }}
                /> :
                <InputText values={loginName} disabled /> }
                {showValidation && !loginName && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('user:display_name')} </FormLabel>
              <>
                <InputText name='name' values={name} onChange={this._onTextChange.bind(this)} />
                {showValidation && !name && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <></>

            {parseInt(match.params.id) === 0 && 
            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('user:password')} </FormLabel>
              <>
                <InputText name='password' values={password} disabled={match.params.id > 0} type='password' onChange={this._onTextChange.bind(this)} />
                {showValidation && parseInt(match.params.id) === 0 && !password && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>}

            {parseInt(match.params.id) === 0 &&
            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('user:repeat_password')} </FormLabel>
              <>
                <InputText name='repeatPassword' values={repeatPassword} type='password' onChange={this._onTextChange.bind(this)} />
                {showValidation && parseInt(match.params.id) === 0 && !repeatPassword && <Danger>{t('message:required')}</Danger>}
                {showValidation && parseInt(match.params.id) === 0 && password && repeatPassword && password !== repeatPassword && <Danger>{t('message:repeat_password_not_match')}</Danger>}
              </>
            </ColumnContainer>}
            
            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('user:first_name')} </FormLabel>
              <>
                <InputText name='firstName' values={firstName} onChange={this._onTextChange.bind(this)} />
                {showValidation && !firstName && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('user:last_name')} </FormLabel>
              <>
                <InputText name='lastName' values={lastName} onChange={this._onTextChange.bind(this)} />
                {showValidation && !lastName && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('user:email')} </FormLabel>
              <>
                <InputText name='email' values={email} onChange={this._onTextChange.bind(this)} />
                {showValidation && email && !validEmail(email) && <Danger>{t('message:invalid_email')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('user:phone')} </FormLabel>
              <>
                <InputText name='phone' values={phone} onChange={this._onTextChange.bind(this)} />
                {showValidation && !phone && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('branch:name')} </FormLabel>
              <>
                <CustomSelect 
                  name={'branchId'}
                  value={branchId}
                  onChange={this._onNumberChange.bind(this)}
                  items={branchSelectItems}
                  label={t('branch:name')} />
                {showValidation && branchId === 0 && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('role:name')} </FormLabel>
              <>
                <CustomSelect 
                  name={'roleId'}
                  multiple
                  value={this.state.user.roleIds ?? []}
                  onChange={this._onRoleChange.bind(this)}
                  items={roleSelectItems}
                  label={t('role:name')} />
                {showValidation && roleIds.length === 0 && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('user:start_date')} </FormLabel>
              <Datetime
                dateFormat={GlobalConst.DATE_FORMAT}
                timeFormat={false}
                inputProps={{ placeholder: t('user:start_date') }}
                onChange={this._onStartDateChange.bind(this)}
                value={startDate}
              />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('user:end_date')} </FormLabel>
              <Datetime
                dateFormat={GlobalConst.DATE_FORMAT}
                timeFormat={false}
                inputProps={{ placeholder: t('user:end_date') }}
                onChange={this._onEndDateChange.bind(this)}
                value={endDate}
              />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('user:salary')} </FormLabel>
              <InputText name='salary' values={salary} numberOnly thousandSeparator onChange={this._onNumberChange.bind(this)} />
            </ColumnContainer>
            
            <></>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('user:avatar')} </FormLabel>
              <ImageUpload 
                image={avatar ? `/Upload/UserAvatar/${avatar}` : null}
                onImageChange={(file: any) => this.setState({ avatarFile: file })}/>
            </ColumnContainer>
          </ColumnContainer>

          <GridContainer justify="center">
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button>
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button>
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.User)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onStartDateChange(value: string | Moment) {
    let user = this.state.user;
    user.startDate = moment(value).format(GlobalConst.DATE_FORMAT);
    this.setState({ user: user });
  }

  private _onEndDateChange(value: string | Moment) {
    let user = this.state.user;
    user.endDate = moment(value).format(GlobalConst.DATE_FORMAT);
    this.setState({ user: user });
  }

  private _onRoleChange(values: number[]) {
    this.setState({ user: { ...this.state.user, roleIds: values } });
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ user: {...this.state.user, [name]: value }});
  }

  private _onUsernameChange(event: any) {
    event.persist();
    const { target: { name, value } } = event
    this.setState({ user: {...this.state.user, [name]: value}});
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ user: {...this.state.user, [name]: value }});
  }

  private _onValidate() {
    let { user: { loginName, name, branchId, phone, firstName, lastName, email } } = this.state;

    return loginName && name && firstName && lastName && branchId && phone && (!email || validEmail(email));
  }

  private _onSave(close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { avatarFile, user: { name, number, loginName, firstName, lastName, password, email, phone, branchId, roleIds, salary, startDate, endDate } } = this.state;
    let user = new UserDTO();
    user.id = parseInt(this.props.match.params.id);
    user.number = number;
    user.name = name;
    user.loginName = loginName;
    user.firstName = firstName;
    user.lastName = lastName;
    user.password = password;
    user.email = email;
    user.phone = phone;
    user.branchId = branchId;
    user.roleIds = roleIds;
    user.salary = salary;
    user.startDate = startDate;
    user.endDate = endDate;

    this.props.saveUser(user, avatarFile).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        user.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close) {
          history.push(URL.User);
        } else if (parseInt(match.params.id) === 0) {
          this.setState({ user: response.data });
          history.push(URL.UserDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(UserDetail)));