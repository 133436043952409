const SHOW_LOADING_INDICATOR: string = 'SHOW_LOADING_INDICATOR';
const HIDE_LOADING_INDICATOR: string = 'HIDE_LOADING_INDICATOR';
const SET_ACTIVE_ROUTE: string = 'SET_ACTIVE_ROUTE';
const GET_VERSIONS: string = 'GET_VERSIONS';

const initialState = {
  showLoadingIndicator: false,
  activeRoutes: [],
  versions: [],
}

export function commonReducer(state = initialState, action: any) {

  switch (action.type) {

    case SHOW_LOADING_INDICATOR:
      return Object.assign({}, state, {
        showLoadingIndicator: true
      });

    case HIDE_LOADING_INDICATOR:
      return Object.assign({}, state, {
        showLoadingIndicator: false
      });

    case SET_ACTIVE_ROUTE:
      return Object.assign({}, state, {
        activeRoutes: action.payload
      });

    case GET_VERSIONS:
      return Object.assign({}, state, {
        versions: action.response
      })

    default:
      return state;

  }

}

export function setLoadingIndicator(isShow: boolean) {
  return {
    type: isShow ? SHOW_LOADING_INDICATOR : HIDE_LOADING_INDICATOR
  }
}

export function setActiveRoute(routes: any[]) {
  return {
    type: SET_ACTIVE_ROUTE,
    payload: routes
  }
}