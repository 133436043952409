import { FilterDTO, InventoryImportDTO } from '../../DTO';

const INVENTORY_IMPORT_REQUEST: string = 'INVENTORY_IMPORT_REQUEST';

const INVENTORY_IMPORT_GET_LIST_SUCCESS: string = 'INVENTORY_IMPORT_GET_LIST_SUCCESS';
const INVENTORY_IMPORT_GET_LIST_FAIL: string = 'INVENTORY_IMPORT_GET_LIST_FAIL';

const INVENTORY_IMPORT_SET_EDITING_INVENTORY_IMPORT: string = 'INVENTORY_IMPORT_SET_EDITING_INVENTORY_IMPORT';

const INVENTORY_IMPORT_GET_BY_ID_SUCCESS: string = 'INVENTORY_IMPORT_GET_BY_ID_SUCCESS';
const INVENTORY_IMPORT_GET_BY_ID_FAIL: string = 'INVENTORY_IMPORT_GET_BY_ID_FAIL';

const INVENTORY_IMPORT_SAVE_SUCCESS: string = 'INVENTORY_IMPORT_SAVE_SUCCESS';
const INVENTORY_IMPORT_SAVE_FAIL: string = 'INVENTORY_IMPORT_SAVE_FAIL';

const INVENTORY_IMPORT_DELETE_SUCCESS: string = 'INVENTORY_IMPORT_DELETE_SUCCESS';
const INVENTORY_IMPORT_DELETE_FAIL: string = 'INVENTORY_IMPORT_DELETE_FAIL';

const INVENTORY_IMPORT_APPROVE_SUCCESS: string = 'INVENTORY_IMPORT_APPROVE_SUCCESS';
const INVENTORY_IMPORT_APPROVE_FAIL: string = 'INVENTORY_IMPORT_APPROVE_FAIL';

const INVENTORY_IMPORT_CANCEL_SUCCESS: string = 'INVENTORY_IMPORT_CANCEL_SUCCESS';
const INVENTORY_IMPORT_CANCEL_FAIL: string = 'INVENTORY_IMPORT_CANCEL_FAIL';

const initialState = {
  editingInventoryImport: {},
}

export function inventoryImportReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case INVENTORY_IMPORT_SET_EDITING_INVENTORY_IMPORT:
      return Object.assign({}, state, {
        editingInventoryImport: action.payload
      });

    default:
      return state;

  }

}

export function getListInventoryImport(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [INVENTORY_IMPORT_REQUEST, INVENTORY_IMPORT_GET_LIST_SUCCESS, INVENTORY_IMPORT_GET_LIST_FAIL],
      EndPoint: `InventoryImports${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingInventoryImport(inventoryImport: InventoryImportDTO) {
  return {
    type: INVENTORY_IMPORT_SET_EDITING_INVENTORY_IMPORT,
    payload: inventoryImport
  }
}

export function getInventoryImportById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [INVENTORY_IMPORT_REQUEST, INVENTORY_IMPORT_GET_BY_ID_SUCCESS, INVENTORY_IMPORT_GET_BY_ID_FAIL],
      EndPoint: `InventoryImports\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveInventoryImport(inventoryImport: InventoryImportDTO, action: string) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: JSON.stringify(inventoryImport),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [INVENTORY_IMPORT_REQUEST, INVENTORY_IMPORT_SAVE_SUCCESS, INVENTORY_IMPORT_SAVE_FAIL],
      EndPoint: `InventoryImports?action=${action}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteInventoryImport(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [INVENTORY_IMPORT_REQUEST, INVENTORY_IMPORT_DELETE_SUCCESS, INVENTORY_IMPORT_DELETE_FAIL],
      EndPoint: 'InventoryImports',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function approveInventoryImport(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [INVENTORY_IMPORT_REQUEST, INVENTORY_IMPORT_APPROVE_SUCCESS, INVENTORY_IMPORT_APPROVE_FAIL],
      EndPoint: 'Inventories\\Approve',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function cancelInventoryImport(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [INVENTORY_IMPORT_REQUEST, INVENTORY_IMPORT_CANCEL_SUCCESS, INVENTORY_IMPORT_CANCEL_FAIL],
      EndPoint: 'Inventories\\Cancel',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}