import { FilterDTO, PaymentAccountDTO } from '../../DTO';

const PAYMENT_ACCOUNT_REQUEST: string = 'PAYMENT_ACCOUNT_REQUEST';

const PAYMENT_ACCOUNT_GET_LIST_SUCCESS: string = 'PAYMENT_ACCOUNT_GET_LIST_SUCCESS';
const PAYMENT_ACCOUNT_GET_LIST_FAIL: string = 'PAYMENT_ACCOUNT_GET_LIST_FAIL';

const PAYMENT_ACCOUNT_SET_EDITING_PAYMENT_ACCOUNT: string = 'PAYMENT_ACCOUNT_SET_EDITING_PAYMENT_ACCOUNT';

const PAYMENT_ACCOUNT_GET_BY_ID_SUCCESS: string = 'PAYMENT_ACCOUNT_GET_BY_ID_SUCCESS';
const PAYMENT_ACCOUNT_GET_BY_ID_FAIL: string = 'PAYMENT_ACCOUNT_GET_BY_ID_FAIL';

const PAYMENT_ACCOUNT_SAVE_SUCCESS: string = 'PAYMENT_ACCOUNT_SAVE_SUCCESS';
const PAYMENT_ACCOUNT_SAVE_FAIL: string = 'PAYMENT_ACCOUNT_SAVE_FAIL';

const PAYMENT_ACCOUNT_DELETE_SUCCESS: string = 'PAYMENT_ACCOUNT_DELETE_SUCCESS';
const PAYMENT_ACCOUNT_DELETE_FAIL: string = 'PAYMENT_ACCOUNT_DELETE_FAIL';

const PAYMENT_ACCOUNT_GET_NAMES_SUCCESS: string = 'PAYMENT_ACCOUNT_GET_NAMES_SUCCESS';
const PAYMENT_ACCOUNT_GET_NAMES_FAIL: string = 'PAYMENT_ACCOUNT_GET_NAMES_FAIL';

const initialState = {
  editingPaymentAccount: {},

  paymentAccountSelectItems: [],
}

export function paymentAccountReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case PAYMENT_ACCOUNT_SET_EDITING_PAYMENT_ACCOUNT:
      return Object.assign({}, state, {
        editingPaymentAccount: action.payload
      });

    case PAYMENT_ACCOUNT_GET_NAMES_SUCCESS:
      return Object.assign({}, state, {
        paymentAccountSelectItems: action.response?.data ?? []
      })

    default:
      return state;

  }

}

export function getListPaymentAccount(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [PAYMENT_ACCOUNT_REQUEST, PAYMENT_ACCOUNT_GET_LIST_SUCCESS, PAYMENT_ACCOUNT_GET_LIST_FAIL],
      EndPoint: `PaymentAccounts${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingPaymentAccount(paymentAccount: PaymentAccountDTO) {
  return {
    type: PAYMENT_ACCOUNT_SET_EDITING_PAYMENT_ACCOUNT,
    payload: paymentAccount
  }
}

export function getPaymentAccountById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [PAYMENT_ACCOUNT_REQUEST, PAYMENT_ACCOUNT_GET_BY_ID_SUCCESS, PAYMENT_ACCOUNT_GET_BY_ID_FAIL],
      EndPoint: `PaymentAccounts\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function savePaymentAccount(paymentAccount: PaymentAccountDTO) {
  let initRequest: RequestInit = {
    method: paymentAccount.id ? 'PATCH' : 'POST',
    body: JSON.stringify(paymentAccount),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [PAYMENT_ACCOUNT_REQUEST, PAYMENT_ACCOUNT_SAVE_SUCCESS, PAYMENT_ACCOUNT_SAVE_FAIL],
      EndPoint: 'PaymentAccounts',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deletePaymentAccount(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [PAYMENT_ACCOUNT_REQUEST, PAYMENT_ACCOUNT_DELETE_SUCCESS, PAYMENT_ACCOUNT_DELETE_FAIL],
      EndPoint: 'PaymentAccounts',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function getPaymentAccountSelectItems() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [PAYMENT_ACCOUNT_REQUEST, PAYMENT_ACCOUNT_GET_NAMES_SUCCESS, PAYMENT_ACCOUNT_GET_NAMES_FAIL],
      EndPoint: 'PaymentAccounts/selectList',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}