export class CurrentUserDTO {
  public id: number;
  public loginName: string;
  public name: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone: string;
  public functionIds: number[];
  public avatar: string;
  public password: string;
  public repeatPassword: string;
  public branchId: number;
  

  constructor() {
    this.id = 0;
    this.loginName = '';
    this.name = '';
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.phone = '';
    this.functionIds = [];
    this.avatar = '';
    this.password = '';
    this.repeatPassword = '';
    this.branchId = 0;
  }
}

export function currrentUserToFormData(user: CurrentUserDTO) {
  let formData = new FormData();
  formData.append('name', user.name);
  formData.append('firstName', user.firstName ?? '');
  formData.append('lastName', user.lastName ?? '');
  formData.append('email', user.email ?? '');
  formData.append('phone', user.phone ?? '');
  formData.append('password', user.password ?? '');

  return formData;
}