import { FilterDTO, SupplierDTO } from '../../DTO';

const SUPPLIER_REQUEST: string = 'SUPPLIER_REQUEST';

const SUPPLIER_GET_LIST_SUCCESS: string = 'SUPPLIER_GET_LIST_SUCCESS';
const SUPPLIER_GET_LIST_FAIL: string = 'SUPPLIER_GET_LIST_FAIL';

const SUPPLIER_SET_EDITING_SUPPLIER: string = 'SUPPLIER_SET_EDITING_SUPPLIER';

const SUPPLIER_GET_BY_ID_SUCCESS: string = 'SUPPLIER_GET_BY_ID_SUCCESS';
const SUPPLIER_GET_BY_ID_FAIL: string = 'SUPPLIER_GET_BY_ID_FAIL';

const SUPPLIER_SAVE_SUCCESS: string = 'SUPPLIER_SAVE_SUCCESS';
const SUPPLIER_SAVE_FAIL: string = 'SUPPLIER_SAVE_FAIL';

const SUPPLIER_DELETE_SUCCESS: string = 'SUPPLIER_DELETE_SUCCESS';
const SUPPLIER_DELETE_FAIL: string = 'SUPPLIER_DELETE_FAIL';

const SUPPLIER_GET_NAMES_SUCCESS: string = 'SUPPLIER_GET_NAMES_SUCCESS';
const SUPPLIER_GET_NAMES_FAIL: string = 'SUPPLIER_GET_NAMES_FAIL';

const SUPPLIER_EXPORT_LIST_SUCCESS: string = 'SUPPLIER_EXPORT_LIST_SUCCESS';
const SUPPLIER_EXPORT_LIST_FAIL: string = 'SUPPLIER_EXPORT_LIST_FAIL';

const initialState = {
  editingSupplier: {},
  supplierSelectItems: [],
}

export function supplierReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case SUPPLIER_SET_EDITING_SUPPLIER:
      return Object.assign({}, state, {
        editingSupplier: action.payload
      });

    case SUPPLIER_GET_NAMES_SUCCESS:
      return Object.assign({}, state, {
        supplierSelectItems: action.response?.data ?? []
      })

    default:
      return state;

  }

}

export function getListSupplier(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SUPPLIER_REQUEST, SUPPLIER_GET_LIST_SUCCESS, SUPPLIER_GET_LIST_FAIL],
      EndPoint: `Suppliers${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingSupplier(supplier: SupplierDTO) {
  return {
    type: SUPPLIER_SET_EDITING_SUPPLIER,
    payload: supplier
  }
}

export function getSupplierById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SUPPLIER_REQUEST, SUPPLIER_GET_BY_ID_SUCCESS, SUPPLIER_GET_BY_ID_FAIL],
      EndPoint: `Suppliers\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveSupplier(supplier: SupplierDTO) {
  let initRequest: RequestInit = {
    method: supplier.id ? 'PATCH' : 'POST',
    body: JSON.stringify(supplier),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SUPPLIER_REQUEST, SUPPLIER_SAVE_SUCCESS, SUPPLIER_SAVE_FAIL],
      EndPoint: 'Suppliers',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteSupplier(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SUPPLIER_REQUEST, SUPPLIER_DELETE_SUCCESS, SUPPLIER_DELETE_FAIL],
      EndPoint: 'Suppliers',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function getSupplierSelectItems() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SUPPLIER_REQUEST, SUPPLIER_GET_NAMES_SUCCESS, SUPPLIER_GET_NAMES_FAIL],
      EndPoint: 'Suppliers/selectList',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}