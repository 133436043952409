import { FilterDTO, ProductDTO } from '../../DTO';

const PRODUCT_REQUEST: string = 'PRODUCT_REQUEST';

const PRODUCT_GET_LIST_SUCCESS: string = 'PRODUCT_GET_LIST_SUCCESS';
const PRODUCT_GET_LIST_FAIL: string = 'PRODUCT_GET_LIST_FAIL';

const PRODUCT_SET_EDITING_PRODUCT: string = 'PRODUCT_SET_EDITING_PRODUCT';

const PRODUCT_GET_BY_ID_SUCCESS: string = 'PRODUCT_GET_BY_ID_SUCCESS';
const PRODUCT_GET_BY_ID_FAIL: string = 'PRODUCT_GET_BY_ID_FAIL';

const PRODUCT_SAVE_SUCCESS: string = 'PRODUCT_SAVE_SUCCESS';
const PRODUCT_SAVE_FAIL: string = 'PRODUCT_SAVE_FAIL';

const PRODUCT_DELETE_SUCCESS: string = 'PRODUCT_DELETE_SUCCESS';
const PRODUCT_DELETE_FAIL: string = 'PRODUCT_DELETE_FAIL';

const PRODUCT_GET_ALL_SUCCESS: string = 'PRODUCT_GET_ALL_SUCCESS';
const PRODUCT_GET_ALL_FAIL: string = 'PRODUCT_GET_ALL_FAIL';

const PRODUCT_GET_NAMES_SUCCESS: string = 'PRODUCT_GET_NAMES_SUCCESS';
const PRODUCT_GET_NAMES_FAIL: string = 'PRODUCT_GET_NAMES_FAIL';

const PRODUCT_EXPORT_LIST_SUCCESS: string = 'PRODUCT_EXPORT_LIST_SUCCESS';
const PRODUCT_EXPORT_LIST_FAIL: string = 'PRODUCT_EXPORT_LIST_FAIL';

const initialState = {
  editingProduct: {},
  allProducts: [],
  productSelectItems: [],
}

export function productReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case PRODUCT_GET_ALL_SUCCESS:
      if (!action.response.errorCode) {
        return Object.assign({}, state, {
          allProducts: action.response.data
        });
      } else {
        return state;
      }
        

    case PRODUCT_SET_EDITING_PRODUCT:
      return Object.assign({}, state, {
        editingProduct: action.payload
      });

    case PRODUCT_GET_NAMES_SUCCESS:
      return Object.assign({}, state, {
        productSelectItems: action.response?.data ? action.response.data : []
      })

    default:
      return state;

  }

}

export function getListProduct(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [PRODUCT_REQUEST, PRODUCT_GET_LIST_SUCCESS, PRODUCT_GET_LIST_FAIL],
      EndPoint: `Products${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingProduct(product: ProductDTO) {
  return {
    type: PRODUCT_SET_EDITING_PRODUCT,
    payload: product
  }
}

export function getProductById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [PRODUCT_REQUEST, PRODUCT_GET_BY_ID_SUCCESS, PRODUCT_GET_BY_ID_FAIL],
      EndPoint: `Products\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveProduct(product: ProductDTO) {
  let initRequest: RequestInit = {
    method: product.id ? 'PATCH' : 'POST',
    body: JSON.stringify(product),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [PRODUCT_REQUEST, PRODUCT_SAVE_SUCCESS, PRODUCT_SAVE_FAIL],
      EndPoint: 'Products',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteProduct(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [PRODUCT_REQUEST, PRODUCT_DELETE_SUCCESS, PRODUCT_DELETE_FAIL],
      EndPoint: 'Products',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function getAllProduct() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  let filterDTO = new FilterDTO();
  filterDTO.pageSize = 9999;

  return {
    CallAPI: {
      Actions: [PRODUCT_REQUEST, PRODUCT_GET_ALL_SUCCESS, PRODUCT_GET_ALL_FAIL],
      EndPoint: `Products${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function getProductSelectItems() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [PRODUCT_REQUEST, PRODUCT_GET_NAMES_SUCCESS, PRODUCT_GET_NAMES_FAIL],
      EndPoint: 'Products/selectList',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function exportProduct() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [PRODUCT_REQUEST, PRODUCT_EXPORT_LIST_SUCCESS, PRODUCT_EXPORT_LIST_FAIL],
      EndPoint: `Products\\export`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}