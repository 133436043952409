import React from 'react';
import { connect } from 'react-redux';
import { URL } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { ActionCreator } from 'redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { GridContainer, GridItem, ChartCard, Button } from '../../Components';
import ChartistGraph from "react-chartist";
import { Refresh, ArrowUpward, AccessTime, Money } from '@material-ui/icons';
import { withStyles, Tooltip } from '@material-ui/core';
import dashboardStyle from './DashboardStyle';
var Chartist = require("chartist");

interface IDashboardProps extends WithTranslation {
  classes: any;

  setActiveRoute: ActionCreator<any>;
}

interface IDashboardStates {}

function mapStateToProps(state: any) {
  return ({})
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
  })
}

var delays = 80, durations = 500;
const dailySalesChart = {
  data: {
    labels: ["T2", "T3", "T4", "T5", "T6", "T7", "CN"],
    series: [[12, 17, 7, 17, 23, 18, 38]]
  },
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0
    }),
    low: 0,
    high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  },
  // for animation
  animation: {
    draw: function(data: any) {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint
          }
        });
      } else if (data.type === "point") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: "ease"
          }
        });
      }
    }
  }
};

const emailsSubscriptionChart = {
  data: {
    labels: [
      "T1",
      "T2",
      "T3",
      "T4",
      "T5",
      "T6",
      "T7",
      "T8",
      "T9",
      "T10",
      "T11",
      "T12"
    ],
    series: [[54, 44, 32, 78, 55, 45, 32, 43, 56, 61, 75, 89]]
  },
  options: {
    axisX: {
      showGrid: false
    },
    low: 0,
    high: 100,
    chartPadding: {
      top: 0,
      right: 5,
      bottom: 0,
      left: 0
    }
  },
  responsiveOptions: [
    [
      "screen and (max-width: 640px)",
      {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function(value: any) {
            return value[0];
          }
        }
      }
    ]
  ],
  animation: {
    draw: function(data: any) {
      if (data.type === "bar") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: "ease"
          }
        });
      }
    }
  }
};

const completedTasksChart = {
  data: {
    labels: ["10/2", "17/2", "24/2", '2/3', '9/3', '16/3', '23/3'],
    series: [[23, 75, 45, 30, 28, 24, 20]]
  },
  options: {
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0
    }),
    low: 0,
    high: 100, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  },
  animation: {
    draw: function(data: any) {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint
          }
        });
      } else if (data.type === "point") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: "ease"
          }
        });
      }
    }
  }
};

class Dashboard extends React.Component<IDashboardProps, IDashboardStates> {

  componentDidMount() {
    this.props.setActiveRoute([{path: URL.Dashboard, name: this.props.t('menu:dashboard')}]);
  }

  render() {
    const { classes } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <ChartCard
            chart={
              <ChartistGraph
                className="ct-chart-white-colors"
                data={dailySalesChart.data}
                type="Line"
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              />
            }
            underChart={
              <div>
                <Tooltip
                  title="Cập nhật"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button color="infoNoBackground" justIcon>
                    <Refresh className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </div>
            }
            hover
            chartColor="blue"
            title="Số đơn hàng"
            text={
              <span>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} />
                </span>{" "}
                Tăng 111% số đơn trong ngày hôm nay.
              </span>
            }
            statIcon={AccessTime}
            statText="cập nhật 4 phút trước"
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <ChartCard
            chart={
              <ChartistGraph
                className="ct-chart-white-colors"
                data={emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            }
            underChart={
              <div>
                <Tooltip
                  id="tooltip-top"
                  title="Cập nhật"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button color="infoNoBackground" justIcon>
                    <Refresh className={classes.underChartIcons} />
                  </Button>
                </Tooltip>                
              </div>
            }
            hover
            chartColor="orange"
            title="Doanh thu trong năm"
            text={<span>
              <span className={classes.successText}>
                <Money className={classes.upArrowCardCategory} />
              </span>{" "}
              Đơn vị: triệu đồng
            </span>}
            statIcon={AccessTime}
            statText="cập nhật 4 phút trước"
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <ChartCard
            chart={
              <ChartistGraph
                className="ct-chart-white-colors"
                data={completedTasksChart.data}
                type="Line"
                options={completedTasksChart.options}
                listener={completedTasksChart.animation}
              />
            }
            underChart={
              <div>
                <Tooltip
                  id="tooltip-top"
                  title="Cập nhật"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button color="infoNoBackground" justIcon>
                    <Refresh className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </div>
            }
            hover
            chartColor="red"
            title="Nợ cước"
            text={<span>
              <span className={classes.successText}>
                <Money className={classes.upArrowCardCategory} />
              </span>{" "}
              Đơn vị: triệu đồng
            </span>}
            statIcon={AccessTime}
            statText="cập nhật 4 phút trước"
          />
        </GridItem>

      </GridContainer>
    )
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(dashboardStyle)(Dashboard)));