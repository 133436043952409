import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { GlobalConst, InventoryImportStatus, Permission, URL, WorkflowStatus } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, InputText, ColumnContainer, StateButtons, DataList, CustomSelect, Autocomplete, Checkbox, Danger } from '../../Components';
import { People, Save, KeyboardArrowLeft, Add, Clear } from '@material-ui/icons';
import { Dialog, DialogTitle, DialogContent, DialogActions, FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { approveInventoryImport, cancelInventoryImport, getInventoryImportById, saveInventoryImport } from '../../Redux/InventoryImport/InventoryImportReducer';
import { currency, showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { CurrentUserDTO, InventoryImportDTO, InventoryImportItemDTO, SelectItemDTO } from '../../DTO';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
import { getProductSelectItems } from '../../Redux/Product/ProductReducer';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';
import { getPaymentAccountSelectItems } from '../../Redux/PaymentAccount/PaymentAccountReducer';
let moment = require('moment');

interface IInventoryImportDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  currentUser: CurrentUserDTO;
  inventoryImport: InventoryImportDTO;
  productSelectItems: SelectItemDTO[];
  branchSelectItems: SelectItemDTO[];
  paymentAccountSelectItems: SelectItemDTO[];

  setActiveRoute: ActionCreator<any>,
  getInventoryImportById: ActionCreator<any>,
  saveInventoryImport: ActionCreator<any>,
  getProductSelectItems: ActionCreator<any>,
  getBranchSelectItems: ActionCreator<any>,
  approveInventoryImport: ActionCreator<any>,
  cancelInventoryImport: ActionCreator<any>,
  getPaymentAccountSelectItems: ActionCreator<any>,
}

interface IInventoryImportDetailStates {
  isSaving?: boolean;

  inventoryImport: InventoryImportDTO;
  showValidation?: boolean;

  showEditDialog?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    inventoryImport: state.inventoryImport.editingInventoryImport,
    productSelectItems: state.product.productSelectItems,
    branchSelectItems: state.branch.branchSelectItems,
    currentUser: state.user.currentUser,
    paymentAccountSelectItems: state.paymentAccount.paymentAccountSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getInventoryImportById: (id: number) => dispatch(getInventoryImportById(id)),
    saveInventoryImport: (inventoryImport: InventoryImportDTO, action: string) => dispatch(saveInventoryImport(inventoryImport, action)),
    getProductSelectItems: () => dispatch(getProductSelectItems()),
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
    approveInventoryImport: (id: number) => dispatch(approveInventoryImport(id)),
    cancelInventoryImport: (id: number) => dispatch(cancelInventoryImport(id)),
    getPaymentAccountSelectItems: () => dispatch(getPaymentAccountSelectItems()),
  })
}

class InventoryImportDetail extends React.Component<IInventoryImportDetailProps, IInventoryImportDetailStates> {

  constructor(props: IInventoryImportDetailProps) {
    super(props);
    this.state = {
      inventoryImport: {...new InventoryImportDTO(), ...props.inventoryImport}
    }
  }

  componentDidMount() {
    const { setActiveRoute, match, getInventoryImportById, t } = this.props;
    !this.props.paymentAccountSelectItems?.length && this.props.getPaymentAccountSelectItems();

    setActiveRoute([{path: URL.InventoryImport, name: this.props.t('menu:inventoryImport')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    !this.props.productSelectItems?.length && this.props.getProductSelectItems();
    !this.props.branchSelectItems?.length && this.props.getBranchSelectItems();

    if (match.params.id > 0) {
      getInventoryImportById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.InventoryImport);
          } else {
            this.setState({ inventoryImport: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes, currentUser, branchSelectItems, paymentAccountSelectItems } = this.props;
    let { showValidation, inventoryImport: { id, number, status, dateImport, branchId, items, workflow, payByCash, receiveAccountId } } = this.state;

    let statusStr = WorkflowStatus.find(s => s.value === status)?.text ?? '';
    if (statusStr) {
      statusStr = t(`status:${statusStr}`);
    }
    let tDate = dateImport ? moment(dateImport, GlobalConst.DATE_TIME_FORMAT) : moment(new Date());
    let now = moment(new Date());
    let readOnly = (status === InventoryImportStatus.Approved || status === InventoryImportStatus.Canceled);

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={People}
          iconColor={'green'}
          title={t('common:information')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('inventoryImport:number')} </FormLabel>
              <InputText values={number} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('inventoryImport:status')} </FormLabel>
              <InputText values={statusStr} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('inventoryImport:date')} </FormLabel>
              {!readOnly ? 
                <Datetime
                  dateFormat={GlobalConst.DATE_FORMAT}
                  timeFormat={"HH:mm"}
                  inputProps={{ placeholder: t('inventoryImport:date') }}
                  onChange={this._onDateChange.bind(this)}
                  value={dateImport}
                /> : <InputText disabled values={dateImport} /> }
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('inventoryImport:total')} </FormLabel>
              <InputText values={currency(items ? items.map(i => i.price * i.quantity).reduce((a, b) => a + b, 0) : 0)} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('inventoryImport:branch')} </FormLabel>
              <Autocomplete
                value={branchId}
                disabled={readOnly}
                options={branchSelectItems}
                onChange={(value: number) => this.setState({ inventoryImport: { ...this.state.inventoryImport, branchId: value } }) }
              />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('card:pay_by_cash')} </FormLabel>
              <Checkbox
                checked={payByCash}
                name='payByCash'
                onClick={this._onCheckboxChange.bind(this)}
              />
            </ColumnContainer>

            {payByCash ? null : <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('card:receive_account')} </FormLabel>
              <>
                <CustomSelect 
                  name={'receiveAccountId'}
                  value={receiveAccountId}
                  onChange={this._onNumberChange.bind(this)}
                  items={paymentAccountSelectItems}
                  label={t('card:receive_account')}
                  disabled={readOnly} />
                {showValidation && !receiveAccountId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>}

          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs:12}]}>
            {!readOnly ? <ColumnContainer columnWidth={[{xs:12}]}>
              <div className={classes.right}>
                <Button color='primary' customClass={classes.marginRight} round onClick={this._onAddItem.bind(this)}>
                  <Add className={classes.icons} /> {t('button:add')}
                </Button>
              </div>
            </ColumnContainer> : null}

            <ColumnContainer columnWidth={[{xs:12}]}>
              <DataList 
                currentPage={0}
                pageSize={1000}
                totalPage={1}
                data={this._mapData(items, readOnly)}
                columns={this._getColumns()}
                // fetchData={this._onLoadData.bind(this)}
                // onChangePage={this._onChangePage.bind(this)}
                hidePaging
              />
            </ColumnContainer>
          </ColumnContainer>

          <GridContainer justify="center">
            { id === 0 ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSaveWF.bind(this, 'save')} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button> : 
            <StateButtons workflowButton={workflow?.workflowStates.find(s => s.status === status)?.workflowButtons ?? []} onSave={this._onSaveWF.bind(this)} /> }
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.InventoryImport)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:back')}
            </Button>
          </GridContainer>

        </IconCard>

      </ColumnContainer>
    )
  }

  private _onCheckboxChange(event: any) {
    event.persist && event.persist();
    const { target: { name, checked } } = event;
    let inventoryImport = this.state.inventoryImport;
    this.setState({ inventoryImport: {...inventoryImport, [name]: checked }});
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ inventoryImport: {...this.state.inventoryImport, [name]: value }});    
  }

  private _onApprove() {
    const { t } = this.props;
    this.setState({ isSaving: true });

    this.props.approveInventoryImport(this.state.inventoryImport.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.InventoryImport);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

  private _onCancel() {
    const { t } = this.props;
    this.setState({ isSaving: true });
    
    this.props.cancelInventoryImport(this.state.inventoryImport.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.InventoryImport);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

  private _onAddItem() {
    this.setState({ inventoryImport: {...this.state.inventoryImport, items: [...this.state.inventoryImport.items, {id: 0, productId: 0, productName: '', quantity: 0, price: 0}]} })
  }

  private _getColumns() {
    const { t } = this.props;
    return [
      { Header: t('inventoryImport:product'), accessor: "productId", sortable: false, filterable: false },
      { Header: t('inventoryImport:quantity'), accessor: "quantity", sortable: false, filterable: false },
      { Header: t('inventoryImport:price'), accessor: "price", sortable: false, filterable: false },
      { Header: t('inventoryImport:total'), accessor: "total", sortable: false, filterable: false },
    ]
  }

  private _mapData(data: InventoryImportItemDTO[], readOnly: boolean) {
    const { t, productSelectItems } = this.props;
    if (!data) return [];

    return data.map((i: InventoryImportItemDTO, idx: number) => {
      return {
        ...i,
        productId: <CustomSelect name='productId' disabled={readOnly} value={i.productId} onChange={this._onChange.bind(this, idx)} items={productSelectItems} />,
        quantity: <InputText name='quantity' disabled={readOnly} value={i.quantity} numberOnly thousandSeparator onChange={this._onChange.bind(this, idx)} />,
        price: <InputText name='price' disabled={readOnly} value={i.price} numberOnly thousandSeparator onChange={this._onChange.bind(this, idx)} />,
        total: currency(i.price * i.quantity)
      }
    });
  }

  private _onChange(idx: number, value: number, name: string) {
    let imports = [...this.state.inventoryImport.items];
    imports[idx][name] = value;
    this.setState({ inventoryImport: {...this.state.inventoryImport, items: imports} })
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ inventoryImport: {...this.state.inventoryImport, [name]: value }});    
  }

  private _onDateChange(value: string | Moment) {
    let inventoryImport = this.state.inventoryImport;
    inventoryImport.dateImport = moment(value).format(GlobalConst.DATE_TIME_FORMAT);
    this.setState({ inventoryImport: inventoryImport });
  }

  private _onValidate() {
    let { inventoryImport: { dateImport, branchId, items, payByCash, receiveAccountId } } = this.state;

    return dateImport && items && items.length && branchId && (payByCash || receiveAccountId);
  }

  private _onSaveWF(action: string) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false, isSaving: true });

    const { t, match } = this.props;
    let { inventoryImport: { dateImport, branchId, items, payByCash, receiveAccountId } } = this.state;
    let inventoryImport = new InventoryImportDTO();
    inventoryImport.id = parseInt(this.props.match.params.id);
    inventoryImport.dateImport = dateImport;
    inventoryImport.branchId = branchId;
    inventoryImport.items = items;
    inventoryImport.payByCash = payByCash;
    inventoryImport.receiveAccountId = payByCash ? null : receiveAccountId

    this.props.saveInventoryImport(inventoryImport, action).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        inventoryImport.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (parseInt(match.params.id) === 0) {
          history.push(URL.InventoryImportDetail.replace(':id', response.data.id));
        } else if (action !== 'save') { 
          history.push(URL.InventoryImport);
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

  // private _onSave(close: boolean = true) {
  //   if (!this._onValidate()) {
  //     this.setState({ showValidation: true });
  //     return;
  //   }
  //   this.setState({ showValidation: false });

  //   this.setState({ isSaving: true});
  //   const { t, match } = this.props;
  //   let { inventoryImport: { dateImport } } = this.state;
  //   let inventoryImport = new InventoryImportDTO();
  //   inventoryImport.id = parseInt(this.props.match.params.id);
  //   inventoryImport.dateImport = dateImport;

  //   this.props.saveInventoryImport(inventoryImport).then((response: any) => {
  //     this.setState({ isSaving: false });
  //     if (response && !response.errorCode) {
  //       inventoryImport.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
  //       if (close) {
  //         history.push(URL.InventoryImport);
  //       } else if (parseInt(match.params.id) === 0) {
  //         history.push(URL.InventoryImportDetail.replace(':id', response.data.id));
  //       }
  //     } else {
  //       showError(t, response.errorCode, response.errorMessage);        
  //     }
  //   })
  // }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(InventoryImportDetail)));