import { SellProductItemDTO } from ".";
import { GlobalConst } from "../Consts";
import { WorkflowDTO } from "./WorkflowDTO";

let moment = require('moment');

export class SellProductDTO {
  public id: number;
  public number: string;
  public date: string;
  public branchId: number;
  public total: number;
  public status: number;
  public workflow: WorkflowDTO;
  public items: SellProductItemDTO[];
  public payByCash: boolean;
  public receiveAccountId: number | null;

  constructor() {
    this.id = 0;
    this.number = '';
    this.status = 0;
    this.date = moment().format(GlobalConst.DATE_TIME_FORMAT);
    this.branchId = 0;
    this.total = 0;
    this.workflow = new WorkflowDTO();
    this.items = [];
    this.payByCash = true;
    this.receiveAccountId = null;
  }
}