import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer, Autocomplete } from '../../Components';
import { People, Save, KeyboardArrowLeft } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getTelecomServiceById, saveTelecomService } from '../../Redux/TelecomService/TelecomServiceReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { TelecomServiceDTO, SelectItemDTO } from '../../DTO';
import { getNetworkProviderSelectItems } from '../../Redux/NetworkProvider/NetworkProviderReducer';

interface ITelecomServiceDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  telecomService: TelecomServiceDTO;
  networkProviderSelectItems: SelectItemDTO[];

  setActiveRoute: ActionCreator<any>,
  getTelecomServiceById: ActionCreator<any>,
  saveTelecomService: ActionCreator<any>,
  getNetworkProviderSelectItems: ActionCreator<any>,
}

interface ITelecomServiceDetailStates {
  isSaving?: boolean;

  telecomService: TelecomServiceDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    telecomService: state.telecomService.editingTelecomService,
    networkProviderSelectItems: state.networkProvider.networkProviderSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getTelecomServiceById: (id: number) => dispatch(getTelecomServiceById(id)),
    saveTelecomService: (telecomService: TelecomServiceDTO) => dispatch(saveTelecomService(telecomService)),
    getNetworkProviderSelectItems: () => dispatch(getNetworkProviderSelectItems()),
  })
}

class TelecomServiceDetail extends React.Component<ITelecomServiceDetailProps, ITelecomServiceDetailStates> {

  constructor(props: ITelecomServiceDetailProps) {
    super(props);
    this.state = {
      telecomService: {...new TelecomServiceDTO(), ...props.telecomService}
    }
  }

  componentDidMount() {
    !this.props.networkProviderSelectItems?.length && this.props.getNetworkProviderSelectItems();

    const { setActiveRoute, match, getTelecomServiceById, t } = this.props;

    setActiveRoute([{path: URL.TelecomService, name: this.props.t('menu:telecomService')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getTelecomServiceById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.TelecomService);
          } else {
            this.setState({ telecomService: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes, networkProviderSelectItems } = this.props;
    let { showValidation, telecomService: { name, number, salePrice, multipleSalePrice, price, note, networkProviderId } } = this.state;

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={People}
          iconColor={'green'}
          title={t('common:information')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('telecomService:name')} </FormLabel>
              <>
                <InputText name='name' values={name} onChange={this._onTextChange.bind(this)} />
                {showValidation && !name && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('telecomService:number')} </FormLabel>
              <>
                <InputText name='number' values={number} onChange={this._onTextChange.bind(this)} />
                {showValidation && !number && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            {/* <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('telecomService:sale_price')} </FormLabel>
              <>
                <InputText numberOnly thousandSeparator name='salePrice' values={salePrice} onChange={this._onNumberChange.bind(this)} />
                {showValidation && !salePrice && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('telecomService:multiple_sale_price')} </FormLabel>
              <>
                <InputText numberOnly thousandSeparator name='multipleSalePrice' values={multipleSalePrice} onChange={this._onNumberChange.bind(this)} />
                {showValidation && !multipleSalePrice && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('telecomService:price')} </FormLabel>
              <>
                <InputText numberOnly thousandSeparator name='price' values={price} onChange={this._onNumberChange.bind(this)} />
                {showValidation && !price && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer> */}

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('telecomService:network_provider')} </FormLabel>
              <>
                <Autocomplete
                  name='networkProviderId'
                  value={networkProviderId}
                  options={networkProviderSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                />
                {showValidation && !networkProviderId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('telecomService:note')} </FormLabel>
              <>
                <InputText name='note' values={note} onChange={this._onTextChange.bind(this)} />
              </>
            </ColumnContainer>

          </ColumnContainer>

          <GridContainer justify="center">
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button>
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button>
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.TelecomService)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ telecomService: {...this.state.telecomService, [name]: value }});
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ telecomService: {...this.state.telecomService, [name]: value }});
  }

  private _onValidate() {
    let { telecomService: { name, number, networkProviderId } } = this.state;

    return name && number && networkProviderId;
  }

  private _onSave(close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { telecomService: { name, number, note, networkProviderId } } = this.state;
    let telecomService = new TelecomServiceDTO();
    telecomService.id = parseInt(this.props.match.params.id);
    telecomService.name = name;
    telecomService.number = number;
    telecomService.note = note;
    telecomService.networkProviderId = networkProviderId;

    this.props.saveTelecomService(telecomService).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        telecomService.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close) {
          history.push(URL.TelecomService);
        } else if (parseInt(match.params.id) === 0) {
          history.push(URL.TelecomServiceDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(TelecomServiceDetail)));