import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL, Permission, TransferStatus, GlobalConst, ClientType } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer, ImageUpload, Autocomplete, Checkbox, CustomSelect } from '../../Components';
import { People, Save, KeyboardArrowLeft, Clear } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getCashCollectionById, saveCashCollection, approveCashCollection, cancelCashCollection } from '../../Redux/CashCollection/CashCollectionReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { CashCollectionDTO, SelectItemDTO, CurrentUserDTO } from '../../DTO';
import { getCashCollectionSettingSelectItems } from '../../Redux/CashCollectionSetting/CashCollectionSettingReducer';
import { getClientSelectItems } from '../../Redux/Client/ClientReducer';
import { getBankSelectItems } from '../../Redux/Bank/BankReducer';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';
import { getUserSelectItems } from '../../Redux/User/UserReducer';
import _ from 'lodash';
import { getCashCollectionProductSelectItems } from '../../Redux/CashCollectionProduct/CashCollectionProductReducer';
import { getPaymentAccountSelectItems } from '../../Redux/PaymentAccount/PaymentAccountReducer';
let moment = require('moment');

interface ICashCollectionDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  currentUser: CurrentUserDTO;
  cashCollection: CashCollectionDTO;
  cashCollectionSettingSelectItems: SelectItemDTO[];
  clientSelectItems: SelectItemDTO[];
  bankSelectItems: SelectItemDTO[];
  branchSelectItems: SelectItemDTO[];
  userSelectItems: SelectItemDTO[];
  cashCollectionProductSelectItems: SelectItemDTO[];
  paymentAccountSelectItems: SelectItemDTO[];

  setActiveRoute: ActionCreator<any>,
  getCashCollectionById: ActionCreator<any>,
  saveCashCollection: ActionCreator<any>,
  approveCashCollection: ActionCreator<any>,
  cancelCashCollection: ActionCreator<any>,
  getCashCollectionSettingSelectItems: ActionCreator<any>,
  getClientSelectItems: ActionCreator<any>,
  getBankSelectItems: ActionCreator<any>,
  getBranchSelectItems: ActionCreator<any>,
  getUserSelectItems: ActionCreator<any>,
  getCashCollectionProductSelectItems: ActionCreator<any>,
  getPaymentAccountSelectItems: ActionCreator<any>,
}

interface ICashCollectionDetailStates {
  isSaving?: boolean;

  cashCollection: CashCollectionDTO;
  showValidation?: boolean;
  referenceFile?: any;
  confirmationFile?: any;
}

function mapStateToProps(state: any) {
  return ({
    cashCollection: state.cashCollection.editingCashCollection,
    cashCollectionSettingSelectItems: state.cashCollectionSetting.cashCollectionSettingSelectItems,
    clientSelectItems: state.client.clientSelectItems,
    currentUser: state.user.currentUser,
    bankSelectItems: state.bank.bankSelectItems,
    branchSelectItems: state.branch.branchSelectItems,
    userSelectItems: state.user.userSelectItems,
    cashCollectionProductSelectItems: state.cashCollectionProduct.cashCollectionProductSelectItems,
    paymentAccountSelectItems: state.paymentAccount.paymentAccountSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getCashCollectionById: (id: number) => dispatch(getCashCollectionById(id)),
    saveCashCollection: (cashCollection: CashCollectionDTO, submit: boolean, reference: any, confirmation: any) => dispatch(saveCashCollection(cashCollection, submit, reference, confirmation)),
    getCashCollectionSettingSelectItems: () => dispatch(getCashCollectionSettingSelectItems()),
    approveCashCollection: (id: number) => dispatch(approveCashCollection(id)),
    cancelCashCollection: (id: number) => dispatch(cancelCashCollection(id)),
    getClientSelectItems: () => dispatch(getClientSelectItems()),
    getBankSelectItems: () => dispatch(getBankSelectItems()),
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
    getUserSelectItems: () => dispatch(getUserSelectItems()),
    getCashCollectionProductSelectItems: () => dispatch(getCashCollectionProductSelectItems()),
    getPaymentAccountSelectItems: () => dispatch(getPaymentAccountSelectItems()),
  })
}

class CashCollectionDetail extends React.Component<ICashCollectionDetailProps, ICashCollectionDetailStates> {

  constructor(props: ICashCollectionDetailProps) {
    super(props);
    this.state = {
      cashCollection: {...new CashCollectionDTO(), ...props.cashCollection, branchId: props.currentUser.branchId, commissionUserId: props.currentUser.id }
    }
  }

  componentDidMount() {
    !this.props.clientSelectItems?.length && this.props.getClientSelectItems();
    !this.props.cashCollectionSettingSelectItems?.length && this.props.getCashCollectionSettingSelectItems();
    !this.props.bankSelectItems?.length && this.props.getBankSelectItems();
    !this.props.branchSelectItems?.length && this.props.getBranchSelectItems();
    !this.props.userSelectItems?.length && this.props.getUserSelectItems();
    !this.props.cashCollectionProductSelectItems?.length && this.props.getCashCollectionProductSelectItems();
    !this.props.paymentAccountSelectItems?.length && this.props.getPaymentAccountSelectItems();

    const { setActiveRoute, match, getCashCollectionById, t } = this.props;

    setActiveRoute([{path: URL.CashCollection, name: this.props.t('menu:cashCollection')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getCashCollectionById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.CashCollection);
          } else {
            this.setState({ cashCollection: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  componentWillReceiveProps(newProps: ICashCollectionDetailProps) {
    if (_.isEqual(this.props.currentUser, newProps.currentUser)) {
      if (!this.state.cashCollection.branchId) {
        this.setState({ cashCollection: {...this.state.cashCollection, branchId: newProps.currentUser.branchId } })
      }
      if (!this.state.cashCollection.commissionUserId) {
        this.setState({ cashCollection: {...this.state.cashCollection, commissionUserId: newProps.currentUser.id } })
      }
    }
  }

  render() {
    const { t, classes, currentUser, clientSelectItems, bankSelectItems, branchSelectItems, userSelectItems, cashCollectionProductSelectItems, paymentAccountSelectItems } = this.props;
    let { showValidation, cashCollection: { id, name, number, contractNumber, status, cashCollectionDate, createdBy, clientId, amount, fee, discount, bankId, branchId, commissionUserId, cashCollectionProductId, referenceFileName, confirmationFileName, payByCash, receiveAccountId } } = this.state;

    const types = [
      { value: 1, text: t('client:type_single')},
      { value: 2, text: t('client:type_multiple')},
      { value: 3, text: t('client:type_branch')},
    ];

    const statusText = [
      { value: 0, text: t('cashCollection:status_draft')},
      { value: 1, text: t('cashCollection:status_pending_approve')},
      { value: 2, text: t('cashCollection:status_approved')},
      { value: 3, text: t('cashCollection:status_cancel')},
    ]

    let statusStr = statusText.find(s => s.value === status)?.text || '';
    let tDate = cashCollectionDate ? moment(cashCollectionDate, GlobalConst.DATE_TIME_FORMAT) : moment(new Date());
    let now = moment(new Date());
    let readOnly = (status === TransferStatus.Approved || status === TransferStatus.Canceled) && (tDate.year() * 12 + tDate.month() < now.year() * 12 + now.month());

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={People}
          iconColor={'green'}
          title={t('common:information')}>

          {id && currentUser.functionIds.includes(Permission.ApproveCashCollections) && status === TransferStatus.PendingApprove ? 
            <ColumnContainer columnWidth={[{xs:12, sm:12}]}>
              <div className={classes.right}>
                <Button color='primary' customClass={classes.marginRight} round onClick={this._onApprove.bind(this)} disabled={this.state.isSaving}>
                  <Save className={classes.icons} /> {t('button:approve')}
                </Button>
                <Button color='danger' customClass={classes.marginRight} round onClick={this._onCancel.bind(this)} disabled={this.state.isSaving}>
                  <Clear className={classes.icons} /> {t('button:cancel')}
                </Button>
              </div>
            </ColumnContainer>
          : null }

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('cashCollection:number')} </FormLabel>
              <InputText values={number} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('cashCollection:status')} </FormLabel>
              <InputText values={statusStr} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('cashCollection:product')} </FormLabel>
              <>
                <Autocomplete
                  name='cashCollectionProductId'
                  value={cashCollectionProductId}
                  options={cashCollectionProductSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !cashCollectionProductId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <></>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('cashCollection:date')} </FormLabel>
              {!readOnly ? 
                <Datetime
                  dateFormat={GlobalConst.DATE_FORMAT}
                  timeFormat={"HH:mm"}
                  inputProps={{ placeholder: t('cashCollection:date') }}
                  onChange={this._onDateChange.bind(this)}
                  value={cashCollectionDate}
                /> : <InputText disabled values={cashCollectionDate} /> }
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('cashCollection:user')} </FormLabel>
              <InputText disabled values={id === 0 ? currentUser.name : (userSelectItems.find(u => u.value === createdBy.toString())?.text ?? '')} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('cashCollection:client')} </FormLabel>
              <>
                <Autocomplete
                  value={clientId}
                  options={clientSelectItems}
                  onChange={this._onClientChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !clientId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('client:type')} </FormLabel>
              <InputText disabled values={types.find(t => t.value === clientSelectItems?.find(c => c.value === clientId)?.details.type)?.text || ''} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('client:phone')} </FormLabel>
              <InputText disabled values={clientSelectItems?.find(c => c.value === clientId)?.details.phone || ''} />
            </ColumnContainer>

            <></>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('cashCollection:name')} </FormLabel>
              <>
                <InputText name='name' values={name} onChange={this._onTextChange.bind(this)} disabled={readOnly} />
                {showValidation && !name && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('cashCollection:contract_number')} </FormLabel>
              <>
                <InputText name='contractNumber' values={contractNumber} onChange={this._onTextChange.bind(this)} disabled={readOnly} />
                {showValidation && !contractNumber && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('cashCollection:amount')} </FormLabel>
              <>
                <InputText 
                  name="amount"
                  numberOnly
                  thousandSeparator
                  values={amount} 
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly} />
                {showValidation && !amount && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('cashCollection:fee')} </FormLabel>
              <InputText numberOnly thousandSeparator values={fee} disabled />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('cashCollection:discount')} </FormLabel>
              <>
                <InputText 
                  name="discount"
                  numberOnly
                  thousandSeparator
                  values={discount} 
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly} />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('cashCollection:total')} </FormLabel>
              <InputText 
                disabled 
                numberOnly 
                thousandSeparator 
                values={parseFloat('0' + amount.toString()) + parseFloat('0' + fee.toString()) - parseFloat('0' + discount.toString())} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('cashCollection:bank')} </FormLabel>
              <>
                <Autocomplete
                  name='bankId'
                  value={bankId}
                  options={bankSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !bankId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <> </>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('cashCollection:branch')} </FormLabel>
              <>
                <Autocomplete
                  name='branchId'
                  value={branchId}
                  options={branchSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !branchId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('cashCollection:commission_user')} </FormLabel>
              <>
                <Autocomplete
                  name='commissionUserId'
                  value={commissionUserId}
                  options={userSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !commissionUserId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('cashCollection:reference')} </FormLabel>
              <ImageUpload 
                image={id && referenceFileName ? `/Upload/CashCollectionRef/${id}/${referenceFileName}` : null}
                onImageChange={(file: any) => this.setState({ referenceFile: file })}
                onImageRemoved={() => { let cashCollection = this.state.cashCollection; cashCollection.referenceFileName = ''; this.setState({ cashCollection: cashCollection }) }}
                hideButtons={readOnly} />
            </ColumnContainer>
            
            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('cashCollection:confirmation')} </FormLabel>
              <ImageUpload 
                image={id && confirmationFileName ? `/Upload/CashCollectionConfirmation/${id}/${confirmationFileName}` : null}
                onImageChange={(file: any) => this.setState({ confirmationFile: file })}
                onImageRemoved={() => { let cashCollection = this.state.cashCollection; cashCollection.confirmationFileName = ''; this.setState({ cashCollection: cashCollection }) }}
                hideButtons={readOnly} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('cashCollection:pay_by_cash')} </FormLabel>
              <Checkbox
                checked={payByCash}
                name='payByCash'
                onClick={this._onCheckboxChange.bind(this)}
              />
            </ColumnContainer>

            {payByCash ? null : <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('cashCollection:receive_account')} </FormLabel>
              <>
                <CustomSelect 
                  name={'receiveAccountId'}
                  value={receiveAccountId}
                  onChange={this._onNumberChange.bind(this)}
                  items={paymentAccountSelectItems}
                  label={t('cashCollection:receive_account')}
                  disabled={readOnly} />
                {showValidation && !receiveAccountId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>}

          </ColumnContainer>

          <GridContainer justify="center">
          {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button> : null}
            {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button> : null}
            {status === TransferStatus.Draft ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_submit')}
            </Button> : null}
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.CashCollection)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onCheckboxChange(event: any) {
    event.persist && event.persist();
    const { target: { name, checked } } = event;
    let cashCollection = this.state.cashCollection;
    this.setState({ cashCollection: {...cashCollection, [name]: checked }});
  }

  private _onDateChange(value: string | Moment) {
    let cashCollection = this.state.cashCollection;
    cashCollection.cashCollectionDate = moment(value).format(GlobalConst.DATE_TIME_FORMAT);
    this.setState({ cashCollection: cashCollection });
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ cashCollection: { ...this.state.cashCollection, [name]: value }});
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ cashCollection: { ...this.state.cashCollection, [name]: value }}, () => {
      if (name === 'amount') {
        this._updateCommissionFee();
      }
    });
  }

  private _updateCommissionFee() {
    let { cashCollection } = this.state;
    let amount = cashCollection.amount;
    let clientId = cashCollection.clientId;
    let setting = this.props.cashCollectionSettingSelectItems.find(s => s.details.fromAmount <= amount && s.details.toAmount > amount);
    let client = this.props.clientSelectItems?.find(c => c.value === clientId);
    this.setState({ cashCollection: {...cashCollection, commission: setting?.details.commission ?? 0, fee: [ClientType.Multiple, ClientType.Branch].includes(client?.details.type) ? (setting?.details.multipleSalePrice ?? 0) : (setting?.details.salePrice ?? 0) }});
  }

  private _onClientChange(value: number) {
    this.setState({ cashCollection: {...this.state.cashCollection, clientId: value }}, () => this._updateCommissionFee());
  }

  private _onValidate() {
    let { cashCollection: { name, cashCollectionProductId, cashCollectionDate, clientId, contractNumber, amount, bankId, branchId, commissionUserId, payByCash, receiveAccountId } } = this.state;

    return name && cashCollectionProductId && cashCollectionDate && clientId && contractNumber && amount && bankId && branchId && commissionUserId && (payByCash || receiveAccountId);
  }

  private _onSave(submit: boolean = true, close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { referenceFile, confirmationFile, cashCollection: { name, contractNumber, clientId, bankId, amount, fee, discount, commission, branchId, commissionUserId, cashCollectionDate, cashCollectionProductId, payByCash, receiveAccountId } } = this.state;
    let cashCollection = new CashCollectionDTO();
    cashCollection.id = parseInt(this.props.match.params.id);
    cashCollection.name = name;
    cashCollection.contractNumber = contractNumber;
    cashCollection.clientId = clientId;
    cashCollection.bankId = bankId;
    cashCollection.amount = amount;
    cashCollection.fee = fee;
    cashCollection.discount = discount;
    cashCollection.commission = commission;
    cashCollection.branchId = branchId;
    cashCollection.commissionUserId = commissionUserId;
    cashCollection.cashCollectionDate = cashCollectionDate;
    cashCollection.cashCollectionProductId = cashCollectionProductId;
    cashCollection.payByCash = payByCash;
    cashCollection.receiveAccountId = payByCash ? null : receiveAccountId;

    this.props.saveCashCollection(cashCollection, submit, referenceFile, confirmationFile).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        cashCollection.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close || submit) {
          history.push(URL.CashCollection);
        } else if (parseInt(match.params.id) === 0) {
          this.setState({ cashCollection: response.data });
          history.push(URL.CashCollectionDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

  private _onApprove() {
    const { t } = this.props;
    this.setState({ isSaving: true });

    this.props.approveCashCollection(this.state.cashCollection.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.CashCollection);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

  private _onCancel() {
    const { t } = this.props;
    this.setState({ isSaving: true });
    
    this.props.cancelCashCollection(this.state.cashCollection.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.CashCollection);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(CashCollectionDetail)));