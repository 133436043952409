export class RoleDTO {
  public id: number;
  public name: string;
  public functionIds: number[];

  constructor() {
    this.id = 0;
    this.name = '';
    this.functionIds = [];
  }
}