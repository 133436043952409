import { FilterDTO, TelecomServiceDTO } from '../../DTO';

const TELECOM_SERVICE_REQUEST: string = 'TELECOM_SERVICE_REQUEST';

const TELECOM_SERVICE_GET_LIST_SUCCESS: string = 'TELECOM_SERVICE_GET_LIST_SUCCESS';
const TELECOM_SERVICE_GET_LIST_FAIL: string = 'TELECOM_SERVICE_GET_LIST_FAIL';

const TELECOM_SERVICE_SET_EDITING_TELECOM_SERVICE: string = 'TELECOM_SERVICE_SET_EDITING_TELECOM_SERVICE';

const TELECOM_SERVICE_GET_BY_ID_SUCCESS: string = 'TELECOM_SERVICE_GET_BY_ID_SUCCESS';
const TELECOM_SERVICE_GET_BY_ID_FAIL: string = 'TELECOM_SERVICE_GET_BY_ID_FAIL';

const TELECOM_SERVICE_SAVE_SUCCESS: string = 'TELECOM_SERVICE_SAVE_SUCCESS';
const TELECOM_SERVICE_SAVE_FAIL: string = 'TELECOM_SERVICE_SAVE_FAIL';

const TELECOM_SERVICE_DELETE_SUCCESS: string = 'TELECOM_SERVICE_DELETE_SUCCESS';
const TELECOM_SERVICE_DELETE_FAIL: string = 'TELECOM_SERVICE_DELETE_FAIL';

const TELECOM_SERVICE_GET_NAMES_SUCCESS: string = 'TELECOM_SERVICE_GET_NAMES_SUCCESS';
const TELECOM_SERVICE_GET_NAMES_FAIL: string = 'TELECOM_SERVICE_GET_NAMES_FAIL';

const initialState = {
  editingTelecomService: {},
  telecomServiceSelectItems: [],
}

export function telecomServiceReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case TELECOM_SERVICE_SET_EDITING_TELECOM_SERVICE:
      return Object.assign({}, state, {
        editingTelecomService: action.payload
      });

    case TELECOM_SERVICE_GET_NAMES_SUCCESS:
      return Object.assign({}, state, {
        telecomServiceSelectItems: action.response?.data ?? []
      })

    default:
      return state;

  }

}

export function getListTelecomService(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TELECOM_SERVICE_REQUEST, TELECOM_SERVICE_GET_LIST_SUCCESS, TELECOM_SERVICE_GET_LIST_FAIL],
      EndPoint: `TelecomServices${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingTelecomService(telecomService: TelecomServiceDTO) {
  return {
    type: TELECOM_SERVICE_SET_EDITING_TELECOM_SERVICE,
    payload: telecomService
  }
}

export function getTelecomServiceById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TELECOM_SERVICE_REQUEST, TELECOM_SERVICE_GET_BY_ID_SUCCESS, TELECOM_SERVICE_GET_BY_ID_FAIL],
      EndPoint: `TelecomServices\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveTelecomService(telecomService: TelecomServiceDTO) {
  let initRequest: RequestInit = {
    method: telecomService.id ? 'PATCH' : 'POST',
    body: JSON.stringify(telecomService),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TELECOM_SERVICE_REQUEST, TELECOM_SERVICE_SAVE_SUCCESS, TELECOM_SERVICE_SAVE_FAIL],
      EndPoint: 'TelecomServices',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteTelecomService(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TELECOM_SERVICE_REQUEST, TELECOM_SERVICE_DELETE_SUCCESS, TELECOM_SERVICE_DELETE_FAIL],
      EndPoint: 'TelecomServices',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function getTelecomServiceSelectItems() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TELECOM_SERVICE_REQUEST, TELECOM_SERVICE_GET_NAMES_SUCCESS, TELECOM_SERVICE_GET_NAMES_FAIL],
      EndPoint: 'TelecomServices/selectList',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}