import React from 'react';
import { IconCard, DataList, Button, CustomInput, ColumnContainer, CustomSelect, Accordion, Autocomplete } from '../../Components';
import { Assignment, Edit, Replay, Add, Delete, Search, GetApp, OpenInNew } from '@material-ui/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ActionCreator } from 'redux';
import { connect } from 'react-redux';
import { getListTopUp, deleteTopUp, setEditingTopUp, exportTopUp } from '../../Redux/TopUp/TopUpReducer';
import { IconButton, withStyles, InputAdornment, FormLabel } from '@material-ui/core';
import { URL, GlobalConst, TransferStatus } from '../../Consts';
import history from '../../history';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { FilterDTO, TopUpDTO, SelectItemDTO, FilterItemDTO } from '../../DTO';
import styles from '../../styles';
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from 'react-toastify';
import { showError, currency } from '../../Utils';
import _ from 'lodash';
import { getUserSelectItems } from '../../Redux/User/UserReducer';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
import { getTopUpTypeSelectItems } from '../../Redux/TopUpType/TopUpTypeReducer';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';
let moment = require('moment');

interface ITopUpProps extends WithTranslation {
  classes: any;

  userSelectItems: SelectItemDTO[];
  topUpTypeSelectItems: SelectItemDTO[];
  branchSelectItems: SelectItemDTO[];

  getTopUpList: ActionCreator<any>,
  setActiveRoute: ActionCreator<any>,
  setEditingTopUp: ActionCreator<any>,
  deleteTopUp: ActionCreator<any>,
  getUserSelectItems: ActionCreator<any>,
  exportTopUp: ActionCreator<any>,
  getTopUpTypeSelectItems: ActionCreator<any>,
  getBranchSelectItems: ActionCreator<any>,
}

interface ITopUpStates {
  data: TopUpDTO[],
  page: number;
  pageSize: number;
  totalCount: number;

  searchString: string;
  showDeleteConfirm?: boolean;
  deletingId?: number;

  dateFrom?: string;
  dateTo?: string;
  branchId: number;
  userId: number;
  status: number;
  topUpTypeId: number;

  isExporting: boolean;
}

function mapStateToProps(state: any) {
  return ({
    userSelectItems: state.user.userSelectItems,
    topUpTypeSelectItems: state.topUpType.topUpTypeSelectItems,
    branchSelectItems: state.branch.branchSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    getTopUpList: (filterDTO: FilterDTO) => dispatch(getListTopUp(filterDTO)),
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    setEditingTopUp: (topUp: TopUpDTO) => dispatch(setEditingTopUp(topUp)),
    deleteTopUp: (id: number) => dispatch(deleteTopUp(id)),
    getUserSelectItems: () => dispatch(getUserSelectItems()),
    exportTopUp: (filterDTO: FilterDTO) => dispatch(exportTopUp(filterDTO)),
    getTopUpTypeSelectItems: () => dispatch(getTopUpTypeSelectItems()),
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
  })
}

class TopUp extends React.Component<ITopUpProps, ITopUpStates> {

  constructor(props: ITopUpProps){
    super(props);

    this.state = {
      data: [],
      page: 0,
      pageSize: 20,
      totalCount: 0,
      searchString: '',
      dateFrom: moment().format(GlobalConst.DATE_FORMAT),
      dateTo: moment().format(GlobalConst.DATE_FORMAT),
      isExporting: false,
      branchId: 0,
      userId: 0,
      status: -1,
      topUpTypeId: 0,
    }

    this._getTopUpWithDebounce = _.debounce(this._getTopUpWithDebounce.bind(this), GlobalConst.DEBOUNCE_TIME);
  }

  componentDidMount() {
    !this.props.userSelectItems?.length && this.props.getUserSelectItems();
    !this.props.topUpTypeSelectItems?.length && this.props.getTopUpTypeSelectItems();
    !this.props.branchSelectItems?.length && this.props.getBranchSelectItems();

    this.props.setActiveRoute([{path: URL.TopUp, name: this.props.t('menu:topUp')}]);
  }

  render() {
    const { t, classes } = this.props;
    let { data, page, pageSize, totalCount, isExporting } = this.state;
    
    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={Assignment}
          iconColor={'green'}
          title={t('topUp:topUp')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>
            <CustomInput
              labelText={t('common:search')}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                value: this.state.searchString,
                name: 'searchString',
                onChange: this._onSearchStringChange.bind(this)
              }}
            />

            <div className={classes.right}>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onEdit.bind(this, new TopUpDTO())}>
                <Add className={classes.icons} /> {t('button:add')}
              </Button>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onRefresh.bind(this)}>
                <Replay className={classes.icons} /> {t('button:refresh')}
              </Button>
              <Button color='primary' customClass={classes.marginRight} disabled={isExporting} round onClick={this._onExport.bind(this)}>
                <GetApp className={classes.icons} /> {t('button:excel')}
              </Button>
            </div>
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs: 12}]}>
            <Accordion
              active={-1}
              collapses={[
                {
                  title: t('common:advance_search'),
                  content: this._renderAdvancedSearch()
                }
              ]}
            />
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs:12}]}>
            <DataList 
              currentPage={page}
              pageSize={pageSize}
              totalPage={Math.ceil(totalCount / pageSize)}
              data={this._mapData(data)}
              columns={this._getColumns()}
              fetchData={this._onLoadData.bind(this)}
              onPageSizeChange={(ps: number) => {this.setState({ pageSize: ps, page: 0 }, () => this._search())}}
              onChangePage={this._onChangePage.bind(this)}
            />
          </ColumnContainer>
          
          {this.state.showDeleteConfirm && <SweetAlert
            warning
            //style={{ display: "block", marginTop: "-100px" }}
            title={t('message:confirm_delete')}
            onConfirm={this._onDelete.bind(this)}
            onCancel={() => this.setState({ showDeleteConfirm: false})}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={t('button:delete')}
            cancelBtnText={t('button:cancel')}
            showCancel
          >
          </SweetAlert>}

        </IconCard>
      </ColumnContainer>
    )
  }

  private _renderAdvancedSearch() {
    const { t, classes, branchSelectItems, userSelectItems, topUpTypeSelectItems } = this.props;
    let { dateFrom, dateTo, branchId, userId, status, topUpTypeId } = this.state;

    let statusSelectOptions = [
      { value: -1, text: t('common:all') } as SelectItemDTO,
      { value: TransferStatus.Draft, text: t('topUp:status_draft')} as SelectItemDTO,
      { value: TransferStatus.PendingApprove, text: t('topUp:status_pending_approve')} as SelectItemDTO,
      { value: TransferStatus.Approved, text: t('topUp:status_approved')} as SelectItemDTO,
      { value: TransferStatus.Canceled, text: t('topUp:status_cancel')} as SelectItemDTO,
    ]

    return (
      <ColumnContainer columnWidth={[{xs:12, sm:6, md:4}]}>

        <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
          <FormLabel className={classes.labelHorizontal}> {t('topUp:date_from')} </FormLabel>
          <Datetime
            dateFormat={GlobalConst.DATE_FORMAT}
            timeFormat={false}
            inputProps={{ placeholder: t('topUp:date_from') }}
            onChange={(value: string | Moment) => this.setState({ dateFrom: value ? moment(value).format(GlobalConst.DATE_FORMAT) : '', page: 0 }, () => this._search())}
            value={dateFrom}
          />
        </ColumnContainer>

        <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
          <FormLabel className={classes.labelHorizontal}> {t('topUp:date_to')} </FormLabel>
          <Datetime
            dateFormat={GlobalConst.DATE_FORMAT}
            timeFormat={false}
            inputProps={{ placeholder: t('topUp:date_to') }}
            onChange={(value: string | Moment) => this.setState({ dateTo: value ? moment(value).format(GlobalConst.DATE_FORMAT) : '', page: 0 }, () => this._search())}
            value={dateTo}
          />
        </ColumnContainer>

        <ColumnContainer columnWidth={[{xs:12, sm:4}, {xs:12, sm:8}]}>
          <FormLabel className={classes.labelHorizontal}> {t('topUp:status')} </FormLabel>
          <CustomSelect 
            value={status}
            onChange={(value: number) => this.setState({ status: value, page: 0 }, () => this._search()) }
            items={statusSelectOptions}
            label={t('topUp:status')} />
        </ColumnContainer>

        <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
          <FormLabel className={classes.labelHorizontal}> {t('topUp:branch')} </FormLabel>
          <Autocomplete
            value={branchId}
            includeEmptyOption
            emptyText={t('common:default')}
            options={branchSelectItems}
            onChange={(value: number) => this.setState({ branchId: value, page: 0 }, () => this._search()) }
          />
        </ColumnContainer>

        <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
          <FormLabel className={classes.labelHorizontal}> {t('topUp:user')} </FormLabel>
          <Autocomplete
            value={userId}
            includeEmptyOption
            emptyText={t('common:all')}
            options={userSelectItems}
            onChange={(value: number) => this.setState({ userId: value, page: 0 }, () => this._search()) }
          />
        </ColumnContainer>

        <ColumnContainer columnWidth={[{xs:12, sm:4}, {xs:12, sm:8}]}>
          <FormLabel className={classes.labelHorizontal}> {t('topUp:top_up_type')} </FormLabel>
          <Autocomplete
            value={topUpTypeId}
            includeEmptyOption
            emptyText={t('common:all')}
            options={topUpTypeSelectItems}
            onChange={(value: number) => this.setState({ topUpTypeId: value, page: 0 }, () => this._search()) }
          />
        </ColumnContainer>

      </ColumnContainer>
    )
  }

  private _getFilter(tableState: any = null): FilterDTO {
    let { pageSize, searchString, dateFrom, dateTo, branchId, status, userId, topUpTypeId } = this.state;

    let filter: FilterDTO = new FilterDTO(tableState);
    if (!tableState) {
      filter.page = 0;
    }
    filter.pageSize = pageSize;
    filter.searchString = searchString;
    filter.filters = [
      { key: 'dateFrom', value: dateFrom } as FilterItemDTO,
      { key: 'dateTo', value: dateTo } as FilterItemDTO
    ];
    if (branchId) {
      filter.filters.push({ key: 'branch', value: branchId } as FilterItemDTO);
    }
    if (status >= 0) {
      filter.filters.push({ key: 'status', value: status } as FilterItemDTO);
    }
    if (userId) {
      filter.filters.push({ key: 'user', value: userId } as FilterItemDTO);
    }
    if (topUpTypeId) {
      filter.filters.push({ key: 'type', value: topUpTypeId } as FilterItemDTO);
    }

    return filter;
  }

  _getTopUpWithDebounce(filterDTO: FilterDTO) {
    this.props.getTopUpList(filterDTO).then((response: any) => {
      if (response && !response.errorCode) {
        this.setState({ data: response.data, totalCount: response.totalCount });
      } else {
        showError(this.props.t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _onSearchStringChange(event: any) {
    event.persist && event.persist();
    const { target: { value } } = event;
    this.setState({ searchString: value || '', page: 0 }, () => this._search());
  }

  private _search() {
    this._getTopUpWithDebounce(this._getFilter());    
  }

  private _getColumns() {
    const { t } = this.props;
    return [
      { Header: t('topUp:number'), accessor: "number", sortable: false, filterable: false },
      { Header: t('topUp:date'), accessor: "date", sortable: false, filterable: false },
      { Header: t('topUp:phone'), accessor: "phone", sortable: false, filterable: false },
      { Header: t('topUp:top_up_type'), accessor: "topUpType", sortable: false, filterable: false },
      { Header: t('topUp:amount'), accessor: "amount", sortable: false, filterable: false },
      { Header: t('topUp:discount'), accessor: "discount", sortable: false, filterable: false },
      { Header: t('topUp:price'), accessor: "price", sortable: false, filterable: false },
      { Header: t('topUp:total'), accessor: "total", sortable: false, filterable: false },
      { Header: t('topUp:commission_user'), accessor: "commissionUser", sortable: false, filterable: false },
      { Header: t('topUp:status'), accessor: "status", sortable: false, filterable: false },
      { Header: t('topUp:approved_by'), accessor: "approvedBy", sortable: false, filterable: false },
      { Header: t('topUp:approved_date'), accessor: "approvedDate", sortable: false, filterable: false },
      { Header: '', accessor: "actions", sortable: false, filterable: false }
    ]
  }

  private _onEdit(topUp: TopUpDTO) {
    this.props.setEditingTopUp(topUp);
    history.push(URL.TopUpDetail.replace(':id', topUp.id.toString()));
  }

  private _onDelete() {
    const { t } = this.props;

    this.setState({ showDeleteConfirm: false });
    this.props.deleteTopUp(this.state.deletingId).then((response: any) => {
      if (response && !response.errorCode) {
        toast.success(t('message:delete_success'));
        this._onRefresh();
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _mapData(data: TopUpDTO[]) {
    if (!data) return [];

    const { t, userSelectItems, topUpTypeSelectItems } = this.props;

    const statusText = [
      { value: TransferStatus.Draft, text: t('topUp:status_draft')},
      { value: TransferStatus.PendingApprove, text: t('topUp:status_pending_approve')},
      { value: TransferStatus.Approved, text: t('topUp:status_approved')},
      { value: TransferStatus.Canceled, text: t('topUp:status_cancel')},
    ]

    return data.map((u: TopUpDTO) => {
      return {
        ...u,
        topUpType: topUpTypeSelectItems.find(s => s.value === u.topUpTypeId)?.text ?? '',
        amount: currency(u.amount),
        price: currency(u.price),
        discount: currency(u.discount),
        total: currency((u.amount ?? 0) - (u.discount ?? 0)),
        status: statusText.find(s => s.value === u.status)?.text,
        commissionUser: userSelectItems.find(s => s.value === u.commissionUserId)?.text ?? '',
        approvedBy: userSelectItems.find(s => s.value === u.approvedById)?.text ?? '',
        approvedDate: u.approvedById != null ? u.approvedDate : '',
        actions: (
          <div className="actions-right">
            <IconButton className='small-action' onClick={this._onEdit.bind(this, u)}> <Edit /> </IconButton>
            <IconButton className='small-action' onClick={() => window.open(URL.TopUpDetail.replace(':id', u.id.toString()), '_blank')}> <OpenInNew /> </IconButton>
            <IconButton className='small-action' onClick={() => this.setState({ showDeleteConfirm: true, deletingId: u.id })}> <Delete color='error' /> </IconButton>
          </div>
        )
      }
    });
  }

  private _onLoadData(tableState: any, instance: any) {
    this._getTopUpWithDebounce(this._getFilter(tableState));
  }

  private _onRefresh() {
    this._getTopUpWithDebounce(this._getFilter());
  }

  private _onExport() {
    this.setState({ isExporting: true });
    this.props.exportTopUp(this._getFilter()).then((response: any) => {
      this.setState({ isExporting: false });
      if (response && !response.errorCode) {
        window.open(response.data, '_blank');
      } else {
        showError(this.props.t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _onChangePage(pageIndex: any) {
    this.setState({ page: pageIndex});
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(TopUp)));