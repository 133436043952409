import React from 'react';
import { IconCard, DataList, Button, CustomInput, ColumnContainer } from '../../Components';
import { Message as MessageIcon, Edit, Replay, Add, Delete, Search } from '@material-ui/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ActionCreator } from 'redux';
import { connect } from 'react-redux';
import { getListMessage, deleteMessage, setEditingMessage } from '../../Redux/Message/MessageReducer';
import { IconButton, withStyles, InputAdornment } from '@material-ui/core';
import { URL, GlobalConst, MessageStatus, MessageType } from '../../Consts';
import history from '../../history';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { FilterDTO, MessageDTO } from '../../DTO';
import styles from '../../styles';
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from 'react-toastify';
import { showError } from '../../Utils';
import _ from 'lodash';

interface IMessageProps extends WithTranslation {
  classes: any;

  getMessageList: ActionCreator<any>,
  setActiveRoute: ActionCreator<any>,
  setEditingMessage: ActionCreator<any>,
  deleteMessage: ActionCreator<any>,
}

interface IMessageStates {
  data: MessageDTO[],
  page: number;
  pageSize: number;
  totalCount: number;

  searchString: string;
  showDeleteConfirm?: boolean;
  deletingId?: number;
}

function mapStateToProps(state: any) {
  return ({})
}

function mapDispatchToProps(dispatch: any) {
  return ({
    getMessageList: (filterDTO: FilterDTO) => dispatch(getListMessage(filterDTO)),
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    setEditingMessage: (message: MessageDTO) => dispatch(setEditingMessage(message)),
    deleteMessage: (id: number) => dispatch(deleteMessage(id)),
  })
}

class Message extends React.Component<IMessageProps, IMessageStates> {

  constructor(props: IMessageProps){
    super(props);

    this.state = {
      data: [],
      page: 0,
      pageSize: 100,
      totalCount: 0,
      searchString: '',
    }

    this._getMessageWithDebounce = _.debounce(this._getMessageWithDebounce.bind(this), GlobalConst.DEBOUNCE_TIME);
  }

  componentDidMount() {
    this.props.setActiveRoute([{path: URL.Message, name: this.props.t('menu:message')}]);
  }

  render() {
    const { t, classes } = this.props;
    let { data, page, pageSize, totalCount } = this.state;
    
    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={MessageIcon}
          iconColor={'green'}
          title={t('message_history:message')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>
            <CustomInput
              labelText={t('common:search')}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                value: this.state.searchString,
                name: 'searchString',
                onChange: this._onSearchStringChange.bind(this)
              }}
            />

            <div className={classes.right}>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onEdit.bind(this, new MessageDTO())}>
                <Add className={classes.icons} /> {t('button:add')}
              </Button>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onRefresh.bind(this)}>
                <Replay className={classes.icons} /> {t('button:refresh')}
              </Button>
            </div>
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs:12}]}>
            <DataList 
              currentPage={page}
              pageSize={pageSize}
              totalPage={Math.ceil(totalCount / pageSize)}
              data={this._mapData(data)}
              columns={this._getColumns()}
              fetchData={this._onLoadData.bind(this)}
              onChangePage={this._onChangePage.bind(this)}
            />
          </ColumnContainer>
          
          {this.state.showDeleteConfirm && <SweetAlert
            warning
            //style={{ display: "block", marginTop: "-100px" }}
            title={t('message:confirm_delete')}
            onConfirm={this._onDelete.bind(this)}
            onCancel={() => this.setState({ showDeleteConfirm: false})}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={t('button:delete')}
            cancelBtnText={t('button:cancel')}
            showCancel
          >
          </SweetAlert>}

        </IconCard>
      </ColumnContainer>
    )
  }

  _getMessageWithDebounce(filterDTO: FilterDTO) {
    this.props.getMessageList(filterDTO).then((response: any) => {
      if (response && !response.errorCode) {
        this.setState({ data: response.data, totalCount: response.totalCount });
      } else {
        showError(this.props.t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _onSearchStringChange(event: any) {
    event.persist && event.persist();
    const { target: { value } } = event;
    this.setState({ searchString: value || '', page: 0 }, () => this._search());
  }

  private _search() {
    let { pageSize, searchString } = this.state;

    let filter: FilterDTO = new FilterDTO();
    filter.page = 0;
    filter.pageSize = pageSize;
    filter.searchString = searchString;

    this._getMessageWithDebounce(filter);    
  }

  private _getColumns() {
    const { t } = this.props;
    return [
      { Header: t('message_history:created_date'), accessor: "createdDate", sortable: false, filterable: false },
      { Header: t('message_history:status'), accessor: "status", sortable: false, filterable: false },
      { Header: t('message_history:type'), accessor: "type", sortable: false, filterable: false },
      { Header: t('message_history:subject'), accessor: "subject", sortable: false, filterable: false },
      { Header: t('message_history:to'), accessor: "to", sortable: false, filterable: false },
      { Header: t('message_history:cc'), accessor: "cc", sortable: false, filterable: false },
      { Header: t('message_history:bcc'), accessor: "bcc", sortable: false, filterable: false },
      { Header: '', accessor: "actions", sortable: false, filterable: false }
    ]
  }

  private _onEdit(message: MessageDTO) {
    this.props.setEditingMessage(message);
    history.push(URL.MessageDetail.replace(':id', message.id.toString()));
  }

  private _onDelete() {
    const { t } = this.props;

    this.setState({ showDeleteConfirm: false });
    this.props.deleteMessage(this.state.deletingId).then((response: any) => {
      if (response && !response.errorCode) {
        toast.success(t('message:delete_success'));
        this._onRefresh();
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _mapData(data: MessageDTO[]) {
    if (!data) return [];

    const { t } = this.props;
    
    let messageStatusSelectItems = [
      { value: MessageStatus.Sending.toString(), text: t('common:processing') },
      { value: MessageStatus.Success.toString(), text: t('common:success') },
      { value: MessageStatus.Fail.toString(), text: t('common:fail') },
      { value: MessageStatus.Cancel.toString(), text: t('common:cancel') },
    ]

    let messageTypeSelectItems = [
      { value: MessageType.Email.toString(), text: t('common:email') },
      { value: MessageType.Sms.toString(), text: t('common:sms') },
    ]

    return data.map((u: MessageDTO) => {
      return {
        ...u,
        type: messageTypeSelectItems.find(t => t.value === u.type.toString())?.text ?? '',
        status: messageStatusSelectItems.find(s => s.value === u.status.toString())?.text ?? '',
        actions: (
          <div className="actions-right">
            <IconButton className='small-action' onClick={this._onEdit.bind(this, u)}> <Edit /> </IconButton>
            <IconButton className='small-action' onClick={() => this.setState({ showDeleteConfirm: true, deletingId: u.id })}> <Delete color='error' /> </IconButton>
          </div>
        )
      }
    });
  }

  private _onLoadData(tableState: any, instance: any) {
    let filter: FilterDTO = new FilterDTO(tableState);

    this._getMessageWithDebounce(filter);
  }

  private _onRefresh() {
    let filter: FilterDTO = new FilterDTO();
    filter.page = this.state.page;
    filter.pageSize = this.state.pageSize;
    filter.searchString = this.state.searchString || '';

    this._getMessageWithDebounce(filter);
  }

  private _onChangePage(pageIndex: any) {
    this.setState({ page: pageIndex});
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(Message)));