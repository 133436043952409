export const GlobalConst = {
  VERSION: '1.2.7',
  PREFIX_API: '/api/',
  LOCAL_STORAGE_TOKEN: 'vugiaerp-token',
  DEBOUNCE_TIME: 500,
  SERVER_DATE_FORMAT: 'DD/MM/YYYY',
  DATE_FORMAT: 'DD/MM/YYYY',
  TIME_FORMAT: 'HH:mm:ss',
  DATE_TIME_FORMAT: 'DD/MM/YYYY HH:mm',
  NONE_SELECTED_TEXT: '--',
  SIGNALR_CONNECTION_URL: '/signalr'
}

export const URL = {
  Login: '/login',
  Dashboard: '/',
  User: '/user',
  UserDetail: '/user/:id',
  UserInfo: '/user-info',
  Function: '/function',
  FunctionDetail: '/function/:id',
  Role: '/role',
  RoleDetail: '/role/:id',
  Supplier: '/supplier',
  SupplierDetail: '/supplier/:id',
  Product: '/product',
  ProductDetail: '/product/:id',
  Branch: '/branch',
  BranchDetail: '/branch/:id',
  Client: '/client',
  ClientDetail: '/client/:id',
  Order: '/order',
  OrderDetail: '/order/:id',
  NotFound: '*',
  PaymentAccount: '/payment-account',
  PaymentAccountDetail: '/payment-account/:id',
  Commission: '/commission',
  Transfer: '/transfer',
  TransferDetail: '/transfer/:id',
  TransferSetting: '/transfer-setting',
  TransferSettingDetail: '/transfer-setting/:id',
  Bank: '/bank',
  BankDetail: '/bank/:id',
  TransferViettel: '/transfer-viettel',
  TransferViettelDetail: '/transfer-viettel/:id',
  TransferViettelSetting: '/transfer-viettel-setting',
  TransferViettelSettingDetail: '/transfer-viettel-setting/:id',
  AppDownload: '/app-download',
  ReceiveViettel: '/receive-viettel',
  ReceiveViettelDetail: '/receive-viettel/:id',
  TransferMoMo: '/transfer-momo',
  TransferMoMoDetail: '/transfer-momo/:id',
  TransferSettingMoMo: '/transfer-setting-momo',
  TransferSettingMoMoDetail: '/transfer-setting-momo/:id',
  CashCollection: '/cash-collection',
  CashCollectionDetail: '/cash-collection/:id',
  CashCollectionSetting: '/cash-collection-setting',
  CashCollectionSettingDetail: '/cash-collection-setting/:id',
  BuySim: '/buy-sim',
  BuySimDetail: '/buy-sim/:id',
  SellSim: '/sell-sim',
  SellSimDetail: '/sell-sim/:id',
  TopUp: '/top-up',
  TopUpDetail: '/top-up/:id',
  NetworkProvider: '/network-provider',
  NetworkProviderDetail: '/network-provider/:id',
  TopUpType: '/top-up-type',
  TopUpTypeDetail: '/top-up-type/:id',
  TelecomService: '/telecom-service',
  TelecomServiceDetail: '/telecom-service/:id',
  Sim: '/sim',
  SimDetail: '/sim/:id',
  Message: '/message',
  MessageDetail: '/message/:id',
  CashCollectionProduct: '/cash-collection-product',
  CashCollectionProductDetail: '/cash-collection-product/:id',
  AutoNumber: '/auto-number',
  AutoNumberDetail: '/auto-number/:id',
  SimStyle: '/sim-style',
  SimStyleDetail: '/sim-style/:id',
  Expense: '/expense',
  ExpenseDetail: '/expense/:id',
  Income: '/income',
  IncomeDetail: '/income/:id',
  Version: '/version',
  VersionDetail: '/version/:id',
  Changelog: '/changelog',
  SellTelecomService: '/sell-telecom-service',
  SellTelecomServiceDetail: '/sell-telecom-service/:id',
  GameProvider: '/gameProvider',
  GameProviderDetail: '/gameProvider/:id',
  Card: '/card',
  CardDetail: '/card/:id',
  Workflow: '/workflow',
  WorkflowDetail: '/workflow/:id',
  InventoryImport: '/import',
  InventoryImportDetail: '/import/:id',
  InventoryExport: '/export',
  InventoryExportDetail: '/export/:id',
  SellProduct: '/sell-product',
  SellProductDetail: '/sell-product/:id',
  Loan: '/loan',
  LoanDetail: '/loan/:id',
  PaySupplier: '/pay-supplier',
  PaySupplierDetail: '/pay-supplier/:id',
  // BBS const URL
}

export enum ErrorCode {
  ServerError = 1,

  LoginFail = 2,
  DuplicateLoginName = 3,
  InvalidPermission = 4,
  CannotEdit = 5
}

export const ClientType = {
  Single: 1,
  Multiple: 2,
  Branch: 3,
}

export const TransferStatus = {
  Draft: 0,
  PendingApprove: 1,
  Approved: 2,
  Canceled: 3
}

export const ReceiveViettelStatus = {
  Draft: 1,
  PendingApprove: 2,
  Approved: 3,
  Cancelled: 4
}

export const InventoryImportStatus = {
  Draft: 1,
  PendingApprove: 2,
  Approved: 3,
  Canceled: 4
}

export const InventoryExportStatus = {
  Draft: 1,
  PendingApprove: 2,
  Approved: 3,
  Canceled: 4
}

export const SellProductStatus = {
  Draft: 1,
  PendingApprove: 2,
  Approved: 3,
  Canceled: 4
}

export const LoanStatus = {
  Draft: 1,
  PendingApprove: 2,
  Approved: 3,
  Canceled: 4
}

export const PaySupplierStatus = {
  Draft: 1,
  PendingApprove: 2,
  Approved: 3,
  Canceled: 4
}

export const Permission = {
  ApproveTransfers: 13,
  ApproveTransferViettels: 17,
  ApproveReceiveViettel: 20,
  ApproveTransferMoMos: 23,
  ApproveCashCollections: 26,
  ApproveTopUps: 36,
  ApproveExpense: 38,
  ApproveIncomes: 41,
  ApproveBuySims: 39,
  ApproveSellSims: 43,
  ApproveSellTelecomServices: 45,
  ApproveCards: 48,
  ApproveInventoryImports: 50,
  ApproveInventoryExports: 52,
  ApproveSellProducts: 54,
  ApproveLoans: 56,
  ApprovePaySuppliers: 58,
}

export const SimStyles = [
  { value: '1', text: 'sim:style_double' },
  { value: '2', text: 'sim:style_triple' },
  { value: '3', text: 'sim:style_quadra' }
]

export const NumberTypes = [
  { value: 1, text: 'sim:number_type_beauty' },
  { value: 2, text: 'sim:number_type_promo' },
]

export const SimTypes = [
  { value: 1, text: 'sim:type_before' },
  { value: 2, text: 'sim:type_after' },
]

export const SimStatus = [
  { value: 1, text: 'sim:status_normal' },
  { value: 2, text: 'sim:status_block_1_way' },
  { value: 3, text: 'sim:status_block_2_ways' }
]

export const MessageType = {
  Email: 1,
  Sms: 2
}

export const MessageStatus = {
  Sending: 1,
  Success: 2,
  Fail: 3,
  Cancel: 4
}

export const AutoNumberResetType = {
  None: 0,
  Daily: 1,
  Monthly: 2,
  Yearly: 3
}

export const SimUpdateType = {
  ClearAll: 1,
  UpdateOnly: 2
}

export const NotificationMethod = {
  SuccessMessage: 'SuccessMessage',
  Reset: 'Reset',
  ResetBank: 'ResetBank',
  ResetBranch: 'ResetBranch',
  ResetCashCollectionSetting: 'ResetCashCollectionSetting',
  ResetCashCollectionProduct: 'ResetCashCollectionProduct',
  ResetClient: 'ResetClient',
  ResetGameProvider: 'ResetGameProvider',
  ResetNetworkProvider: 'ResetNetworkProvider',
  ResetPaymentAccount: 'ResetPaymentAccount',
  ResetSim: 'ResetSim',
  ResetSimStyle: 'ResetSimStyle',
  ResetTelecomService: 'ResetTelecomService',
  ResetTopUpType: 'ResetTopUpType',
  ResetTransferSetting: 'ResetTransferSetting',
  ResetTransferSettingMoMo: 'ResetTransferSettingMoMo',
  ResetTransferViettelSetting: 'ResetTransferViettelSetting',
  ResetUser: 'ResetUser',
  ResetProduct: 'ResetProduct',
}

export const WorkflowObjects = [
  'Transfer',
  'InventoryImport'
]

export const WorkflowStatus = [
  { value: 0, text: 'new' },
  { value: 1, text: 'draft' },
  { value: 2, text: 'pending_approval' },
  { value: 3, text: 'approved' },
  { value: 4, text: 'cancelled' }
]

export const WorkflowButton = [
  'save',
  'submit',
  'approve',
  'cancel'
]