import { FilterDTO, GameProviderDTO } from '../../DTO';

const GAME_PROVIDER_REQUEST: string = 'GAME_PROVIDER_REQUEST';

const GAME_PROVIDER_GET_LIST_SUCCESS: string = 'GAME_PROVIDER_GET_LIST_SUCCESS';
const GAME_PROVIDER_GET_LIST_FAIL: string = 'GAME_PROVIDER_GET_LIST_FAIL';

const GAME_PROVIDER_SET_EDITING_GAME_PROVIDER: string = 'GAME_PROVIDER_SET_EDITING_GAME_PROVIDER';

const GAME_PROVIDER_GET_BY_ID_SUCCESS: string = 'GAME_PROVIDER_GET_BY_ID_SUCCESS';
const GAME_PROVIDER_GET_BY_ID_FAIL: string = 'GAME_PROVIDER_GET_BY_ID_FAIL';

const GAME_PROVIDER_SAVE_SUCCESS: string = 'GAME_PROVIDER_SAVE_SUCCESS';
const GAME_PROVIDER_SAVE_FAIL: string = 'GAME_PROVIDER_SAVE_FAIL';

const GAME_PROVIDER_DELETE_SUCCESS: string = 'GAME_PROVIDER_DELETE_SUCCESS';
const GAME_PROVIDER_DELETE_FAIL: string = 'GAME_PROVIDER_DELETE_FAIL';

const GAME_PROVIDER_GET_NAMES_SUCCESS: string = 'GAME_PROVIDER_GET_NAMES_SUCCESS';
const GAME_PROVIDER_GET_NAMES_FAIL: string = 'GAME_PROVIDER_GET_NAMES_FAIL';

const initialState = {
  editingGameProvider: {},
  gameProviderSelectItems: [],
}

export function gameProviderReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case GAME_PROVIDER_SET_EDITING_GAME_PROVIDER:
      return Object.assign({}, state, {
        editingGameProvider: action.payload
      });

    case GAME_PROVIDER_GET_NAMES_SUCCESS:
      return Object.assign({}, state, {
        gameProviderSelectItems: action.response?.data ?? []
      });

    default:
      return state;

  }

}

export function getListGameProvider(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [GAME_PROVIDER_REQUEST, GAME_PROVIDER_GET_LIST_SUCCESS, GAME_PROVIDER_GET_LIST_FAIL],
      EndPoint: `GameProviders${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingGameProvider(gameProvider: GameProviderDTO) {
  return {
    type: GAME_PROVIDER_SET_EDITING_GAME_PROVIDER,
    payload: gameProvider
  }
}

export function getGameProviderById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [GAME_PROVIDER_REQUEST, GAME_PROVIDER_GET_BY_ID_SUCCESS, GAME_PROVIDER_GET_BY_ID_FAIL],
      EndPoint: `GameProviders\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveGameProvider(gameProvider: GameProviderDTO) {
  let initRequest: RequestInit = {
    method: gameProvider.id ? 'PATCH' : 'POST',
    body: JSON.stringify(gameProvider),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [GAME_PROVIDER_REQUEST, GAME_PROVIDER_SAVE_SUCCESS, GAME_PROVIDER_SAVE_FAIL],
      EndPoint: 'GameProviders',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteGameProvider(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [GAME_PROVIDER_REQUEST, GAME_PROVIDER_DELETE_SUCCESS, GAME_PROVIDER_DELETE_FAIL],
      EndPoint: 'GameProviders',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function getGameProviderSelectItems() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [GAME_PROVIDER_REQUEST, GAME_PROVIDER_GET_NAMES_SUCCESS, GAME_PROVIDER_GET_NAMES_FAIL],
      EndPoint: 'GameProviders/selectList',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}