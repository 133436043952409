import { GlobalConst } from "../Consts";

let moment = require('moment');

export class CashCollectionDTO {
  public id: number;
  public name: string;
  public number: string;
  public contractNumber: string;
  public clientId: number;
  public bankId: number;
  public amount: number;
  public fee: number;
  public discount: number;
  public commission: number;
  public createdBy: number;
  public branchId: number;
  public commissionUserId: number;
  public status: number;
  public cashCollectionDate: string;
  public cashCollectionProductId: number;
  public approvedById: number;
  public approvedDate: string;
  public referenceFileName: string;
  public confirmationFileName: string;
  public payByCash: boolean;
  public receiveAccountId: number | null;

  constructor() {
    this.id = 0;
    this.name = '';
    this.number = '';
    this.contractNumber = '';
    this.clientId = 0;
    this.bankId = 0;
    this.amount = 0;
    this.fee = 0;
    this.discount = 0;
    this.commission = 0;
    this.createdBy = 0;
    this.branchId = 0;
    this.commissionUserId = 0;
    this.status = 0;
    this.cashCollectionDate = moment().format(GlobalConst.DATE_TIME_FORMAT);
    this.cashCollectionProductId = 0;
    this.approvedById = 0;
    this.approvedDate = '';
    this.referenceFileName = '';
    this.confirmationFileName = '';
    this.payByCash = true;
    this.receiveAccountId = null;
  }
}

export function cashCollectionToFormData(cashCollection: CashCollectionDTO) {
  let formData = new FormData();
  formData.append('id', cashCollection.id.toString());
  formData.append('name', cashCollection.name);
  formData.append('number', cashCollection.number);
  formData.append('contractNumber', cashCollection.contractNumber);
  formData.append('clientId', cashCollection.clientId.toString());
  formData.append('bankId', cashCollection.bankId.toString());
  formData.append('amount', cashCollection.amount.toString());
  formData.append('fee', cashCollection.fee.toString());
  formData.append('discount', cashCollection.discount.toString());
  formData.append('commission', cashCollection.commission.toString());
  formData.append('branchId', cashCollection.branchId.toString());
  formData.append('commissionUserId', cashCollection.commissionUserId.toString());
  formData.append('status', cashCollection.status.toString());
  formData.append('cashCollectionDate', cashCollection.cashCollectionDate);
  formData.append('cashCollectionProductId', cashCollection.cashCollectionProductId.toString());
  formData.append('referenceFileName', cashCollection.referenceFileName);
  formData.append('confirmationFileName', cashCollection.confirmationFileName);
  formData.append('payByCash', cashCollection.payByCash ? 'true' : 'false');
  formData.append('receiveAccountId', cashCollection.receiveAccountId?.toString() ?? '');

  return formData;
}