import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@material-ui/core';
import { ActionCreator } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import styles from '../../styles';
import { Button, Danger } from '../../Components';
import { CashCollectionProductDTO } from '../../DTO';
import { saveCashCollectionProduct, getCashCollectionProductSelectItems } from '../../Redux/CashCollectionProduct/CashCollectionProductReducer';
import { Save, KeyboardArrowLeft } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { showError } from '../../Utils';

interface IQuickCashCollectionProductProps extends WithTranslation {
  classes?: any;

  handleClose?: any;

  saveCashCollectionProduct: ActionCreator<any>,
  getCashCollectionProductSelectItems: ActionCreator<any>,
}

interface IQuickCashCollectionProductState {
  showValidation: boolean;

  cashCollectionProduct: CashCollectionProductDTO;
}

function mapStateToProps(state: any) {
  return ({
    cashCollectionProductSelectItems: state.cashCollectionProduct.cashCollectionProductSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    saveCashCollectionProduct: (cashCollectionProduct: CashCollectionProductDTO) => dispatch(saveCashCollectionProduct(cashCollectionProduct)),
    getCashCollectionProductSelectItems: () => dispatch(getCashCollectionProductSelectItems()),
  })
}

class QuickCashCollectionProduct extends React.Component<IQuickCashCollectionProductProps, IQuickCashCollectionProductState> {

  constructor(props: IQuickCashCollectionProductProps) {
    super(props);
    this.state = {
      cashCollectionProduct: new CashCollectionProductDTO(),
      showValidation: false,
    }
  }

  render() {
    const { t, classes } = this.props;
    let { showValidation, cashCollectionProduct: { name, number } } = this.state;

    return (
      <Dialog 
        open 
        onClose={this._onClose.bind(this)} 
        aria-labelledby="form-dialog-title"
        scroll='body'
        >
        <DialogTitle>{t('cashCollectionProduct:quick')}</DialogTitle>
        <DialogContent dividers>
          <TextField
            autoFocus
            margin="dense"
            label={t('cashCollectionProduct:number')}
            fullWidth
            name="number"
            onChange={this._onTextChange.bind(this)}
          />
          {showValidation && !number && <Danger>{t('message:required')}</Danger>}
          <TextField
            margin="dense"
            label={t('cashCollectionProduct:name')}
            fullWidth
            name="name"
            onChange={this._onTextChange.bind(this)}
          />
          {showValidation && !name && <Danger>{t('message:required')}</Danger>}
        </DialogContent>
        <DialogActions>
          <Button round onClick={this._onSave.bind(this)} color="primary">
            <Save className={classes.icons} /> {t('button:save')}
          </Button>
          <Button round onClick={this._onClose.bind(this)}>
            <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private _onClose() {
    const { handleClose } = this.props;
    handleClose && handleClose();
  }

  private _onTextChange(event: any) {
    event.persist();
    const { target: { name, value } } = event;
    let cashCollectionProduct = this.state.cashCollectionProduct;
    this.setState({ cashCollectionProduct: {...cashCollectionProduct, [name]: value} });
  }

  private _onValidate() {
    let { cashCollectionProduct: { name, number } } = this.state;

    return name && number;
  }

  private _onSave() {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });
    const { t, handleClose, getCashCollectionProductSelectItems } = this.props;
    let { cashCollectionProduct: { name, number } } = this.state;
    let cashCollectionProduct = new CashCollectionProductDTO();
    cashCollectionProduct.name = name;
    cashCollectionProduct.number = number;

    this.props.saveCashCollectionProduct(cashCollectionProduct).then((response: any) => {
      handleClose && handleClose();
      if (response && !response.errorCode) {
        toast.success(t('message:create_success'));
        getCashCollectionProductSelectItems();
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    });

  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(QuickCashCollectionProduct)))