export default {
  vi: {
    login: {
      'login': 'ĐĂNG NHẬP',
      'username': 'Tên đăng nhập',
      'password': 'Mật khẩu'
    },
    button: {
      'login': 'Đăng nhập',
      'logout': 'Đăng xuất',
      'refresh': 'Cập nhật',
      'add': 'Thêm mới',
      'edit': 'Chỉnh sửa',
      'delete': 'Xóa',
      'cancel': 'Hủy',
      'save': 'Lưu',
      'change': 'Đổi',
      'remove': 'Xóa',
      'add_image': 'Thêm hình',
      'select_image': 'Chọn hình',
      'revert': 'Hoàn tác',
      'save_close': 'Lưu và thoát',
      'approve': 'Duyệt',
      'save_new': 'Lưu và tạo mới',
      'save_submit': 'Lưu và gửi duyệt',
      'print': 'In phiếu',
      'upload': 'Tải lên',
      'select_file': 'Chọn tập tin',
      'down_template': 'Tải mẫu',
      'submit': 'Gửi duyệt',
      'back': 'Quay lại'
    },
    menu: {
      'dashboard': 'Bảng điều khiển',
      'user': 'Nhân viên',
      'function': 'Chức năng',
      'role': 'Phân quyền',
      'supplier': 'Nhà cung cấp',
      'product': 'Sản phẩm',
      'branch': 'Chi nhánh',
      'client': 'Khách hàng',
      'order': 'Đơn hàng',
      'not_found': 'Lỗi',
      'paymentAccount': 'TK thanh toán',
      'commission': 'Hoa hồng',
      'manage_user': 'Người dùng',
      'transfer_group': 'Chuyển tiền',
      'tenant_setting': 'Thiết lập',
      'transfer': 'Chuyển tiền NH',
      'transferSetting': 'Cài đặt chuyển tiền NH',
      'bank': 'DS Ngân hàng',
      'transferViettel': 'Chuyển tiền Viettel',
      'transferViettelSetting': 'Cài đặt chuyển tiền VT',
      'appDownload': 'Tải phần mềm',
      'receiveViettel': 'Nhận tiền Viettel',
      'transferMoMo': 'Chuyển tiền MoMo',
      'transferSettingMoMo': 'Cài đặt chuyển tiền MoMo',
      'cash_collection_group': 'Thu hộ',
      'cashCollection': 'Thu hộ',
      'cashCollectionSetting': 'Cài đặt thu hộ',
      'sim_card_group': 'Sim card',
      'buySim': 'Mua Sim',
      'sellSim': 'Bán Sim',
      'topUp': 'Nạp tiền ĐT',
      'networkProvider': 'Nhà mạng',
      'topUpType': 'Loại nạp tiền',
      'telecomService': 'Danh mục DVVT',
      'sim': 'Sim',
      'message': 'Thông báo',
      'cashCollectionProduct': 'Danh mục thu hộ',
      'autoNumber': 'Mã tự động',
      'simStyle': 'Loại số',
      'top_up_group': 'Nạp tiền ĐT',
      'expense': 'Chi tiền',
      'income': 'Thu tiền',
      'expense_group': 'Thu Chi',
      'version': 'Phiên bản',
      'changelog': 'Chi tiết cập nhật',
      'sellTelecomService': 'DV Viễn thông',
      'service_group': 'Dịch vụ',
      'gameProvider': 'Thẻ game',
      'card': 'Card',
      'workflow': 'Quy trình',
      "product_group": "Sản phẩm",
      'inventoryImport': 'Nhập kho',
      'inventoryExport': 'Xuất kho',
      'sellProduct': 'Bán hàng',
      'loan': 'Thu công nợ',
      'paySupplier': 'Trả công nợ'// BBS Resource menu
    },
    user: {
      'user': 'Danh sách nhân viên',
      'personal': 'Thông tin cá nhân',
      'setting': 'Cài đặt',
      'number': 'Mã NV',
      'first_name': 'Tên',
      'last_name': 'Họ',
      'display_name': 'Tên đầy đủ',
      'login_name': 'Tên đăng nhập',
      'phone': 'Điện thoại',
      'email': 'Email',
      'password': 'Mật khẩu',
      'repeat_password': 'Xác nhận MK',
      'avatar': 'Ảnh',
      'salary': 'Lương cơ bản',
      'start_date': 'Ngày bắt đầu',
      'end_date': 'Ngày kết thúc'
    },
    branch: {
      'branch': 'Chi nhánh',
      'name': 'Tên chi nhánh',
      'higher_branch': 'Chi nhánh cấp trên',
      'cash_amount': 'Tiền mặt'
    },
    function: {
      'function': 'Chức năng',
      'name': 'Tên chức năng',

      'Users': 'Nhân viên',
      'Functions': 'Chức năng',
      'Roles': 'Phân quyền',
      'Suppliers': 'Nhà cung cấp',
      'Branches': 'Chi nhánh',
      'Clients': 'Khách hàng',
      'Products': 'Sản phẩm',
      'PaymentAccounts': 'TK thanh toán',
      'Commissions': 'Hoa hồng',
      'Transfers': 'Chuyển tiền NH',
      'TransferSettings': 'Cài đặt chuyển tiền NH',
      'ApproveTransfers': 'Xét duyệt chuyển tiền NH',
      'Banks': 'DS ngân hàng',
      'TransferViettelSettings': 'Cài đặt chuyển tiền VT',
      'TransferViettels': 'Chuyển tiền VT',
      'ReceiveViettels': 'Nhận tiền VT',
      'ApproveReceiveViettels': 'Xét duyệt nhận tiền VT',
      'ApproveTransferViettels': 'Xét duyệt chuyển tiền VT',
      'TransferMoMos': 'Chuyển tiền MoMo',
      'TransferSettingMoMos': 'Cài đặt chuyển tiền MoMo',
      'ApproveTransferMoMos': 'Xét duyệt chuyển tiền MoMo',
      'CashCollections': 'Thu hộ',
      'CashCollectionSettings': 'Cài đặt thu hộ',
      'ApproveCashCollections': 'Xét duyệt thu hộ',
      'BuySims': 'Mua Sim',
      'ApproveBuySims': 'Xét duyệt mua Sim',
      'SellSims': 'Bán Sim',
      'TopUps': 'Nạp tiền ĐT',
      'NetworkProviders': 'Nhà mạng',
      'TopUpTypes': 'Loại nạp tiền',
      'TelecomServices': 'DV Viễn thông',
      'Sims': 'Sim',
      'CashCollectionProducts': 'Danh mục thu hộ',
      'AutoNumbers': 'Mã tự động',
      'ApproveTopUps': 'Xét duyệt nạp tiền',
      'Expenses': 'Chi tiền',
      'ApproveExpenses': 'Xét duyệt chi tiền',
      'Incomes': 'Thu tiền',
      'ApproveIncomes': 'Xét duyệt thu tiền',
      'ApproveSellSims': 'Xét duyệt bán Sim',
      'SimStyles': 'Loại sim',
      'ApproveSellTelecomServices': 'Xét duyệt DVVT',
      'SellTelecomServices': 'DV Viễn thông',
      'Cards': 'Card',
      'ApproveCards': 'Xét duyệt bán Card',
      'GameProviders': 'Thẻ game',
      'Workflows': 'Quy trình',
      'InventoryImports': 'Nhập kho',
      'InventoryExports': 'Xuất kho',
      'SellProducts': 'Bán hàng',
      'ApproveInventoryImports': 'Xét duyệt nhập kho',
      'ApproveInventoryExports': 'Xét duyệt xuất kho',
      'ApproveSellProducts': 'Xét duyệt bán hàng',
      'Loans': 'Thu công nợ',
      'ApproveLoans': 'Xét duyệt thu công nợ',
      'PaySuppliers': 'Trả công nợ',
      'ApprovePaySuppliers': 'Xét duyệt trả công nợ'
    },
    role: {
      'role': 'Phân quyền',
      'name': 'Tên phân quyền'
    },
    supplier: {
      'supplier': 'Nhà cung cấp',
      'number': 'Mã nhà cung cấp',
      'name': 'Tên NCC',
      'email': 'Email',
      'website': 'Website',
      'address': 'Địa chỉ',
      'phone': 'Số điện thoại',
      'contact_name': 'Người đại diện',
      'contact_phone': 'SĐT NĐD',
      'bank_name': 'Ngân hàng',
      'bank_number': 'STK ngân hàng',
      'group': 'Loại NCC',
      'group_single': 'Khách lẻ',
      'group_service': 'Dịch vụ',
      'group_support': 'Hổ trợ cửa hàng',
      'group_branch': 'Đại lý số đẹp',
      'group_collaborator': 'Cộng tác viên',
      'group_sale_point': 'Điểm bán thường',
      'note': 'Ghi chú',
      'amount': 'Công nợ'
    },
    product: {
      'product': 'Sản phẩm',
      'name': 'Tên SP',
      'multiple_sale_price': 'Giá sỉ',
      'sale_price': 'Giá lẻ',
      'note': 'Ghi chú',
      'is_service': 'Dịch vụ?',
      'type': 'Loại',
      'service': 'Dịch vụ',
      'statistic': 'Thống kê hàng tồn',
      'branch': 'Kho',
      'quantity': 'Số lượng'
    },
    client: {
      'client': 'Khách hàng',
      'name': 'Tên KH',
      'phone': 'SĐT',
      'address': 'Địa chỉ',
      'birthday': 'Sinh nhật',
      'email': 'Email',
      'type': 'Loại KH',
      'type_single': 'Khách lẻ',
      'type_multiple': 'Khách sỉ',
      'type_branch': 'Đại lý',
      'username': 'NV tạo',
      'branch': 'Chi nhánh',
      'created_date': 'Ngày tạo',
      'quick': 'Tạo nhanh khách hàng',
      'loan': 'Công nợ'
    },
    order: {
      'order': 'Đơn hàng',
      'number': 'Mã đơn hàng',
      'date_release': 'Ngày xuất',
      'item': 'Dịch vụ',
      'add_item': 'Thêm dịch vụ',
      'max_item_reached': 'Tối đa 100 dịch vụ cho 1 đơn hàng',
      'item_quantity': 'Số lượng',
      'item_price': 'Giá',
      'total': 'Thành tiền'
    },
    not_found: {
      'not_found': 'Trang không tìm thấy hoặc đã bị xóa!'
    },
    paymentAccount: {
      'paymentAccount': 'TK thanh toán',
      'name': 'Tên TK',
      'bank_name': 'Tên NH',
      'account_number': 'Số TK',
      'amount': 'Số dư'
    },
    commission: {
      'commission': 'Cài đặt hoa hồng',
      'amount': 'Cố định',
      'percent': 'Phần trăm'
    },
    transfer: {
      'client': 'Người gửi',
      'user': 'Nhân viên tạo',
      'transfer': 'Chuyển tiền NH',
      'number': 'Mã',
      'to_bank': 'Tên NH',
      'to_account_name': 'Tên người nhận',
      'to_account_number': 'Số TK',
      'detail': 'Nội dung CK',
      'amount': 'Số tiền',
      'fee': 'Phí DV',
      'commission': 'Hoa hồng',
      'discount': 'Giảm giá',
      'total': 'Thành tiền',
      'reference': 'Chứng từ',
      'note': 'Ghi chú',
      'status': 'Trạng thái',
      'status_draft': 'Nháp',
      'status_pending_approve': 'Chờ duyệt',
      'status_approved': 'Đã duyệt',
      'status_cancel': 'Hủy',
      'date': 'Đơn ngày',
      'date_from': 'Từ ngày',
      'date_to': 'Đến ngày',
      'confirmation': 'Xác nhận chuyển khoản',
      'select_confirmation': 'Vui lòng chọn hình ảnh xác nhận đã chuyển khoản',
      'approved_by': 'Người duyệt',
      'approved_date': 'Ngày duyệt',
      'branch': 'Chi nhánh',
      'commission_user': 'NVPT',
      'pay_by_cash': 'Thanh toán tiền mặt?',
      'receive_account': 'Tài khoản nhận tiền'
    },
    transferSetting: {
      'transferSetting': 'Cài đặt chuyển tiền NH',
      'name': 'Tên',
      'number': 'Mã',
      'from_amount': 'Từ số tiền',
      'to_amount': 'Tới số tiền',
      'sale_price': 'Giá lẻ',
      'multiple_sale_price': 'Giá sỉ',
      'commission': 'Hoa hồng NV',
      'note': 'Ghi chú'
    },
    bank: {
      'bank': 'Ngân hàng',
      'name': 'Tên NH',
      'number': 'Mã NH'
    },
    transferViettel: {
      'client': 'Người gửi',
      'user': 'Nhân viên tạo',
      'transferViettel': 'Chuyển tiền Viettel',
      'number': 'Mã',
      'to_bank': 'Tên NH',
      'to_account_name': 'Tên người nhận',
      'to_account_number': 'Số TK',
      'to_address': 'Địa chỉ người nhận',
      'to_phone': 'SĐT người nhận',
      'received_at_home': 'Giao tiền tại nhà',
      'fee_at_home': 'Phí giao tiền',
      'detail': 'Nội dung CK',
      'amount': 'Số tiền',
      'fee': 'Phí DV',
      'commission': 'Hoa hồng',
      'discount': 'Giảm giá',
      'buy_price': 'Giá gốc',
      'total': 'Thành tiền',
      'reference': 'Chứng từ',
      'note': 'Ghi chú',
      'status': 'Trạng thái',
      'status_draft': 'Nháp',
      'status_pending_approve': 'Chờ duyệt',
      'status_approved': 'Đã duyệt',
      'status_cancel': 'Đã hủy',
      'date': 'Đơn ngày',
      'date_from': 'Từ ngày',
      'date_to': 'Đến ngày',
      'confirmation': 'Xác nhận chuyển khoản',
      'select_confirmation': 'Vui lòng chọn hình ảnh xác nhận đã chuyển khoản',
      'approved_by': 'Người duyệt',
      'approved_date': 'Ngày duyệt',
      'branch': 'Chi nhánh',
      'commission_user': 'NVPT',
      'pay_by_cash': 'Thanh toán tiền mặt?',
      'receive_account': 'Tài khoản nhận tiền'
    },
    transferViettelSetting: {
      'transferViettelSetting': 'Cài đặt chuyển tiền VT',
      'name': 'Tên',
      'number': 'Mã',
      'from_amount': 'Từ số tiền',
      'to_amount': 'Tới số tiền',
      'sale_price': 'Giá lẻ',
      'multiple_sale_price': 'Giá sỉ',
      'buy_price': 'Giá gốc',
      'commission': 'Hoa hồng NV',
      'note': 'Ghi chú'
    },
    float_menu: {
      'quick_client': 'Tạo nhanh khách hàng',
      'quick_cash_collection_product': 'Tạo nhanh DM thu hộ'
    },
    appDownload: {
      'appDownload': 'Tải phần mềm',
      'appKey': 'Lấy key',
      'master': 'Mobi Master',
      'regInfo': 'Đổi TT-DV',
      'normalize': 'Chuấn hóa',
      'personalInfo': 'TT Cá nhân',
      'c6': 'Chọn số c6',
      'checkMobicard': 'Trạng thái Mobicard',
      'checkMobiGold': 'Trạng thái MobiGold',
      'generateKey': 'Tạo key',
      'getMacScript': 'Code lấy mã: getmac|clip',
      'macAddress': 'Mã máy',
      'secretKey': 'Mật khẩu',
      'key': 'Key {{index}}',
      'downloadInstaller': 'Tải bản cài'
    },
    receiveViettel: {
      'receiveViettel': 'Nhận tiền Viettel',
      'number': 'Mã',
      'status': 'Trạng thái',
      'status_draft': 'Nháp',
      'status_pending_approve': 'Chờ duyệt',
      'status_approved': 'Đã duyệt',
      'status_cancel': 'Đã hủy',
      'time': 'Thời gian',
      'user': 'Người tạo',
      'receiver_name': 'Tên người nhận',
      'identity_number': 'CMND',
      'phone': 'Số điện thoại',
      'secret_code': 'Mã bí mật',
      'amount': 'Số tiền',
      'fee': 'Phí',
      'discount': 'Giảm giá',
      'commission': 'Hoa hồng',
      'total': 'Tổng',
      'branch': 'Chi nhánh',
      'commission_user': 'NVPT',
      'date_from': 'Từ ngày',
      'date_to': 'Tới ngày',
      'approved_by': 'Người duyệt',
      'approved_date': 'Ngày duyệt',
      'pay_by_cash': 'Thanh toán tiền mặt?',
      'receive_account': 'Tài khoản nhận tiền'
    },
    transferMoMo: {
      'client': 'Người gửi',
      'user': 'Nhân viên tạo',
      'transferMoMo': 'Chuyển tiền MoMo',
      'number': 'Mã',
      'to_account_name': 'Tên người nhận',
      'to_account_number': 'Số TK',
      'detail': 'Nội dung CK',
      'amount': 'Số tiền',
      'fee': 'Phí DV',
      'commission': 'Hoa hồng',
      'discount': 'Giảm giá',
      'total': 'Thành tiền',
      'reference': 'Chứng từ',
      'note': 'Ghi chú',
      'status': 'Trạng thái',
      'status_draft': 'Nháp',
      'status_pending_approve': 'Chờ duyệt',
      'status_approved': 'Đã duyệt',
      'status_cancel': 'Hủy',
      'date': 'Đơn ngày',
      'date_from': 'Từ ngày',
      'date_to': 'Đến ngày',
      'confirmation': 'Xác nhận chuyển khoản',
      'select_confirmation': 'Vui lòng chọn hình ảnh xác nhận đã chuyển khoản',
      'approved_by': 'Người duyệt',
      'approved_date': 'Ngày duyệt',
      'branch': 'Chi nhánh',
      'commission_user': 'NVPT',
      'pay_by_cash': 'Thanh toán tiền mặt?',
      'receive_account': 'Tài khoản nhận tiền'
    },
    transferSettingMoMo: {
      'transferSettingMoMo': 'Cài đặt chuyển tiền MoMo',
      'name': 'Tên',
      'number': 'Mã',
      'from_amount': 'Từ số tiền',
      'to_amount': 'Tới số tiền',
      'sale_price': 'Giá lẻ',
      'multiple_sale_price': 'Giá sỉ',
      'commission': 'Hoa hồng NV',
      'note': 'Ghi chú'
    },
    cashCollectionSetting: {
      'cashCollectionSetting': 'Cài đặt thu hộ',
      'date': 'Ngày thu hộ',
      'name': 'Tên',
      'number': 'Mã',
      'from_amount': 'Từ số tiền',
      'to_amount': 'Tới số tiền',
      'sale_price': 'Giá lẻ',
      'multiple_sale_price': 'Giá sỉ',
      'commission': 'Hoa hồng NV',
      'note': 'Ghi chú'
    },
    cashCollection: {
      'cashCollection': 'Thu hộ',
      'number': 'Mã',
      'product': 'Mục thu hộ',
      'name': 'Người đứng tên',
      'client': 'Khách hàng',
      'date': 'Giờ thu',
      'contract_number': 'Số hợp đồng',
      'amount': 'Số tiền',
      'fee': 'Phí',
      'discount': 'Giảm giá',
      'total': 'Tổng',
      'bank': 'Ngân hàng',
      'branch': 'Chi nhánh',
      'commission_user': 'NVPT',
      'user': 'Người tạo',
      'status': 'Trạng thái',
      'status_draft': 'Nháp',
      'status_pending_approve': 'Chờ duyệt',
      'status_approved': 'Đã duyệt',
      'status_cancel': 'Đã hủy',
      'approved_by': 'Người duyệt',
      'approved_date': 'Ngày duyệt',
      'date_from': 'Từ ngày',
      'date_to': 'Đến ngày',
      'reference': 'Chứng từ',
      'confirmation': 'Xác nhận',
      'pay_by_cash': 'Thanh toán tiền mặt?',
      'receive_account': 'Tài khoản nhận tiền'
    },
    buySim: {
      'buySim': 'Mua Sim',
      'number': 'Mã',
      'sim': 'Số',
      'network_provider': 'Mạng',
      'buy_date': 'Ngày mua',
      'seller': 'Người bán',
      'amount': 'Số tiền',
      'fee': 'Phí',
      'discount': 'Giảm giá',
      'commission': 'Hoa hồng',
      'branch': 'Chi nhánh',
      'commission_user': 'NVPT',
      'total': 'Tổng tiền',
      'status': 'Trạng thái',
      'status_draft': 'Nháp',
      'status_pending_approve': 'Chờ duyệt',
      'status_approved': 'Đã duyệt',
      'status_cancel': 'Đã hủy',
      'date_from': 'Từ ngày',
      'date_to': 'Đến ngày',
      'approved_by': 'Người duyệt',
      'approved_date': 'Ngày duyệt',
      'user': 'Người tạo'
    },
    sellSim: {
      'sellSim': 'Bán Sim',
      'sim': 'Số',
      'number': 'Mã',
      'date_from': 'Từ ngày',
      'date_to': 'Đến ngày',
      'status': 'Trạng thái',
      'status_draft': 'Nháp',
      'status_pending_approve': 'Chờ duyệt',
      'status_approved': 'Đã duyệt',
      'status_cancel': 'Đã hủy',
      'user': 'Người tạo',
      'sell_date': 'Ngày bán',
      'buyer': 'Người mua',
      'amount': 'Số tiền',
      'fee': 'Phí',
      'discount': 'Giảm giá',
      'commission': 'Hoa hồng',
      'branch': 'Chi nhánh',
      'commission_user': 'NVPT',
      'total': 'Tổng tiền',
      'approved_by': 'Người duyệt',
      'approved_date': 'Ngày duyệt'
    },
    topUp: {
      'topUp': 'Nạp tiền',
      'client': 'Khách hàng',
      'number': 'Mã',
      'phone': 'Số điện thoại',
      'date': 'Ngày nạp',
      'amount': 'Số tiền',
      'discount': 'Giảm giá',
      'price': 'Giá gốc',
      'branch': 'Chi nhánh',
      'commission_user': 'NVPT',
      'network_provider': 'Mạng điện thoại',
      'top_up_type': 'Hình thức nạp',
      'total': 'Tổng tiền',
      'status': 'Trạng thái',
      'status_draft': 'Nháp',
      'status_pending_approve': 'Chờ duyệt',
      'status_approved': 'Đã duyệt',
      'status_cancel': 'Đã hủy',
      'approved_by': 'Người duyệt',
      'approved_date': 'Ngày duyệt',
      'date_from': 'Từ ngày',
      'date_to': 'Đến ngày',
      'user': 'Người tạo',
      'pay_by_cash': 'Thanh toán tiền mặt?',
      'receive_account': 'Tài khoản nhận tiền'
    },
    networkProvider: {
      'networkProvider': 'Nhà mạng',
      'name': 'Tên'
    },
    topUpType: {
      'topUpType': 'Loại nạp tiền',
      'name': 'Tên'
    },
    telecomService: {
      'telecomService': 'Danh mục DVVT',
      'name': 'Tên',
      'number': 'Mã',
      'sale_price': 'Giá lẻ',
      'multiple_sale_price': 'Giá sỉ',
      'price': 'Giá phải trả',
      'network_provider': 'Mạng',
      'note': 'Ghi chú'
    },
    sim: {
      'sim': 'Sim',
      'name': 'Số liền',
      'number': 'Số chấm',
      'price': 'Giá bán',
      'style': 'Dạng số',
      'style_double': 'Số cặp',
      'style_triple': 'Số tam',
      'style_quadra': 'Tứ quý',
      'number_type': 'Loại số',
      'number_type_beauty': 'Số đẹp',
      'number_type_promo': 'Số KM',
      'type': 'Loại TB',
      'type_before': 'Trả trước',
      'type_after': 'Trả sau',
      'network_provider': 'Mạng',
      'status': 'Tình trạng',
      'status_normal': 'Bình thường',
      'status_block_1_way': 'Chặn 1 chiều',
      'status_block_2_ways': 'Chặn 2 chiều',
      'is_out_of_stock': 'Hết hàng?',
      'out_of_stock_true': 'Hết hàng',
      'out_of_stock_false': 'Còn hàng',
      'out_of_stock_status': 'Tình trạng hàng',
      'upload': 'Tải lên',
      'update_type_clear_all': 'Đánh dấu tất cả sim hiện tại là hết hàng và cập nhật dữ liệu theo file tải lên',
      'update_type_update_only': 'Chỉ cập nhật theo file tải lên',
      'start_with': 'Đầu',
      'end_with': 'Đuôi',
    },
    message_history: {
      'message': 'Thông báo',
      'subject': 'Tiêu đề',
      'content': 'Nội dung',
      'to': 'Người nhận',
      'cc': 'Nhận bản sao',
      'bcc': 'Nhận bản sao ẩn',
      'status': 'Trạng thái',
      'type': 'Loại',
      'isHTML': 'Định dạng HTML',
      'created_date': 'Ngày tạo'
    },
    cashCollectionProduct: {
      'cashCollectionProduct': 'Danh mục thu hộ',
      'name': 'Tên mục thu hộ',
      'number': 'Mã',
      'quick': 'Tạo nhanh danh mục thu hộ'
    },
    autoNumber: {
      'autoNumber': 'Mã tự dộng',
      'number': 'Mã',
      'name': 'Dạng mã',
      'reset_type': 'Chu kì',
      'reset_type_none': 'Tăng liên tục',
      'reset_type_daily': 'Theo ngày',
      'reset_type_monthly': 'Theo tháng',
      'reset_type_yearly': 'Theo năm',
      'is_disabled': 'Ngừng hoạt động'
    },
    simStyle: {
      'simStyle': 'Loại số',
      'name': 'Tên'
    },
    expense: {
      'expense': 'Chi tiền',
      'number': 'Mã',
      'date_from': 'Từ ngày',
      'date_to': 'Đến ngày',
      'date': 'Ngày thực hiện',
      'amount': 'Số tiền',
      'detail': 'Nội dung',
      'status': 'Trạng thái',
      'status_draft': 'Nháp',
      'status_pending_approve': 'Chờ duyệt',
      'status_approved': 'Đã duyệt',
      'status_cancelled': 'Đã hủy',
      'approved_by': 'Người duyệt',
      'approved_date': 'Ngày duyệt',
      'branch': 'Chi nhánh',
      'commission_user': 'NVPT',
      'user': 'Người tạo',
      'receive_name': 'Tên người nhận',
      'receive_phone': 'SĐT người nhận',
      'type': 'Hình thức chi',
      'is_cash': 'Tiền mặt',
      'is_cash_cash': 'Tiền mặt',
      'is_cash_transfer': 'Chuyển khoản',
      'receive_bank': 'Ngân hàng nhận',
      'receive_bank_number': 'Số tài khoản',
      'payment_account': 'Tài khoản chi',
      'client': 'Người nhận',
      'client_phone': 'SĐT người nhận'
    },
    income: {
      'income': 'Thu tiền',
      'number': 'Mã',
      'date': 'Ngày thu',
      'type': 'Hình thức thu',
      'is_cash': 'Tiền mặt?',
      'is_cash_cash': 'Tiền mặt',
      'is_cash_transfer': 'Chuyển khoản',
      'amount': 'Số tiền',
      'payment_account': 'Vào TK',
      'detail': 'Nội dung',
      'from_branch': 'Từ chi nhánh',
      'status': 'Trạng thái',
      'status_draft': 'Nháp',
      'status_pending_approve': 'Chờ duyệt',
      'status_approved': 'Đã duyệt',
      'status_cancelled': 'Đã hủy',
      'commission_user': 'NVPT',
      'branch': 'Chi nhánh',
      'created_by': 'Người tạo',
      'approved_by': 'Người duyệt',
      'approved_date': 'Ngày duyệt',
      'date_from': 'Từ ngày',
      'date_to': 'Đến ngày'
    },
    version: {
      'version': 'Phiên bản',
      'number': 'Mã',
      'detail': 'Nội dung'
    },
    sellTelecomService: {
      'sellTelecomService': 'DV Viễn thông',
      'number': 'Mã',
      'status': 'Trạng thái',
      'status_draft': 'Nháp',
      'status_pending_approve': 'Chờ duyệt',
      'status_approved': 'Đã duyệt',
      'status_cancel': 'Đã hủy',
      'date_from': 'Từ ngày',
      'date_to': 'Tới ngày',
      'branch': 'Chi nhánh',
      'user': 'Người tạo',
      'date': 'Ngày thực hiện',
      'client': 'Khách hàng',
      'service': 'Dịch vụ',
      'supplier': 'Nhà cung cấp',
      'phone': 'Áp dụng cho SĐT',
      'amount': 'Giá tiền',
      'price': 'Giá gốc',
      'discount': 'Giảm giá',
      'total': 'Tổng',
      'commission': 'Hoa hồng',
      'commission_user': 'NVPT',
      'approved_by': 'Người duyệt',
      'approved_date': 'Ngày duyệt',
      'pay_by_cash': 'Thanh toán tiền mặt?',
      'receive_account': 'Tài khoản nhận tiền'
    },
    gameProvider: {
      'gameProvider': 'Thẻ game',
      'name': 'Tên'
    },
    card: {
      'card': 'Card',
      'number': 'Mã',
      'date': 'Ngày bán',
      'date_from': 'Từ ngày',
      'date_to': 'Đến ngày',
      'user': 'Người tạo',
      'client': 'Khách hàng',
      'game_provider': 'Thẻ game',
      'network_provider': 'Mạng',
      'top_up_type': 'Hình thức nạp',
      'amount': 'Mệnh giá',
      'price': 'Giá gốc',
      'quantity': 'Số lượng',
      'total': 'Thành tiền',
      'commission': 'Hoa hồng',
      'discount': 'Giảm giá',
      'status': 'Trạng thái',
      'status_draft': 'Nháp',
      'status_pending_approve': 'Chờ duyệt',
      'status_approved': 'Đã duyệt',
      'status_cancel': 'Đã hủy',
      'approved_by': 'Người duyệt',
      'approved_date': 'Ngày duyệt',
      'branch': 'Chi nhánh',
      'commission_user': 'NVPT',
      'pay_by_cash': 'Thanh toán tiền mặt?',
      'receive_account': 'Tài khoản nhận tiền'
    },
    workflow: {
      'workflow': 'Quy trình',
      'name': 'Tên',
      'number': 'Mã',
      'object': 'Đối tượng',
      'is_active': 'Đang hoạt động?',
      'from_date': 'Hiệu lực từ',
      'to_date': 'Hiệu lực đến',
      'details': 'Chi tiết',
      'add_state': 'Thêm bước',
      'add_button': 'Thêm hành động',
      'state_index': 'Bước {{num}}',
      'state_name': 'Tên',
      'state_status': 'Trạng thái',
      'state_buttons': 'Hành động',
      'state_button_name': 'Tên',
      'state_button_text_code': 'Hiển thị',
      'state_button_action': 'Thực thi',
      'state_button_icon': 'Biểu tượng',
      'state_button_color': 'Màu',
      'state_button_class': 'Lớp'
    },
    inventoryImport: {
      'inventoryImport': 'Nhập kho',
      'number': 'Mã',
      'status': 'Trạng thái',
      'status_new': 'Tạo mới',
      'status_draft': 'Nháp',
      'status_pending_approval': 'Chờ duyệt',
      'status_approved': 'Đã duyệt',
      'status_cancelled': 'Đã hủy',
      'date': 'Ngày nhập',
      'date_from': 'Từ ngày',
      'date_to': 'Đến ngày',
      'user': 'Người tạo',
      'branch': 'Chi nhánh',
      'product': 'Sản phẩm',
      'quantity': 'Số lượng',
      'price': 'Giá',
      'total': 'Tổng',
      'pay_by_cash': 'Thanh toán tiền mặt?',
      'receive_account': 'Tài khoản nhận tiền'
    },
    inventoryExport: {
      'inventoryExport': 'Xuất kho',
      'number': 'Mã',
      'status': 'Trạng thái',
      'status_new': 'Tạo mới',
      'status_draft': 'Nháp',
      'status_pending_approval': 'Chờ duyệt',
      'status_approved': 'Đã duyệt',
      'status_cancelled': 'Đã hủy',
      'date': 'Ngày nhập',
      'date_from': 'Từ ngày',
      'date_to': 'Đến ngày',
      'user': 'Người tạo',
      'from_branch': 'Từ chi nhánh',
      'to_branch': 'Tới chi nhánh',
      'product': 'Sản phẩm',
      'quantity': 'Số lượng'
    },
    sellProduct: {
      'sellProduct': 'Bán hàng',
      'number': 'Mã',
      'status': 'Trạng thái',
      'status_new': 'Tạo mới',
      'status_draft': 'Nháp',
      'status_pending_approval': 'Chờ duyệt',
      'status_approved': 'Đã duyệt',
      'status_cancelled': 'Đã hủy',
      'date': 'Ngày bán',
      'total': 'Tổng',
      'price': 'Giá',
      'date_from': 'Từ ngày',
      'date_to': 'Đến ngày',
      'user': 'Người tạo',
      'branch': 'Chi nhánh',
      'product': 'Sản phẩm',
      'quantity': 'Số lượng',
      'pay_by_cash': 'Thanh toán tiền mặt?',
      'receive_account': 'Tài khoản nhận tiền'
    },
    loan: {
      'loan': 'Thu công nợ',
      'number': 'Mã',
      'client': 'Khách hàng',
      'date': 'Ngày thu',
      'status': 'Trạng thái',
      'status_draft': 'Nháp',
      'status_pending_approval': 'Chờ duyệt',
      'status_approved': 'Đã duyệt',
      'status_cancelled': 'Đã hủy',
      'from_date': 'Từ ngày',
      'to_date': 'Tới ngày',
      'amount': 'Số tiền',
      'pay_by_cash': 'Thanh toán tiền mặt',
      'payment_account': 'Tài khoản nhận tiền'
    },
    paySupplier: {
      'paySupplier': 'Trả công nợ',
      'number': 'Mã',
      'supplier': 'Nhà cung cấp',
      'date': 'Ngày trả',
      'status': 'Trạng thái',
      'status_draft': 'Nháp',
      'status_pending_approval': 'Chờ duyệt',
      'status_approved': 'Đã duyệt',
      'status_cancelled': 'Đã hủy',
      'from_date': 'Từ ngày',
      'to_date': 'Tới ngày',
      'amount': 'Số tiền',
      'pay_by_cash': 'Thanh toán tiền mặt',
      'payment_account': 'Tài khoản trả tiền',
      'branch': 'Tiền mặt của chi nhánh',
    },
    // BBS Resource entity
    workflow_object: {
      'transfer': 'Chuyển tiền NH',
      'inventoryimport': 'Nhập kho'
    },
    common: {
      'loading': 'Đang tải ...',
      'no_data': 'Không có dữ liệu',
      'page': 'Trang',
      'page_sizes': 'Số dòng dữ liệu',
      'version': 'Phiên bản',
      'search': 'Tìm kiếm',
      'information': 'Thông tin',
      'all': 'Tất cả',
      'email': 'Email',
      'sms': 'Sms',
      'processing': 'Đang xử lý',
      'success': 'Thành công',
      'fail': 'Thất bại',
      'cancel': 'Hủy',
      'advance_search': 'Tìm kiếm nâng cao',
      'default': 'Mặc định'
    },
    message: {
      'confirm_delete': 'Bạn có chắc chắn muốn xóa mục đã chọn?',
      'save_success': 'Lưu thành công!',
      'create_success': 'Tạo mới thành công!',
      'delete_success': 'Xóa thành công!',
      'required': 'Đây là trường bắt buộc, vui lòng điền dữ liệu!',
      'duplicate_login_name': 'Tên đăng nhập đã tồn tại, vui lòng chọn tên đăng nhập khác',
      'invalid_email': 'Email không hợp lệ!',
      'repeat_password_not_match': 'Xác nhận mật khẩu không trùng khớp!',
      'old_version': 'Phiên bản hiện tại đã cũ. Vui lòng tải lại trang để sử dụng phiên bản mới nhất!',
      'upload_success': 'Tải lên thành công',
      'license_almost_expire': 'Giấy phép sử dụng của công ty sẽ hết hạn sau {{day}} ngày nữa. Vui lòng gia hạn để không cản trở công việc!',
      'license_last_day': 'Giấy phép sử dụng của công ty sẽ hết hạn trong hôm nay. Vui lòng gia hạn để không cản trở công việc!'
    },
    status: {
      'new': 'Tạo mới',
      'draft': 'Nháp',
      'pending_approval': 'Chờ duyệt',
      'approved': 'Đã duyệt',
      'cancelled': 'Đã hủy'
    },
    error: {
      '1': 'Lỗi hệ thống',
      '2': 'Tên đăng nhập hoặc mật khẩu không đúng. Vui lòng thử lại',
      '3': 'Tên đăng nhập đã tồn tại',
      '4': 'Bạn không đủ quyền hạn để sử dụng chức năng này.',
      '5': 'Không thể cập nhật cho mục này, vui lòng tải lại trang và thử lại',
      '6': 'Trùng số điện thoại',
      '7': 'Giấy phép sử dụng của công ty đã hết hạn sử dụng. Vui lòng gia hạn giấy phép để tiếp tục sử dụng',
      '8': 'Sim số này đang chờ xét duyệt để bán hoặc đã được bán. Vui lòng chọn sim khác!',
      '9': 'Không tìm thấy quy trình phù hợp',
      '10': 'Không đủ số lượng sản phẩm để chuyển kho!',
    }
  }
}