import React from 'react';
import { ActionCreator } from 'redux';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withStyles, FormLabel } from '@material-ui/core';
import styles from '../../styles';
import { ColumnContainer, IconCard, GridContainer, Button, InputText, Info, Danger } from '../../Components';
import { GetApp, VpnKey } from '@material-ui/icons';
import Datetime from 'react-datetime';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GlobalConst, URL as URLConsts } from '../../Consts';
import { Moment } from 'moment';
import { GenerateAppKeyDTO } from '../../DTO';
import { generateKeys } from '../../Redux/AppDownload/AppDownloadReducer';
import { showError } from '../../Utils';
import { saveAs } from 'file-saver';
let moment = require('moment');
var FileSaver = require('file-saver');

interface IAppDownloadProps extends WithTranslation {
  classes?: any,

  setActiveRoute: ActionCreator<any>,
  generateKeys: ActionCreator<any>,
}

interface IAppDownloadStates {
  isSaving: boolean;
  showValidation: boolean;

  generateAppKeyDTO: GenerateAppKeyDTO;
  keys: string[],
}

function mapStateToProps(state: any) {
  return ({})
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    generateKeys: (generateAppKeyDTO: GenerateAppKeyDTO) => dispatch(generateKeys(generateAppKeyDTO)),
  })
}

class AppDownload extends React.Component<IAppDownloadProps, IAppDownloadStates> {

  constructor(props: IAppDownloadProps) {
    super(props);

    this.state = {
      isSaving: false,
      showValidation: false,
      generateAppKeyDTO: new GenerateAppKeyDTO(),
      keys: [],
    }
  }

  componentDidMount() {
    const { setActiveRoute, t } = this.props;

    setActiveRoute([{path: URLConsts.AppDownload, name: t('menu:appDownload')}]);
  }

  render() {
    const { t, classes } = this.props;
    let { showValidation, keys, generateAppKeyDTO: { secretKey, masterDate, regInfoDate, normalizeDate, personalInfoDate, c6Date, checkMobicardDate, checkMobiGoldDate, macAddressStr} } = this.state;

    return (
      <ColumnContainer columnWidth={[{xs:12, sm:6, md:8}, {xs:12, sm:6, md:4}]}>
        <IconCard
          icon={VpnKey}
          iconColor={'green'}
          title={t('appDownload:appKey')}>
          
          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>
            <ColumnContainer columnWidth={[{xs:12, sm:4}, {xs:12, sm:8}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('appDownload:secretKey')} </FormLabel>
              <>
                <InputText 
                  name="secretKey" 
                  type='password'
                  values={secretKey} 
                  onChange={this._onTextChange.bind(this)}
                />
                {showValidation && !secretKey && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <></>

            <ColumnContainer columnWidth={[{xs:12, sm:4}, {xs:12, sm:8}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('appDownload:master')} </FormLabel>
              <>
                <Datetime
                  dateFormat={GlobalConst.DATE_FORMAT}
                  inputProps={{ placeholder: t('appDownload:master') }}
                  onChange={this._onDateChange.bind(this, 'masterDate')}
                  value={masterDate}
                />
                {showValidation && !masterDate && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:4}, {xs:12, sm:8}]}>
              <FormLabel className={classes.labelHorizontal}> {t('appDownload:regInfo')} </FormLabel>
              <Datetime
                dateFormat={GlobalConst.DATE_FORMAT}
                inputProps={{ placeholder: t('appDownload:regInfo') }}
                onChange={this._onDateChange.bind(this, 'regInfoDate')}
                value={regInfoDate}
              />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:4}, {xs:12, sm:8}]}>
              <FormLabel className={classes.labelHorizontal}> {t('appDownload:normalize')} </FormLabel>
              <Datetime
                dateFormat={GlobalConst.DATE_FORMAT}
                inputProps={{ placeholder: t('appDownload:normalize') }}
                onChange={this._onDateChange.bind(this, 'normalizeDate')}
                value={normalizeDate}
              />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:4}, {xs:12, sm:8}]}>
              <FormLabel className={classes.labelHorizontal}> {t('appDownload:personalInfo')} </FormLabel>
              <Datetime
                dateFormat={GlobalConst.DATE_FORMAT}
                inputProps={{ placeholder: t('appDownload:personalInfo') }}
                onChange={this._onDateChange.bind(this, 'personalInfoDate')}
                value={personalInfoDate}
              />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:4}, {xs:12, sm:8}]}>
              <FormLabel className={classes.labelHorizontal}> {t('appDownload:c6')} </FormLabel>
              <Datetime
                dateFormat={GlobalConst.DATE_FORMAT}
                inputProps={{ placeholder: t('appDownload:c6') }}
                onChange={this._onDateChange.bind(this, 'c6Date')}
                value={c6Date}
              />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:4}, {xs:12, sm:8}]}>
              <FormLabel className={classes.labelHorizontal}> {t('appDownload:checkMobicard')} </FormLabel>
              <Datetime
                dateFormat={GlobalConst.DATE_FORMAT}
                inputProps={{ placeholder: t('appDownload:checkMobicard') }}
                onChange={this._onDateChange.bind(this, 'checkMobicardDate')}
                value={checkMobicardDate}
              />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:4}, {xs:12, sm:8}]}>
              <FormLabel className={classes.labelHorizontal}> {t('appDownload:checkMobiGold')} </FormLabel>
              <Datetime
                dateFormat={GlobalConst.DATE_FORMAT}
                inputProps={{ placeholder: t('appDownload:checkMobiGold') }}
                onChange={this._onDateChange.bind(this, 'checkMobiGoldDate')}
                value={checkMobiGoldDate}
              />
            </ColumnContainer>
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs:12, sm:2}, {xs:12, sm:10}]}>
            <FormLabel className={classes.labelHorizontal} required> {t('appDownload:macAddress')} </FormLabel>
            <>
              <InputText 
                multiline={true}
                name="macAddressStr" 
                values={macAddressStr} 
                onChange={this._onTextChange.bind(this)}
              />
              <Info>{t('appDownload:getMacScript')}</Info>
            </>
          </ColumnContainer>

          {keys && keys.length ?
            <GridContainer>
              {keys.map((k: string, idx: number) => {
                return (
                  <Button color='primary' customClass={classes.marginRight} round key={idx} onClick={this._saveKey.bind(this, k, idx + 1)}>
                    <VpnKey className={classes.icons} /> {t('appDownload:key', {index: idx + 1})}
                  </Button>
                )
              })}
            </GridContainer> : null}

          <GridContainer justify="center">
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onGenKey.bind(this)} disabled={this.state.isSaving}>
              <VpnKey className={classes.icons} /> {t('appDownload:generateKey')}
            </Button>
          </GridContainer>
        </IconCard>
        
        <IconCard
          icon={GetApp}
          iconColor={'green'}
          title={t('appDownload:appDownload')}>
            
          <GridContainer justify="center">
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onDownload.bind(this)}>
              <GetApp className={classes.icons} /> {t('appDownload:downloadInstaller')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onValidate() {
    let { generateAppKeyDTO: { secretKey, masterDate }} = this.state;

    return secretKey && masterDate;
  }

  private _onDateChange(name: string, value: string | Moment) {
    let generateAppKeyDTO = this.state.generateAppKeyDTO;
    this.setState({ generateAppKeyDTO: {...generateAppKeyDTO, [name]: value ? moment(value).format(GlobalConst.DATE_TIME_FORMAT) : '' }});
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ generateAppKeyDTO: {...this.state.generateAppKeyDTO, [name]: value }});
  }

  private _onGenKey() {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }

    this.setState({ showValidation: false });

    let generateAppKeyDTO = Object.assign({}, this.state.generateAppKeyDTO, { macAddressStr: ''});

    let address = this.state.generateAppKeyDTO.macAddressStr.split('\n') ?? [];
    for (let i = 3; i + 1 < address.length; i++) {
      generateAppKeyDTO.macAddress.push(address[i].substr(0, 17));
    }

    this.setState({ isSaving: true });
    this.props.generateKeys(generateAppKeyDTO).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        this.setState({ keys: response.data });
      } else {
        showError(this.props.t, response.errorCode, response.errorMessage);
      }
    })
  }

  private _saveKey(key: string, idx: number) {
    const element = document.createElement("a");
    const file = new Blob([key]);
    element.href = URL.createObjectURL(file);
    element.download = `key${idx}`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  private _onDownload() {
    window.open('https://snipe-development.s3.amazonaws.com/%2Fe5b581a4-b1e6-4003-bc17-2f7d0c2b8588%2FCAG-Appx-002BB_v1.4_copy.pdf', '_blank');
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(AppDownload)));