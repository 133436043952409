import React from 'react';
import inputWithButtonStyle from './InputWithButtonStyle';
import { withStyles, Paper, InputBase, IconButton } from "@material-ui/core";

interface IInputWithButtonProps {
  classes: any;

  placeHolder?: string;
  label?: string;
  icon: any;
  onClick: any;
}

interface IInputWithButtonStates {}

class InputWithButton extends React.Component<IInputWithButtonProps, IInputWithButtonStates> {

  render() {
    const { classes, placeHolder, label, icon, onClick } = this.props;
    
    return (
      <Paper component="form" className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder={placeHolder}
          inputProps={{ 'aria-label': label }}
        />
        <IconButton className={classes.iconButton} onClick={onClick}>
          {icon}
        </IconButton>
      </Paper>
    )
  }

}

export default withStyles(inputWithButtonStyle)(InputWithButton);