// ##############################
// // // ChartCard styles
// #############################

import {
  card,
  cardHeader,
  defaultFont,
  orangeCardHeader,
  greenCardHeader,
  redCardHeader,
  blueCardHeader,
  purpleCardHeader,
  roseCardHeader,
  cardActions,
  grayColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  primaryColor,
  roseColor
} from "../../Assets/material-dashboard-pro-react";
import { createStyles } from '@material-ui/core';

const chartCardStyle = createStyles({
  card: {
    ...card,
    overflow: "visible"
  },
  cardHeader: {
    ...cardHeader,
    padding: "0",
    minHeight: "160px",
    ...defaultFont,
    position: "relative",
    zIndex: 3,
    transition: "all 300ms cubic-bezier(0.34, 1.61, 0.7, 1)",
    transform: "translate3d(0, 0, 0)"
  },
  orangeCardHeader: {
    ...orangeCardHeader
  },
  greenCardHeader: {
    ...greenCardHeader
  },
  redCardHeader: {
    ...redCardHeader
  },
  blueCardHeader: {
    ...blueCardHeader
  },
  purpleCardHeader: {
    ...purpleCardHeader
  },
  roseCardHeader: {
    ...roseCardHeader
  },
  cardContent: {
    padding: "15px 20px",
    position: "relative"
  },
  cardTitle: {
    marginTop: "0",
    marginBottom: "5px",
    ...defaultFont,
    fontSize: "1.175em"
  },
  cardCategory: {
    marginBottom: "0",
    color: grayColor,
    ...defaultFont,
    fontSize: "0.9em"
  },
  cardActions: {
    ...cardActions,
    padding: "10px 0 0 0!important"
  },
  cardStats: {
    lineHeight: "22px",
    color: grayColor,
    fontSize: "12px",
    display: "inline-block",
    margin: "0!important"
  },
  cardStatsIcon: {
    position: "relative",
    top: "4px",
    width: "16px",
    height: "16px"
  },
  warningCardStatsIcon: {
    color: warningColor
  },
  primaryCardStatsIcon: {
    color: primaryColor
  },
  dangerCardStatsIcon: {
    color: dangerColor
  },
  successCardStatsIcon: {
    color: successColor
  },
  infoCardStatsIcon: {
    color: infoColor
  },
  roseCardStatsIcon: {
    color: roseColor
  },
  grayCardStatsIcon: {
    color: grayColor
  },
  cardStatsLink: {
    color: primaryColor,
    textDecoration: "none",
    ...defaultFont
  },
  underChart: {
    position: "absolute",
    zIndex: 1,
    top: "-50px",
    width: "calc(100% - 30px)",
    left: "17px",
    right: "17px",
    textAlign: "center"
  },
  moveChartUp: {
    transform: "translate3d(0, -50px, 0)"
  }
});

export default chartCardStyle;
