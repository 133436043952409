import { GlobalConst } from "../Consts";

let moment = require('moment');

export class CardDTO {
  public id: number;
  public number: string;
  public date: string;
  public networkProviderId: number | null;
  public gameProviderId: number | null;
  public clientId: number;
  public topUpTypeId: number;
  public price: number;
  public amount: number;
  public discount: number;
  public commission: number;
  public quantity: number;
  public status: number;
  public approvedById: number | null;
  public approvedDate: string;
  public commissionUserId: number;
  public branchId: number;
  public payByCash: boolean;
  public receiveAccountId: number | null;

  constructor() {
    this.id = 0;
    this.number = '';
    this.date = moment().format(GlobalConst.DATE_TIME_FORMAT);
    this.networkProviderId = null;
    this.gameProviderId = null;
    this.clientId = 0;
    this.topUpTypeId = 0;
    this.price = 0;
    this.amount = 0;
    this.discount = 0;
    this.commission = 0;
    this.quantity = 1;
    this.status = 0;
    this.approvedById = null;
    this.approvedDate = '';
    this.commissionUserId = 0;
    this.branchId = 0;
    this.payByCash = true;
    this.receiveAccountId = null;
  }
}