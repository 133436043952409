import { combineReducers } from "redux";
import {
  commonReducer,
  tenantReducer,
  userReducer,
  branchReducer,
  functionReducer,
  roleReducer,
  supplierReducer,
  productReducer,
  clientReducer,
  orderReducer,
  paymentAccountReducer,
  commissionReducer,
  transferReducer,
  transferSettingReducer,
  bankReducer,
  transferViettelReducer,
  transferViettelSettingReducer,
  appDownloadReducer,
  receiveViettelReducer,
  transferMoMoReducer,
  transferSettingMoMoReducer,
  cashCollectionReducer,
  cashCollectionSettingReducer,
  buySimReducer,
  sellSimReducer,
  topUpReducer,
  networkProviderReducer,
  topUpTypeReducer,
  telecomServiceReducer,
  simReducer,
  messageReducer,
  cashCollectionProductReducer,
  autoNumberReducer,
  simStyleReducer,
  expenseReducer,
  incomeReducer,
  versionReducer,
  sellTelecomServiceReducer,
  gameProviderReducer,
  cardReducer,
  workflowReducer,
  inventoryImportReducer,
  inventoryExportReducer,
  sellProductReducer,
  loanReducer,
  paySupplierReducer,
  // BBS Import Reducers
} from './';

const rootReducer = combineReducers({
  common: commonReducer,
  user: userReducer,
  tenant: tenantReducer,
  branch: branchReducer,
  function: functionReducer,
  role: roleReducer,
  supplier: supplierReducer,
  product: productReducer,
  client: clientReducer,
  order: orderReducer,
  paymentAccount: paymentAccountReducer,
  commission: commissionReducer,
  transfer: transferReducer,
  transferSetting: transferSettingReducer,
  bank: bankReducer,
  transferViettel: transferViettelReducer,
  transferViettelSetting: transferViettelSettingReducer,
  appDownloadReducer: appDownloadReducer,
  receiveViettelReducer: receiveViettelReducer,
  receiveViettel: receiveViettelReducer,
  transferMoMo: transferMoMoReducer,
  transferSettingMoMo: transferSettingMoMoReducer,
  cashCollection: cashCollectionReducer,
  cashCollectionSetting: cashCollectionSettingReducer,
  buySim: buySimReducer,
  sellSim: sellSimReducer,
  topUp: topUpReducer,
  networkProvider: networkProviderReducer,
  topUpType: topUpTypeReducer,
  telecomService: telecomServiceReducer,
  sim: simReducer,
  message: messageReducer,
  cashCollectionProduct: cashCollectionProductReducer,
  autoNumber: autoNumberReducer,
  simStyle: simStyleReducer,
  expense: expenseReducer,
  income: incomeReducer,
  version: versionReducer,
  sellTelecomService: sellTelecomServiceReducer,
  gameProvider: gameProviderReducer,
  card: cardReducer,
  workflow: workflowReducer,
  inventoryImport: inventoryImportReducer,
  inventoryExport: inventoryExportReducer,
  sellProduct: sellProductReducer,
  loan: loanReducer,
  paySupplier: paySupplierReducer,
  // BBS define reducer
})

export default rootReducer;