import { FilterDTO, CardDTO } from '../../DTO';

const CARD_REQUEST: string = 'CARD_REQUEST';

const CARD_GET_LIST_SUCCESS: string = 'CARD_GET_LIST_SUCCESS';
const CARD_GET_LIST_FAIL: string = 'CARD_GET_LIST_FAIL';

const CARD_SET_EDITING_CARD: string = 'CARD_SET_EDITING_CARD';

const CARD_GET_BY_ID_SUCCESS: string = 'CARD_GET_BY_ID_SUCCESS';
const CARD_GET_BY_ID_FAIL: string = 'CARD_GET_BY_ID_FAIL';

const CARD_SAVE_SUCCESS: string = 'CARD_SAVE_SUCCESS';
const CARD_SAVE_FAIL: string = 'CARD_SAVE_FAIL';

const CARD_DELETE_SUCCESS: string = 'CARD_DELETE_SUCCESS';
const CARD_DELETE_FAIL: string = 'CARD_DELETE_FAIL';

const CARD_APPROVE_SUCCESS: string = 'CARD_APPROVE_SUCCESS';
const CARD_APPROVE_FAIL: string = 'CARD_APPROVE_FAIL';

const CARD_CANCEL_SUCCESS: string = 'CARD_CANCEL_SUCCESS';
const CARD_CANCEL_FAIL: string = 'CARD_CANCEL_FAIL';

const CARD_EXPORT_LIST_SUCCESS: string = 'CARD_EXPORT_LIST_SUCCESS';
const CARD_EXPORT_LIST_FAIL: string = 'CARD_EXPORT_LIST_FAIL';

const initialState = {
  editingCard: {},
}

export function cardReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case CARD_SET_EDITING_CARD:
      return Object.assign({}, state, {
        editingCard: action.payload
      });

    default:
      return state;

  }

}

export function getListCard(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CARD_REQUEST, CARD_GET_LIST_SUCCESS, CARD_GET_LIST_FAIL],
      EndPoint: `Cards${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingCard(card: CardDTO) {
  return {
    type: CARD_SET_EDITING_CARD,
    payload: card
  }
}

export function getCardById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CARD_REQUEST, CARD_GET_BY_ID_SUCCESS, CARD_GET_BY_ID_FAIL],
      EndPoint: `Cards\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveCard(card: CardDTO, submit: boolean) {
  let initRequest: RequestInit = {
    method: card.id ? 'PATCH' : 'POST',
    body: JSON.stringify(card),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CARD_REQUEST, CARD_SAVE_SUCCESS, CARD_SAVE_FAIL],
      EndPoint: `Cards\\${submit}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteCard(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CARD_REQUEST, CARD_DELETE_SUCCESS, CARD_DELETE_FAIL],
      EndPoint: 'Cards',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function approveCard(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CARD_REQUEST, CARD_APPROVE_SUCCESS, CARD_APPROVE_FAIL],
      EndPoint: 'Cards\\Approve',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function cancelCard(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CARD_REQUEST, CARD_CANCEL_SUCCESS, CARD_CANCEL_FAIL],
      EndPoint: 'Cards\\Cancel',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function exportCard(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CARD_REQUEST, CARD_EXPORT_LIST_SUCCESS, CARD_EXPORT_LIST_FAIL],
      EndPoint: `Cards\\export${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}