import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL, GlobalConst, TransferStatus, Permission } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer, Autocomplete } from '../../Components';
import { PhoneIphone, Save, KeyboardArrowLeft, Clear } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getSellSimById, saveSellSim, approveSellSim, cancelSellSim } from '../../Redux/SellSim/SellSimReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { SellSimDTO, SelectItemDTO, CurrentUserDTO } from '../../DTO';
import { getClientSelectItems } from '../../Redux/Client/ClientReducer';
import { getUserSelectItems } from '../../Redux/User/UserReducer';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';
import { getSimSelectItems } from '../../Redux/Sim/SimReducer';
let moment = require('moment');

interface ISellSimDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  sellSim: SellSimDTO;
  clientSelectItems: SelectItemDTO[];
  branchSelectItems: SelectItemDTO[];
  userSelectItems: SelectItemDTO[];
  simSelectItems: SelectItemDTO[];
  currentUser: CurrentUserDTO;

  setActiveRoute: ActionCreator<any>,
  getSellSimById: ActionCreator<any>,
  saveSellSim: ActionCreator<any>,
  getClientSelectItems: ActionCreator<any>,
  getBranchSelectItems: ActionCreator<any>,
  getUserSelectItems: ActionCreator<any>,
  approveSellSim: ActionCreator<any>,
  cancelSellSim: ActionCreator<any>,
  getSimSelectItems: ActionCreator<any>,
}

interface ISellSimDetailStates {
  isSaving?: boolean;

  sellSim: SellSimDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    sellSim: state.sellSim.editingSellSim,
    clientSelectItems: state.client.clientSelectItems,
    branchSelectItems: state.branch.branchSelectItems,
    userSelectItems: state.user.userSelectItems,
    currentUser: state.user.currentUser,
    simSelectItems: state.sim.simSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getSellSimById: (id: number) => dispatch(getSellSimById(id)),
    saveSellSim: (sellSim: SellSimDTO, submit: boolean) => dispatch(saveSellSim(sellSim, submit)),
    getClientSelectItems: () => dispatch(getClientSelectItems()),
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
    getUserSelectItems: () => dispatch(getUserSelectItems()),
    approveSellSim: (id: number) => dispatch(approveSellSim(id)),
    cancelSellSim: (id: number) => dispatch(cancelSellSim(id)),
    getSimSelectItems: () => dispatch(getSimSelectItems()),
  })
}

class SellSimDetail extends React.Component<ISellSimDetailProps, ISellSimDetailStates> {

  constructor(props: ISellSimDetailProps) {
    super(props);
    this.state = {
      sellSim: {...new SellSimDTO(), ...props.sellSim}
    }
  }

  componentDidMount() {
    !this.props.clientSelectItems?.length && this.props.getClientSelectItems();
    !this.props.branchSelectItems?.length && this.props.getBranchSelectItems();
    !this.props.userSelectItems?.length && this.props.getUserSelectItems();
    !this.props.simSelectItems?.length && this.props.getSimSelectItems();

    const { setActiveRoute, match, getSellSimById, t } = this.props;

    setActiveRoute([{path: URL.SellSim, name: this.props.t('menu:sellSim')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getSellSimById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.SellSim);
          } else {
            this.setState({ sellSim: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes, currentUser, clientSelectItems, branchSelectItems, userSelectItems, simSelectItems } = this.props;
    let { showValidation, sellSim: { id, number, simId, sellDate, buyerId, amount, fee, discount, commission, branchId, commissionUserId, status } } = this.state;

    const statusText = [
      { value: TransferStatus.Draft, text: t('sellSim:status_draft')},
      { value: TransferStatus.PendingApprove, text: t('sellSim:status_pending_approve')},
      { value: TransferStatus.Approved, text: t('sellSim:status_approved')},
      { value: TransferStatus.Canceled, text: t('sellSim:status_cancel')},
    ]

    let statusStr = statusText.find(s => s.value === status)?.text || '';
    let tDate = sellDate ? moment(sellDate, GlobalConst.DATE_TIME_FORMAT) : moment(new Date());
    let now = moment(new Date());
    let readOnly = (status === TransferStatus.Approved || status === TransferStatus.Canceled) && (tDate.year() * 12 + tDate.month() < now.year() * 12 + now.month());

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={PhoneIphone}
          iconColor={'green'}
          title={t('common:information')}>

          {id && currentUser.functionIds.includes(Permission.ApproveSellSims) && status === TransferStatus.PendingApprove ? 
            <ColumnContainer columnWidth={[{xs:12, sm:12}]}>
              <div className={classes.right}>
                <Button color='primary' customClass={classes.marginRight} round onClick={this._onApprove.bind(this)} disabled={this.state.isSaving}>
                  <Save className={classes.icons} /> {t('button:approve')}
                </Button>
                <Button color='danger' customClass={classes.marginRight} round onClick={this._onCancel.bind(this)} disabled={this.state.isSaving}>
                  <Clear className={classes.icons} /> {t('button:cancel')}
                </Button>
              </div>
            </ColumnContainer>
          : null }

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('sellSim:number')} </FormLabel>
              <InputText values={number} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('sellSim:status')} </FormLabel>
              <InputText values={statusStr} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('sellSim:sell_date')} </FormLabel>
              {!readOnly ? 
                <Datetime
                  dateFormat={GlobalConst.DATE_FORMAT}
                  timeFormat={"HH:mm"}
                  inputProps={{ placeholder: t('sellSim:sell_date') }}
                  onChange={this._onDateChange.bind(this)}
                  value={sellDate}
                /> : <InputText disabled values={sellDate} /> }
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('sellSim:sim')} </FormLabel>
              <>
                <Autocomplete
                  name='simId'
                  value={simId}
                  options={simSelectItems?.filter(s => !s.details.isOutOfStock)}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !simId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('sellSim:buyer')} </FormLabel>
              <>
                <Autocomplete
                  name='buyerId'
                  value={buyerId}
                  options={clientSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  getOptionLabel={(option: SelectItemDTO) => `${option.text} - ${option.details.phone}`}
                />
                {showValidation && !buyerId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('sellSim:amount')} </FormLabel>
              <>
                <InputText 
                  name="amount"
                  numberOnly
                  thousandSeparator
                  values={amount} 
                  onChange={this._onNumberChange.bind(this)} />
                {showValidation && !amount && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('sellSim:fee')} </FormLabel>
              <>
                <InputText 
                  name="fee"
                  numberOnly
                  thousandSeparator
                  values={fee} 
                  onChange={this._onNumberChange.bind(this)} />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('sellSim:discount')} </FormLabel>
              <>
                <InputText 
                  name="discount"
                  numberOnly
                  thousandSeparator
                  values={discount} 
                  onChange={this._onNumberChange.bind(this)} />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('sellSim:commission')} </FormLabel>
              <>
                <InputText 
                  name="commission"
                  numberOnly
                  thousandSeparator
                  values={commission} 
                  onChange={this._onNumberChange.bind(this)} />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('sellSim:total')} </FormLabel>
              <>
                <InputText 
                  numberOnly
                  thousandSeparator
                  values={parseFloat(amount.toString()) + parseFloat(fee.toString()) - parseFloat(discount.toString())} 
                  disabled />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('sellSim:branch')} </FormLabel>
              <>
                <Autocomplete
                  name='branchId'
                  value={branchId}
                  options={branchSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                />
                {showValidation && !branchId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('sellSim:commission_user')} </FormLabel>
              <>
                <Autocomplete
                  name='commissionUserId'
                  value={commissionUserId}
                  options={userSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                />
                {showValidation && !commissionUserId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

          </ColumnContainer>

          <GridContainer justify="center">
          {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button> : null}
            {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button> : null}
            {status === TransferStatus.Draft ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_submit')}
            </Button> : null}
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.SellSim)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ sellSim: {...this.state.sellSim, [name]: value }});
  }

  private _onDateChange(value: string | Moment) {
    let sellSim = this.state.sellSim;
    sellSim.sellDate = moment(value).format(GlobalConst.DATE_TIME_FORMAT);
    this.setState({ sellSim: sellSim });
  }

  private _onValidate() {
    let { sellSim: { simId, buyerId, amount, branchId, commissionUserId } } = this.state;

    return simId && buyerId && amount && branchId && commissionUserId;
  }

  private _onSave(submit: boolean = false, close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { sellSim: { simId, sellDate, buyerId, amount, fee, discount, commission, branchId, commissionUserId } } = this.state;
    let sellSim = new SellSimDTO();
    sellSim.id = parseInt(this.props.match.params.id);
    sellSim.simId = simId;
    sellSim.sellDate = sellDate;
    sellSim.buyerId = buyerId;
    sellSim.amount = amount;
    sellSim.fee = fee;
    sellSim.discount = discount;
    sellSim.commission = commission;
    sellSim.branchId = branchId;
    sellSim.commissionUserId = commissionUserId;

    this.props.saveSellSim(sellSim, submit).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        sellSim.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close || submit) {
          history.push(URL.SellSim);
        } else if (parseInt(match.params.id) === 0) {
          this.setState({ sellSim: response.data });
          history.push(URL.SellSimDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

  private _onApprove() {
    const { t } = this.props;
    this.setState({ isSaving: true });

    this.props.approveSellSim(this.state.sellSim.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.SellSim);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

  private _onCancel() {
    const { t } = this.props;
    this.setState({ isSaving: true });
    
    this.props.cancelSellSim(this.state.sellSim.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.SellSim);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(SellSimDetail)));