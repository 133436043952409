import { FilterDTO, ExpenseDTO } from '../../DTO';

const EXPENSE_REQUEST: string = 'EXPENSE_REQUEST';

const EXPENSE_GET_LIST_SUCCESS: string = 'EXPENSE_GET_LIST_SUCCESS';
const EXPENSE_GET_LIST_FAIL: string = 'EXPENSE_GET_LIST_FAIL';

const EXPENSE_SET_EDITING_EXPENSE: string = 'EXPENSE_SET_EDITING_EXPENSE';

const EXPENSE_GET_BY_ID_SUCCESS: string = 'EXPENSE_GET_BY_ID_SUCCESS';
const EXPENSE_GET_BY_ID_FAIL: string = 'EXPENSE_GET_BY_ID_FAIL';

const EXPENSE_SAVE_SUCCESS: string = 'EXPENSE_SAVE_SUCCESS';
const EXPENSE_SAVE_FAIL: string = 'EXPENSE_SAVE_FAIL';

const EXPENSE_DELETE_SUCCESS: string = 'EXPENSE_DELETE_SUCCESS';
const EXPENSE_DELETE_FAIL: string = 'EXPENSE_DELETE_FAIL';

const EXPENSE_APPROVE_SUCCESS: string = 'EXPENSE_APPROVE_SUCCESS';
const EXPENSE_APPROVE_FAIL: string = 'EXPENSE_APPROVE_FAIL';

const EXPENSE_CANCEL_SUCCESS: string = 'EXPENSE_CANCEL_SUCCESS';
const EXPENSE_CANCEL_FAIL: string = 'EXPENSE_CANCEL_FAIL';

const EXPENSE_EXPORT_LIST_SUCCESS: string = 'EXPENSE_EXPORT_LIST_SUCCESS';
const EXPENSE_EXPORT_LIST_FAIL: string = 'EXPENSE_EXPORT_LIST_FAIL';

const initialState = {
  editingExpense: {},
}

export function expenseReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case EXPENSE_SET_EDITING_EXPENSE:
      return Object.assign({}, state, {
        editingExpense: action.payload
      });

    default:
      return state;

  }

}

export function getListExpense(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [EXPENSE_REQUEST, EXPENSE_GET_LIST_SUCCESS, EXPENSE_GET_LIST_FAIL],
      EndPoint: `Expenses${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingExpense(expense: ExpenseDTO) {
  return {
    type: EXPENSE_SET_EDITING_EXPENSE,
    payload: expense
  }
}

export function getExpenseById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [EXPENSE_REQUEST, EXPENSE_GET_BY_ID_SUCCESS, EXPENSE_GET_BY_ID_FAIL],
      EndPoint: `Expenses\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveExpense(expense: ExpenseDTO, submit: boolean) {
  let initRequest: RequestInit = {
    method: expense.id ? 'PATCH' : 'POST',
    body: JSON.stringify(expense),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [EXPENSE_REQUEST, EXPENSE_SAVE_SUCCESS, EXPENSE_SAVE_FAIL],
      EndPoint: `Expenses\\${submit}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteExpense(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [EXPENSE_REQUEST, EXPENSE_DELETE_SUCCESS, EXPENSE_DELETE_FAIL],
      EndPoint: 'Expenses',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function approveExpense(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [EXPENSE_REQUEST, EXPENSE_APPROVE_SUCCESS, EXPENSE_APPROVE_FAIL],
      EndPoint: 'Expenses\\Approve',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function cancelExpense(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [EXPENSE_REQUEST, EXPENSE_CANCEL_SUCCESS, EXPENSE_CANCEL_FAIL],
      EndPoint: 'Expenses\\Cancel',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function exportExpense(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [EXPENSE_REQUEST, EXPENSE_EXPORT_LIST_SUCCESS, EXPENSE_EXPORT_LIST_FAIL],
      EndPoint: `Expenses\\export${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}