import React from 'react';
import cx from "classnames";

import loginHeaderStyle from './LoginHeaderStyle';
import { withStyles, AppBar, Toolbar, Button } from '@material-ui/core';

interface ILoginHeaderProps {
  classes: any;
  color: string;
}

interface ILoginHeaderStates {
  open?: boolean;
}

class LoginHeader extends React.Component<ILoginHeaderProps, ILoginHeaderStates> {

  constructor(props: ILoginHeaderProps) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { classes, color } = this.props;
    const appBarClasses = cx({
      [" " + classes[color]]: color
    });

    return (
      <AppBar position="static" className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <div className={classes.flex}>
            <Button href="#" className={classes.title}>
              Vũ Gia ERP
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    )
  }
}

export default withStyles(loginHeaderStyle)(LoginHeader)