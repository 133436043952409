export class SimDTO {
  public id: number;
  public name: string;
  public number: string;
  public price: number;
  public styleId: number;
  public numberType: number;
  public type: number;
  public networkProviderId: number;
  public status: number;
  public isOutOfStock: boolean;

  constructor() {
    this.id = 0;
    this.name = '';
    this.number = '';
    this.price = 0;
    this.styleId = 0;
    this.numberType = 1;
    this.type = 1;
    this.networkProviderId = 0;
    this.status = 1;
    this.isOutOfStock = false;
  }
}