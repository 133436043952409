// User JSX because of react-table (stupid)

import React from 'react';
import ReactTable from 'react-table'
import { useTranslation } from 'react-i18next';
import { GridContainer, GridItem, Pagination, CustomSelect } from '../';

const pageSizes = [10, 20, 50, 100, 1000];
const defaultPageSize = 20;

export default function DataList(props) {
  const { t } = useTranslation();
  const { data, columns, pageSize, fetchData, currentPage, totalPage, onChangePage, onPageSizeChange, hidePaging } = props;

  let paging = [{ text: currentPage + 1, active: true }];
  if (currentPage > 0) {
    paging.unshift({ text: currentPage });
  }
  if (currentPage + 1 < totalPage) {
    paging.push({ text: currentPage + 2 });
  }

  if (currentPage === 2) {
    paging.unshift({ text: 1 });
  } else if (currentPage > 2) {
    paging.unshift({ text: 1 }, {});
  }

  if (currentPage + 3 === totalPage) {
    paging.push({ text: totalPage })
  } else if (currentPage + 3 < totalPage) {
    paging.push({}, { text: totalPage });
  }

  return (
    <>
      <ReactTable
        data={data}
        columns={columns}
        onFetchData={fetchData ? ((tableState, instance) => fetchData(tableState, instance)) : undefined}
        page={currentPage}
        minRows={2}
        manual={!!fetchData}
        defaultPageSize={pageSize || defaultPageSize}
        pageSizeOptions={pageSizes}
        showPaginationTop={false}
        showPaginationBottom={false}
        className="-striped -highlight"
        noDataText={t('common:no_data')}
      />
      {!hidePaging ? <GridContainer>
        <GridItem xs={12} sm={1} md={1}>
          <CustomSelect
            style={{paddingTop: '18px'}} 
            value={pageSize}
            onChange={(value) => onPageSizeChange(parseInt(value ?? defaultPageSize))}
            items={pageSizes.map(i => { return { value: i, text: i.toString() }})}
            label={t('common:page_sizes')} />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            <Pagination
              onClick={onChangePage}
              pages={paging}
            />
        </GridItem>
      </GridContainer> : null}
    </>
  )
}