export class VersionDTO {
  public id: number;
  public number: string;
  public detail: string;
  public createdDate: string;

  constructor() {
    this.id = 0;
    this.number = '';
    this.detail = '';
    this.createdDate = '';
  }
}