import { UserLoginDTO, FilterDTO, UserDTO, CurrentUserDTO, userToFormData, currrentUserToFormData } from '../../DTO';
import { GlobalConst } from '../../Consts';

const USER_REQUEST: string = 'USER_REQUEST';
const USER_FAIL: string = 'USER_FAIL';

const USER_LOGIN_SUCCESS: string = 'USER_LOGIN_SUCCESS';
const USER_LOGOUT_SUCCESS: string = 'USER_LOGOUT_SUCCESS';

const USER_GET_LIST_SUCCESS: string = 'USER_GET_LIST_SUCCESS';
const USER_GET_LIST_FAIL: string = 'USER_GET_LIST_FAIL';

const USER_SET_EDITING_USER: string = 'USER_SET_EDITING_USER';

const USER_GET_BY_ID_SUCCESS: string = 'USER_GET_BY_ID_SUCCESS';
const USER_GET_BY_ID_FAIL: string = 'USER_GET_BY_ID_FAIL';

const USER_SAVE_SUCCESS: string = 'USER_SAVE_SUCCESS';
const USER_SAVE_FAIL: string = 'USER_SAVE_FAIL';

const USER_DELETE_SUCCESS: string = 'USER_DELETE_SUCCESS';
const USER_DELETE_FAIL: string = 'USER_DELETE_FAIL';

const USER_GET_INFO_SUCCESS: string = 'USER_GET_INFO_SUCCESS';
const USER_GET_INFO_FAIL: string = 'USER_GET_INFO_FAIL';

const USER_INFO_SAVE_SUCCESS: string = 'USER_INFO_SAVE_SUCCESS';
const USER_INFO_SAVE_FAIL: string = 'USER_INFO_SAVE_FAIL';

const USER_GET_NAMES_SUCCESS: string = 'USER_GET_NAMES_SUCCESS';
const USER_GET_NAMES_FAIL: string = 'USER_GET_NAMES_FAIL';

const initialState = {
  token: localStorage.getItem(GlobalConst.LOCAL_STORAGE_TOKEN) || null,
  isLoading: false,
  currentUser: new CurrentUserDTO(),

  userSelectItems: [],

  editingUser: {},
}

export function userReducer(state = initialState, action: any) {

  switch (action.type) {

    case USER_REQUEST:
      return Object.assign({}, state, {
        isLoading: true
      });

    case USER_LOGIN_SUCCESS:
      localStorage.setItem(GlobalConst.LOCAL_STORAGE_TOKEN, action.response.token);
      return Object.assign({}, state, {
        isLoading: false,
        token: action.response.token,
        user: {displayName: 'Người dùng'}
      });

    case USER_LOGOUT_SUCCESS:
      localStorage.removeItem(GlobalConst.LOCAL_STORAGE_TOKEN);
      return Object.assign({}, state, {
        isLoading: false,
        token: '',
      });

    case USER_GET_LIST_SUCCESS:
      return state;

    case USER_GET_LIST_FAIL:
      return state;    
    
    case USER_SET_EDITING_USER:
      return Object.assign({}, state, {
        editingUser: action.payload
      });

    case USER_GET_INFO_SUCCESS:
      return Object.assign({}, state, {
        currentUser: action.response.data
      })

    case USER_GET_NAMES_SUCCESS:
      return Object.assign({}, state, {
        userSelectItems: action.response?.data ?? []
      })

    default:
      return state;

  }

}

export function login(loginDTO: UserLoginDTO) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: JSON.stringify(loginDTO),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [USER_REQUEST, USER_LOGIN_SUCCESS, USER_FAIL],
      EndPoint: 'users/login',
      AuthenticationRequire: false,
      Config: initRequest
    }
  }
}

export function logout() {
  let initRequest: RequestInit = {
    method: 'POST',
    body: JSON.stringify({ token: localStorage.getItem(GlobalConst.LOCAL_STORAGE_TOKEN) }), // TODO
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [USER_REQUEST, USER_LOGOUT_SUCCESS, USER_FAIL],
      EndPoint: 'users/logout',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function getListUser(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [USER_REQUEST, USER_GET_LIST_SUCCESS, USER_GET_LIST_FAIL],
      EndPoint: `Users${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingUser(user: UserDTO) {
  return {
    type: USER_SET_EDITING_USER,
    payload: user
  }
}

export function getUserById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [USER_REQUEST, USER_GET_BY_ID_SUCCESS, USER_GET_BY_ID_FAIL],
      EndPoint: `Users\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveUser(user: UserDTO, avatarFile: any) {
  let formData = userToFormData(user);
  if (avatarFile) {
    formData.append('avatar', avatarFile);
  }

  let initRequest: RequestInit = {
    method: user.id ? 'PATCH' : 'POST',
    body: formData,
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [USER_REQUEST, USER_SAVE_SUCCESS, USER_SAVE_FAIL],
      EndPoint: 'Users',
      AuthenticationRequire: true,
      Config: initRequest,
      IsUpload: true
    }
  }
}

export function deleteUser(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [USER_REQUEST, USER_DELETE_SUCCESS, USER_DELETE_FAIL],
      EndPoint: 'Users',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function getUserInfo() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [USER_REQUEST, USER_GET_INFO_SUCCESS, USER_GET_INFO_FAIL],
      EndPoint: `Users\\info`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveUserInfo(user: CurrentUserDTO, avatarFile: any) {
  let formData = currrentUserToFormData(user);
  if (avatarFile) {
    formData.append('avatar', avatarFile);
  }

  let initRequest: RequestInit = {
    method: 'POST',
    body: formData,
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [USER_REQUEST, USER_INFO_SAVE_SUCCESS, USER_INFO_SAVE_FAIL],
      EndPoint: 'Users/info',
      AuthenticationRequire: true,
      Config: initRequest,
      IsUpload: true
    }
  }
}

export function getUserSelectItems() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [USER_REQUEST, USER_GET_NAMES_SUCCESS, USER_GET_NAMES_FAIL],
      EndPoint: 'Users/selectList',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}