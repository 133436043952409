import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer } from '../../Components';
import { People, Save, KeyboardArrowLeft } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getBankById, saveBank } from '../../Redux/Bank/BankReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { BankDTO } from '../../DTO';

interface IBankDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  bank: BankDTO;

  setActiveRoute: ActionCreator<any>,
  getBankById: ActionCreator<any>,
  saveBank: ActionCreator<any>,
}

interface IBankDetailStates {
  isSaving?: boolean;

  bank: BankDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    bank: state.bank.editingBank,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getBankById: (id: number) => dispatch(getBankById(id)),
    saveBank: (bank: BankDTO) => dispatch(saveBank(bank)),
  })
}

class BankDetail extends React.Component<IBankDetailProps, IBankDetailStates> {

  constructor(props: IBankDetailProps) {
    super(props);
    this.state = {
      bank: {...new BankDTO(), ...props.bank}
    }
  }

  componentDidMount() {
    const { setActiveRoute, match, getBankById, t } = this.props;

    setActiveRoute([{path: URL.Bank, name: this.props.t('menu:bank')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getBankById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.Bank);
          } else {
            this.setState({ bank: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes } = this.props;
    let { showValidation, bank: { name, number } } = this.state;

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={People}
          iconColor={'green'}
          title={t('common:information')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('bank:number')} </FormLabel>
              <>
                <InputText name='number' values={number} onChange={this._onTextChange.bind(this)} />
                {showValidation && !number && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('bank:name')} </FormLabel>
              <>
                <InputText name='name' values={name} onChange={this._onTextChange.bind(this)} />
                {showValidation && !name && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

          </ColumnContainer>

          <GridContainer justify="center">
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button>
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button>
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.Bank)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ bank: {...this.state.bank, [name]: value }});    
  }

  private _onValidate() {
    let { bank: { name, number } } = this.state;

    return name && number;
  }

  private _onSave(close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { bank: { name, number } } = this.state;
    let bank = new BankDTO();
    bank.id = parseInt(this.props.match.params.id);
    bank.name = name;
    bank.number = number;

    this.props.saveBank(bank).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        bank.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close) {
          history.push(URL.Bank);
        } else if (parseInt(match.params.id) === 0) {
          history.push(URL.BankDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(BankDetail)));