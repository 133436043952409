import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer } from '../../Components';
import { People, Save, KeyboardArrowLeft } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getTopUpTypeById, saveTopUpType } from '../../Redux/TopUpType/TopUpTypeReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { TopUpTypeDTO } from '../../DTO';

interface ITopUpTypeDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  topUpType: TopUpTypeDTO;

  setActiveRoute: ActionCreator<any>,
  getTopUpTypeById: ActionCreator<any>,
  saveTopUpType: ActionCreator<any>,
}

interface ITopUpTypeDetailStates {
  isSaving?: boolean;

  topUpType: TopUpTypeDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    topUpType: state.topUpType.editingTopUpType,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getTopUpTypeById: (id: number) => dispatch(getTopUpTypeById(id)),
    saveTopUpType: (topUpType: TopUpTypeDTO) => dispatch(saveTopUpType(topUpType)),
  })
}

class TopUpTypeDetail extends React.Component<ITopUpTypeDetailProps, ITopUpTypeDetailStates> {

  constructor(props: ITopUpTypeDetailProps) {
    super(props);
    this.state = {
      topUpType: {...new TopUpTypeDTO(), ...props.topUpType}
    }
  }

  componentDidMount() {
    const { setActiveRoute, match, getTopUpTypeById, t } = this.props;

    setActiveRoute([{path: URL.TopUpType, name: this.props.t('menu:topUpType')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getTopUpTypeById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.TopUpType);
          } else {
            this.setState({ topUpType: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes } = this.props;
    let { showValidation, topUpType: { name } } = this.state;

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
          <IconCard
            icon={People}
            iconColor={'green'}
            title={t('common:information')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('topUpType:name')} </FormLabel>
              <>
                <InputText name='name' values={name} onChange={this._onTextChange.bind(this)} />
                {showValidation && !name && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

          </ColumnContainer>

          <GridContainer justify="center">
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button>
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button>
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.TopUpType)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ topUpType: {...this.state.topUpType, [name]: value }});    
  }

  private _onValidate() {
    let { topUpType: { name } } = this.state;

    return name;
  }

  private _onSave(close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { topUpType: { name } } = this.state;
    let topUpType = new TopUpTypeDTO();
    topUpType.id = parseInt(this.props.match.params.id);
    topUpType.name = name;

    this.props.saveTopUpType(topUpType).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        topUpType.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close) {
          history.push(URL.TopUpType);
        } else if (parseInt(match.params.id) === 0) {
          history.push(URL.TopUpTypeDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(TopUpTypeDetail)));