import { FilterDTO, VersionDTO } from '../../DTO';

const VERSION_REQUEST: string = 'VERSION_REQUEST';

const VERSION_GET_LIST_SUCCESS: string = 'VERSION_GET_LIST_SUCCESS';
const VERSION_GET_LIST_FAIL: string = 'VERSION_GET_LIST_FAIL';

const VERSION_SET_EDITING_VERSION: string = 'VERSION_SET_EDITING_VERSION';

const VERSION_GET_BY_ID_SUCCESS: string = 'VERSION_GET_BY_ID_SUCCESS';
const VERSION_GET_BY_ID_FAIL: string = 'VERSION_GET_BY_ID_FAIL';

const VERSION_SAVE_SUCCESS: string = 'VERSION_SAVE_SUCCESS';
const VERSION_SAVE_FAIL: string = 'VERSION_SAVE_FAIL';

const VERSION_DELETE_SUCCESS: string = 'VERSION_DELETE_SUCCESS';
const VERSION_DELETE_FAIL: string = 'VERSION_DELETE_FAIL';

const VERSION_GET_ALL_SUCCESS: string = 'VERSION_GET_ALL_SUCCESS';
const VERSION_GET_ALL_FAIL: string = 'VERSION_GET_ALL_FAIL';

const initialState = {
  editingVersion: {},
  allVersion: []
}

export function versionReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case VERSION_SET_EDITING_VERSION:
      return Object.assign({}, state, {
        editingVersion: action.payload
      });

    case VERSION_GET_ALL_SUCCESS:
      return Object.assign({}, state, {
        allVersion: action.response?.data ?? []
      });

    default:
      return state;

  }

}

export function getListVersion(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [VERSION_REQUEST, VERSION_GET_LIST_SUCCESS, VERSION_GET_LIST_FAIL],
      EndPoint: `Versions${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingVersion(version: VersionDTO) {
  return {
    type: VERSION_SET_EDITING_VERSION,
    payload: version
  }
}

export function getVersionById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [VERSION_REQUEST, VERSION_GET_BY_ID_SUCCESS, VERSION_GET_BY_ID_FAIL],
      EndPoint: `Versions\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveVersion(version: VersionDTO) {
  let initRequest: RequestInit = {
    method: version.id ? 'PATCH' : 'POST',
    body: JSON.stringify(version),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [VERSION_REQUEST, VERSION_SAVE_SUCCESS, VERSION_SAVE_FAIL],
      EndPoint: 'Versions',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteVersion(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [VERSION_REQUEST, VERSION_DELETE_SUCCESS, VERSION_DELETE_FAIL],
      EndPoint: 'Versions',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function getAllVersion() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [VERSION_REQUEST, VERSION_GET_ALL_SUCCESS, VERSION_GET_ALL_FAIL],
      EndPoint: 'Versions\\list',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}