import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, CustomSelect, InputText, ColumnContainer, Autocomplete } from '../../Components';
import { People, Save, KeyboardArrowLeft } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { SupplierDTO, SelectItemDTO } from '../../DTO';
import { getSupplierById, saveSupplier } from '../../Redux/Supplier/SupplierReducer';
import { getBankSelectItems } from '../../Redux/Bank/BankReducer';
import { currency, showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';

interface ISupplierDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  supplier: SupplierDTO;
  bankSelectItems: SelectItemDTO[];

  setActiveRoute: ActionCreator<any>,
  getSupplierById: ActionCreator<any>,
  saveSupplier: ActionCreator<any>,
  getBankSelectItems: ActionCreator<any>,
}

interface ISupplierDetailStates {
  isSaving?: boolean;

  supplier: SupplierDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    supplier: state.supplier.editingSupplier,
    bankSelectItems: state.bank.bankSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getSupplierById: (id: number) => dispatch(getSupplierById(id)),
    saveSupplier: (supplier: SupplierDTO) => dispatch(saveSupplier(supplier)),
    getBankSelectItems: () => dispatch(getBankSelectItems()),
  })
}

class SupplierDetail extends React.Component<ISupplierDetailProps, ISupplierDetailStates> {

  constructor(props: ISupplierDetailProps) {
    super(props);
    this.state = {
      supplier: {...new SupplierDTO(), ...props.supplier}
    }
  }

  componentDidMount() {
    !this.props.bankSelectItems?.length && this.props.getBankSelectItems();

    const { setActiveRoute, match, getSupplierById, t } = this.props;

    setActiveRoute([{path: URL.Supplier, name: this.props.t('menu:supplier')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getSupplierById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.Supplier);
          } else {
            this.setState({ supplier: response.data });
          }
        } else {
          showError(t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes, bankSelectItems } = this.props;
    let { showValidation, supplier: { number, name, group, phone, address, email, website, contactName, contactPhone, bankId, bankNumber, note, amount } } = this.state;

    const groupItems = [
      { value: 1, text: t('supplier:group_single')} as SelectItemDTO,
      { value: 2, text: t('supplier:group_service')} as SelectItemDTO,
      { value: 3, text: t('supplier:group_support')} as SelectItemDTO,
      { value: 4, text: t('supplier:group_branch')} as SelectItemDTO,
      { value: 5, text: t('supplier:group_collaborator')} as SelectItemDTO,
      { value: 6, text: t('supplier:group_sale_point')} as SelectItemDTO,
    ]

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={People}
          iconColor={'green'}
          title={t('common:information')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('supplier:number')} </FormLabel>
              <>
                <InputText name='number' values={number} onChange={this._onTextChange.bind(this)} />
                {showValidation && !number && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('supplier:name')} </FormLabel>
              <>
                <InputText name='name' values={name} onChange={this._onTextChange.bind(this)} />
                {showValidation && !name && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('supplier:phone')} </FormLabel>
              <InputText name='phone' values={phone} onChange={this._onTextChange.bind(this)} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('supplier:address')} </FormLabel>
              <InputText name='address' values={address} onChange={this._onTextChange.bind(this)} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('supplier:email')} </FormLabel>
              <InputText name='email' values={email} onChange={this._onTextChange.bind(this)} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('supplier:website')} </FormLabel>
              <InputText name='website' values={website} onChange={this._onTextChange.bind(this)} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('supplier:contact_name')} </FormLabel>
              <InputText name='contactName' values={contactName} onChange={this._onTextChange.bind(this)} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('supplier:contact_phone')} </FormLabel>
              <InputText name='contactPhone' values={contactPhone} onChange={this._onTextChange.bind(this)} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('supplier:bank_name')} </FormLabel>
              <Autocomplete
                name='bankId'
                value={bankId}
                includeEmptyOption
                options={bankSelectItems}
                onChange={this._onNumberChange.bind(this)}
              />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('supplier:bank_number')} </FormLabel>
              <InputText name='bankNumber' values={bankNumber} onChange={this._onTextChange.bind(this)} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('supplier:group')} </FormLabel>
              <>
                <CustomSelect 
                  name={'group'}
                  value={this.state.supplier.group}
                  onChange={this._onNumberChange.bind(this)}
                  items={groupItems}
                  label={t('supplier:group')} />
                {showValidation && parseInt(group.toString()) === 0 && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('supplier:note')} </FormLabel>
              <InputText name='note' values={note} onChange={this._onTextChange.bind(this)} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('supplier:amount')} </FormLabel>
              <InputText name='amount' values={currency(amount)} disabled />
            </ColumnContainer>

          </ColumnContainer>

          <GridContainer justify="center">
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button>
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button>
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.Supplier)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ supplier: {...this.state.supplier, [name]: value }});    
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ supplier: {...this.state.supplier, [name]: value }});    
  }

  private _onValidate() {
    let { supplier: { name, number } } = this.state;

    return name && number;
  }

  private _onSave(close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { supplier: { number, name, phone, address, email, group, note, contactName, contactPhone, website, bankId, bankNumber } } = this.state;
    let supplier = new SupplierDTO();
    supplier.id = parseInt(this.props.match.params.id);
    supplier.number = number;
    supplier.name = name;
    supplier.phone = phone;
    supplier.address = address;
    supplier.email = email;
    supplier.group = group;
    supplier.note = note;
    supplier.contactName = contactName;
    supplier.contactPhone = contactPhone;
    supplier.website = website;
    supplier.bankId = null;
    if (bankId) {
      supplier.bankId = bankId;
    }
    supplier.bankNumber = bankNumber;

    this.props.saveSupplier(supplier).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        supplier.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close) {
          history.push(URL.Supplier);
        } else if (parseInt(match.params.id) === 0) {
          history.push(URL.SupplierDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(SupplierDetail)));