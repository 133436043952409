import * as React from 'react';
import PropTypes from "prop-types";
import cx from "classnames";
import footerStyle from './FooterStyle';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { GlobalConst, URL } from '../../Consts';
import { Link } from 'react-router-dom';

function Footer({ ...props }) {
  const { classes, fluid, white } = props;
  const { t } = useTranslation();
  
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  });
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white
    });
  return (
    <footer className={classes.footer}>
      <div className={container}>
        <span className={classes.left}>
          <Link to={URL.Changelog}>
            {`${t('common:version')} ${GlobalConst.VERSION}`}
          </Link>
        </span>
        <span className={classes.right}>
          &copy; {new Date().getFullYear()}{" "}
          <a href="tel:0786363636" target='_blank' rel="noopener noreferrer" className={anchor}>
            VŨ GIA GROUP - <b>0786.36.36.36</b>
          </a>
        </span>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
