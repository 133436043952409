import React from 'react';
import { FormControlLabel, Radio, withStyles } from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import styles from '../../styles';

function CustomRadio(props: any) {
  return (
    <FormControlLabel
      classes={{
        label: props.classes.label
      }}
      label={props.label}
      control={
        <Radio
          checked={props.checked}
          onChange={props.onChange}
          value={props.value}
          name={props.name}
          icon={ <FiberManualRecord className={props.classes.radioUnchecked} /> }
          checkedIcon={ <FiberManualRecord className={props.classes.radioChecked} /> }
          classes={{ checked: props.classes.radio }} />
        
      }>
      
    </FormControlLabel>
  )
}

export default withStyles(styles)(CustomRadio);