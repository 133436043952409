import React from 'react';
import { Switch, Redirect } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import bgImage from "../../Assets/images/register.jpeg";
import LoginHeader from './LoginHeader';
import LoginFooter from './LoginFooter';
import { validToken } from '../../Utils';
import { connect } from 'react-redux';
import loginLayoutStyle from './LoginLayoutStyle';
import { URL } from '../../Consts';

interface ILoginLayoutProps {
  classes?: any;

  token: string
}

interface ILoginLayoutState {}

function mapStateToProps(state: any) {
  return ({
    token: state.user.token
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({})
}

class LoginLayout extends React.Component<ILoginLayoutProps, ILoginLayoutState> {

  render() {
    const { token, classes } = this.props;
    
    if (token && validToken(token)) {
      return <Redirect to={URL.Dashboard} />;
    }
    
    return (
      <div>
        <LoginHeader color='' />
        <div className={classes.wrapper} ref="wrapper">
          <div className={classes.fullPage}>
            <Switch>
              {this.props.children}
            </Switch>
            <LoginFooter white />
            <div
              className={classes.fullPageBackground}
              style={{ backgroundImage: "url(" + bgImage + ")" }}
            />
          </div>
        </div>
      </div>
    )
  }

}

export default withStyles(loginLayoutStyle)(connect(mapStateToProps, mapDispatchToProps)(LoginLayout));