import { FilterDTO, TransferSettingDTO } from '../../DTO';

const TRANSFER_SETTING_REQUEST: string = 'TRANSFER_SETTING_REQUEST';

const TRANSFER_SETTING_GET_LIST_SUCCESS: string = 'TRANSFER_SETTING_GET_LIST_SUCCESS';
const TRANSFER_SETTING_GET_LIST_FAIL: string = 'TRANSFER_SETTING_GET_LIST_FAIL';

const TRANSFER_SETTING_SET_EDITING_TRANSFER_SETTING: string = 'TRANSFER_SETTING_SET_EDITING_TRANSFER_SETTING';

const TRANSFER_SETTING_GET_BY_ID_SUCCESS: string = 'TRANSFER_SETTING_GET_BY_ID_SUCCESS';
const TRANSFER_SETTING_GET_BY_ID_FAIL: string = 'TRANSFER_SETTING_GET_BY_ID_FAIL';

const TRANSFER_SETTING_SAVE_SUCCESS: string = 'TRANSFER_SETTING_SAVE_SUCCESS';
const TRANSFER_SETTING_SAVE_FAIL: string = 'TRANSFER_SETTING_SAVE_FAIL';

const TRANSFER_SETTING_DELETE_SUCCESS: string = 'TRANSFER_SETTING_DELETE_SUCCESS';
const TRANSFER_SETTING_DELETE_FAIL: string = 'TRANSFER_SETTING_DELETE_FAIL';

const TRANSFER_SETTING_GET_NAMES_SUCCESS: string = 'TRANSFER_SETTING_GET_NAMES_SUCCESS';
const TRANSFER_SETTING_GET_NAMES_FAIL: string = 'TRANSFER_SETTING_GET_NAMES_FAIL';

const initialState = {
  editingTransferSetting: {},
  transferSettingSelectItems: [],
}

export function transferSettingReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case TRANSFER_SETTING_SET_EDITING_TRANSFER_SETTING:
      return Object.assign({}, state, {
        editingTransferSetting: action.payload
      });

    case TRANSFER_SETTING_GET_NAMES_SUCCESS:
      return Object.assign({}, state, {
        transferSettingSelectItems: action.response?.data ?? []
      });

    default:
      return state;

  }

}

export function getListTransferSetting(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_SETTING_REQUEST, TRANSFER_SETTING_GET_LIST_SUCCESS, TRANSFER_SETTING_GET_LIST_FAIL],
      EndPoint: `TransferSettings${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingTransferSetting(transferSetting: TransferSettingDTO) {
  return {
    type: TRANSFER_SETTING_SET_EDITING_TRANSFER_SETTING,
    payload: transferSetting
  }
}

export function getTransferSettingById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_SETTING_REQUEST, TRANSFER_SETTING_GET_BY_ID_SUCCESS, TRANSFER_SETTING_GET_BY_ID_FAIL],
      EndPoint: `TransferSettings\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveTransferSetting(transferSetting: TransferSettingDTO) {
  let initRequest: RequestInit = {
    method: transferSetting.id ? 'PATCH' : 'POST',
    body: JSON.stringify(transferSetting),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_SETTING_REQUEST, TRANSFER_SETTING_SAVE_SUCCESS, TRANSFER_SETTING_SAVE_FAIL],
      EndPoint: 'TransferSettings',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteTransferSetting(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_SETTING_REQUEST, TRANSFER_SETTING_DELETE_SUCCESS, TRANSFER_SETTING_DELETE_FAIL],
      EndPoint: 'TransferSettings',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function getTransferSettingSelectItems() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_SETTING_REQUEST, TRANSFER_SETTING_GET_NAMES_SUCCESS, TRANSFER_SETTING_GET_NAMES_FAIL],
      EndPoint: 'TransferSettings/selectList',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}