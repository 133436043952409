import { GlobalConst } from "../Consts";

let moment = require('moment');

export class SellSimDTO {
  public id: number;
  public number: string;
  public simId: number;
  public sellDate: string;
  public buyerId: number;
  public amount: number;
  public fee: number;
  public discount: number;
  public commission: number;
  public branchId: number;
  public commissionUserId: number;
  public status: number;
  public approvedById: number | null;
  public approvedDate: string;

  constructor() {
    this.id = 0;
    this.number = '';
    this.simId = 0;
    this.sellDate = moment().format(GlobalConst.DATE_TIME_FORMAT);
    this.buyerId = 0;
    this.amount = 0;
    this.fee = 0;
    this.discount = 0;
    this.commission = 0;
    this.branchId = 0;
    this.commissionUserId = 0;
    this.status = 0;
    this.approvedById = null;
    this.approvedDate = '';
  }
}