import { WorkflowDTO } from "./WorkflowDTO";
import { GlobalConst } from "../Consts";

let moment = require('moment');

export class LoanDTO {
  public id: number;
  public number: string;
  public clientId: number;
  public clientName: string;
  public date: string;
  public amount: number;
  public payByCash: boolean;
  public paymentAccountId: number | null;
  public status: number;
  public workflow: WorkflowDTO;

  constructor() {
    this.id = 0;
    this.number = '';
    this.clientId = 0;
    this.clientName = '';
    this.date = moment().format(GlobalConst.DATE_TIME_FORMAT);
    this.amount = 0;
    this.payByCash = true;
    this.paymentAccountId = null;
    this.status = 0;
    this.workflow = new WorkflowDTO();
  }
}