import React from 'react';
import { IconCard, DataList, Button, CustomInput, ColumnContainer } from '../../Components';
import { Assignment, Edit, Replay, Add, Delete, Search, OpenInNew } from '@material-ui/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ActionCreator } from 'redux';
import { connect } from 'react-redux';
import { getListCashCollectionProduct, deleteCashCollectionProduct, setEditingCashCollectionProduct } from '../../Redux/CashCollectionProduct/CashCollectionProductReducer';
import { IconButton, withStyles, InputAdornment } from '@material-ui/core';
import { URL, GlobalConst } from '../../Consts';
import history from '../../history';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { FilterDTO, CashCollectionProductDTO } from '../../DTO';
import styles from '../../styles';
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from 'react-toastify';
import { showError } from '../../Utils';
import _ from 'lodash';

interface ICashCollectionProductProps extends WithTranslation {
  classes: any;

  getCashCollectionProductList: ActionCreator<any>,
  setActiveRoute: ActionCreator<any>,
  setEditingCashCollectionProduct: ActionCreator<any>,
  deleteCashCollectionProduct: ActionCreator<any>,
}

interface ICashCollectionProductStates {
  data: CashCollectionProductDTO[],
  page: number;
  pageSize: number;
  totalCount: number;

  searchString: string;
  showDeleteConfirm?: boolean;
  deletingId?: number;
}

function mapStateToProps(state: any) {
  return ({})
}

function mapDispatchToProps(dispatch: any) {
  return ({
    getCashCollectionProductList: (filterDTO: FilterDTO) => dispatch(getListCashCollectionProduct(filterDTO)),
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    setEditingCashCollectionProduct: (cashCollectionProduct: CashCollectionProductDTO) => dispatch(setEditingCashCollectionProduct(cashCollectionProduct)),
    deleteCashCollectionProduct: (id: number) => dispatch(deleteCashCollectionProduct(id)),
  })
}

class CashCollectionProduct extends React.Component<ICashCollectionProductProps, ICashCollectionProductStates> {

  constructor(props: ICashCollectionProductProps){
    super(props);

    this.state = {
      data: [],
      page: 0,
      pageSize: 100,
      totalCount: 0,
      searchString: '',
    }

    this._getCashCollectionProductWithDebounce = _.debounce(this._getCashCollectionProductWithDebounce.bind(this), GlobalConst.DEBOUNCE_TIME);
  }

  componentDidMount() {
    this.props.setActiveRoute([{path: URL.CashCollectionProduct, name: this.props.t('menu:cashCollectionProduct')}]);
  }

  render() {
    const { t, classes } = this.props;
    let { data, page, pageSize, totalCount } = this.state;
    
    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={Assignment}
          iconColor={'green'}
          title={t('cashCollectionProduct:cashCollectionProduct')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>
            <CustomInput
              labelText={t('common:search')}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                value: this.state.searchString,
                name: 'searchString',
                onChange: this._onSearchStringChange.bind(this)
              }}
            />

            <div className={classes.right}>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onEdit.bind(this, new CashCollectionProductDTO())}>
                <Add className={classes.icons} /> {t('button:add')}
              </Button>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onRefresh.bind(this)}>
                <Replay className={classes.icons} /> {t('button:refresh')}
              </Button>
            </div>
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs:12}]}>
            <DataList 
              currentPage={page}
              pageSize={pageSize}
              totalPage={Math.ceil(totalCount / pageSize)}
              data={this._mapData(data)}
              columns={this._getColumns()}
              fetchData={this._onLoadData.bind(this)}
              onPageSizeChange={(ps: number) => {this.setState({ pageSize: ps, page: 0 }, () => this._search())}}
              onChangePage={this._onChangePage.bind(this)}
            />
          </ColumnContainer>
          
          {this.state.showDeleteConfirm && <SweetAlert
            warning
            //style={{ display: "block", marginTop: "-100px" }}
            title={t('message:confirm_delete')}
            onConfirm={this._onDelete.bind(this)}
            onCancel={() => this.setState({ showDeleteConfirm: false})}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={t('button:delete')}
            cancelBtnText={t('button:cancel')}
            showCancel
          >
          </SweetAlert>}

        </IconCard>
      </ColumnContainer>
    )
  }

  _getCashCollectionProductWithDebounce(filterDTO: FilterDTO) {
    this.props.getCashCollectionProductList(filterDTO).then((response: any) => {
      if (response && !response.errorCode) {
        this.setState({ data: response.data, totalCount: response.totalCount });
      } else {
        showError(this.props.t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _onSearchStringChange(event: any) {
    event.persist && event.persist();
    const { target: { value } } = event;
    this.setState({ searchString: value || '', page: 0 }, () => this._search());
  }

  private _search() {
    let { pageSize, searchString } = this.state;

    let filter: FilterDTO = new FilterDTO();
    filter.page = 0;
    filter.pageSize = pageSize;
    filter.searchString = searchString;

    this._getCashCollectionProductWithDebounce(filter);    
  }

  private _getColumns() {
    const { t } = this.props;
    return [
      { Header: t('cashCollectionProduct:number'), accessor: "number", sortable: false, filterable: false },
      { Header: t('cashCollectionProduct:name'), accessor: "name", sortable: false, filterable: false },
      { Header: '', accessor: "actions", sortable: false, filterable: false }
    ]
  }

  private _onEdit(cashCollectionProduct: CashCollectionProductDTO) {
    this.props.setEditingCashCollectionProduct(cashCollectionProduct);
    history.push(URL.CashCollectionProductDetail.replace(':id', cashCollectionProduct.id.toString()));
  }

  private _onDelete() {
    const { t } = this.props;

    this.setState({ showDeleteConfirm: false });
    this.props.deleteCashCollectionProduct(this.state.deletingId).then((response: any) => {
      if (response && !response.errorCode) {
        toast.success(t('message:delete_success'));
        this._onRefresh();
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _mapData(data: CashCollectionProductDTO[]) {
    if (!data) return [];

    return data.map((u: CashCollectionProductDTO) => {
      return {
        ...u,
        actions: (
          <div className="actions-right">
            <IconButton className='small-action' onClick={this._onEdit.bind(this, u)}> <Edit /> </IconButton>
            <IconButton className='small-action' onClick={() => window.open(URL.CashCollectionProductDetail.replace(':id', u.id.toString()), '_blank')}> <OpenInNew /> </IconButton>
            <IconButton className='small-action' onClick={() => this.setState({ showDeleteConfirm: true, deletingId: u.id })}> <Delete color='error' /> </IconButton>
          </div>
        )
      }
    });
  }

  private _onLoadData(tableState: any, instance: any) {
    let filter: FilterDTO = new FilterDTO(tableState);

    this._getCashCollectionProductWithDebounce(filter);
  }

  private _onRefresh() {
    let filter: FilterDTO = new FilterDTO();
    filter.page = this.state.page;
    filter.pageSize = this.state.pageSize;
    filter.searchString = this.state.searchString || '';

    this._getCashCollectionProductWithDebounce(filter);
  }

  private _onChangePage(pageIndex: any) {
    this.setState({ page: pageIndex});
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(CashCollectionProduct)));