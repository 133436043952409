import { ErrorCode } from "./Consts";
import { toast } from 'react-toastify';

const jwt = require('jsonwebtoken')
export function validToken(token: string) {
  let decoded = jwt.decode(token);
  return decoded && new Date().getTime() < decoded.exp * 1000;
}

export function timeoutToken(token: string) {
  let decoded = jwt.decode(token);
  if (!decoded) {
    return 0;
  }
  return decoded.exp * 1000 - new Date().getTime();
}

export function showError(t: any, code: ErrorCode, message: string) {
  toast.error(`[${code}] ${t('error:' + code)} ${message || ''}`);
}

export function validEmail(email: string) {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
}

export function currency(num: number | string) {
  return ('' + num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' đ';
}