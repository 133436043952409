import React from 'react';
import { ActionCreator } from 'redux';
import { ColumnContainer } from '../../Components';
import { connect } from 'react-redux';
import styles from '../../styles';
import { withStyles, Card, CardContent, Badge } from '@material-ui/core';
import { VersionDTO } from '../../DTO';
import { getAllVersion } from '../../Redux/Version/VersionReducer';
import { Extension } from '@material-ui/icons';
import { WithTranslation, withTranslation } from 'react-i18next';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { URL, GlobalConst } from '../../Consts';
let moment = require('moment');

interface IChangelogProps extends WithTranslation {
  classes: any;

  getListVersion: ActionCreator<any>,
  setActiveRoute: ActionCreator<any>,
}

interface IChangelogStates {
  versions: VersionDTO[],
}

function mapStateToProps(state: any) {
  return ({ })
}

function mapDispatchToProps(dispatch: any) {
  return ({ 
    getListVersion: () => dispatch(getAllVersion()),
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
  })
}

class Changelog extends React.Component<IChangelogProps, IChangelogStates> {

  constructor(props: IChangelogProps) {
    super(props);

    this.state = {
      versions: [],
    }
  }

  componentDidMount() {
    this.props.setActiveRoute([{path: URL.Changelog, name: this.props.t('menu:changelog')}]);

    this.props.getListVersion().then((response: any) => {
      this.setState({ versions: response.data })
    });
  }

  render() {
    const { classes } = this.props;

    const cardClasses = classes.card + ' ' + classes.cardPlain;

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <Card className={cardClasses}>
          <CardContent className={classes.cardContent}>
            <ul className={classes.timeline}>
            {this.state.versions.map((v: VersionDTO, idx: number) => {
              const panelClasses = classes.timelinePanel + (idx % 2 === 1 ? ' ' + [classes.timelinePanelInverted] : '');

              return (
                <li className={classes.item} key={v.number}>
                  <div className={classes.timelineBadge + " " + classes['success']}>
                    <Extension className={classes.badgeIcon} />
                  </div>
                  <div className={panelClasses}>
                    <div className={classes.timelineHeading}>
                      <Badge className={classes.badge}>{v.number}</Badge>
                    </div>
                    <div className={classes.timelineBody}>
                      <p style={{whiteSpace: 'pre-line'}}>{v.detail} </p>
                    </div>
                    <h6 className={classes.footerTitle}>{moment.utc(v.createdDate, GlobalConst.DATE_TIME_FORMAT).local().format(GlobalConst.DATE_TIME_FORMAT)}</h6>
                  </div>
                </li>
              )
            })}
            </ul>
          </CardContent>
        </Card>
      </ColumnContainer>
    )
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(Changelog)));