import { GlobalConst } from '../Consts';
import { setLoadingIndicator } from './Common/CommonReducer';

function callAPI(endPoint: string, config: any, authenticatedRequest: boolean = true, isUpload: boolean = false, responseHtml: boolean = false) {
  let token = localStorage.getItem(GlobalConst.LOCAL_STORAGE_TOKEN) || null;

  if (authenticatedRequest && token && config.headers) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  if (!isUpload) {
    config.headers['content-type'] = 'application/json';
  }

  const apiEndPoint = GlobalConst.PREFIX_API + endPoint;

  if (responseHtml) {
    return fetch(apiEndPoint, config);
  }

  return fetch(apiEndPoint, config)
    .then(response => response.json().then(resource => ({ resource, response }), () => (Promise.resolve({ resource: {}, response }))))
    .then(({ resource, response }) => {
      if (!response.ok && response.status !== 304) {
        return Promise.reject({ data: resource?.data, response });
      }
      resource = {
        data: resource,
        config: config,
        headers: response.headers,
        status: response.status,
      };
      return resource;
    });
}

export default function customAPI() {
  return (store: any) => (dispatch: any) => (action: any) => {
    
    let apiOptions = action['CallAPI'];
    
    if (!apiOptions) {
      return dispatch(action);
    }

    function actionWith(data: any) {
      const finalAction = Object.assign({}, action, data);
      delete finalAction['CallAPI'];
      return finalAction;
    }

    let { EndPoint, Config, AuthenticationRequire, Actions: [RequestAction, SuccessAction, FailAction], IsUpload, ResponseHtml } = apiOptions;

    dispatch(setLoadingIndicator(true));
    dispatch({ type: RequestAction });
    
    return callAPI(EndPoint, Config, AuthenticationRequire, IsUpload, ResponseHtml)
      .then(response => ResponseHtml ? response.text() : response)
      .then(
        response => {
          dispatch(setLoadingIndicator(false));
          
          // TODO: handle other response code
          if (response.status === 200) {
            if (ResponseHtml || !response.errorCode) {
              dispatch(
                actionWith({
                  response: ResponseHtml ? response : response.data,
                  AuthenticationRequire,
                  type: SuccessAction,
                })
              );
            } else {
              dispatch(
                actionWith({
                  response: response.data,
                  AuthenticationRequire,
                  type: FailAction,
                })
              );
            }
          } else {
            dispatch(
              actionWith({
                type: FailAction,
                AuthenticationRequire,
              })
            );
          }
          
          return ResponseHtml ? response : response.data;
        },
        error => {
          dispatch(setLoadingIndicator(false));
          dispatch(
            actionWith({
              type: FailAction,
              error: error || 'Error',
            })
          );
          return error;
        }
      );

  }
}