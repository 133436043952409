import { FilterDTO, TransferMoMoDTO, transferMoMoToFormData } from '../../DTO';

const TRANSFER_MOMO_REQUEST: string = 'TRANSFER_MOMO_REQUEST';

const TRANSFER_MOMO_GET_LIST_SUCCESS: string = 'TRANSFER_MOMO_GET_LIST_SUCCESS';
const TRANSFER_MOMO_GET_LIST_FAIL: string = 'TRANSFER_MOMO_GET_LIST_FAIL';

const TRANSFER_MOMO_SET_EDITING_TRANSFER_MOMO: string = 'TRANSFER_MOMO_SET_EDITING_TRANSFER_MOMO';

const TRANSFER_MOMO_GET_BY_ID_SUCCESS: string = 'TRANSFER_MOMO_GET_BY_ID_SUCCESS';
const TRANSFER_MOMO_GET_BY_ID_FAIL: string = 'TRANSFER_MOMO_GET_BY_ID_FAIL';

const TRANSFER_MOMO_SAVE_SUCCESS: string = 'TRANSFER_MOMO_SAVE_SUCCESS';
const TRANSFER_MOMO_SAVE_FAIL: string = 'TRANSFER_MOMO_SAVE_FAIL';

const TRANSFER_MOMO_DELETE_SUCCESS: string = 'TRANSFER_MOMO_DELETE_SUCCESS';
const TRANSFER_MOMO_DELETE_FAIL: string = 'TRANSFER_MOMO_DELETE_FAIL';

const TRANSFER_MOMO_APPROVE_SUCCESS: string = 'TRANSFER_MOMO_APPROVE_SUCCESS';
const TRANSFER_MOMO_APPROVE_FAIL: string = 'TRANSFER_MOMO_APPROVE_FAIL';

const TRANSFER_MOMO_CANCEL_SUCCESS: string = 'TRANSFER_MOMO_CANCEL_SUCCESS';
const TRANSFER_MOMO_CANCEL_FAIL: string = 'TRANSFER_MOMO_CANCEL_FAIL';

const TRANSFER_MOMO_UPDATE_CONFIRM_SUCCESS: string = 'TRANSFER_MOMO_UPDATE_CONFIRM_SUCCESS';
const TRANSFER_MOMO_UPDATE_CONFIRM_FAIL: string = 'TRANSFER_MOMO_UPDATE_CONFIRM_FAIL';

const TRANSFER_MOMO_EXPORT_LIST_SUCCESS: string = 'TRANSFER_MOMO_EXPORT_LIST_SUCCESS';
const TRANSFER_MOMO_EXPORT_LIST_FAIL: string = 'TRANSFER_MOMO_EXPORT_LIST_FAIL';

const TRANSFER_MOMO_PRINT_SUCCESS: string = 'TRANSFER_MOMO_PRINT_SUCCESS';
const TRANSFER_MOMO_PRINT_FAIL: string = 'TRANSFER_MOMO_PRINT_FAIL';

const initialState = {
  editingTransferMoMo: {},
}

export function transferMoMoReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case TRANSFER_MOMO_SET_EDITING_TRANSFER_MOMO:
      return Object.assign({}, state, {
        editingTransferMoMo: action.payload
      });

    default:
      return state;

  }

}

export function getListTransferMoMo(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_MOMO_REQUEST, TRANSFER_MOMO_GET_LIST_SUCCESS, TRANSFER_MOMO_GET_LIST_FAIL],
      EndPoint: `TransferMoMos${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingTransferMoMo(transferMoMo: TransferMoMoDTO) {
  return {
    type: TRANSFER_MOMO_SET_EDITING_TRANSFER_MOMO,
    payload: transferMoMo
  }
}

export function getTransferMoMoById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_MOMO_REQUEST, TRANSFER_MOMO_GET_BY_ID_SUCCESS, TRANSFER_MOMO_GET_BY_ID_FAIL],
      EndPoint: `TransferMoMos\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveTransferMoMo(transferMoMo: TransferMoMoDTO, submit: boolean, reference: any, confirmation: any) {
  let formData = transferMoMoToFormData(transferMoMo);
  if (reference) {
    formData.append('reference', reference);
  }
  if (confirmation) {
    formData.append('confirmation', confirmation);
  }

  let initRequest: RequestInit = {
    method: transferMoMo.id ? 'PATCH' : 'POST',
    body: formData,
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_MOMO_REQUEST, TRANSFER_MOMO_SAVE_SUCCESS, TRANSFER_MOMO_SAVE_FAIL],
      EndPoint: `TransferMoMos\\${submit}`,
      AuthenticationRequire: true,
      Config: initRequest,
      IsUpload: true
    }
  }
}

export function deleteTransferMoMo(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_MOMO_REQUEST, TRANSFER_MOMO_DELETE_SUCCESS, TRANSFER_MOMO_DELETE_FAIL],
      EndPoint: 'TransferMoMos',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function approveTransferMoMo(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_MOMO_REQUEST, TRANSFER_MOMO_APPROVE_SUCCESS, TRANSFER_MOMO_APPROVE_FAIL],
      EndPoint: 'TransferMoMos\\Approve',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function cancelTransferMoMo(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_MOMO_REQUEST, TRANSFER_MOMO_CANCEL_SUCCESS, TRANSFER_MOMO_CANCEL_FAIL],
      EndPoint: 'TransferMoMos\\Cancel',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function updateConfirmation(id: number, confirmation: any) {
  let formData = new FormData();
  formData.append("confirmation", confirmation);

  let initRequest: RequestInit = {
    method: 'POST',
    body: formData,
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_MOMO_REQUEST, TRANSFER_MOMO_UPDATE_CONFIRM_SUCCESS, TRANSFER_MOMO_UPDATE_CONFIRM_FAIL],
      EndPoint: `TransferMoMos\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest,
      IsUpload: true
    }
  }
}

export function exportTransferMoMo(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_MOMO_REQUEST, TRANSFER_MOMO_EXPORT_LIST_SUCCESS, TRANSFER_MOMO_EXPORT_LIST_FAIL],
      EndPoint: `TransferMoMos\\export${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function printTransferMoMo(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_MOMO_REQUEST, TRANSFER_MOMO_PRINT_SUCCESS, TRANSFER_MOMO_PRINT_FAIL],
      EndPoint: `TransferMoMos\\print\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest,
      ResponseHtml: true
    }
  }
}