import { BranchCommissionDTO } from "../../DTO";

const COMMISSION_REQUEST: string = 'COMMISSION_REQUEST';
const COMMISSION_GET_SUCCESS: string = 'COMMISSION_GET_SUCCESS';
const COMMISSION_GET_FAIL: string = 'COMMISSION_GET_FAIL';

const COMMISSION_SAVE_SUCCESS: string = 'COMMISSION_SAVE_SUCCESS';
const COMMISSION_SAVE_FAIL: string = 'COMMISSION_SAVE_FAIL';

const initialState = {
}

export function commissionReducer(state = initialState, action: any) {
  switch (action.type) {

    default:
      return state;

  }
}

export function getCommissions() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [COMMISSION_REQUEST, COMMISSION_GET_SUCCESS, COMMISSION_GET_FAIL],
      EndPoint: 'Commissions',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveCommissions(branches: BranchCommissionDTO[]) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: JSON.stringify(branches),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [COMMISSION_REQUEST, COMMISSION_SAVE_SUCCESS, COMMISSION_SAVE_FAIL],
      EndPoint: 'Commissions',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}