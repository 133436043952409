// ##############################
// // // IconCard styles
// #############################

import {
  card,
  cardHeader,
  orangeCardHeader,
  greenCardHeader,
  redCardHeader,
  blueCardHeader,
  purpleCardHeader,
  roseCardHeader
} from "../../Assets/material-dashboard-pro-react";
import { createStyles } from "@material-ui/core";

const iconCardStyle = createStyles({
  card: {
    ...card,
    overflow: "visible"
  },
  cardPlain: {
    background: "transparent",
    boxShadow: "none"
  },
  cardHeader: {
    ...cardHeader,
    float: "left"
  },
  orangeCardHeader: {
    ...orangeCardHeader
  },
  greenCardHeader: {
    ...greenCardHeader
  },
  redCardHeader: {
    ...redCardHeader
  },
  blueCardHeader: {
    ...blueCardHeader
  },
  purpleCardHeader: {
    ...purpleCardHeader
  },
  roseCardHeader: {
    ...roseCardHeader
  },
  cardContent: {
    padding: "15px 20px",
    position: "relative"
  },
  cardAvatar: {
    margin: "0px"
  },
  cardIcon: {
    paddingTop: "3px",
    paddingLeft: "4px",
    paddingRight: "5px",
    color: "#FFFFFF",
    width: "33px",
    height: "27px"
  },
  cardTitle: {
    marginTop: "0",
    marginBottom: "3px",
    color: "#3C4858",
    textDecoration: "none"
  },
  cardCategory: {
    color: "#999999",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "1",
    marginBottom: "0"
  },
  cardFooter: {
    margin: "0 20px 10px",
    paddingTop: "10px",
    borderTop: "1px solid #eeeeee"
  }
});

export default iconCardStyle;
