import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL, ClientType, Permission, TransferStatus, GlobalConst } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, CustomSelect, ImageUpload, InputText, ColumnContainer, Autocomplete, Checkbox } from '../../Components';
import { People, Save, KeyboardArrowLeft, Clear, Print } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getTransferById, saveTransfer, approveTransfer, cancelTransfer, updateConfirmation, printTransfer } from '../../Redux/Transfer/TransferReducer';
import { getClientSelectItems } from '../../Redux/Client/ClientReducer';
import { getPaymentAccountSelectItems } from '../../Redux/PaymentAccount/PaymentAccountReducer';
import { getTransferSettingSelectItems } from '../../Redux/TransferSetting/TransferSettingReducer';
import { getBankSelectItems } from '../../Redux/Bank/BankReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { TransferDTO, SelectItemDTO, CurrentUserDTO } from '../../DTO';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';
import { getUserSelectItems } from '../../Redux/User/UserReducer';
let moment = require('moment');

interface ITransferDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  currentUser: CurrentUserDTO;
  transfer: TransferDTO;
  clientSelectItems: SelectItemDTO[];
  paymentAccountSelectItems: SelectItemDTO[];
  transferSettingSelectItems: SelectItemDTO[];
  bankSelectItems: SelectItemDTO[];
  branchSelectItems: SelectItemDTO[];
  userSelectItems: SelectItemDTO[];

  setActiveRoute: ActionCreator<any>,
  getTransferById: ActionCreator<any>,
  saveTransfer: ActionCreator<any>,
  getClientSelectItems: ActionCreator<any>,
  getPaymentAccountSelectItems: ActionCreator<any>,
  getTransferSettingSelectItems: ActionCreator<any>,
  approveTransfer: ActionCreator<any>,
  cancelTransfer: ActionCreator<any>,
  updateConfirmation: ActionCreator<any>,
  getBankSelectItems: ActionCreator<any>,
  getBranchSelectItems: ActionCreator<any>,
  getUserSelectItems: ActionCreator<any>,
  printTransfer: ActionCreator<any>,
}

interface ITransferDetailStates {
  isSaving?: boolean;

  transfer: TransferDTO;
  showValidation?: boolean;
  referenceFile?: any;
  confirmationFile?: any;
}

function mapStateToProps(state: any) {
  return ({
    transfer: state.transfer.editingTransfer,
    clientSelectItems: state.client.clientSelectItems,
    paymentAccountSelectItems: state.paymentAccount.paymentAccountSelectItems,
    transferSettingSelectItems: state.transferSetting.transferSettingSelectItems,
    currentUser: state.user.currentUser,
    bankSelectItems: state.bank.bankSelectItems,
    branchSelectItems: state.branch.branchSelectItems,
    userSelectItems: state.user.userSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getTransferById: (id: number) => dispatch(getTransferById(id)),
    saveTransfer: (transfer: TransferDTO, submit: boolean, reference: any, confirmation: any) => dispatch(saveTransfer(transfer, submit, reference, confirmation)),
    getClientSelectItems: () => dispatch(getClientSelectItems()),
    getPaymentAccountSelectItems: () => dispatch(getPaymentAccountSelectItems()),
    getTransferSettingSelectItems: () => dispatch(getTransferSettingSelectItems()),
    approveTransfer: (id: number) => dispatch(approveTransfer(id)),
    cancelTransfer: (id: number) => dispatch(cancelTransfer(id)),
    updateConfirmation: (id: number, confirmation: any) => dispatch(updateConfirmation(id, confirmation)),
    getBankSelectItems: () => dispatch(getBankSelectItems()),
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
    getUserSelectItems: () => dispatch(getUserSelectItems()),
    printTransfer: (id: number) => dispatch(printTransfer(id)),
  })
}

class TransferDetail extends React.Component<ITransferDetailProps, ITransferDetailStates> {

  constructor(props: ITransferDetailProps) {
    super(props);
    
    this.state = {
      transfer: {...new TransferDTO(), ...props.transfer},
    }
  }

  componentDidMount() {
    !this.props.clientSelectItems?.length && this.props.getClientSelectItems();
    !this.props.paymentAccountSelectItems?.length && this.props.getPaymentAccountSelectItems();
    !this.props.transferSettingSelectItems?.length && this.props.getTransferSettingSelectItems();
    !this.props.bankSelectItems?.length && this.props.getBankSelectItems();
    !this.props.branchSelectItems?.length && this.props.getBranchSelectItems();
    !this.props.userSelectItems?.length && this.props.getUserSelectItems();

    const { setActiveRoute, match, getTransferById, t } = this.props;

    setActiveRoute([{path: URL.Transfer, name: this.props.t('menu:transfer')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);
    if (match.params.id > 0) {
      getTransferById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.Transfer);
          } else {
            this.setState({ transfer: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes, clientSelectItems, paymentAccountSelectItems, currentUser, bankSelectItems, branchSelectItems, userSelectItems } = this.props;
    let { showValidation, transfer: { id, number, clientId, createdUser, toAccountName, toAccountNumber, detail, bankId, amount, fee, commission, discount, paymentAccountId, referenceFileName, confirmationFileName, status, transferDate, note, branchId, commissionUserId, payByCash, receiveAccountId } } = this.state;

    const types = [
      { value: 1, text: t('client:type_single')},
      { value: 2, text: t('client:type_multiple')},
      { value: 3, text: t('client:type_branch')},
    ];

    const statusText = [
      { value: 0, text: t('transfer:status_draft')},
      { value: 1, text: t('transfer:status_pending_approve')},
      { value: 2, text: t('transfer:status_approved')},
      { value: 3, text: t('transfer:status_cancel')},
    ]

    let statusStr = statusText.find(s => s.value === status)?.text || '';
    let tDate = transferDate ? moment(transferDate, GlobalConst.DATE_TIME_FORMAT) : moment(new Date());
    let now = moment(new Date());
    let readOnly = (status === TransferStatus.Approved || status === TransferStatus.Canceled) && (tDate.year() * 12 + tDate.month() < now.year() * 12 + now.month());

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={People}
          iconColor={'green'}
          title={t('common:information')}>

          {id ?
          <ColumnContainer columnWidth={[{xs:12, sm:12}]}>
            <div className={classes.right}>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onPrint.bind(this)} disabled={this.state.isSaving}>
                <Print className={classes.icons} /> {t('button:print')}
              </Button>
              {currentUser.functionIds.includes(Permission.ApproveTransfers) && status === TransferStatus.PendingApprove ?
                <>
                  <Button color='primary' customClass={classes.marginRight} round onClick={this._onApprove.bind(this)} disabled={this.state.isSaving}>
                    <Save className={classes.icons} /> {t('button:approve')}
                  </Button>
                  <Button color='danger' customClass={classes.marginRight} round onClick={this._onCancel.bind(this)} disabled={this.state.isSaving}>
                    <Clear className={classes.icons} /> {t('button:cancel')}
                  </Button>
                </>
              : null}
            </div>
          </ColumnContainer> : null}

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transfer:number')} </FormLabel>
              <InputText values={number} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transfer:status')} </FormLabel>
              <InputText values={statusStr} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transfer:date')} </FormLabel>
              {!readOnly ? 
                <Datetime
                  dateFormat={GlobalConst.DATE_FORMAT}
                  timeFormat={"HH:mm"}
                  inputProps={{ placeholder: t('transfer:date') }}
                  onChange={this._onDateChange.bind(this)}
                  value={transferDate}
                /> : <InputText disabled values={transferDate} /> }
            </ColumnContainer>
            
            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transfer:user')} </FormLabel>
              <InputText disabled values={id === 0 ? currentUser.name : createdUser} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transfer:client')} </FormLabel>
              <>
                <Autocomplete
                  name='clientId'
                  value={clientId}
                  options={clientSelectItems}
                  onChange={this._onClientChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !clientId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('client:type')} </FormLabel>
              <InputText disabled values={types.find(t => t.value === clientSelectItems?.find(c => c.value === clientId)?.details.type)?.text || ''} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('client:phone')} </FormLabel>
              <InputText disabled values={clientSelectItems?.find(c => c.value === clientId)?.details.phone || ''} />
            </ColumnContainer>

            <></>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transfer:to_account_name')} </FormLabel>
              <>
                <InputText 
                  name="toAccountName" 
                  values={toAccountName} 
                  onChange={this._onTextChange.bind(this)} 
                  disabled={readOnly} 
                />
                {showValidation && !toAccountName && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transfer:to_account_number')} </FormLabel>
              <>
                <InputText name='toAccountNumber' values={toAccountNumber} onChange={this._onTextChange.bind(this)} disabled={readOnly} />
                {showValidation && !toAccountNumber && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transfer:to_bank')} </FormLabel>
              <>
                <Autocomplete
                  name='bankId'
                  value={bankId}
                  options={bankSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !bankId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transfer:amount')} </FormLabel>
              <>
                <InputText 
                  name="amount"
                  numberOnly
                  thousandSeparator
                  values={amount} 
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly} />
                {showValidation && !amount && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transfer:detail')} </FormLabel>
              <InputText 
                name='detail'
                values={detail}
                onChange={this._onTextChange.bind(this)}
                disabled={readOnly}
              />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transfer:fee')} </FormLabel>
              <InputText numberOnly thousandSeparator values={fee} disabled />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transfer:commission')} </FormLabel>
              <InputText disabled numberOnly thousandSeparator values={commission} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transfer:discount')} </FormLabel>
              <InputText 
                numberOnly 
                thousandSeparator 
                name='discount' 
                values={discount}
                onChange={this._onNumberChange.bind(this)} 
                disabled={readOnly} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transfer:total')} </FormLabel>
              <InputText 
                disabled 
                numberOnly 
                thousandSeparator 
                values={parseFloat('0' + amount.toString()) + parseFloat('0' + fee.toString()) - parseFloat('0' + discount.toString())} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('paymentAccount:name')} </FormLabel>
              <>
                <CustomSelect 
                  name={'paymentAccountId'}
                  value={paymentAccountId}
                  onChange={this._onNumberChange.bind(this)}
                  items={paymentAccountSelectItems}
                  label={t('paymentAccount:name')}
                  disabled={readOnly} />
                {showValidation && !paymentAccountId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transfer:note')} </FormLabel>
              <InputText 
                name='note' 
                values={note} 
                onChange={this._onTextChange.bind(this)} 
                disabled={readOnly} />
            </ColumnContainer>

            <> </>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transfer:branch')} </FormLabel>
              <>
                <Autocomplete
                  name='branchId'
                  value={branchId}
                  options={branchSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !branchId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transfer:commission_user')} </FormLabel>
              <>
                <Autocomplete
                  name='commissionUserId'
                  value={commissionUserId}
                  options={userSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !commissionUserId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transfer:reference')} </FormLabel>
              <ImageUpload 
                image={id && referenceFileName ? `/Upload/TransferRef/${id}/${referenceFileName}` : null}
                onImageChange={(file: any) => this.setState({ referenceFile: file })}
                onImageRemoved={() => { let transfer = this.state.transfer; transfer.referenceFileName = ''; this.setState({ transfer: transfer }) }}
                hideButtons={readOnly} />
            </ColumnContainer>
            
            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transfer:confirmation')} </FormLabel>
              <ImageUpload 
                image={id && confirmationFileName ? `/Upload/TransferConfirmation/${id}/${confirmationFileName}` : null}
                onImageChange={(file: any) => this.setState({ confirmationFile: file })}
                onImageRemoved={() => { let transfer = this.state.transfer; transfer.confirmationFileName = ''; this.setState({ transfer: transfer }) }}
                hideButtons={readOnly} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transfer:pay_by_cash')} </FormLabel>
              <Checkbox
                checked={payByCash}
                name='payByCash'
                onClick={this._onCheckboxChange.bind(this)}
              />
            </ColumnContainer>

            {payByCash ? null : <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transfer:receive_account')} </FormLabel>
              <>
                <CustomSelect 
                  name={'receiveAccountId'}
                  value={receiveAccountId}
                  onChange={this._onNumberChange.bind(this)}
                  items={paymentAccountSelectItems}
                  label={t('transfer:receive_account')}
                  disabled={readOnly} />
                {showValidation && !receiveAccountId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>}

          </ColumnContainer>

          <GridContainer justify="center">
            {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button> : null}
            {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button> : null}
            {status === TransferStatus.Draft ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_submit')}
            </Button> : null}
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.Transfer)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onCheckboxChange(event: any) {
    event.persist && event.persist();
    const { target: { name, checked } } = event;
    let transfer = this.state.transfer;
    this.setState({ transfer: {...transfer, [name]: checked }});
  }

  private _onClientChange(value: number) {
    this.setState({ transfer: { ...this.state.transfer, clientId: value }}, () => this._updateComissionFee());
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ transfer: {...this.state.transfer, [name]: value }});
  }
  
  private _onNumberChange(value: number, name: string) {
    this.setState({ transfer: { ...this.state.transfer, [name]: value } }, () => { name === 'amount' && this._updateComissionFee(); });
  }

  private _updateComissionFee() {
    let transfer = this.state.transfer;
    let clientId = transfer.clientId;
    let amount = transfer.amount;
    let setting = this.props.transferSettingSelectItems?.find(s => s.details.fromAmount <= amount && s.details.toAmount > amount);
    let client = this.props.clientSelectItems.find(c => c.value === clientId);
    this.setState({ transfer: { ...transfer, commission: setting?.details.commission ?? 0, fee: [ClientType.Multiple, ClientType.Branch].includes(client?.details.type) ? (setting?.details.multipleSalePrice ?? 0) : (setting?.details.salePrice ?? 0)} });
  }

  private _onDateChange(value: string | Moment) {
    let transfer = this.state.transfer;
    transfer.transferDate = moment(value).format(GlobalConst.DATE_TIME_FORMAT);
    this.setState({ transfer: transfer });
  }

  private _onValidate() {
    let { transfer: { clientId, toAccountName, toAccountNumber, bankId, amount, paymentAccountId, branchId, commissionUserId, payByCash, receiveAccountId } } = this.state;

    return clientId && toAccountName && toAccountNumber && bankId && amount && paymentAccountId && branchId && commissionUserId && (payByCash || receiveAccountId);
  }

  private _onSave(submit: boolean = false, close: boolean = false) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { referenceFile, confirmationFile, transfer: { clientId, transferDate, toAccountName, toAccountNumber, detail, bankId, amount, fee, commission, discount, paymentAccountId, note, branchId, commissionUserId, referenceFileName, confirmationFileName, payByCash, receiveAccountId } } = this.state;
    let transfer = new TransferDTO();
    transfer.id = parseInt(this.props.match.params.id);
    transfer.clientId = clientId;
    transfer.toAccountName = toAccountName;
    transfer.toAccountNumber = toAccountNumber;
    transfer.detail = detail;
    transfer.bankId = bankId;
    transfer.amount = amount;
    transfer.fee = fee;
    transfer.commission = commission;
    transfer.discount = discount;
    transfer.paymentAccountId = paymentAccountId;
    transfer.note = note;
    transfer.transferDate = transferDate;
    transfer.branchId = branchId;
    transfer.commissionUserId = commissionUserId;
    transfer.referenceFileName = referenceFileName;
    transfer.confirmationFileName = confirmationFileName;
    transfer.payByCash = payByCash;
    transfer.receiveAccountId = payByCash ? null : receiveAccountId;

    this.props.saveTransfer(transfer, submit, referenceFile, confirmationFile).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        transfer.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close || submit) {
          history.push(URL.Transfer);
        } else if (parseInt(match.params.id) === 0) {
          this.setState({ transfer: response.data });
          history.push(URL.TransferDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

  // private _onUpdateConfirmation() {
  //   const { t } = this.props;
  //   if (this.state.confirmationFile) {
  //     this.props.updateConfirmation(this.state.transfer.id, this.state.confirmationFile).then((response: any) => {
  //       if (response && !response.errorCode) {
  //         toast.success(t('message:save_success'));
  //       } else {
  //         showError(t, response.errorCode, response.errorMessage);
  //       }
  //     });
  //   } else {
  //     toast.warn(t('transfer:select_confirmation'));
  //   }
  // }

  private _onApprove() {
    const { t } = this.props;
    this.setState({ isSaving: true });

    this.props.approveTransfer(this.state.transfer.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.Transfer);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

  private _onCancel() {
    const { t } = this.props;
    this.setState({ isSaving: true });
    
    this.props.cancelTransfer(this.state.transfer.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.Transfer);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

  private _onPrint() {
    this.props.printTransfer(parseInt(this.props.match.params.id)).then((response: any) => {
      var newTab = window.open("about:blank", "", "_blank");
      newTab?.document.write(response);
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(TransferDetail)));