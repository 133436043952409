import { GlobalConst } from "../Consts";

let moment = require('moment');

export class SellTelecomServiceDTO {
  public id: number;
  public number: string;
  public serviceId: number;
  public supplierId: number;
  public phone: string;
  public clientId: number;
  public sellDate: string;
  public price: number;
  public amount: number;
  public discount: number;
  public commission: number;
  public commissionUserId: number;
  public status: number;
  public approvedById: number | null;
  public approvedDate: string;
  public branchId: number;
  public createdBy: number;
  public payByCash: boolean;
  public receiveAccountId: number | null;

  constructor() {
    this.id = 0;
    this.number = '';
    this.serviceId = 0;
    this.supplierId = 0;
    this.phone = '';
    this.clientId = 0;
    this.sellDate = moment().format(GlobalConst.DATE_TIME_FORMAT);
    this.price = 0;
    this.amount = 0;
    this.discount = 0;
    this.commission = 0;
    this.commissionUserId = 0;
    this.status = 0;
    this.approvedById = null;
    this.approvedDate = '';
    this.branchId = 0;
    this.createdBy = 0;
    this.payByCash = true;
    this.receiveAccountId = null;
  }
}