import * as React from 'react';
import { withStyles, InputAdornment } from '@material-ui/core';
import LoginStyle from "./LoginStyle";
import { 
  GridContainer,
  GridItem,
  LoginCard,
  Button,
  CustomInput
} from '../../Components';
import { Email, LockOutlined } from '@material-ui/icons';
import { connect } from 'react-redux';
import { login } from '../../Redux/User/UserReducer';
import { ActionCreator } from 'redux';
import { UserLoginDTO } from '../../DTO';
import { withTranslation, WithTranslation } from 'react-i18next';
import { showError } from '../../Utils';

interface ILoginProps extends WithTranslation {
  classes: any;

  login: ActionCreator<any>;
  testing: ActionCreator<any>;
}

interface ILoginStates {
  isLogging?: boolean;
  cardAnimaton: string,
  userName: string;
  password: string;
  isRemember: boolean;
}

function mapStateToProps(state: any) {
  return ({})
}

function mapDispatchToProps(dispatch: any) {
  return ({
    login: (userLoginDTO: UserLoginDTO) => dispatch(login(userLoginDTO)),
  })
}

class Login extends React.Component<ILoginProps, ILoginStates> {

  constructor(props: ILoginProps) {
    super(props);

    this.state = {
      cardAnimaton: "cardHidden",
      userName: '',
      password: '',
      isRemember: false,
    };
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(this._removeAnimation.bind(this), 700);
  }

  private _removeAnimation() {
    this.setState({ cardAnimaton: "" });
  }

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <form>
                <LoginCard 
                  customCardClass={classes[this.state.cardAnimaton]}
                  headerColor="green"
                  cardTitle={t('login:login')}
                  // cardSubtitle="Or Be Classical"
                  footerAlign="center"
                  footer={
                    <Button color="roseNoBackground" wd size="lg" disabled={this.state.isLogging} onClick={this._onLogin.bind(this)}>
                      {t('button:login')}
                    </Button>
                  }
                  content={this._loginForm()}
                />
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    )    
  }

  private _loginForm() {
    const { classes, t } = this.props;

    return (
      <div>
        <CustomInput
          labelText={t('login:username')}
          id="email"
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Email className={classes.inputAdornmentIcon} />
              </InputAdornment>
            ),
            value: this.state.userName,
            name: 'userName',
            onChange: this._onTextChange.bind(this)
          }}
        />

        <CustomInput
          labelText={t('login:password')}
          id="password"
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <LockOutlined
                  className={classes.inputAdornmentIcon}
                />
              </InputAdornment>
            ),
            value: this.state.password,
            type: 'password',
            name: 'password',
            onChange: this._onTextChange.bind(this)
          }}
        />

      </div>
    )
  }

  private _onTextChange(event: any) {
    event.persist();
    const { target: { name, value } } = event
    this.setState({ [name]: value } as Pick<ILoginStates, keyof ILoginStates>)
  }

  private _onLogin() {
    const { t } = this.props;

    let userLoginDTO = new UserLoginDTO();
    userLoginDTO.userName = this.state.userName;
    userLoginDTO.password = this.state.password;
    
    this.setState({ isLogging: true });

    this.props.login(userLoginDTO).then((response: any) => {
      this.setState({ isLogging: false });
      if (response && response.errorCode) {
        showError(t, response.errorCode, response.errorMessage);
      }
    });
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(LoginStyle)(Login)));