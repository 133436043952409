import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL, NumberTypes, SimTypes, SimStatus } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer, CustomSelect, Autocomplete, Checkbox } from '../../Components';
import { PhoneIphone, Save, KeyboardArrowLeft } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getSimById, saveSim } from '../../Redux/Sim/SimReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { SimDTO, SelectItemDTO } from '../../DTO';
import { getNetworkProviderSelectItems } from '../../Redux/NetworkProvider/NetworkProviderReducer';
import { getSimStyleSelectItems } from '../../Redux/SimStyle/SimStyleReducer';

interface ISimDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  sim: SimDTO;
  networkProviderSelectItems: SelectItemDTO[];
  simStyleSelectItems: SelectItemDTO[];

  setActiveRoute: ActionCreator<any>,
  getSimById: ActionCreator<any>,
  saveSim: ActionCreator<any>,
  getNetworkProviderSelectItems: ActionCreator<any>,
  getSimStyleSelectItems: ActionCreator<any>,
}

interface ISimDetailStates {
  isSaving?: boolean;

  sim: SimDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    sim: state.sim.editingSim,
    networkProviderSelectItems: state.networkProvider.networkProviderSelectItems,
    simStyleSelectItems: state.simStyle.simStyleSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getSimById: (id: number) => dispatch(getSimById(id)),
    saveSim: (sim: SimDTO) => dispatch(saveSim(sim)),
    getNetworkProviderSelectItems: () => dispatch(getNetworkProviderSelectItems()),
    getSimStyleSelectItems: () => dispatch(getSimStyleSelectItems()),
  })
}

class SimDetail extends React.Component<ISimDetailProps, ISimDetailStates> {

  constructor(props: ISimDetailProps) {
    super(props);
    this.state = {
      sim: {...new SimDTO(), ...props.sim}
    }
  }

  componentDidMount() {
    !this.props.networkProviderSelectItems?.length && this.props.getNetworkProviderSelectItems();
    !this.props.simStyleSelectItems?.length && this.props.getSimStyleSelectItems();

    const { setActiveRoute, match, getSimById, t } = this.props;

    setActiveRoute([{path: URL.Sim, name: this.props.t('menu:sim')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getSimById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.Sim);
          } else {
            this.setState({ sim: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes, networkProviderSelectItems, simStyleSelectItems } = this.props;
    let { showValidation, sim: { name, number, price, styleId, numberType, type, networkProviderId, status, isOutOfStock } } = this.state;

    const numberTypeOptions = NumberTypes.map(s => { return {...s, text: t(s.text)} as SelectItemDTO } );
    const simTypeOptions = SimTypes.map(s => { return {...s, text: t(s.text)} as SelectItemDTO } );
    const simStatusOptions = SimStatus.map(s => { return {...s, text: t(s.text)} as SelectItemDTO } );

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={PhoneIphone}
          iconColor={'green'}
          title={t('common:information')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('sim:name')} </FormLabel>
              <>
                <InputText name='name' values={name} onChange={this._onTextChange.bind(this)} />
                {showValidation && !name && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('sim:number')} </FormLabel>
              <>
                <InputText name='number' values={number} onChange={this._onTextChange.bind(this)} />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('sim:price')} </FormLabel>
              <>
                <InputText name="price" numberOnly thousandSeparator values={price} onChange={this._onNumberChange.bind(this)} />
                {showValidation && !price && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('sim:network_provider')} </FormLabel>
              <>
                <Autocomplete
                  name='networkProviderId'
                  value={networkProviderId}
                  options={networkProviderSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                />
                {showValidation && !networkProviderId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('sim:style')} </FormLabel>
              <>
                <Autocomplete
                  name='styleId'
                  value={styleId}
                  options={simStyleSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                />
                {showValidation && !styleId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('sim:number_type')} </FormLabel>
              <CustomSelect 
                name={'numberType'}
                value={numberType}
                onChange={this._onNumberChange.bind(this)}
                items={numberTypeOptions}
                label={t('sim:number_type')} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('sim:type')} </FormLabel>
              <CustomSelect 
                name={'type'}
                value={type}
                onChange={this._onNumberChange.bind(this)}
                items={simTypeOptions}
                label={t('sim:type')} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('sim:status')} </FormLabel>
              <CustomSelect 
                name={'status'}
                value={status}
                onChange={this._onNumberChange.bind(this)}
                items={simStatusOptions}
                label={t('sim:status')} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('sim:is_out_of_stock')} </FormLabel>
              <Checkbox
                checked={isOutOfStock}
                name='isOutOfStock'
                onClick={this._onCheckboxChange.bind(this)}
              />
            </ColumnContainer>

          </ColumnContainer>

          <GridContainer justify="center">
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button>
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button>
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.Sim)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ sim: {...this.state.sim, [name]: value }});    
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ sim: {...this.state.sim, [name]: value }});    
  }

  private _onCheckboxChange(event: any) {
    event.persist && event.persist();
    const { target: { name, checked } } = event;
    let sim = this.state.sim;
    this.setState({ sim: {...sim, [name]: checked }});
  }

  private _onValidate() {
    let { sim: { name, price, networkProviderId, styleId } } = this.state;

    return name && price && networkProviderId && styleId;
  }

  private _onSave(close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { sim: { name, number, price, networkProviderId, styleId, numberType, type, status, isOutOfStock } } = this.state;
    let sim = new SimDTO();
    sim.id = parseInt(this.props.match.params.id);
    sim.name = name;
    sim.number = number;
    sim.price = price;
    sim.networkProviderId = networkProviderId;
    sim.styleId = styleId;
    sim.numberType = numberType;
    sim.type = type;
    sim.status = status;
    sim.isOutOfStock = isOutOfStock;

    this.props.saveSim(sim).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        sim.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close) {
          history.push(URL.Sim);
        } else if (parseInt(match.params.id) === 0) {
          history.push(URL.SimDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(SimDetail)));