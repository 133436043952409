import { ClientType } from "../Consts";

export class ClientDTO {
  public id: number;
  public name: string;
  public phone: string;
  public address: string;
  public birthday: string;
  public type: number;
  public email: string;
  public userName: string;
  public branchId: number;
  public createdDate: string;
  public loanAmount: number;

  constructor() {
    this.id = 0;
    this.name = '';
    this.phone = '';
    this.address = '';
    this.birthday = '';
    this.type = ClientType.Single;
    this.email = '';
    this.userName = '';
    this.branchId = 0;
    this.createdDate = '';
    this.loanAmount = 0;
  }
}