import { FilterDTO, PaySupplierDTO } from '../../DTO';

const PAY_SUPPLIER_REQUEST: string = 'PAY_SUPPLIER_REQUEST';

const PAY_SUPPLIER_GET_LIST_SUCCESS: string = 'PAY_SUPPLIER_GET_LIST_SUCCESS';
const PAY_SUPPLIER_GET_LIST_FAIL: string = 'PAY_SUPPLIER_GET_LIST_FAIL';

const PAY_SUPPLIER_SET_EDITING_PAY_SUPPLIER: string = 'PAY_SUPPLIER_SET_EDITING_PAY_SUPPLIER';

const PAY_SUPPLIER_GET_BY_ID_SUCCESS: string = 'PAY_SUPPLIER_GET_BY_ID_SUCCESS';
const PAY_SUPPLIER_GET_BY_ID_FAIL: string = 'PAY_SUPPLIER_GET_BY_ID_FAIL';

const PAY_SUPPLIER_SAVE_SUCCESS: string = 'PAY_SUPPLIER_SAVE_SUCCESS';
const PAY_SUPPLIER_SAVE_FAIL: string = 'PAY_SUPPLIER_SAVE_FAIL';

const PAY_SUPPLIER_DELETE_SUCCESS: string = 'PAY_SUPPLIER_DELETE_SUCCESS';
const PAY_SUPPLIER_DELETE_FAIL: string = 'PAY_SUPPLIER_DELETE_FAIL';

const initialState = {
  editingPaySupplier: {},
}

export function paySupplierReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case PAY_SUPPLIER_SET_EDITING_PAY_SUPPLIER:
      return Object.assign({}, state, {
        editingPaySupplier: action.payload
      });

    default:
      return state;

  }

}

export function getListPaySupplier(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [PAY_SUPPLIER_REQUEST, PAY_SUPPLIER_GET_LIST_SUCCESS, PAY_SUPPLIER_GET_LIST_FAIL],
      EndPoint: `PaySuppliers${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingPaySupplier(paySupplier: PaySupplierDTO) {
  return {
    type: PAY_SUPPLIER_SET_EDITING_PAY_SUPPLIER,
    payload: paySupplier
  }
}

export function getPaySupplierById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [PAY_SUPPLIER_REQUEST, PAY_SUPPLIER_GET_BY_ID_SUCCESS, PAY_SUPPLIER_GET_BY_ID_FAIL],
      EndPoint: `PaySuppliers\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function savePaySupplier(paySupplier: PaySupplierDTO, action: string) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: JSON.stringify(paySupplier),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [PAY_SUPPLIER_REQUEST, PAY_SUPPLIER_SAVE_SUCCESS, PAY_SUPPLIER_SAVE_FAIL],
      EndPoint: `PaySuppliers?action=${action}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deletePaySupplier(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [PAY_SUPPLIER_REQUEST, PAY_SUPPLIER_DELETE_SUCCESS, PAY_SUPPLIER_DELETE_FAIL],
      EndPoint: 'PaySuppliers',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}