import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL, TransferStatus, GlobalConst, Permission } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer, Autocomplete, Checkbox, CustomSelect } from '../../Components';
import { People, Save, KeyboardArrowLeft, Clear } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getCardById, saveCard, approveCard, cancelCard } from '../../Redux/Card/CardReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { CardDTO, CurrentUserDTO, SelectItemDTO } from '../../DTO';
import { getClientSelectItems } from '../../Redux/Client/ClientReducer';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';
import { getUserSelectItems } from '../../Redux/User/UserReducer';
import { getTopUpTypeSelectItems } from '../../Redux/TopUpType/TopUpTypeReducer';
import { getNetworkProviderSelectItems } from '../../Redux/NetworkProvider/NetworkProviderReducer';
import { getGameProviderSelectItems } from '../../Redux/GameProvider/GameProviderReducer';
import { getPaymentAccountSelectItems } from '../../Redux/PaymentAccount/PaymentAccountReducer';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
let moment = require('moment');

interface ICardDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  card: CardDTO;
  currentUser: CurrentUserDTO;
  clientSelectItems: SelectItemDTO[];
  branchSelectItems: SelectItemDTO[];
  userSelectItems: SelectItemDTO[];
  topUpTypeSelectItems: SelectItemDTO[];
  networkProviderSelectItems: SelectItemDTO[];
  gameProviderSelectItems: SelectItemDTO[];
  paymentAccountSelectItems: SelectItemDTO[];

  setActiveRoute: ActionCreator<any>,
  getCardById: ActionCreator<any>,
  saveCard: ActionCreator<any>,
  approveCard: ActionCreator<any>,
  cancelCard: ActionCreator<any>,
  getClientSelectItems: ActionCreator<any>,
  getBranchSelectItems: ActionCreator<any>,
  getUserSelectItems: ActionCreator<any>,
  getTopUpTypeSelectItems: ActionCreator<any>,
  getNetworkProviderSelectItems: ActionCreator<any>,
  getGameProviderSelectItems: ActionCreator<any>,
  getPaymentAccountSelectItems: ActionCreator<any>,
}

interface ICardDetailStates {
  isSaving?: boolean;

  card: CardDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    card: state.card.editingCard,
    currentUser: state.user.currentUser,
    clientSelectItems: state.client.clientSelectItems,
    branchSelectItems: state.branch.branchSelectItems,
    userSelectItems: state.user.userSelectItems,
    topUpTypeSelectItems: state.topUpType.topUpTypeSelectItems,
    networkProviderSelectItems: state.networkProvider.networkProviderSelectItems,
    gameProviderSelectItems: state.gameProvider.gameProviderSelectItems,
    paymentAccountSelectItems: state.paymentAccount.paymentAccountSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getCardById: (id: number) => dispatch(getCardById(id)),
    saveCard: (card: CardDTO, submit: boolean) => dispatch(saveCard(card, submit)),
    approveCard: (id: number) => dispatch(approveCard(id)),
    cancelCard: (id: number) => dispatch(cancelCard(id)),
    getClientSelectItems: () => dispatch(getClientSelectItems()),
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
    getUserSelectItems: () => dispatch(getUserSelectItems()),
    getTopUpTypeSelectItems: () => dispatch(getTopUpTypeSelectItems()),
    getNetworkProviderSelectItems: () => dispatch(getNetworkProviderSelectItems()),
    getGameProviderSelectItems: () => dispatch(getGameProviderSelectItems()),
    getPaymentAccountSelectItems: () => dispatch(getPaymentAccountSelectItems()),
  })
}

class CardDetail extends React.Component<ICardDetailProps, ICardDetailStates> {

  constructor(props: ICardDetailProps) {
    super(props);
    this.state = {
      card: {...new CardDTO(), ...props.card}
    }
  }

  componentDidMount() {
    !this.props.clientSelectItems?.length && this.props.getClientSelectItems();
    !this.props.branchSelectItems?.length && this.props.getBranchSelectItems();
    !this.props.userSelectItems?.length && this.props.getUserSelectItems();
    !this.props.topUpTypeSelectItems?.length && this.props.getTopUpTypeSelectItems();
    !this.props.networkProviderSelectItems?.length && this.props.getNetworkProviderSelectItems();
    !this.props.gameProviderSelectItems?.length && this.props.getGameProviderSelectItems();
    !this.props.paymentAccountSelectItems?.length && this.props.getPaymentAccountSelectItems();

    const { setActiveRoute, match, getCardById, t } = this.props;

    setActiveRoute([{path: URL.Card, name: this.props.t('menu:card')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getCardById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.Card);
          } else {
            this.setState({ card: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes, currentUser, networkProviderSelectItems, gameProviderSelectItems, clientSelectItems, branchSelectItems, userSelectItems, topUpTypeSelectItems, paymentAccountSelectItems } = this.props;
    let { showValidation, card: { id, number, date, status, networkProviderId, gameProviderId, clientId, topUpTypeId, price, amount, commission, discount, quantity, branchId, commissionUserId, payByCash, receiveAccountId } } = this.state;

    const statusText = [
      { value: TransferStatus.Draft, text: t('card:status_draft')},
      { value: TransferStatus.PendingApprove, text: t('card:status_pending_approve')},
      { value: TransferStatus.Approved, text: t('card:status_approved')},
      { value: TransferStatus.Canceled, text: t('card:status_cancel')},
    ]

    let statusStr = statusText.find(s => s.value === status)?.text || '';
    let tDate = date ? moment(date, GlobalConst.DATE_TIME_FORMAT) : moment(new Date());
    let now = moment(new Date());
    let readOnly = (status === TransferStatus.Approved || status === TransferStatus.Canceled) && (tDate.year() * 12 + tDate.month() < now.year() * 12 + now.month());

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={People}
          iconColor={'green'}
          title={t('common:information')}>

          {id && currentUser.functionIds.includes(Permission.ApproveCards) && status === TransferStatus.PendingApprove ? 
            <ColumnContainer columnWidth={[{xs:12, sm:12}]}>
              <div className={classes.right}>
                <Button color='primary' customClass={classes.marginRight} round onClick={this._onApprove.bind(this)} disabled={this.state.isSaving}>
                  <Save className={classes.icons} /> {t('button:approve')}
                </Button>
                <Button color='danger' customClass={classes.marginRight} round onClick={this._onCancel.bind(this)} disabled={this.state.isSaving}>
                  <Clear className={classes.icons} /> {t('button:cancel')}
                </Button>
              </div>
            </ColumnContainer>
          : null }

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('card:number')} </FormLabel>
              <InputText values={number} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('card:status')} </FormLabel>
              <InputText values={statusStr} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('card:date')} </FormLabel>
              {!readOnly ? 
                <Datetime
                  dateFormat={GlobalConst.DATE_FORMAT}
                  timeFormat={"HH:mm"}
                  inputProps={{ placeholder: t('card:date') }}
                  onChange={this._onDateChange.bind(this)}
                  value={date}
                /> : <InputText disabled values={date} /> }
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('card:client')} </FormLabel>
              <>
                <Autocomplete
                  name='clientId'
                  value={clientId}
                  options={clientSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  getOptionLabel={(option: SelectItemDTO) => `${option.text} - ${option.details.phone}`}
                  disabled={readOnly}
                />
                {showValidation && !clientId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('card:top_up_type')} </FormLabel>
              <>
                <Autocomplete
                  name='topUpTypeId'
                  value={topUpTypeId}
                  options={topUpTypeSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !topUpTypeId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <> </>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('card:network_provider')} </FormLabel>
              <>
                <Autocomplete
                  value={networkProviderId}
                  options={networkProviderSelectItems}
                  onChange={(value: number) => { this.setState({ card: { ...this.state.card, networkProviderId: value, gameProviderId: 0 } }) }}
                  disabled={readOnly}
                />
                {showValidation && !networkProviderId && !gameProviderId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('card:game_provider')} </FormLabel>
              <>
                <Autocomplete
                  value={gameProviderId}
                  options={gameProviderSelectItems}
                  onChange={(value: number) => { this.setState({ card: { ...this.state.card, gameProviderId: value, networkProviderId: 0 } }) }}
                  disabled={readOnly}
                />
                {showValidation && !networkProviderId && !gameProviderId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('card:amount')} </FormLabel>
              <>
                <InputText 
                  name="amount"
                  numberOnly
                  thousandSeparator
                  values={amount} 
                  disabled={readOnly}
                  onChange={this._onNumberChange.bind(this)} />
                {showValidation && !amount && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('card:price')} </FormLabel>
              <>
                <InputText 
                  name="price"
                  numberOnly
                  thousandSeparator
                  values={price} 
                  disabled={readOnly}
                  onChange={this._onNumberChange.bind(this)} />
                {showValidation && !price && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('card:quantity')} </FormLabel>
              <>
                <InputText 
                  name="quantity"
                  numberOnly
                  thousandSeparator
                  isInt
                  values={quantity} 
                  disabled={readOnly}
                  onChange={this._onNumberChange.bind(this)} />
                {showValidation && !quantity && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('card:discount')} </FormLabel>
              <>
                <InputText 
                  name="discount"
                  numberOnly
                  thousandSeparator
                  values={discount} 
                  disabled={readOnly}
                  onChange={this._onNumberChange.bind(this)} />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('card:commission')} </FormLabel>
              <>
                <InputText 
                  name="commission"
                  numberOnly
                  thousandSeparator
                  values={commission} 
                  disabled={readOnly}
                  onChange={this._onNumberChange.bind(this)} />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('buySim:total')} </FormLabel>
              <>
                <InputText 
                  numberOnly
                  thousandSeparator
                  values={parseFloat(amount.toString()) * parseInt(quantity.toString()) - parseFloat(discount.toString())} 
                  disabled />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('card:branch')} </FormLabel>
              <>
                <Autocomplete
                  name='branchId'
                  value={branchId}
                  options={branchSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !branchId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('card:commission_user')} </FormLabel>
              <>
                <Autocomplete
                  name='commissionUserId'
                  value={commissionUserId}
                  options={userSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !commissionUserId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('card:pay_by_cash')} </FormLabel>
              <Checkbox
                checked={payByCash}
                name='payByCash'
                onClick={this._onCheckboxChange.bind(this)}
              />
            </ColumnContainer>

            {payByCash ? null : <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('card:receive_account')} </FormLabel>
              <>
                <CustomSelect 
                  name={'receiveAccountId'}
                  value={receiveAccountId}
                  onChange={this._onNumberChange.bind(this)}
                  items={paymentAccountSelectItems}
                  label={t('card:receive_account')}
                  disabled={readOnly} />
                {showValidation && !receiveAccountId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>}

          </ColumnContainer>

          <GridContainer justify="center">
          {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button> : null}
            {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button> : null}
            {status === TransferStatus.Draft ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_submit')}
            </Button> : null}
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.Card)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onCheckboxChange(event: any) {
    event.persist && event.persist();
    const { target: { name, checked } } = event;
    let card = this.state.card;
    this.setState({ card: {...card, [name]: checked }});
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ card: {...this.state.card, [name]: value }});    
  }

  private _onDateChange(value: string | Moment) {
    let card = this.state.card;
    card.date = moment(value).format(GlobalConst.DATE_TIME_FORMAT);
    this.setState({ card: card });
  }

  private _onValidate() {
    let { card: { clientId, topUpTypeId, gameProviderId, networkProviderId, amount, price, quantity, branchId, commissionUserId } } = this.state;

    return clientId && topUpTypeId && (networkProviderId || gameProviderId) && amount && price && quantity && branchId && commissionUserId;
  }

  private _onSave(submit: boolean = false, close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { card: { date, networkProviderId, gameProviderId, clientId, topUpTypeId, price, amount, quantity, discount, commission, branchId, commissionUserId, payByCash, receiveAccountId } } = this.state;
    let card = new CardDTO();
    card.id = parseInt(this.props.match.params.id);
    card.date = date;
    card.networkProviderId = networkProviderId ? networkProviderId : null;
    card.gameProviderId = gameProviderId ? gameProviderId : null;
    card.clientId = clientId;
    card.topUpTypeId = topUpTypeId;
    card.price = price;
    card.amount = amount;
    card.quantity = quantity;
    card.discount = discount;
    card.commission = commission;
    card.branchId = branchId;
    card.commissionUserId = commissionUserId;
    card.payByCash = payByCash;
    card.receiveAccountId = payByCash ? null : receiveAccountId;

    this.props.saveCard(card, submit).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        card.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close || submit) {
          history.push(URL.Card);
        } else if (parseInt(match.params.id) === 0) {
          this.setState({ card: response.data });
          history.push(URL.CardDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

  private _onApprove() {
    const { t } = this.props;
    this.setState({ isSaving: true });

    this.props.approveCard(this.state.card.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.Card);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

  private _onCancel() {
    const { t } = this.props;
    this.setState({ isSaving: true });
    
    this.props.cancelCard(this.state.card.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.Card);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(CardDetail)));