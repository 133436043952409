import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL, WorkflowObjects, GlobalConst, WorkflowStatus, WorkflowButton } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer, Autocomplete, Checkbox, DataList, CustomSelect } from '../../Components';
import { Timeline, Save, KeyboardArrowLeft, DeviceHub, Delete } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getWorkflowById, saveWorkflow } from '../../Redux/Workflow/WorkflowReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { WorkflowDTO, SelectItemDTO, WorkflowStateDTO, WorkflowButtonDTO } from '../../DTO';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
import IconButton from '../../Components/Button/IconButton';
let moment = require('moment');

interface IWorkflowDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  workflow: WorkflowDTO;

  setActiveRoute: ActionCreator<any>,
  getWorkflowById: ActionCreator<any>,
  saveWorkflow: ActionCreator<any>,
}

interface IWorkflowDetailStates {
  isSaving?: boolean;

  workflow: WorkflowDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    workflow: state.workflow.editingWorkflow,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getWorkflowById: (id: number) => dispatch(getWorkflowById(id)),
    saveWorkflow: (workflow: WorkflowDTO) => dispatch(saveWorkflow(workflow)),
  })
}

class WorkflowDetail extends React.Component<IWorkflowDetailProps, IWorkflowDetailStates> {

  constructor(props: IWorkflowDetailProps) {
    super(props);
    this.state = {
      workflow: {...new WorkflowDTO(), ...props.workflow}
    }
  }

  componentDidMount() {
    const { setActiveRoute, match, getWorkflowById, t } = this.props;

    setActiveRoute([{path: URL.Workflow, name: this.props.t('menu:workflow')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getWorkflowById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.Workflow);
          } else {
            this.setState({ workflow: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes } = this.props;
    let { showValidation, workflow: { name, number, objectName, isActive, fromDate, toDate, workflowStates } } = this.state;

    let emptyOption = { value: '', text: '' } as SelectItemDTO;
    let objectOptions = [emptyOption, ...WorkflowObjects.map(o => ({ value: o, text: t(`workflow_object:${o.toLowerCase()}`) } as SelectItemDTO))]

    let readOnly = false;

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={Timeline}
          iconColor={'green'}
          title={t('common:information')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('workflow:number')} </FormLabel>
              <InputText values={number} disabled />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('workflow:name')} </FormLabel>
              <>
                <InputText name='name' values={name} onChange={this._onTextChange.bind(this)} />
                {showValidation && !name && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('workflow:object')} </FormLabel>
              <>
                <Autocomplete
                  name='objectName'
                  emptyValue=''
                  value={objectName}
                  options={objectOptions}
                  onChange={this._onTextChange.bind(this)}
                />
                {showValidation && !objectName && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('workflow:is_active')} </FormLabel>
              <Checkbox
                checked={isActive}
                name='isActive'
                onClick={this._onCheckboxChange.bind(this)} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('workflow:from_date')} </FormLabel>
              {!readOnly ? 
                <Datetime
                  dateFormat={GlobalConst.DATE_FORMAT}
                  timeFormat={"HH:mm"}
                  inputProps={{ placeholder: t('workflow:from_date') }}
                  onChange={this._onFromDateChange.bind(this)}
                  value={fromDate}
                /> : <InputText disabled values={fromDate} /> }
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('workflow:to_date')} </FormLabel>
              {!readOnly ? 
                <Datetime
                  dateFormat={GlobalConst.DATE_FORMAT}
                  timeFormat={"HH:mm"}
                  inputProps={{ placeholder: t('workflow:to_date') }}
                  onChange={this._onToDateChange.bind(this)}
                  value={toDate}
                /> : <InputText disabled values={toDate} /> }
            </ColumnContainer>

          </ColumnContainer>

          <GridContainer justify="center">
            <Button color='primary' customClass={classes.marginRight} round onClick={this._addState.bind(this)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('workflow:add_state')}
            </Button>
          </GridContainer>

          <GridContainer justify="center">
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button>
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button>
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.Workflow)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>

        {workflowStates && workflowStates.length ? workflowStates.sort((w1: WorkflowStateDTO, w2: WorkflowStateDTO) => { return w1.order - w2.order }).map((s: WorkflowStateDTO, idx: number) => this._renderState(idx)) : null}
        
      </ColumnContainer>
    )
  }

  private _addState() {
    let { workflow } = this.state;

    let newState = new WorkflowStateDTO();
    newState.order = workflow.workflowStates?.length ?? 0;
    workflow.workflowStates.push(newState);

    this.setState({ workflow: workflow });
  }

  private _addButton(idx: number) {
    let { workflow } = this.state;

    let newButton = new WorkflowButtonDTO();
    newButton.order = workflow.workflowStates[idx]?.workflowButtons?.length ?? 0;
    workflow.workflowStates[idx].workflowButtons.push(newButton);

    this.setState({ workflow: workflow });
  }

  private _deleteButton(idx: number, btnIdx: number) {
    // TODO
  }

  private _renderState(idx: number) {
    const { t, classes } = this.props;
    let { workflow, showValidation } = this.state;
    let { name, status } = workflow.workflowStates[idx];

    let statusOption = WorkflowStatus.map(s => ({ value: s.value, text: t(`status:${s.text}`) } as SelectItemDTO));

    return (
      <IconCard
        key={idx}
        icon={DeviceHub}
        iconColor={'green'}
        title={t('workflow:state_index', { num: idx + 1})}>

        <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

          <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
            <FormLabel className={classes.labelHorizontal}> {t('workflow:state_name')} </FormLabel>
            <>
              <InputText name='name' values={name} onChange={this._onTextStateChange.bind(this, idx)} />
            </>
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
            <FormLabel className={classes.labelHorizontal} required> {t('workflow:state_status')} </FormLabel>
            <>
              <CustomSelect
                name={'status'}
                value={status}
                onChange={this._onNumberStateChange.bind(this, idx)}
                items={statusOption}
                label={t('workflow:state_status')} />
              {showValidation && !status && <Danger>{t('message:required')}</Danger>}
            </>
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
            <FormLabel className={classes.labelHorizontal}> {t('workflow:state_buttons')} </FormLabel>
            <>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._addButton.bind(this, idx)} disabled={this.state.isSaving}>
                <Save className={classes.icons} /> {t('workflow:add_button')}
              </Button>
            </>
          </ColumnContainer>

        </ColumnContainer>

        <ColumnContainer columnWidth={[{xs:12}]}>
          <DataList 
            currentPage={0}
            pageSize={1000}
            totalPage={1}
            data={this._mapButtons(idx)}
            columns={this._getButtonColumns()}
            hidePaging
          />
        </ColumnContainer>

      </IconCard>
    )
  }

  private _onTextButtonChange(idx: number, btnIdx: number, value: string, name: string) {
    let { workflow } = this.state;
    workflow.workflowStates[idx].workflowButtons[btnIdx] = { ...workflow.workflowStates[idx].workflowButtons[btnIdx], [name]: value };

    this.setState({ workflow: workflow });
  }

  private _onTextStateChange(idx: number, value: string, name: string) {
    let { workflow } = this.state;
    workflow.workflowStates[idx] = { ...workflow.workflowStates[idx], [name]: value };
    
    this.setState({ workflow: workflow });
  }

  private _onNumberStateChange(idx: number, value: number, name: string) {
    let { workflow } = this.state;
    workflow.workflowStates[idx] = { ...workflow.workflowStates[idx], [name]: value };
    
    this.setState({ workflow: workflow });
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ workflow: {...this.state.workflow, [name]: value }});    
  }

  private _onCheckboxChange(event: any) {
    event.persist && event.persist();
    const { target: { name, checked } } = event;
    let workflow = this.state.workflow;
    this.setState({ workflow: {...workflow, [name]: checked }});
  }

  private _onFromDateChange(value: string | Moment) {
    let workflow = this.state.workflow;
    workflow.fromDate = moment(value).format(GlobalConst.DATE_TIME_FORMAT);
    this.setState({ workflow: workflow });
  }

  private _onToDateChange(value: string | Moment) {
    let workflow = this.state.workflow;
    workflow.toDate = moment(value).format(GlobalConst.DATE_TIME_FORMAT);
    this.setState({ workflow: workflow });
  }

  private _getButtonColumns() {
    const { t } = this.props;
    return [
      { Header: t('workflow:state_button_text_code'), accessor: "textCode", sortable: false, filterable: false },
      { Header: t('workflow:state_button_action'), accessor: "actionName", sortable: false, filterable: false },
      { Header: t('workflow:state_button_icon'), accessor: "icon", sortable: false, filterable: false },
      { Header: t('workflow:state_button_color'), accessor: "color", sortable: false, filterable: false },
      { Header: t('workflow:state_button_class'), accessor: "class", sortable: false, filterable: false },
      { Header: '', accessor: "actions", sortable: false, filterable: false }
    ]
  }

  private _mapButtons(idx: number) {
    let data = this.state.workflow.workflowStates[idx].workflowButtons;

    if (!data) return [];

    const { t } = this.props;
    let btnOptions = WorkflowButton.map((b: string) => ({ value: b, text: t(`button:${b}`) } as SelectItemDTO))

    return data.map((u: WorkflowButtonDTO, btnIdx: number) => {
      return {
        ...u,
        name: ( <InputText name='name' values={u.name} onChange={this._onTextButtonChange.bind(this, idx, btnIdx)} /> ),
        textCode: ( <CustomSelect name={'textCode'} value={u.textCode} onChange={this._onTextButtonChange.bind(this, idx, btnIdx)} items={btnOptions} label={t('workflow:state_button_text_code')} /> ),
        icon: ( <InputText name='icon' values={u.icon} onChange={this._onTextButtonChange.bind(this, idx, btnIdx)} /> ),
        color: ( <InputText name='color' values={u.color} onChange={this._onTextButtonChange.bind(this, idx, btnIdx)} /> ),
        class: ( <InputText name='class' values={u.class} onChange={this._onTextButtonChange.bind(this, idx, btnIdx)} /> ),
        actionName: ( <InputText name='actionName' values={u.name} onChange={this._onTextButtonChange.bind(this, idx, btnIdx)} /> ),
        actions: (
          <div className="actions-right">
            <IconButton className='small-action' onClick={this._deleteButton.bind(this, idx, btnIdx)}> <Delete color='error' /> </IconButton>
          </div>
        )
      }
    });
  }

  private _onValidate() {
    let { workflow: { name, objectName, workflowStates } } = this.state;

    return name && objectName && (!workflowStates || workflowStates.every(s => s.status));
  }

  private _onSave(close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { workflow: { name, objectName, isActive, fromDate, toDate, workflowStates } } = this.state;
    let workflow = new WorkflowDTO();
    workflow.id = parseInt(this.props.match.params.id);
    workflow.name = name;
    workflow.objectName = objectName;
    workflow.isActive = isActive;
    workflow.fromDate = fromDate;
    workflow.toDate = toDate;
    workflow.workflowStates = workflowStates;

    this.props.saveWorkflow(workflow).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        workflow.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close) {
          history.push(URL.Workflow);
        } else if (parseInt(match.params.id) === 0) {
          this.setState({ workflow: response.data })
          history.push(URL.WorkflowDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(WorkflowDetail)));