import React from 'react';
import Datetime from 'react-datetime';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL, GlobalConst } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, CustomSelect, InputText, ColumnContainer, Autocomplete } from '../../Components';
import { People, Save, KeyboardArrowLeft } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { ClientDTO, SelectItemDTO } from '../../DTO';
import { getClientById, saveClient } from '../../Redux/Client/ClientReducer';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';
import { showError, validEmail } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { Moment } from 'moment';
let moment = require('moment');

interface IClientDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  client: ClientDTO;
  branchSelectItems: SelectItemDTO[];

  setActiveRoute: ActionCreator<any>,
  getClientById: ActionCreator<any>,
  saveClient: ActionCreator<any>,
  getBranchSelectItems: ActionCreator<any>,
}

interface IClientDetailStates {
  isSaving?: boolean;

  client: ClientDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    client: state.client.editingClient,
    branchSelectItems: state.branch.branchSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getClientById: (id: number) => dispatch(getClientById(id)),
    saveClient: (client: ClientDTO) => dispatch(saveClient(client)),
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
  })
}

class ClientDetail extends React.Component<IClientDetailProps, IClientDetailStates> {

  constructor(props: IClientDetailProps) {
    super(props);
    this.state = {
      client: {...new ClientDTO(), ...props.client}
    }
  }

  componentDidMount() {
    !this.props.branchSelectItems?.length && this.props.getBranchSelectItems();

    const { setActiveRoute, match, getClientById, t } = this.props;

    setActiveRoute([{path: URL.Client, name: this.props.t('menu:client')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getClientById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.Client);
          } else {
            response.data.birthday = response.data.birthday ? moment(response.data.birthday, GlobalConst.SERVER_DATE_FORMAT).format(GlobalConst.DATE_FORMAT) : '';
            this.setState({ client: response.data });
          }
        } else {
          showError(t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes, branchSelectItems } = this.props;
    let { showValidation, client: { name, email, type, phone, address, branchId } } = this.state;

    const types = [
      { value: 1, text: t('client:type_single')} as SelectItemDTO,
      { value: 2, text: t('client:type_multiple')} as SelectItemDTO,
      { value: 3, text: t('client:type_branch')} as SelectItemDTO,
    ];

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={People}
          iconColor={'green'}
          title={t('common:information')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('client:name')} </FormLabel>
              <>
                <InputText name='name' values={name} onChange={this._onTextChange.bind(this)} />
                {showValidation && !name && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('client:phone')} </FormLabel>
              <InputText name='phone' values={phone} onChange={this._onTextChange.bind(this)} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('client:address')} </FormLabel>
              <InputText name='address' values={address} onChange={this._onTextChange.bind(this)} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('client:birthday')} </FormLabel>
              <Datetime
                dateFormat={GlobalConst.DATE_FORMAT}
                timeFormat={false}
                inputProps={{ placeholder: t('client:birthday') }}
                onChange={this._onDateChange.bind(this)}
                value={this.state.client.birthday}
              />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('client:type')} </FormLabel>
              <>
                <CustomSelect 
                  name={'type'}
                  value={this.state.client.type}
                  onChange={this._onNumberChange.bind(this)}
                  items={types}
                  label={t('client:type')} />
                {showValidation && parseInt(type.toString()) === 0 && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('client:email')} </FormLabel>
              <>
                <InputText name='email' values={email} onChange={this._onTextChange.bind(this)} />
                {showValidation && email && !validEmail(email) && <Danger>{t('message:invalid_email')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('client:branch')} </FormLabel>
              <>
                <Autocomplete
                  name='branchId'
                  value={branchId}
                  options={branchSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                />
                {showValidation && !branchId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

          </ColumnContainer>

          <GridContainer justify="center">
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button>
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button>
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.Client)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ client: {...this.state.client, [name]: value }});    
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ client: {...this.state.client, [name]: value }});    
  }

  private _onDateChange(value: string | Moment) {
    let client = this.state.client;
    client.birthday = moment(value).format(GlobalConst.DATE_FORMAT);
    this.setState({ client: client });
  }

  private _onValidate() {
    let { client: { name, email, branchId } } = this.state;

    return name && (!email || validEmail(email)) && branchId;
  }

  private _onSave(close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match, branchSelectItems } = this.props;
    let { client: { name, phone, address, birthday, type, email, branchId } } = this.state;
    let client = new ClientDTO();
    client.id = parseInt(this.props.match.params.id);
    client.name = name;
    client.phone = phone;
    client.address = address;
    client.birthday = birthday;
    client.type = type;
    client.email = email;
    client.branchId = branchId === 0 ? parseInt(branchSelectItems[0].value.toString()) : branchId;

    this.props.saveClient(client).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        client.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close) {
          history.push(URL.Client);
        } else if (parseInt(match.params.id) === 0) {
          history.push(URL.ClientDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(ClientDetail)));