import * as React from 'react';
import cx from "classnames";
import { Redirect } from "react-router-dom";
import { withStyles, LinearProgress, MenuItem, ClickAwayListener, Grow, Paper, MenuList } from '@material-ui/core';
import { connect } from 'react-redux';
import { timeoutToken, validToken } from '../../Utils';
import mainLayoutStyle from './MainLayoutStyle'
import { AdminRoutes } from '../../App';
import { SideBar, Header, Footer, CustomIconButton } from '../';
import logo from "../../Assets/images/logo512.png";
import image from "../../Assets/images/sidebar-2.jpg";
import { withTranslation, WithTranslation } from 'react-i18next';
import PerfectScrollbar from 'perfect-scrollbar';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';
import { getRoleSelectItems } from '../../Redux/Role/RoleReducer';
import { getSupplierSelectItems } from '../../Redux/Supplier/SupplierReducer';
import { getFunctionSelectItems } from '../../Redux/Function/FunctionReducer';
import { getUserInfo, getUserSelectItems } from '../../Redux/User/UserReducer';
import { ActionCreator } from 'redux';
import { CurrentUserDTO } from '../../DTO';
import { Add } from '@material-ui/icons';
import { Manager, Target, Popper } from "react-popper";
import { QuickClient, QuickCashCollectionProduct } from '../../Pages';
import { withRouter } from 'react-router';
import { getAllVersion } from '../../Redux/Version/VersionReducer';
import { GlobalConst, NotificationMethod } from '../../Consts';
import { toast } from 'react-toastify';
import * as SignalR from "@microsoft/signalr";
import { getBankSelectItems } from '../../Redux/Bank/BankReducer';
import { getSimSelectItems } from '../../Redux/Sim/SimReducer';
import { getClientSelectItems } from '../../Redux/Client/ClientReducer';
import { getNetworkProviderSelectItems } from '../../Redux/NetworkProvider/NetworkProviderReducer';
import { getTopUpTypeSelectItems } from '../../Redux/TopUpType/TopUpTypeReducer';
import { getGameProviderSelectItems } from '../../Redux/GameProvider/GameProviderReducer';
import { getCashCollectionSettingSelectItems } from '../../Redux/CashCollectionSetting/CashCollectionSettingReducer';
import { getCashCollectionProductSelectItems } from '../../Redux/CashCollectionProduct/CashCollectionProductReducer';
import { getPaymentAccountSelectItems } from '../../Redux/PaymentAccount/PaymentAccountReducer';
import { getTelecomServiceSelectItems } from '../../Redux/TelecomService/TelecomServiceReducer';
import { getSimStyleSelectItems } from '../../Redux/SimStyle/SimStyleReducer';
import { getTransferSettingSelectItems } from '../../Redux/TransferSetting/TransferSettingReducer';
import { getTransferSettingMoMoSelectItems } from '../../Redux/TransferSettingMoMo/TransferSettingMoMoReducer';
import { getTransferViettelSettingSelectItems } from '../../Redux/TransferViettelSetting/TransferViettelSettingReducer';
import { getTenantInfo } from '../../Redux/Tenant/TenantReducer';
import { getProductSelectItems } from '../../Redux/Product/ProductReducer';
let moment = require('moment');

interface IMainLayoutProps extends WithTranslation {
  classes: any;
  location?: any;
  history?: any;

  token: string;
  showLoadingIndicator: boolean;
  currentUser: CurrentUserDTO;

  getBranchSelectItems: ActionCreator<any>,
  getFunctionSelectItems: ActionCreator<any>,
  getUserInfo: ActionCreator<any>,
  getListVersion: ActionCreator<any>,
  getBankSelectItems: ActionCreator<any>,
  getSimSelectItems: ActionCreator<any>,
  getUserSelectItems: ActionCreator<any>,
  getClientSelectItems: ActionCreator<any>,
  getNetworkProviderSelectItems: ActionCreator<any>,
  getTopUpTypeSelectItems: ActionCreator<any>,
  getGameProviderSelectItems: ActionCreator<any>,
  getCashCollectionSettingSelectItems: ActionCreator<any>,
  getCashCollectionProductSelectItems: ActionCreator<any>,
  getPaymentAccountSelectItems: ActionCreator<any>,
  getTelecomServiceSelectItems: ActionCreator<any>,
  getSimStyleSelectItems: ActionCreator<any>,
  getTransferSettingSelectItems: ActionCreator<any>,
  getTransferSettingMoMoSelectItems: ActionCreator<any>,
  getTransferViettelSettingSelectItems: ActionCreator<any>,
  getTenantInfo: ActionCreator<any>,
  getProductSelectItems: ActionCreator<any>,
}

interface IMainLayoutState {
  miniActive: boolean;
  mobileOpen: boolean;
  anchorEl?: any;
  open: boolean;
  showQuickClient: boolean;
  showQuickCashCollectionProduct: boolean;
  needLogin: boolean;
}

function mapStateToProps(state: any) {
  return ({
    token: state.user.token,
    showLoadingIndicator: state.common.showLoadingIndicator,
    currentUser: state.user.currentUser,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
    getRoleSelectItems: () => dispatch(getRoleSelectItems()),
    getSupplierSelectItems: () => dispatch(getSupplierSelectItems()),
    getFunctionSelectItems: () => dispatch(getFunctionSelectItems()),
    getUserInfo: () => dispatch(getUserInfo()),
    getListVersion: () => dispatch(getAllVersion()),
    getBankSelectItems: () => dispatch(getBankSelectItems()),
    getSimSelectItems: () => dispatch(getSimSelectItems()),
    getUserSelectItems: () => dispatch(getUserSelectItems()),
    getClientSelectItems: () => dispatch(getClientSelectItems()),
    getNetworkProviderSelectItems: () => dispatch(getNetworkProviderSelectItems()),
    getTopUpTypeSelectItems: () => dispatch(getTopUpTypeSelectItems()),
    getGameProviderSelectItems: () => dispatch(getGameProviderSelectItems()),
    getCashCollectionSettingSelectItems: () => dispatch(getCashCollectionSettingSelectItems()),
    getCashCollectionProductSelectItems: () => dispatch(getCashCollectionProductSelectItems()),
    getPaymentAccountSelectItems: () => dispatch(getPaymentAccountSelectItems()),
    getTelecomServiceSelectItems: () => dispatch(getTelecomServiceSelectItems()),
    getSimStyleSelectItems: () => dispatch(getSimStyleSelectItems()),
    getTransferSettingSelectItems: () => dispatch(getTransferSettingSelectItems()),
    getTransferSettingMoMoSelectItems: () => dispatch(getTransferSettingMoMoSelectItems()),
    getTransferViettelSettingSelectItems: () => dispatch(getTransferViettelSettingSelectItems()),
    getTenantInfo: () => dispatch(getTenantInfo()),
    getProductSelectItems: () => dispatch(getProductSelectItems()),
  })
}

class MainLayout extends React.Component<IMainLayoutProps, IMainLayoutState> {
  private _ps: any;
  private _checkVersionId: any;
  private _timeoutId: any = undefined;

  constructor(props: IMainLayoutProps) {
    super(props);

    this.state = {
      miniActive: false,
      mobileOpen: false,
      open: false,
      showQuickClient: false,
      showQuickCashCollectionProduct: false,
      needLogin: !validToken(props.token),
    }
  }

  componentDidMount() {
    const { t, getUserInfo, getTenantInfo } = this.props;

    getUserInfo();
    getTenantInfo().then((response: any) => {
      if (response.data) {
        let expiredDate = moment(response.licenseEndDate, GlobalConst.DATE_FORMAT);
        let today = moment({ hour: 0 });
        let noDay = expiredDate.diff(today, 'day')
        
        if (noDay <= 0) {
          toast.warn(t('message:license_last_day'), {autoClose: 20000});
        } else if (noDay <= 10) {
          toast.warn(t('message:license_almost_expire', { day: noDay }), {autoClose: 20000});
        }
      }
    });

    this._checkVersion();
    this._checkVersionId = setInterval(() => {
      this._checkVersion();
    }, 60 * 60 * 1000);
    

    if (navigator.platform.indexOf("Win") > -1 && this.refs.mainPanel) {
      this._ps = new PerfectScrollbar(this.refs.mainPanel as Element, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }

    let timeout = timeoutToken(this.props.token);
    if (timeout > 0) {
      this._connectSignalR();
      this._timeoutId = setTimeout(() => { this.setState({ needLogin: true }) }, timeout);
    }
  }

  private _connectSignalR() {
    const hubConnection = new SignalR.HubConnectionBuilder()
      .withUrl(GlobalConst.SIGNALR_CONNECTION_URL, { accessTokenFactory: () => this.props.token })
      .withAutomaticReconnect()
      .configureLogging(SignalR.LogLevel.Error)
      .build();

    try {
      hubConnection
      .start()
      .then(() => {
        console.log('Signalr connected:', hubConnection.connectionId);
      });
    } catch(err) {
      console.log(err);
    }

    hubConnection.on(NotificationMethod.SuccessMessage, (message: string) => {
      toast.success(message);
    });

    hubConnection.on(NotificationMethod.Reset, (code: string) => {
      switch(code) {

        case NotificationMethod.ResetBank:
          this.props.getBankSelectItems();
          break;

        case NotificationMethod.ResetBranch:
          this.props.getBranchSelectItems();
          break;

        case NotificationMethod.ResetCashCollectionProduct:
          this.props.getCashCollectionProductSelectItems();
          break;

        case NotificationMethod.ResetCashCollectionSetting:
          this.props.getCashCollectionSettingSelectItems();
          break;

        case NotificationMethod.ResetClient:
          this.props.getClientSelectItems();
          break;

        case NotificationMethod.ResetGameProvider:
          this.props.getGameProviderSelectItems();
          break;

        case NotificationMethod.ResetNetworkProvider:
          this.props.getNetworkProviderSelectItems();
          break;

        case NotificationMethod.ResetPaymentAccount:
          this.props.getPaymentAccountSelectItems();
          break;

        case NotificationMethod.ResetSim:
          this.props.getSimSelectItems();
          break;

        case NotificationMethod.ResetSimStyle:
          this.props.getSimStyleSelectItems();
          break;

        case NotificationMethod.ResetTelecomService:
          this.props.getTelecomServiceSelectItems();
          break;

        case NotificationMethod.ResetTopUpType:
          this.props.getTopUpTypeSelectItems();
          break;

        case NotificationMethod.ResetTransferSetting:
          this.props.getTransferSettingSelectItems();
          break;

        case NotificationMethod.ResetTransferSettingMoMo:
          this.props.getTransferSettingMoMoSelectItems();
          break;

        case NotificationMethod.ResetTransferViettelSetting:
          this.props.getTransferViettelSettingSelectItems();
          break;

        case NotificationMethod.ResetUser:
          this.props.getUserSelectItems();
          break;

        case NotificationMethod.ResetProduct:
          this.props.getProductSelectItems();
      }
    });
  }

  private _checkVersion() {
    this.props.getListVersion().then((response: any) => {
      if (!response?.data?.data) {
        return;
      }
      let curVersion = GlobalConst.VERSION.split('.').map((i: string) => parseInt(i));
      let newestVersion = (response.data?.data?.length ? response.data?.data[0] : '1.1.1').number.split('.').map((i: string) => parseInt(i));

      if (!(curVersion[0] > newestVersion[0] || curVersion[1] > newestVersion[1] || curVersion[2] >= newestVersion[2])) {
        toast.warn(this.props.t('message:old_version'), {autoClose: 20000});
      }
    });
  }

  componentWillReceiveProps(nextProps: IMainLayoutProps) {
    if (this.props.token !== nextProps.token) {
      this.setState({ needLogin: !validToken(nextProps.token) })
    }
  }

  componentDidUpdate(e: any) {
    if (e.history.location.pathname !== e.location.pathname && this.refs.mainPanel) {
      (this.refs.mainPanel as Element).scrollTop = 0;
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1 && this._ps) {
      this._ps.destroy();
    }

    clearInterval(this._checkVersionId);
    clearTimeout(this._timeoutId);
  }

  render() {
    const { classes, token, t, showLoadingIndicator, currentUser, ...rest } = this.props;
    let { needLogin, open, showQuickClient, showQuickCashCollectionProduct } = this.state;

    if (needLogin) {
      return <Redirect to='/login' />;
    }

    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });

    const dropdownItem =
      classes.dropdownItem +
      " " +
      cx({
        [classes.dropdownItemRTL]: false
      });

    let routes = JSON.parse(JSON.stringify(AdminRoutes));
    for (let i = 0; i < routes.length; i++) {
      routes[i].icon = AdminRoutes[i].icon;
      routes[i].component = AdminRoutes[i].component;
  
      if (routes[i].children) {
        for (let j = 0; j < routes[i].children.length; j++) {
          routes[i].children[j].icon = AdminRoutes[i].children![j].icon;
          routes[i].children[j].component = AdminRoutes[i].children![j].component;
        }
      }
    }
  
    routes = routes.filter((ar: any) => ar.id <= 0 || currentUser?.functionIds?.some((id: number) => id === ar.id));
    for (let i = routes.length - 1; i >= 0; i--) {
      if (routes[i].collapse && routes[i].children) {
        routes[i].children = routes[i].children!.slice().filter((c: any) => c.id === 0 || currentUser?.functionIds?.some((id: number) => id === c.id))
        if (routes[i].children.length === 0) {
          routes.splice(i, 1);
        }
      }
    }
    
    let childrenRoutes: any[] = [];
    routes.forEach((r: any) => {
      if (r.children) {
        childrenRoutes.push(...r.children.filter((c: any) => c.id === 0 || currentUser?.functionIds?.some((id: number) => id === c.id) ));
      }
    });
    
    return (
      <div className={classes.wrapper}>
        {showLoadingIndicator && <LinearProgress className={classes.progressBar} />}
        <SideBar
          routes={routes}
          logoText={"Vũ Gia ERP"}
          logo={logo}
          image={image}
          handleDrawerToggle={this._handleDrawerToggle.bind(this)}
          open={this.state.mobileOpen}
          color="green"
          bgColor="black"
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
          <Header
            sidebarMinimize={this._sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            routes={routes}
            handleDrawerToggle={this._handleDrawerToggle.bind(this)}
            color=''
            {...rest}
          />
          <div className={classes.content}>
            <div className={classes.container}>
              {this.props.children}
            </div>
            <div className={classes.floatDiv}>
              <ClickAwayListener onClickAway={this._handleClose.bind(this)}>
                <Manager>
                  <Target>
                    <CustomIconButton color='primary' onClick={(event: any) => this.setState({ anchorEl: event.currentTarget, open: true })}>
                      <Add />
                    </CustomIconButton>
                  </Target>
                  <Popper
                    placement="bottom-start"
                    eventsEnabled={open}
                    className={
                      cx({ [classes.popperClose]: !open }) +
                      " " +
                      classes.pooperResponsive
                    }>
                    <Grow
                      in={open}
                      style={{ transformOrigin: "0 0 0" }}>
                      <Paper className={classes.dropdown}>
                        <MenuList>
                          <MenuItem
                            onClick={this._showQuickClient.bind(this)}
                            className={dropdownItem}>
                            {t('float_menu:quick_client')}
                          </MenuItem>
                          <MenuItem
                            onClick={this._showQuickCashCollectionProduct.bind(this)}
                            className={dropdownItem}>
                            {t('float_menu:quick_cash_collection_product')}
                          </MenuItem>
                        </MenuList>
                      </Paper>
                    </Grow>
                  </Popper>
                </Manager>
              </ClickAwayListener>              
            </div>
          </div>
          <Footer fluid />
        </div>

        {showQuickClient ? <QuickClient handleClose={this._hideQuickClient.bind(this)} /> : null}
        {showQuickCashCollectionProduct ? <QuickCashCollectionProduct handleClose={this._hideQuickCashCollectionProduct.bind(this)} /> : null}

      </div>
    )
  }

  private _handleClose() {
    this.setState({ open: false });
  }

  private _handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  private _sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }

  private _showQuickClient() {
    this.setState({ showQuickClient: true });
  }

  private _hideQuickClient() {
    this.setState({ showQuickClient: false });
  }

  private _showQuickCashCollectionProduct() {
    this.setState({ showQuickCashCollectionProduct: true });
  }

  private _hideQuickCashCollectionProduct() {
    this.setState({ showQuickCashCollectionProduct: false });
  }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(mainLayoutStyle)(MainLayout))));
// export default withStyles(mainLayoutStyle)(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MainLayout)));