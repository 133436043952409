import { InventoryExportItemDTO } from ".";
import { GlobalConst } from "../Consts";
import { WorkflowDTO } from "./WorkflowDTO";

let moment = require('moment');

export class InventoryExportDTO {
  public id: number;
  public number: string;
  public dateExport: string;
  public fromBranchId: number;
  public toBranchId: number;
  public total: number;
  public status: number;
  public workflow: WorkflowDTO;
  public items: InventoryExportItemDTO[];

  constructor() {
    this.id = 0;
    this.number = '';
    this.status = 0;
    this.dateExport = moment().format(GlobalConst.DATE_TIME_FORMAT);
    this.fromBranchId = 0;
    this.toBranchId = 0;
    this.total = 0;
    this.workflow = new WorkflowDTO();
    this.items = [];
  }
}