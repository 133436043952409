import { FilterDTO, CashCollectionProductDTO } from '../../DTO';

const CASH_COLLECTION_PRODUCT_REQUEST: string = 'CASH_COLLECTION_PRODUCT_REQUEST';

const CASH_COLLECTION_PRODUCT_GET_LIST_SUCCESS: string = 'CASH_COLLECTION_PRODUCT_GET_LIST_SUCCESS';
const CASH_COLLECTION_PRODUCT_GET_LIST_FAIL: string = 'CASH_COLLECTION_PRODUCT_GET_LIST_FAIL';

const CASH_COLLECTION_PRODUCT_SET_EDITING_CASH_COLLECTION_PRODUCT: string = 'CASH_COLLECTION_PRODUCT_SET_EDITING_CASH_COLLECTION_PRODUCT';

const CASH_COLLECTION_PRODUCT_GET_BY_ID_SUCCESS: string = 'CASH_COLLECTION_PRODUCT_GET_BY_ID_SUCCESS';
const CASH_COLLECTION_PRODUCT_GET_BY_ID_FAIL: string = 'CASH_COLLECTION_PRODUCT_GET_BY_ID_FAIL';

const CASH_COLLECTION_PRODUCT_SAVE_SUCCESS: string = 'CASH_COLLECTION_PRODUCT_SAVE_SUCCESS';
const CASH_COLLECTION_PRODUCT_SAVE_FAIL: string = 'CASH_COLLECTION_PRODUCT_SAVE_FAIL';

const CASH_COLLECTION_PRODUCT_DELETE_SUCCESS: string = 'CASH_COLLECTION_PRODUCT_DELETE_SUCCESS';
const CASH_COLLECTION_PRODUCT_DELETE_FAIL: string = 'CASH_COLLECTION_PRODUCT_DELETE_FAIL';

const CASH_COLLECTION_PRODUCT_GET_NAMES_SUCCESS: string = 'CASH_COLLECTION_PRODUCT_GET_NAMES_SUCCESS';
const CASH_COLLECTION_PRODUCT_GET_NAMES_FAIL: string = 'CASH_COLLECTION_PRODUCT_GET_NAMES_FAIL';

const initialState = {
  editingCashCollectionProduct: {},
  cashCollectionProductSelectItems: [],
}

export function cashCollectionProductReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case CASH_COLLECTION_PRODUCT_SET_EDITING_CASH_COLLECTION_PRODUCT:
      return Object.assign({}, state, {
        editingCashCollectionProduct: action.payload
      });

    case CASH_COLLECTION_PRODUCT_GET_NAMES_SUCCESS:
      return Object.assign({}, state, {
        cashCollectionProductSelectItems: action.response?.data ?? []
      })

    default:
      return state;

  }

}

export function getListCashCollectionProduct(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CASH_COLLECTION_PRODUCT_REQUEST, CASH_COLLECTION_PRODUCT_GET_LIST_SUCCESS, CASH_COLLECTION_PRODUCT_GET_LIST_FAIL],
      EndPoint: `CashCollectionProducts${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingCashCollectionProduct(cashCollectionProduct: CashCollectionProductDTO) {
  return {
    type: CASH_COLLECTION_PRODUCT_SET_EDITING_CASH_COLLECTION_PRODUCT,
    payload: cashCollectionProduct
  }
}

export function getCashCollectionProductById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CASH_COLLECTION_PRODUCT_REQUEST, CASH_COLLECTION_PRODUCT_GET_BY_ID_SUCCESS, CASH_COLLECTION_PRODUCT_GET_BY_ID_FAIL],
      EndPoint: `CashCollectionProducts\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveCashCollectionProduct(cashCollectionProduct: CashCollectionProductDTO) {
  let initRequest: RequestInit = {
    method: cashCollectionProduct.id ? 'PATCH' : 'POST',
    body: JSON.stringify(cashCollectionProduct),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CASH_COLLECTION_PRODUCT_REQUEST, CASH_COLLECTION_PRODUCT_SAVE_SUCCESS, CASH_COLLECTION_PRODUCT_SAVE_FAIL],
      EndPoint: 'CashCollectionProducts',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteCashCollectionProduct(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CASH_COLLECTION_PRODUCT_REQUEST, CASH_COLLECTION_PRODUCT_DELETE_SUCCESS, CASH_COLLECTION_PRODUCT_DELETE_FAIL],
      EndPoint: 'CashCollectionProducts',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function getCashCollectionProductSelectItems() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CASH_COLLECTION_PRODUCT_REQUEST, CASH_COLLECTION_PRODUCT_GET_NAMES_SUCCESS, CASH_COLLECTION_PRODUCT_GET_NAMES_FAIL],
      EndPoint: 'CashCollectionProducts/selectList',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}