import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL, ClientType, Permission, TransferStatus, GlobalConst } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, CustomSelect, ImageUpload, InputText, ColumnContainer, Autocomplete, Checkbox } from '../../Components';
import { People, Save, KeyboardArrowLeft, Clear } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getTransferViettelById, saveTransferViettel, approveTransferViettel, cancelTransferViettel, updateViettelConfirmation } from '../../Redux/TransferViettel/TransferViettelReducer';
import { getClientSelectItems } from '../../Redux/Client/ClientReducer';
import { getPaymentAccountSelectItems } from '../../Redux/PaymentAccount/PaymentAccountReducer';
import { getTransferViettelSettingSelectItems } from '../../Redux/TransferViettelSetting/TransferViettelSettingReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { TransferViettelDTO, SelectItemDTO, CurrentUserDTO } from '../../DTO';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';
import { getUserSelectItems } from '../../Redux/User/UserReducer';
let moment = require('moment');

interface ITransferViettelDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  currentUser: CurrentUserDTO;
  transferViettel: TransferViettelDTO;
  clientSelectItems: SelectItemDTO[];
  paymentAccountSelectItems: SelectItemDTO[];
  transferViettelSettingSelectItems: SelectItemDTO[];
  branchSelectItems: SelectItemDTO[];
  userSelectItems: SelectItemDTO[];

  setActiveRoute: ActionCreator<any>,
  getTransferViettelById: ActionCreator<any>,
  saveTransferViettel: ActionCreator<any>,
  getClientSelectItems: ActionCreator<any>,
  getPaymentAccountSelectItems: ActionCreator<any>,
  getTransferViettelSettingSelectItems: ActionCreator<any>,
  approveTransferViettel: ActionCreator<any>,
  cancelTransferViettel: ActionCreator<any>,
  updateViettelConfirmation: ActionCreator<any>,
  getBranchSelectItems: ActionCreator<any>,
  getUserSelectItems: ActionCreator<any>,
}

interface ITransferViettelDetailStates {
  isSaving?: boolean;

  transferViettel: TransferViettelDTO;
  showValidation?: boolean;
  referenceFile?: any;
  referenceFile2?: any;
  confirmationFile?: any;
}

function mapStateToProps(state: any) {
  return ({
    transferViettel: state.transferViettel.editingTransferViettel,
    clientSelectItems: state.client.clientSelectItems,
    paymentAccountSelectItems: state.paymentAccount.paymentAccountSelectItems,
    transferViettelSettingSelectItems: state.transferViettelSetting.transferViettelSettingSelectItems,
    currentUser: state.user.currentUser,
    branchSelectItems: state.branch.branchSelectItems,
    userSelectItems: state.user.userSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getTransferViettelById: (id: number) => dispatch(getTransferViettelById(id)),
    saveTransferViettel: (transferViettel: TransferViettelDTO, submit: boolean, reference: any, reference2: any, confirmationFile: any) => dispatch(saveTransferViettel(transferViettel, submit, reference, reference2, confirmationFile)),
    getClientSelectItems: () => dispatch(getClientSelectItems()),
    getPaymentAccountSelectItems: () => dispatch(getPaymentAccountSelectItems()),
    getTransferViettelSettingSelectItems: () => dispatch(getTransferViettelSettingSelectItems()),
    approveTransferViettel: (id: number) => dispatch(approveTransferViettel(id)),
    cancelTransferViettel: (id: number) => dispatch(cancelTransferViettel(id)),
    updateViettelConfirmation: (id: number, confirmation: any) => dispatch(updateViettelConfirmation(id, confirmation)),
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
    getUserSelectItems: () => dispatch(getUserSelectItems()),
  })
}

class TransferViettelDetail extends React.Component<ITransferViettelDetailProps, ITransferViettelDetailStates> {

  constructor(props: ITransferViettelDetailProps) {
    super(props);
    this.state = {
      transferViettel: {...new TransferViettelDTO(), ...props.transferViettel}
    }
  }

  componentDidMount() {
    !this.props.clientSelectItems?.length && this.props.getClientSelectItems();
    !this.props.paymentAccountSelectItems?.length && this.props.getPaymentAccountSelectItems();
    !this.props.transferViettelSettingSelectItems?.length && this.props.getTransferViettelSettingSelectItems();
    !this.props.branchSelectItems?.length && this.props.getBranchSelectItems();
    !this.props.userSelectItems?.length && this.props.getUserSelectItems();

    const { setActiveRoute, match, getTransferViettelById, t } = this.props;

    setActiveRoute([{path: URL.TransferViettel, name: this.props.t('menu:transferViettel')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getTransferViettelById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.TransferViettel);
          } else {
            this.setState({ transferViettel: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes, clientSelectItems, paymentAccountSelectItems, currentUser, branchSelectItems, userSelectItems } = this.props;
    let { showValidation, transferViettel: { id, number, clientId, createdUser, toAccountName, toAddress, toPhoneNumber, receiveAtHome, feeAtHome, detail, amount, fee, commission, discount, buyPrice, paymentAccountId, referenceFileName, referenceFileName2, confirmationFileName, status, transferDate, note, branchId, commissionUserId, payByCash, receiveAccountId } } = this.state;

    const types = [
      { value: 1, text: t('client:type_single')},
      { value: 2, text: t('client:type_multiple')},
      { value: 3, text: t('client:type_branch')},
    ];

    const statusText = [
      { value: 0, text: t('transfer:status_draft')},
      { value: 1, text: t('transfer:status_pending_approve')},
      { value: 2, text: t('transfer:status_approved')},
      { value: 3, text: t('transfer:status_cancel')},
    ]

    let statusStr = statusText.find(s => s.value === status)?.text || '';
    let tDate = transferDate ? moment(transferDate, GlobalConst.DATE_TIME_FORMAT) : moment(new Date());
    let now = moment(new Date());
    let readOnly = (status === TransferStatus.Approved || status === TransferStatus.Canceled) && (tDate.year() * 12 + tDate.month() < now.year() * 12 + now.month());

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={People}
          iconColor={'green'}
          title={t('common:information')}>

          {id && currentUser.functionIds.includes(Permission.ApproveTransferViettels) && status === TransferStatus.PendingApprove ? 
            <ColumnContainer columnWidth={[{xs:12, sm:12}]}>
              <div className={classes.right}>
                <Button color='primary' customClass={classes.marginRight} round onClick={this._onApprove.bind(this)} disabled={this.state.isSaving}>
                  <Save className={classes.icons} /> {t('button:approve')}
                </Button>
                <Button color='danger' customClass={classes.marginRight} round onClick={this._onCancel.bind(this)} disabled={this.state.isSaving}>
                  <Clear className={classes.icons} /> {t('button:cancel')}
                </Button>
              </div>
            </ColumnContainer>
          : null }

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferViettel:number')} </FormLabel>
              <InputText values={number} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferViettel:status')} </FormLabel>
              <InputText values={statusStr} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferViettel:date')} </FormLabel>
              {!readOnly ? 
                <Datetime
                  dateFormat={GlobalConst.DATE_FORMAT}
                  timeFormat={"HH:mm"}
                  inputProps={{ placeholder: t('transferViettel:date') }}
                  onChange={this._onDateChange.bind(this)}
                  value={transferDate}
                /> :
                <InputText disabled values={transferDate} />
                }
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferViettel:user')} </FormLabel>
              <InputText disabled values={id === 0 ? currentUser.name : createdUser} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferViettel:client')} </FormLabel>
              <>
                <Autocomplete
                  value={clientId}
                  options={clientSelectItems}
                  onChange={this._onClientChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !clientId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('client:type')} </FormLabel>
              <InputText disabled values={types.find(t => t.value === clientSelectItems?.find(c => c.value === clientId)?.details.type)?.text || ''} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('client:phone')} </FormLabel>
              <InputText disabled values={clientSelectItems?.find(c => c.value === clientId)?.details.phone || ''} />
            </ColumnContainer>

            <></>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferViettel:to_account_name')} </FormLabel>
              <>
                <InputText name='toAccountName' values={toAccountName} onChange={this._onTextChange.bind(this)} disabled={readOnly} />
                {showValidation && !toAccountName && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required={receiveAtHome}> {t('transferViettel:to_address')} </FormLabel>
              <>
                <InputText name='toAddress' values={toAddress} onChange={this._onTextChange.bind(this)} disabled={readOnly} />
                {showValidation && receiveAtHome && !toAddress && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferViettel:to_phone')} </FormLabel>
              <>
                <InputText name='toPhoneNumber' values={toPhoneNumber} onChange={this._onTextChange.bind(this)} disabled={readOnly} />
                {showValidation && !toPhoneNumber && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <></>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferViettel:received_at_home')} </FormLabel>
              <Checkbox
                checked={receiveAtHome}
                name='receiveAtHome'
                onClick={this._onCheckboxChange.bind(this)}
              />
            </ColumnContainer>

            {receiveAtHome ? 
              <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
                <FormLabel className={classes.labelHorizontal} required={receiveAtHome}> {t('transferViettel:fee_at_home')} </FormLabel>
                <>
                  <InputText name='feeAtHome' values={feeAtHome} numberOnly thousandSeparator onChange={this._onNumberChange.bind(this)} disabled={readOnly} />
                  {showValidation && receiveAtHome && !feeAtHome && <Danger>{t('message:required')}</Danger>}
                </>
              </ColumnContainer>
            : <></> }

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferViettel:amount')} </FormLabel>
              <>
                <InputText name='amount' values={amount} numberOnly thousandSeparator onChange={this._onNumberChange.bind(this)} disabled={readOnly} />
                {showValidation && !amount && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferViettel:detail')} </FormLabel>
              <InputText name='detail' values={detail} onChange={this._onTextChange.bind(this)} disabled={readOnly} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferViettel:fee')} </FormLabel>
              <InputText disabled values={fee} numberOnly thousandSeparator />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferViettel:commission')} </FormLabel>
              <InputText disabled numberOnly thousandSeparator values={commission} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferViettel:buy_price')} </FormLabel>
              <InputText name='buyPrice' values={buyPrice} numberOnly thousandSeparator onChange={this._onNumberChange.bind(this)} disabled={readOnly} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferViettel:discount')} </FormLabel>
              <InputText name='discount' values={discount} numberOnly thousandSeparator onChange={this._onNumberChange.bind(this)} disabled={readOnly} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferViettel:total')} </FormLabel>
              <InputText disabled numberOnly thousandSeparator values={parseFloat('0' + amount.toString()) + parseFloat('0' + fee.toString()) + parseFloat('0' + feeAtHome.toString()) - parseFloat('0' + discount.toString())} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('paymentAccount:name')} </FormLabel>
              <>
                <CustomSelect 
                  name={'paymentAccountId'}
                  value={paymentAccountId}
                  onChange={this._onNumberChange.bind(this)}
                  items={paymentAccountSelectItems}
                  label={t('paymentAccount:name')}
                  disabled={readOnly} />
                {showValidation && !paymentAccountId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferViettel:note')} </FormLabel>
              <InputText name='note' values={note} onChange={this._onTextChange.bind(this)} disabled={readOnly} />
            </ColumnContainer>

            <></>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferViettel:branch')} </FormLabel>
              <>
                <Autocomplete
                  name='branchId'
                  value={branchId}
                  options={branchSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !branchId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferViettel:commission_user')} </FormLabel>
              <>
                <Autocomplete
                  name='commissionUserId'
                  value={commissionUserId}
                  options={userSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !commissionUserId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:4}, {xs:12, sm:4}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferViettel:reference')} </FormLabel>
              <ImageUpload 
                image={id && referenceFileName ? `/Upload/TransferViettelRef/${id}/${referenceFileName}` : null}
                onImageChange={(file: any) => this.setState({ referenceFile: file })}
                onImageRemoved={() => { let transfer = this.state.transferViettel; transfer.referenceFileName = ''; this.setState({ transferViettel: transfer }) }}
                hideButtons={readOnly} />
              <ImageUpload 
                image={id && referenceFileName2 ? `/Upload/TransferViettelRef/${id}/${referenceFileName2}` : null}
                onImageChange={(file: any) => this.setState({ referenceFile2: file })}
                onImageRemoved={() => { let transfer = this.state.transferViettel; transfer.referenceFileName2 = ''; this.setState({ transferViettel: transfer }) }}
                hideButtons={readOnly} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferViettel:confirmation')} </FormLabel>
              <ImageUpload 
                image={id && confirmationFileName ? `/Upload/TransferViettelConfirmation/${id}/${confirmationFileName}` : null}
                onImageChange={(file: any) => this.setState({ confirmationFile: file })}
                onImageRemoved={() => { let transfer = this.state.transferViettel; transfer.confirmationFileName = ''; this.setState({ transferViettel: transfer }) }} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('transferViettel:pay_by_cash')} </FormLabel>
              <Checkbox
                checked={payByCash}
                name='payByCash'
                onClick={this._onCheckboxChange.bind(this)}
              />
            </ColumnContainer>

            {payByCash ? null : <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('transferViettel:receive_account')} </FormLabel>
              <>
                <CustomSelect 
                  name={'receiveAccountId'}
                  value={receiveAccountId}
                  onChange={this._onNumberChange.bind(this)}
                  items={paymentAccountSelectItems}
                  label={t('transferViettel:receive_account')}
                  disabled={readOnly} />
                {showValidation && !receiveAccountId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>}

          </ColumnContainer>

          <GridContainer justify="center">
            {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button> : null}
            {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button> : null}
            {status === TransferStatus.Draft ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_submit')}
            </Button> : null}
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.TransferViettel)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onCheckboxChange(event: any) {
    event.persist && event.persist();
    const { target: { name, checked } } = event;
    let transfer = this.state.transferViettel;
    this.setState({ transferViettel: {...transfer, [name]: checked, feeAtHome: checked ? transfer.feeAtHome : 0 }});
  }

  private _onClientChange(value: number) {
    this.setState({ transferViettel: {...this.state.transferViettel, clientId: value }}, () => this._updateCommissionFee());
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ transferViettel: {...this.state.transferViettel, [name]: value }});
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ transferViettel: { ...this.state.transferViettel, [name]: value } }, () => { name === 'amount' && this._updateCommissionFee(); });
  }

  private _updateCommissionFee() {
    let transfer = this.state.transferViettel;
    let clientId = transfer.clientId;
    let amount = transfer.amount
    let setting = this.props.transferViettelSettingSelectItems.find(s => s.details.fromAmount <= amount && s.details.toAmount > amount);
    let client = this.props.clientSelectItems.find(c => c.value === clientId);
    this.setState({ transferViettel: {...transfer, commission: setting?.details.commission ?? 0, fee: [ClientType.Multiple, ClientType.Branch].includes(client?.details.type) ? (setting?.details.multipleSalePrice ?? 0) : (setting?.details.salePrice ?? 0)  }});
  }

  private _onDateChange(value: string | Moment) {
    let transfer = this.state.transferViettel;
    transfer.transferDate = moment(value).format(GlobalConst.DATE_TIME_FORMAT);
    this.setState({ transferViettel: transfer });
  }

  private _onValidate() {
    let { transferViettel: { clientId, toAccountName, amount, paymentAccountId, toAddress, receiveAtHome, branchId, commissionUserId, payByCash, receiveAccountId } } = this.state;

    return clientId && toAccountName && amount && paymentAccountId && (!receiveAtHome || toAddress) && branchId && commissionUserId && (payByCash || receiveAccountId);
  }

  private _onSave(submit: boolean = false, close: boolean = false) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { referenceFile, referenceFile2, confirmationFile, transferViettel: { clientId, transferDate, toAccountName, toAddress, toPhoneNumber, receiveAtHome, feeAtHome, detail, amount, fee, commission, buyPrice, discount, paymentAccountId, note, referenceFileName, referenceFileName2, branchId, commissionUserId, payByCash, receiveAccountId } } = this.state;
    let transferViettel = new TransferViettelDTO();
    transferViettel.id = parseInt(this.props.match.params.id);
    transferViettel.clientId = clientId;
    transferViettel.toAccountName = toAccountName;
    transferViettel.toAddress = toAddress;
    transferViettel.toPhoneNumber = toPhoneNumber;
    transferViettel.receiveAtHome = receiveAtHome;
    transferViettel.feeAtHome = feeAtHome;
    transferViettel.amount = amount;
    transferViettel.fee = fee;
    transferViettel.commission = commission;
    transferViettel.discount = discount;
    transferViettel.buyPrice = buyPrice;
    transferViettel.paymentAccountId = paymentAccountId;
    transferViettel.note = note;
    transferViettel.referenceFileName = referenceFileName;
    transferViettel.referenceFileName2 = referenceFileName2;
    transferViettel.detail = detail;
    transferViettel.transferDate = transferDate;
    transferViettel.branchId = branchId;
    transferViettel.commissionUserId = commissionUserId;
    transferViettel.payByCash = payByCash;
    transferViettel.receiveAccountId = payByCash ? null : receiveAccountId;

    this.props.saveTransferViettel(transferViettel, submit, referenceFile, referenceFile2, confirmationFile).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        transferViettel.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close || submit) {
          history.push(URL.TransferViettel);
        } else if (parseInt(match.params.id) === 0) {
          this.setState({ transferViettel: response.data });
          history.push(URL.TransferViettelDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

  // private _onUpdateConfirmation() {
  //   const { t } = this.props;
  //   if (this.state.confirmationFile) {
  //     this.props.updateViettelConfirmation(this.state.transferViettel.id, this.state.confirmationFile).then((response: any) => {
  //       if (response && !response.errorCode) {
  //         toast.success(t('message:save_success'));
  //       } else {
  //         showError(t, response.errorCode, response.errorMessage);
  //       }
  //     });
  //   } else {
  //     toast.warn(t('transferViettel:select_confirmation'));
  //   }
  // }

  private _onApprove() {
    const { t } = this.props;
    this.setState({ isSaving: true });

    this.props.approveTransferViettel(this.state.transferViettel.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.TransferViettel);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

  private _onCancel() {
    const { t } = this.props;
    this.setState({ isSaving: true });
    
    this.props.cancelTransferViettel(this.state.transferViettel.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.TransferViettel);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(TransferViettelDetail)));