import { FilterDTO, InventoryExportDTO } from '../../DTO';

const INVENTORY_EXPORT_REQUEST: string = 'INVENTORY_EXPORT_REQUEST';

const INVENTORY_EXPORT_GET_LIST_SUCCESS: string = 'INVENTORY_EXPORT_GET_LIST_SUCCESS';
const INVENTORY_EXPORT_GET_LIST_FAIL: string = 'INVENTORY_EXPORT_GET_LIST_FAIL';

const INVENTORY_EXPORT_SET_EDITING_INVENTORY_EXPORT: string = 'INVENTORY_EXPORT_SET_EDITING_INVENTORY_EXPORT';

const INVENTORY_EXPORT_GET_BY_ID_SUCCESS: string = 'INVENTORY_EXPORT_GET_BY_ID_SUCCESS';
const INVENTORY_EXPORT_GET_BY_ID_FAIL: string = 'INVENTORY_EXPORT_GET_BY_ID_FAIL';

const INVENTORY_EXPORT_SAVE_SUCCESS: string = 'INVENTORY_EXPORT_SAVE_SUCCESS';
const INVENTORY_EXPORT_SAVE_FAIL: string = 'INVENTORY_EXPORT_SAVE_FAIL';

const INVENTORY_EXPORT_DELETE_SUCCESS: string = 'INVENTORY_EXPORT_DELETE_SUCCESS';
const INVENTORY_EXPORT_DELETE_FAIL: string = 'INVENTORY_EXPORT_DELETE_FAIL';

const INVENTORY_EXPORT_APPROVE_SUCCESS: string = 'INVENTORY_EXPORT_APPROVE_SUCCESS';
const INVENTORY_EXPORT_APPROVE_FAIL: string = 'INVENTORY_EXPORT_APPROVE_FAIL';

const INVENTORY_EXPORT_CANCEL_SUCCESS: string = 'INVENTORY_EXPORT_CANCEL_SUCCESS';
const INVENTORY_EXPORT_CANCEL_FAIL: string = 'INVENTORY_EXPORT_CANCEL_FAIL';

const initialState = {
  editingInventoryExport: {},
}

export function inventoryExportReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case INVENTORY_EXPORT_SET_EDITING_INVENTORY_EXPORT:
      return Object.assign({}, state, {
        editingInventoryExport: action.payload
      });

    default:
      return state;

  }

}

export function getListInventoryExport(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [INVENTORY_EXPORT_REQUEST, INVENTORY_EXPORT_GET_LIST_SUCCESS, INVENTORY_EXPORT_GET_LIST_FAIL],
      EndPoint: `InventoryExports${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingInventoryExport(inventoryExport: InventoryExportDTO) {
  return {
    type: INVENTORY_EXPORT_SET_EDITING_INVENTORY_EXPORT,
    payload: inventoryExport
  }
}

export function getInventoryExportById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [INVENTORY_EXPORT_REQUEST, INVENTORY_EXPORT_GET_BY_ID_SUCCESS, INVENTORY_EXPORT_GET_BY_ID_FAIL],
      EndPoint: `InventoryExports\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveInventoryExport(inventoryExport: InventoryExportDTO, action: string) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: JSON.stringify(inventoryExport),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [INVENTORY_EXPORT_REQUEST, INVENTORY_EXPORT_SAVE_SUCCESS, INVENTORY_EXPORT_SAVE_FAIL],
      EndPoint: `InventoryExports?action=${action}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteInventoryExport(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [INVENTORY_EXPORT_REQUEST, INVENTORY_EXPORT_DELETE_SUCCESS, INVENTORY_EXPORT_DELETE_FAIL],
      EndPoint: 'InventoryExports',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function approveInventoryExport(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [INVENTORY_EXPORT_REQUEST, INVENTORY_EXPORT_APPROVE_SUCCESS, INVENTORY_EXPORT_APPROVE_FAIL],
      EndPoint: 'Inventories\\Approve',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function cancelInventoryExport(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [INVENTORY_EXPORT_REQUEST, INVENTORY_EXPORT_CANCEL_SUCCESS, INVENTORY_EXPORT_CANCEL_FAIL],
      EndPoint: 'Inventories\\Cancel',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}