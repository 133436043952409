export class TransferSettingMoMoDTO {
  public id: number;
  public name: string;
  public number: string;
  public fromAmount: number;
  public toAmount: number;
  public salePrice: number;
  public multipleSalePrice: number;
  public commission: number;
  public note: string;

  constructor() {
    this.id = 0;
    this.name = '';
    this.number = '';
    this.fromAmount = 0;
    this.toAmount = 0;
    this.salePrice = 0;
    this.multipleSalePrice = 0;
    this.commission = 0;
    this.note = '';
  }
}