import React from 'react';
import { IconCard, DataList, Button, CustomInput, ColumnContainer } from '../../Components';
import { Assignment, Edit, Replay, Add, Delete, Search } from '@material-ui/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ActionCreator } from 'redux';
import { connect } from 'react-redux';
import { getListPaySupplier, deletePaySupplier, setEditingPaySupplier } from '../../Redux/PaySupplier/PaySupplierReducer';
import { IconButton, withStyles, InputAdornment } from '@material-ui/core';
import { URL, GlobalConst, PaySupplierStatus } from '../../Consts';
import history from '../../history';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { FilterDTO, PaySupplierDTO } from '../../DTO';
import styles from '../../styles';
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from 'react-toastify';
import { currency, showError } from '../../Utils';
import _ from 'lodash';

interface IPaySupplierProps extends WithTranslation {
  classes: any;

  getPaySupplierList: ActionCreator<any>,
  setActiveRoute: ActionCreator<any>,
  setEditingPaySupplier: ActionCreator<any>,
  deletePaySupplier: ActionCreator<any>,
}

interface IPaySupplierStates {
  data: PaySupplierDTO[],
  page: number;
  pageSize: number;
  totalCount: number;

  searchString: string;
  showDeleteConfirm?: boolean;
  deletingId?: number;
}

function mapStateToProps(state: any) {
  return ({})
}

function mapDispatchToProps(dispatch: any) {
  return ({
    getPaySupplierList: (filterDTO: FilterDTO) => dispatch(getListPaySupplier(filterDTO)),
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    setEditingPaySupplier: (paySupplier: PaySupplierDTO) => dispatch(setEditingPaySupplier(paySupplier)),
    deletePaySupplier: (id: number) => dispatch(deletePaySupplier(id)),
  })
}

class PaySupplier extends React.Component<IPaySupplierProps, IPaySupplierStates> {

  constructor(props: IPaySupplierProps){
    super(props);

    this.state = {
      data: [],
      page: 0,
      pageSize: 100,
      totalCount: 0,
      searchString: '',
    }

    this._getPaySupplierWithDebounce = _.debounce(this._getPaySupplierWithDebounce.bind(this), GlobalConst.DEBOUNCE_TIME);
  }

  componentDidMount() {
    this.props.setActiveRoute([{path: URL.PaySupplier, name: this.props.t('menu:paySupplier')}]);
  }

  render() {
    const { t, classes } = this.props;
    let { data, page, pageSize, totalCount } = this.state;
    
    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={Assignment}
          iconColor={'green'}
          title={t('paySupplier:paySupplier')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>
            <CustomInput
              labelText={t('common:search')}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                value: this.state.searchString,
                name: 'searchString',
                onChange: this._onSearchStringChange.bind(this)
              }}
            />

            <div className={classes.right}>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onEdit.bind(this, new PaySupplierDTO())}>
                <Add className={classes.icons} /> {t('button:add')}
              </Button>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onRefresh.bind(this)}>
                <Replay className={classes.icons} /> {t('button:refresh')}
              </Button>
            </div>
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs:12}]}>
            <DataList 
              currentPage={page}
              pageSize={pageSize}
              totalPage={Math.ceil(totalCount / pageSize)}
              data={this._mapData(data)}
              columns={this._getColumns()}
              fetchData={this._onLoadData.bind(this)}
              onChangePage={this._onChangePage.bind(this)}
            />
          </ColumnContainer>
          
          {this.state.showDeleteConfirm && <SweetAlert
            warning
            //style={{ display: "block", marginTop: "-100px" }}
            title={t('message:confirm_delete')}
            onConfirm={this._onDelete.bind(this)}
            onCancel={() => this.setState({ showDeleteConfirm: false})}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={t('button:delete')}
            cancelBtnText={t('button:cancel')}
            showCancel
          >
          </SweetAlert>}

        </IconCard>
      </ColumnContainer>
    )
  }

  _getPaySupplierWithDebounce(filterDTO: FilterDTO) {
    this.props.getPaySupplierList(filterDTO).then((response: any) => {
      if (response && !response.errorCode) {
        this.setState({ data: response.data, totalCount: response.totalCount });
      } else {
        showError(this.props.t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _onSearchStringChange(event: any) {
    event.persist && event.persist();
    const { target: { value } } = event;
    this.setState({ searchString: value || '', page: 0 }, () => this._search());
  }

  private _search() {
    let { pageSize, searchString } = this.state;

    let filter: FilterDTO = new FilterDTO();
    filter.page = 0;
    filter.pageSize = pageSize;
    filter.searchString = searchString;

    this._getPaySupplierWithDebounce(filter);    
  }

  private _getColumns() {
    const { t } = this.props;
    return [
      { Header: t('paySupplier:number'), accessor: "number", sortable: false, filterable: false },
      { Header: t('paySupplier:supplier'), accessor: "supplierName", sortable: false, filterable: false },
      { Header: t('paySupplier:date'), accessor: "date", sortable: false, filterable: false },
      { Header: t('paySupplier:amount'), accessor: "amount", sortable: false, filterable: false },
      { Header: t('paySupplier:status'), accessor: "status", sortable: false, filterable: false },
      { Header: '', accessor: "actions", sortable: false, filterable: false }
    ]
  }

  private _onEdit(paySupplier: PaySupplierDTO) {
    this.props.setEditingPaySupplier(paySupplier);
    history.push(URL.PaySupplierDetail.replace(':id', paySupplier.id.toString()));
  }

  private _onDelete() {
    const { t } = this.props;

    this.setState({ showDeleteConfirm: false });
    this.props.deletePaySupplier(this.state.deletingId).then((response: any) => {
      if (response && !response.errorCode) {
        toast.success(t('message:delete_success'));
        this._onRefresh();
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _mapData(data: PaySupplierDTO[]) {
    const { t } = this.props;
    if (!data) return [];

    const statusText = [
      { value: PaySupplierStatus.Draft, text: t('paySupplier:status_draft')},
      { value: PaySupplierStatus.PendingApprove, text: t('paySupplier:status_pending_approval')},
      { value: PaySupplierStatus.Approved, text: t('paySupplier:status_approved')},
      { value: PaySupplierStatus.Canceled, text: t('paySupplier:status_cancelled')},
    ]

    return data.map((u: PaySupplierDTO) => {
      return {
        ...u,
        amount: currency(u.amount),
        status: statusText.find(s => s.value === u.status)?.text,
        actions: (
          <div className="actions-right">
            <IconButton className='small-action' onClick={this._onEdit.bind(this, u)}> <Edit /> </IconButton>
            <IconButton className='small-action' onClick={() => this.setState({ showDeleteConfirm: true, deletingId: u.id })}> <Delete color='error' /> </IconButton>
          </div>
        )
      }
    });
  }

  private _onLoadData(tableState: any, instance: any) {
    let filter: FilterDTO = new FilterDTO(tableState);

    this._getPaySupplierWithDebounce(filter);
  }

  private _onRefresh() {
    let filter: FilterDTO = new FilterDTO();
    filter.page = this.state.page;
    filter.pageSize = this.state.pageSize;
    filter.searchString = this.state.searchString || '';

    this._getPaySupplierWithDebounce(filter);
  }

  private _onChangePage(pageIndex: any) {
    this.setState({ page: pageIndex});
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(PaySupplier)));