import { FilterDTO, OrderDTO } from '../../DTO';

const ORDER_REQUEST: string = 'ORDER_REQUEST';

const ORDER_GET_LIST_SUCCESS: string = 'ORDER_GET_LIST_SUCCESS';
const ORDER_GET_LIST_FAIL: string = 'ORDER_GET_LIST_FAIL';

const ORDER_SET_EDITING_ORDER: string = 'ORDER_SET_EDITING_ORDER';

const ORDER_GET_BY_ID_SUCCESS: string = 'ORDER_GET_BY_ID_SUCCESS';
const ORDER_GET_BY_ID_FAIL: string = 'ORDER_GET_BY_ID_FAIL';

const ORDER_SAVE_SUCCESS: string = 'ORDER_SAVE_SUCCESS';
const ORDER_SAVE_FAIL: string = 'ORDER_SAVE_FAIL';

const ORDER_DELETE_SUCCESS: string = 'ORDER_DELETE_SUCCESS';
const ORDER_DELETE_FAIL: string = 'ORDER_DELETE_FAIL';

const initialState = {
  editingOrder: {},
}

export function orderReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case ORDER_SET_EDITING_ORDER:
      return Object.assign({}, state, {
        editingOrder: action.payload
      });

    default:
      return state;

  }

}

export function getListOrder(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [ORDER_REQUEST, ORDER_GET_LIST_SUCCESS, ORDER_GET_LIST_FAIL],
      EndPoint: `Orders${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingOrder(order: OrderDTO) {
  return {
    type: ORDER_SET_EDITING_ORDER,
    payload: order
  }
}

export function getOrderById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [ORDER_REQUEST, ORDER_GET_BY_ID_SUCCESS, ORDER_GET_BY_ID_FAIL],
      EndPoint: `Orders\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveOrder(order: OrderDTO) {
  let initRequest: RequestInit = {
    method: order.id ? 'PATCH' : 'POST',
    body: JSON.stringify(order),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [ORDER_REQUEST, ORDER_SAVE_SUCCESS, ORDER_SAVE_FAIL],
      EndPoint: 'Orders',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteOrder(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [ORDER_REQUEST, ORDER_DELETE_SUCCESS, ORDER_DELETE_FAIL],
      EndPoint: 'Orders',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}