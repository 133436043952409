import { GlobalConst } from "../Consts";

let moment = require('moment');

export class IncomeDTO {
  public id: number;
  public number: string;
  public date: string;
  public isCash: boolean;
  public amount: number;
  public detail: string;
  public paymentAccountId: number | null;
  public fromBranchId: number;
  public status: number;
  public commissionUserId: number;
  public branchId: number;
  public createdBy: number;
  public approvedById: number | null;

  constructor() {
    this.id = 0;
    this.number = '';
    this.date = moment().format(GlobalConst.DATE_TIME_FORMAT);
    this.isCash = false;
    this.amount = 0;
    this.detail = '';
    this.paymentAccountId = null;
    this.fromBranchId = 0;
    this.status = 0;
    this.commissionUserId = 0;
    this.branchId = 0;
    this.createdBy = 0;
    this.approvedById = null;
  }
}