
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL, MessageType, MessageStatus } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer, CustomSelect, Checkbox } from '../../Components';
import { Message as MessageIcon, Save, KeyboardArrowLeft } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getMessageById, saveMessage } from '../../Redux/Message/MessageReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { MessageDTO } from '../../DTO';

interface IMessageDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  message: MessageDTO;

  setActiveRoute: ActionCreator<any>,
  getMessageById: ActionCreator<any>,
  saveMessage: ActionCreator<any>,
}

interface IMessageDetailStates {
  isSaving?: boolean;

  message: MessageDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    message: state.message.editingMessage,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getMessageById: (id: number) => dispatch(getMessageById(id)),
    saveMessage: (message: MessageDTO) => dispatch(saveMessage(message)),
  })
}

class MessageDetail extends React.Component<IMessageDetailProps, IMessageDetailStates> {

  constructor(props: IMessageDetailProps) {
    super(props);
    this.state = {
      message: {...new MessageDTO(), ...props.message}
    }
  }

  componentDidMount() {
    const { setActiveRoute, match, getMessageById, t } = this.props;

    setActiveRoute([{path: URL.Message, name: this.props.t('menu:message')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getMessageById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.Message);
          } else {
            this.setState({ message: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes } = this.props;
    let { showValidation, message: { subject, content, to, cc, bcc, type, isHTML, status } } = this.state;

    let messageTypeSelectItems = [
      { value: MessageType.Email.toString(), text: t('common:email') },
      { value: MessageType.Sms.toString(), text: t('common:sms') },
    ]

    let messageStatusSelectItems = [
      { value: MessageStatus.Sending.toString(), text: t('common:processing') },
      { value: MessageStatus.Success.toString(), text: t('common:success') },
      { value: MessageStatus.Fail.toString(), text: t('common:fail') },
      { value: MessageStatus.Cancel.toString(), text: t('common:cancel') },
    ]
    
    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={MessageIcon}
          iconColor={'green'}
          title={t('common:information')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('message_history:type')} </FormLabel>
              <CustomSelect 
                name={'type'}
                value={type}
                onChange={this._onNumberChange.bind(this)}
                items={messageTypeSelectItems}
                label={t('message_history:type')} />
            </ColumnContainer>

            {this.state.message.id > 0 ? 
            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('message_history:status')} </FormLabel>
              <InputText values={messageStatusSelectItems.find(s => s.value === status.toString())?.text ?? ''} disabled />
            </ColumnContainer> : <></>}

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('message_history:subject')} </FormLabel>
              <>
                <InputText name='subject' values={subject} onChange={this._onTextChange.bind(this)} />
                {showValidation && !subject && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('message_history:content')} </FormLabel>
              <>
                <InputText name='content' values={content} onChange={this._onTextChange.bind(this)} />
                {showValidation && !content && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('message_history:isHTML')} </FormLabel>
              <Checkbox
                checked={isHTML}
                name='isHTML'
                onClick={this._onCheckboxChange.bind(this)}
              />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('message_history:to')} </FormLabel>
              <>
                <InputText name='to' values={to} onChange={this._onTextChange.bind(this)} />
                {showValidation && !to && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('message_history:cc')} </FormLabel>
              <>
                <InputText name='cc' values={cc} onChange={this._onTextChange.bind(this)} />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('message_history:bcc')} </FormLabel>
              <>
                <InputText name='bcc' values={bcc} onChange={this._onTextChange.bind(this)} />
              </>
            </ColumnContainer>

          </ColumnContainer>

          <GridContainer justify="center">
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button>
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button>
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.Message)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ message: {...this.state.message, [name]: value }});    
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ message: {...this.state.message, [name]: value }});    
  }

  private _onCheckboxChange(event: any) {
    event.persist && event.persist();
    const { target: { name, checked } } = event;
    let message = this.state.message;
    this.setState({ message: {...message, [name]: checked }});
  }

  private _onValidate() {
    let { message: { subject, content, to } } = this.state;

    return subject && content && to;
  }

  private _onSave(close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { message: { subject, content, to, cc, bcc, type, isHTML, noTried, status } } = this.state;
    let message = new MessageDTO();
    message.id = parseInt(this.props.match.params.id);
    message.subject = subject;
    message.content = content;
    message.to = to;
    message.cc = cc;
    message.bcc = bcc;
    message.type = type;
    message.isHTML = isHTML;
    message.noTried = noTried;
    message.status = status;

    this.props.saveMessage(message).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        message.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close) {
          history.push(URL.Message);
        } else if (parseInt(match.params.id) === 0) {
          history.push(URL.MessageDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(MessageDetail)));