import React from 'react';
import { IconCard, DataList, Button, CustomInput, ColumnContainer } from '../../Components';
import { Assignment, Edit, Replay, Add, Delete, Search, GetApp } from '@material-ui/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ActionCreator } from 'redux';
import { connect } from 'react-redux';
import { getListProduct, deleteProduct, setEditingProduct, exportProduct } from '../../Redux/Product/ProductReducer';
import { IconButton, withStyles, InputAdornment } from '@material-ui/core';
import { URL, GlobalConst } from '../../Consts';
import history from '../../history';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { FilterDTO, ProductDTO, SelectItemDTO } from '../../DTO';
import styles from '../../styles';
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from 'react-toastify';
import { showError, currency } from '../../Utils';
import _ from 'lodash';

interface IProductProps extends WithTranslation {
  classes: any;

  supplierSelectItems: SelectItemDTO[],

  getProductList: ActionCreator<any>,
  setActiveRoute: ActionCreator<any>,
  setEditingProduct: ActionCreator<any>,
  deleteProduct: ActionCreator<any>,
  exportProduct: ActionCreator<any>,
}

interface IProductStates {
  data: ProductDTO[],
  page: number;
  pageSize: number;
  totalCount: number;

  searchString?: string;
  showDeleteConfirm?: boolean;
  deletingId?: number;

  isExporting?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    supplierSelectItems: state.supplier.supplierSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    getProductList: (filterDTO: FilterDTO) => dispatch(getListProduct(filterDTO)),
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    setEditingProduct: (product: ProductDTO) => dispatch(setEditingProduct(product)),
    deleteProduct: (id: number) => dispatch(deleteProduct(id)),
    exportProduct: () => dispatch(exportProduct()),
  })
}

class Product extends React.Component<IProductProps, IProductStates> {

  constructor(props: IProductProps){
    super(props);

    this.state = {
      data: [],
      page: 0,
      pageSize: 100,
      totalCount: 0,
    }

    this._getProductWithDebounce = _.debounce(this._getProductWithDebounce.bind(this), GlobalConst.DEBOUNCE_TIME);
  }

  componentDidMount() {
    this.props.setActiveRoute([{path: URL.Product, name: this.props.t('menu:product')}]);
  }

  render() {
    const { t, classes } = this.props;
    let { data, page, pageSize, totalCount, isExporting } = this.state;
    
    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={Assignment}
          iconColor={'green'}
          title={t('product:product')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>
            <CustomInput
              labelText={t('common:search')}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                value: this.state.searchString,
                name: 'searchString',
                onChange: this._search.bind(this)
              }}
            />

            <div className={classes.right}>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onEdit.bind(this, new ProductDTO())}>
                <Add className={classes.icons} /> {t('button:add')}
              </Button>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onRefresh.bind(this)}>
                <Replay className={classes.icons} /> {t('button:refresh')}
              </Button>
              <Button color='primary' customClass={classes.marginRight} disabled={isExporting} round onClick={this._onExport.bind(this)}>
                <GetApp className={classes.icons} /> {t('button:excel')}
              </Button>
            </div>
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs:12}]}>
            <DataList 
              currentPage={page}
              pageSize={pageSize}
              totalPage={Math.ceil(totalCount / pageSize)}
              data={this._mapData(data)}
              columns={this._getColumns()}
              fetchData={this._onLoadData.bind(this)}
              onChangePage={this._onChangePage.bind(this)}
            />
          </ColumnContainer>

          {this.state.showDeleteConfirm && <SweetAlert
            warning
            //style={{ display: "block", marginTop: "-100px" }}
            title={t('message:confirm_delete')}
            onConfirm={this._onDelete.bind(this)}
            onCancel={() => this.setState({ showDeleteConfirm: false})}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={t('button:delete')}
            cancelBtnText={t('button:cancel')}
            showCancel
          >
          </SweetAlert>}

        </IconCard>
      </ColumnContainer>
    )
  }

  _getProductWithDebounce(filterDTO: FilterDTO) {
    this.props.getProductList(filterDTO).then((response: any) => {
      if (response && !response.errorCode) {
        this.setState({ data: response.data, totalCount: response.totalCount });
      } else {
        showError(this.props.t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _search(event: any) {
    event.persist();
    const { target: { value } } = event;
    this.setState({ searchString: value, page: 0 });

    let filter: FilterDTO = new FilterDTO();
    filter.page = 0;
    filter.pageSize = this.state.pageSize;
    filter.searchString = value;

    this._getProductWithDebounce(filter);    
  }

  private _getColumns() {
    const { t } = this.props;
    return [
      { Header: t('product:name'), accessor: "name", sortable: false, filterable: false },
      { Header: t('product:multiple_sale_price'), accessor: "multipleSalePrice", sortable: false, filterable: false },
      { Header: t('product:sale_price'), accessor: "salePrice", sortable: false, filterable: false },
      { Header: t('product:type'), accessor: "isService", sortable: false, filterable: false },
      { Header: t('product:note'), accessor: "note", sortable: false, filterable: false },
      { Header: '', accessor: "actions", sortable: false, filterable: false }
    ]
  }

  private _onEdit(product: ProductDTO) {
    this.props.setEditingProduct(product);
    history.push(URL.ProductDetail.replace(':id', product.id.toString()));
  }

  private _onDelete() {
    const { t } = this.props;

    this.setState({ showDeleteConfirm: false });
    this.props.deleteProduct(this.state.deletingId).then((response: any) => {
      if (response && !response.errorCode) {
        toast.success(t('message:delete_success'));
        this._onRefresh();
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _mapData(data: ProductDTO[]) {
    const { t } = this.props;
    if (!data) return [];

    return data.map((p: ProductDTO) => {
      return {
        ...p,
        multipleSalePrice: currency(p.multipleSalePrice),
        salePrice: currency(p.salePrice),
        isService: p.isService ? t('product:service') : t('product:product'),
        actions: (
          <div className="actions-right">
            <IconButton className='small-action' onClick={this._onEdit.bind(this, p)}> <Edit /> </IconButton>
            <IconButton className='small-action' onClick={() => this.setState({ showDeleteConfirm: true, deletingId: p.id })}> <Delete color='error' /> </IconButton>
          </div>
        )
      }
    });
  }

  private _onLoadData(tableState: any, instance: any) {
    let filter: FilterDTO = new FilterDTO(tableState);

    this._getProductWithDebounce(filter);
  }

  private _onRefresh() {
    let filter: FilterDTO = new FilterDTO();
    filter.page = this.state.page;
    filter.pageSize = this.state.pageSize;
    filter.searchString = this.state.searchString || '';

    this._getProductWithDebounce(filter);
  }

  private _onExport() {
    this.setState({ isExporting: true });
    this.props.exportProduct().then((response: any) => {
      this.setState({ isExporting: false });
      if (response && !response.errorCode) {
        window.open(response.data, '_blank');
      } else {
        showError(this.props.t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _onChangePage(pageIndex: any) {
    this.setState({ page: pageIndex});
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(Product)));