import { FilterDTO, FunctionDTO } from '../../DTO';

const FUNCTION_REQUEST: string = 'FUNCTION_REQUEST';

const FUNCTION_GET_LIST_SUCCESS: string = 'FUNCTION_GET_LIST_SUCCESS';
const FUNCTION_GET_LIST_FAIL: string = 'FUNCTION_GET_LIST_FAIL';

const FUNCTION_SET_EDITING_FUNCTION: string = 'FUNCTION_SET_EDITING_FUNCTION';

const FUNCTION_GET_BY_ID_SUCCESS: string = 'FUNCTION_GET_BY_ID_SUCCESS';
const FUNCTION_GET_BY_ID_FAIL: string = 'FUNCTION_GET_BY_ID_FAIL';

const FUNCTION_SAVE_SUCCESS: string = 'FUNCTION_SAVE_SUCCESS';
const FUNCTION_SAVE_FAIL: string = 'FUNCTION_SAVE_FAIL'

const FUNCTION_DELETE_SUCCESS: string = 'FUNCTION_DELETE_SUCCESS';
const FUNCTION_DELETE_FAIL: string = 'FUNCTION_DELETE_FAIL';

const FUNCTION_GET_NAMES_SUCCESS: string = 'FUNCTION_GET_NAMES_SUCCESS';
const FUNCTION_GET_NAMES_FAIL: string = 'FUNCTION_GET_NAMES_FAIL';

const initialState = {
  editingFunction: {},

  functionSelectItems: [],
}

export function functionReducer(state = initialState, action: any) {

  switch (action.type) {

    case FUNCTION_GET_NAMES_SUCCESS:
      return Object.assign({}, state, {
        functionSelectItems: action.response?.data ?? []
      })

    default:
      return state;

  }

}

export function getListFunction(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [FUNCTION_REQUEST, FUNCTION_GET_LIST_SUCCESS, FUNCTION_GET_LIST_FAIL],
      EndPoint: `Functions${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingFunction(func: FunctionDTO) {
  return {
    type: FUNCTION_SET_EDITING_FUNCTION,
    payload: func
  }
}

export function getFunctionById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [FUNCTION_REQUEST, FUNCTION_GET_BY_ID_SUCCESS, FUNCTION_GET_BY_ID_FAIL],
      EndPoint: `Functions\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveFunction(func: FunctionDTO) {
  let initRequest: RequestInit = {
    method: func.id ? 'PATCH' : 'POST',
    body: JSON.stringify(func),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [FUNCTION_REQUEST, FUNCTION_SAVE_SUCCESS, FUNCTION_SAVE_FAIL],
      EndPoint: 'Functions',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteFunction(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [FUNCTION_REQUEST, FUNCTION_DELETE_SUCCESS, FUNCTION_DELETE_FAIL],
      EndPoint: 'Functions',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function getFunctionSelectItems() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [FUNCTION_REQUEST, FUNCTION_GET_NAMES_SUCCESS, FUNCTION_GET_NAMES_FAIL],
      EndPoint: 'Functions/selectList',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}