import { FilterDTO, SimStyleDTO } from '../../DTO';

const SIM_STYLE_REQUEST: string = 'SIM_STYLE_REQUEST';

const SIM_STYLE_GET_LIST_SUCCESS: string = 'SIM_STYLE_GET_LIST_SUCCESS';
const SIM_STYLE_GET_LIST_FAIL: string = 'SIM_STYLE_GET_LIST_FAIL';

const SIM_STYLE_SET_EDITING_SIM_STYLE: string = 'SIM_STYLE_SET_EDITING_SIM_STYLE';

const SIM_STYLE_GET_BY_ID_SUCCESS: string = 'SIM_STYLE_GET_BY_ID_SUCCESS';
const SIM_STYLE_GET_BY_ID_FAIL: string = 'SIM_STYLE_GET_BY_ID_FAIL';

const SIM_STYLE_SAVE_SUCCESS: string = 'SIM_STYLE_SAVE_SUCCESS';
const SIM_STYLE_SAVE_FAIL: string = 'SIM_STYLE_SAVE_FAIL';

const SIM_STYLE_DELETE_SUCCESS: string = 'SIM_STYLE_DELETE_SUCCESS';
const SIM_STYLE_DELETE_FAIL: string = 'SIM_STYLE_DELETE_FAIL';

const SIM_STYLE_GET_NAMES_SUCCESS: string = 'SIM_STYLE_GET_NAMES_SUCCESS';
const SIM_STYLE_GET_NAMES_FAIL: string = 'SIM_STYLE_GET_NAMES_SUCCESS';

const initialState = {
  editingSimStyle: {},
  simStyleSelectItems: null
}

export function simStyleReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case SIM_STYLE_SET_EDITING_SIM_STYLE:
      return Object.assign({}, state, {
        editingSimStyle: action.payload
      });

    case SIM_STYLE_GET_NAMES_SUCCESS:
      return Object.assign({}, state, {
        simStyleSelectItems: action.response?.data ?? []
      })

    default:
      return state;

  }

}

export function getListSimStyle(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SIM_STYLE_REQUEST, SIM_STYLE_GET_LIST_SUCCESS, SIM_STYLE_GET_LIST_FAIL],
      EndPoint: `SimStyles${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingSimStyle(simStyle: SimStyleDTO) {
  return {
    type: SIM_STYLE_SET_EDITING_SIM_STYLE,
    payload: simStyle
  }
}

export function getSimStyleById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SIM_STYLE_REQUEST, SIM_STYLE_GET_BY_ID_SUCCESS, SIM_STYLE_GET_BY_ID_FAIL],
      EndPoint: `SimStyles\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveSimStyle(simStyle: SimStyleDTO) {
  let initRequest: RequestInit = {
    method: simStyle.id ? 'PATCH' : 'POST',
    body: JSON.stringify(simStyle),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SIM_STYLE_REQUEST, SIM_STYLE_SAVE_SUCCESS, SIM_STYLE_SAVE_FAIL],
      EndPoint: 'SimStyles',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteSimStyle(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SIM_STYLE_REQUEST, SIM_STYLE_DELETE_SUCCESS, SIM_STYLE_DELETE_FAIL],
      EndPoint: 'SimStyles',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function getSimStyleSelectItems() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [SIM_STYLE_REQUEST, SIM_STYLE_GET_NAMES_SUCCESS, SIM_STYLE_GET_NAMES_FAIL],
      EndPoint: 'SimStyles/selectList',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}