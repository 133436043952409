export class UserDTO {
  public id: number;
  public number: string;
  public name: string;
  public loginName: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone: string;
  public password: string;
  public repeatPassword: string;
  public branchId: number;
  public roleIds: number[];
  public avatar: string;
  public salary: number;
  public startDate: string;
  public endDate: string;

  constructor() {
    this.id = 0;
    this.number = '';
    this.name = '';
    this.loginName = '';
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.phone = '';
    this.password = '';
    this.repeatPassword = '';
    this.branchId = 0;
    this.roleIds = [];
    this.avatar = '';
    this.salary = 0;
    this.startDate = '';
    this.endDate = '';
  }
}

export function userToFormData(user: UserDTO) {
  let formData = new FormData();
  formData.append('id', user.id.toString());
  formData.append('number', user.number ?? '');
  formData.append('name', user.name ?? '');
  formData.append('loginName', user.loginName ?? '');
  formData.append('firstName', user.firstName ?? '');
  formData.append('lastName', user.lastName ?? '');
  formData.append('email', user.email ?? '');
  formData.append('phone', user.phone ?? '');
  formData.append('password', user.password ?? '');
  formData.append('branchId', user.branchId.toString());
  formData.append('avatar', user.avatar ?? '');
  formData.append('salary', user.salary.toString());
  formData.append('startDate', user.startDate ?? '');
  formData.append('endDate', user.endDate ?? '');
  if (user.roleIds) {
    user.roleIds.forEach(id => {
      formData.append('roleIds', id.toString());
    });
  }
  return formData;
}