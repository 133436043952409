import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import iconCardStyle from './IconCardStyle';
import { withStyles, Card, CardHeader, CardContent } from "@material-ui/core";

function IconCard({ ...props }) {
  const {
    classes,
    title,
    iconColor,
    category,
    footer,
    plain,
    customCardContentClass
  } = props;
  const cardClasses =
    classes.card +
    " " +
    cx({
      [classes.cardPlain]: plain
    });
  const cardContentClasses =
    classes.cardContent +
    " " +
    cx({
      [customCardContentClass]: customCardContentClass !== undefined
    });

  return (
    <Card className={cardClasses}>
      <CardHeader
        classes={{
          root: classes.cardHeader + " " + classes[iconColor + "CardHeader"],
          avatar: classes.cardAvatar
        }}
        avatar={<props.icon className={classes.cardIcon} />}
      />
      <CardContent className={cardContentClasses}>
        <h4 className={classes.cardTitle}>
          {title}
          {category !== undefined ? (
            <small className={classes.cardCategory}>{category}</small>
          ) : null}
        </h4>
        {props.children}
      </CardContent>
      {footer !== undefined ? (
        <div className={classes.cardFooter}>{footer}</div>
      ) : null}
    </Card>
  )
}

IconCard.defaultProps = {
  iconColor: "purple",
};

IconCard.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.object.isRequired,
  iconColor: PropTypes.oneOf([
    "orange",
    "green",
    "red",
    "blue",
    "purple",
    "rose"
  ]),
  title: PropTypes.node,
  category: PropTypes.node,
  footer: PropTypes.node,
  plain: PropTypes.bool,
  customCardContentClass: PropTypes.string
};

export default withStyles(iconCardStyle)(IconCard);