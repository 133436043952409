import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { 
  Dashboard as DashboardPage,
  Login,
  User,
  UserDetail,
  UserInfo,
  Function,
  FunctionDetail,
  Role,
  RoleDetail,
  Supplier,
  SupplierDetail,
  Product,
  ProductDetail,
  Branch,
  BranchDetail,
  Client,
  ClientDetail,
  NotFound,
  // Order,
  // OrderDetail,
  PaymentAccount,
  PaymentAccountDetail,
  Commission,
  Transfer,
  TransferDetail,
  TransferSetting,
  TransferSettingDetail,
  Bank,
  BankDetail,
  TransferViettel,
  TransferViettelDetail,
  TransferViettelSetting,
  TransferViettelSettingDetail,
  AppDownload,
  ReceiveViettel,
  ReceiveViettelDetail,
  TransferMoMo,
  TransferMoMoDetail,
  TransferSettingMoMo,
  TransferSettingMoMoDetail,
  CashCollection,
  CashCollectionDetail,
  CashCollectionSetting,
  CashCollectionSettingDetail,
  BuySim,
  BuySimDetail,
  SellSim,
  SellSimDetail,
  TopUp,
  TopUpDetail,
  NetworkProvider,
  NetworkProviderDetail,
  TopUpType,
  TopUpTypeDetail,
  TelecomService,
  TelecomServiceDetail,
  Sim,
  SimDetail,
  Message,
  MessageDetail,
  CashCollectionProduct,
  CashCollectionProductDetail,
  AutoNumber,
  AutoNumberDetail,
  SimStyle,
  SimStyleDetail,
  Expense,
  ExpenseDetail,
  Income,
  IncomeDetail,
  Version,
  VersionDetail,
  Changelog,
  SellTelecomService,
  SellTelecomServiceDetail,
  GameProvider,
  GameProviderDetail,
  Card,
  CardDetail,
  Workflow,
  WorkflowDetail,
  InventoryImport,
  InventoryImportDetail,
  InventoryExport,
  InventoryExportDetail,
  SellProduct,
  SellProductDetail,
  Loan,
  LoanDetail,
  PaySupplier,
  PaySupplierDetail,
  // BBS Import Page
} from './Pages';
import {
  Dashboard,
  Person,
  People,
  Web,
  StoreMallDirectory,
  Widgets,
  AccountBalanceWallet,
  AttachMoney,
  Settings,
  AccountBalance,
  GetApp,
  PhoneIphone,
  LocalAtm,
  Message as MessageIcon,
  Assignment,
  Code,
  Money,
  SwapHoriz,
  Ballot,
  SportsEsports,
  Timeline
} from '@material-ui/icons'
import {
  LoginLayout,
  MainLayout
} from './Components';
import history from './history';
import { URL } from './Consts';
import { useSelector } from 'react-redux'

const DetailRoutes = [
  { path: URL.UserDetail, component: UserDetail },
  { path: URL.FunctionDetail, component: FunctionDetail },
  { path: URL.RoleDetail, component: RoleDetail },
  { path: URL.SupplierDetail, component: SupplierDetail },
  { path: URL.ProductDetail, component: ProductDetail },
  { path: URL.BranchDetail, component: BranchDetail },
  { path: URL.ClientDetail, component: ClientDetail },
  { path: URL.PaymentAccountDetail, component: PaymentAccountDetail },
  { path: URL.TransferDetail, component: TransferDetail },
  { path: URL.TransferSettingDetail, component: TransferSettingDetail },
  { path: URL.BankDetail, component: BankDetail },
  { path: URL.TransferViettelDetail, component: TransferViettelDetail },
  { path: URL.TransferViettelSettingDetail, component: TransferViettelSettingDetail },
  { path: URL.ReceiveViettelDetail, component: ReceiveViettelDetail },
  { path: URL.TransferMoMoDetail, component: TransferMoMoDetail },
  { path: URL.TransferSettingMoMoDetail, component: TransferSettingMoMoDetail },
  { path: URL.CashCollectionDetail, component: CashCollectionDetail },
  { path: URL.CashCollectionSettingDetail, component: CashCollectionSettingDetail },
  { path: URL.BuySimDetail, component: BuySimDetail },
  { path: URL.SellSimDetail, component: SellSimDetail },
  { path: URL.TopUpDetail, component: TopUpDetail },
  { path: URL.NetworkProviderDetail, component: NetworkProviderDetail },
  { path: URL.TopUpTypeDetail, component: TopUpTypeDetail },
  { path: URL.TelecomServiceDetail, component: TelecomServiceDetail },
  { path: URL.SimDetail, component: SimDetail },
  { path: URL.MessageDetail, component: MessageDetail },
  { path: URL.CashCollectionProductDetail, component: CashCollectionProductDetail },
  { path: URL.AutoNumberDetail, component: AutoNumberDetail },
  { path: URL.SimStyleDetail, component: SimStyleDetail },
  { path: URL.ExpenseDetail, component: ExpenseDetail },
  { path: URL.IncomeDetail, component: IncomeDetail },
  { path: URL.VersionDetail, component: VersionDetail },
  { path: URL.SellTelecomServiceDetail, component: SellTelecomServiceDetail },
  { path: URL.GameProviderDetail, component: GameProviderDetail },
  { path: URL.CardDetail, component: CardDetail },
  { path: URL.WorkflowDetail, component: WorkflowDetail },
  { path: URL.InventoryImportDetail, component: InventoryImportDetail },
  { path: URL.InventoryExportDetail, component: InventoryExportDetail },
  { path: URL.SellProductDetail, component: SellProductDetail },
  { path: URL.LoanDetail, component: LoanDetail },
  { path: URL.PaySupplierDetail, component: PaySupplierDetail },
  // BBS Detail Admin Routes
  { path: URL.Changelog, component: Changelog },
  { path: URL.UserInfo, component: UserInfo },
  { path: URL.NotFound, component: NotFound },
]

export const AdminRoutes = [
  { id: 0, path: URL.Dashboard, name: "menu:dashboard", icon: Dashboard, component: DashboardPage },
  { id: -1, path: '#', paths: [URL.User, URL.Function, URL.Role], name: 'menu:manage_user', icon: Settings, collapse: true, state: 'openManageUser', 
    children: [
      { id: 2, path: URL.User, name: "menu:user", icon: Person, component: User },
      { id: 3, path: URL.Function, name: "menu:function", icon: Web, component: Function },
      { id: 4, path: URL.Role, name: "menu:role", icon: People, component: Role },
    ] },
  { id: -2, path: '#', paths: [URL.Branch, URL.Supplier, URL.Client, URL.PaymentAccount, URL.Commission], name: 'menu:tenant_setting', icon: People, collapse: true, state: 'openSetting', 
    children: [
      { id: 6, path: URL.Branch, name: 'menu:branch', icon: StoreMallDirectory, component: Branch },
      { id: 5, path: URL.Supplier, name: "menu:supplier", icon: People, component: Supplier },
      { id: 7, path: URL.Client, name: 'menu:client', icon: People, component: Client },
      { id: 9, path: URL.PaymentAccount, name: 'menu:paymentAccount', icon: AccountBalanceWallet, component: PaymentAccount },
      { id: 10, path: URL.Commission, name: 'menu:commission', icon: AttachMoney, component: Commission },
      { id: 14, path: URL.Bank, name: 'menu:bank', icon: AccountBalance, component: Bank },
      { id: 35, path: URL.AutoNumber, name: 'menu:autoNumber', icon: Code, component: AutoNumber },
    ] },
  { id: -3, path: '#', paths: [URL.Transfer, URL.TransferViettel, URL.ReceiveViettel, URL.TransferSetting, URL.TransferViettelSetting], name: 'menu:transfer_group', icon: AccountBalance, collapse: true, state: 'openTransferGroup',
    children: [
      { id: 11, path: URL.Transfer, name: 'menu:transfer', icon: AccountBalance, component: Transfer },
      { id: 21, path: URL.TransferMoMo, name: 'menu:transferMoMo', icon: AccountBalance, component: TransferMoMo },
      { id: 16, path: URL.TransferViettel, name: 'menu:transferViettel', icon: AccountBalance, component: TransferViettel },
      { id: 19, path: URL.ReceiveViettel, name: 'menu:receiveViettel', icon: AccountBalance, component: ReceiveViettel },
      { id: 12, path: URL.TransferSetting, name: 'menu:transferSetting', icon: Settings, component: TransferSetting },
      { id: 22, path: URL.TransferSettingMoMo, name: 'menu:transferSettingMoMo', icon: Settings, component: TransferSettingMoMo },
      { id: 15, path: URL.TransferViettelSetting, name: 'menu:transferViettelSetting', icon: Settings, component: TransferViettelSetting },
    ] },
  { id: -4, path: '#', paths: [URL.CashCollection, URL.CashCollectionSetting], name: 'menu:cash_collection_group', icon: AccountBalance, collapse: true, state: 'openCashCollectionGroup',
    children: [
      { id: 24, path: URL.CashCollection, name: 'menu:cashCollection', icon: LocalAtm, component: CashCollection },
      { id: 25, path: URL.CashCollectionSetting, name: 'menu:cashCollectionSetting', icon: Settings, component: CashCollectionSetting },
      { id: 34, path: URL.CashCollectionProduct, name: 'menu:cashCollectionProduct', icon: Assignment, component: CashCollectionProduct },
      { id: 55, path: URL.Loan, name: 'menu:loan', icon: Money, component: Loan },
      { id: 57, path: URL.PaySupplier, name: 'menu:paySupplier', icon: Money, component: PaySupplier },
    ] },
  { id: -5, path: '#', paths: [ URL.NetworkProvider, URL.SimStyle, URL.BuySim, URL.TopUp, URL.TopUpType, URL.GameProvider ], name: 'menu:sim_card_group', icon: PhoneIphone, collapse: true, state: 'openSimCardGroup',
    children: [
      { id: 33, path: URL.Sim, name: 'menu:sim', icon: PhoneIphone, component: Sim },
      { id: 27, path: URL.BuySim, name: 'menu:buySim', icon: PhoneIphone, component: BuySim },
      { id: 28, path: URL.SellSim, name: 'menu:sellSim', icon: PhoneIphone, component: SellSim },
      { id: 29, path: URL.TopUp, name: 'menu:topUp', icon: Money, component: TopUp },
      { id: 47, path: URL.Card, name: 'menu:card', icon: People, component: Card },
      { id: 42, path: URL.SimStyle, name: 'menu:simStyle', icon: People, component: SimStyle },
      { id: 30, path: URL.NetworkProvider, name: 'menu:networkProvider', icon: People, component: NetworkProvider },
      { id: 31, path: URL.TopUpType, name: 'menu:topUpType', icon: People, component: TopUpType },
      { id: 46, path: URL.GameProvider, name: 'menu:gameProvider', icon: SportsEsports, component: GameProvider },
    ] },
  { id: -6, path: '#', paths: [URL.Expense, URL.Income], name: 'menu:expense_group', icon: SwapHoriz, collapse: true, state: 'openExpenseGroup',
    children: [
      { id: 37, path: URL.Expense, name: 'menu:expense', icon: SwapHoriz, component: Expense },
      { id: 40, path: URL.Income, name: 'menu:income', icon: SwapHoriz, component: Income },
    ] },
  { id: -7, path: '#', paths: [URL.TelecomService, URL.SellTelecomService], name: 'menu:service_group', icon: Ballot, collapse: true, state: 'openServiceGroup',
    children: [
      { id: 44, path: URL.SellTelecomService, name: 'menu:sellTelecomService', icon: Settings, component: SellTelecomService },
      { id: 32, path: URL.TelecomService, name: 'menu:telecomService', icon: Ballot, component: TelecomService },
    ] },
  { id: -8, path: '#', paths: [], name: 'menu:product_group', icon: Ballot, collapse: true, state: 'openProductGroup',
    children: [
      { id: 8, path: URL.Product, name: 'menu:product', icon: Widgets, component: Product },
      { id: 49, path: URL.InventoryImport, name: 'menu:inventoryImport', icon: Widgets, component: InventoryImport },
      { id: 51, path: URL.InventoryExport, name: 'menu:inventoryExport', icon: Widgets, component: InventoryExport },
      { id: 53, path: URL.SellProduct, name: 'menu:sellProduct', icon: Widgets, component: SellProduct },
    ]
  },
  { id: 2147483645, path: URL.Workflow, name: 'menu:workflow', icon: Timeline, component: Workflow },
  // BBS Admin Routes
  { id: 18, path: URL.AppDownload, name: 'menu:appDownload', icon: GetApp, component: AppDownload },
  { id: 2147483646, path: URL.Version, name: 'menu:version', icon: People, component: Version },
  { id: 2147483647, path: URL.Message, name: 'menu:message', icon: MessageIcon, component: Message },

  // { path: URL.Order, name: 'menu:order', icon: People, component: Order },
]

export const App: React.FunctionComponent = () => {
  const currentUser = useSelector((state: any) => state.user.currentUser);
  let routes = JSON.parse(JSON.stringify(AdminRoutes));
  for (let i = 0; i < routes.length; i++) {
    routes[i].icon = AdminRoutes[i].icon;
    routes[i].component = AdminRoutes[i].component;

    if (routes[i].children) {
      for (let j = 0; j < routes[i].children.length; j++) {
        routes[i].children[j].icon = AdminRoutes[i].children![j].icon;
        routes[i].children[j].component = AdminRoutes[i].children![j].component;
      }
    }
  }

  routes = routes.filter((ar: any) => ar.id <= 0 || currentUser?.functionIds?.some((id: number) => id === ar.id));
  for (let i = routes.length - 1; i >= 0; i--) {
    if (routes[i] && routes[i].collapse && routes[i].children) {
      routes[i].children = routes[i].children!.slice().filter((c: any) => c.id === 0 || currentUser?.functionIds?.some((id: number) => id === c.id))
      if (routes[i].children.length === 0) {
        routes.splice(i, 1);
      }
    }
  }
  
  let childrenRoutes: any[] = [];
  routes.forEach((r: any) => {
    if (r.children) {
      childrenRoutes.push(...r.children.filter((c: any) => c.id === 0 || currentUser.functionIds.some((id: number) => id === c.id) ));
    }
  });

  return (
    <Router history={history}>
      <Switch>

        <Route path='/login'>
          <LoginLayout>
            <Route exact path={URL.Login} component={Login}></Route>
          </LoginLayout>
        </Route>

        <Route>
          <MainLayout>

            <Switch>
              {[...routes, ...childrenRoutes].map((r: any) => {
                return <Route exact path={r.path} component={r.component} key={r.path} />
              })}

              {DetailRoutes.map((r: any) => {
                return <Route exact path={r.path} component={r.component} key={r.path} />
              })}
            </Switch>

          </MainLayout>
        </Route>

      </Switch>
    </Router>
  )
}
