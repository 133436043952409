import { AutoNumberResetType } from "../Consts";

export class AutoNumberDTO {
  public id: number;
  public number: string;
  public name: string;
  public resetType: number;
  public isDisabled: boolean;

  constructor() {
    this.id = 0;
    this.name = '';
    this.number = '';
    this.resetType = AutoNumberResetType.Monthly;
    this.isDisabled = false;
  }
}