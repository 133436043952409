import { FilterDTO, WorkflowDTO } from '../../DTO';

const WORKFLOW_REQUEST: string = 'WORKFLOW_REQUEST';

const WORKFLOW_GET_LIST_SUCCESS: string = 'WORKFLOW_GET_LIST_SUCCESS';
const WORKFLOW_GET_LIST_FAIL: string = 'WORKFLOW_GET_LIST_FAIL';

const WORKFLOW_SET_EDITING_WORKFLOW: string = 'WORKFLOW_SET_EDITING_WORKFLOW';

const WORKFLOW_GET_BY_ID_SUCCESS: string = 'WORKFLOW_GET_BY_ID_SUCCESS';
const WORKFLOW_GET_BY_ID_FAIL: string = 'WORKFLOW_GET_BY_ID_FAIL';

const WORKFLOW_SAVE_SUCCESS: string = 'WORKFLOW_SAVE_SUCCESS';
const WORKFLOW_SAVE_FAIL: string = 'WORKFLOW_SAVE_FAIL';

const WORKFLOW_DELETE_SUCCESS: string = 'WORKFLOW_DELETE_SUCCESS';
const WORKFLOW_DELETE_FAIL: string = 'WORKFLOW_DELETE_FAIL';

const initialState = {
  editingWorkflow: {},
}

export function workflowReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case WORKFLOW_SET_EDITING_WORKFLOW:
      return Object.assign({}, state, {
        editingWorkflow: action.payload
      });

    default:
      return state;

  }

}

export function getListWorkflow(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [WORKFLOW_REQUEST, WORKFLOW_GET_LIST_SUCCESS, WORKFLOW_GET_LIST_FAIL],
      EndPoint: `Workflows${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingWorkflow(workflow: WorkflowDTO) {
  return {
    type: WORKFLOW_SET_EDITING_WORKFLOW,
    payload: workflow
  }
}

export function getWorkflowById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [WORKFLOW_REQUEST, WORKFLOW_GET_BY_ID_SUCCESS, WORKFLOW_GET_BY_ID_FAIL],
      EndPoint: `Workflows\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveWorkflow(workflow: WorkflowDTO) {
  let initRequest: RequestInit = {
    method: workflow.id ? 'PATCH' : 'POST',
    body: JSON.stringify(workflow),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [WORKFLOW_REQUEST, WORKFLOW_SAVE_SUCCESS, WORKFLOW_SAVE_FAIL],
      EndPoint: 'Workflows',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteWorkflow(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [WORKFLOW_REQUEST, WORKFLOW_DELETE_SUCCESS, WORKFLOW_DELETE_FAIL],
      EndPoint: 'Workflows',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}