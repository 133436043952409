export class SelectItemDTO {
  public value: string | number;
  public text: string;
  public details: any;

  constructor() {
    this.value = '';
    this.text = '';
    this.details = {};
  }
}