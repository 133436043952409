import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer } from '../../Components';
import { People, Save, KeyboardArrowLeft } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getGameProviderById, saveGameProvider } from '../../Redux/GameProvider/GameProviderReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { GameProviderDTO } from '../../DTO';

interface IGameProviderDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  gameProvider: GameProviderDTO;

  setActiveRoute: ActionCreator<any>,
  getGameProviderById: ActionCreator<any>,
  saveGameProvider: ActionCreator<any>,
}

interface IGameProviderDetailStates {
  isSaving?: boolean;

  gameProvider: GameProviderDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    gameProvider: state.gameProvider.editingGameProvider,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getGameProviderById: (id: number) => dispatch(getGameProviderById(id)),
    saveGameProvider: (gameProvider: GameProviderDTO) => dispatch(saveGameProvider(gameProvider)),
  })
}

class GameProviderDetail extends React.Component<IGameProviderDetailProps, IGameProviderDetailStates> {

  constructor(props: IGameProviderDetailProps) {
    super(props);
    this.state = {
      gameProvider: {...new GameProviderDTO(), ...props.gameProvider}
    }
  }

  componentDidMount() {
    const { setActiveRoute, match, getGameProviderById, t } = this.props;

    setActiveRoute([{path: URL.GameProvider, name: this.props.t('menu:gameProvider')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getGameProviderById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.GameProvider);
          } else {
            this.setState({ gameProvider: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes } = this.props;
    let { showValidation, gameProvider: { name } } = this.state;

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={People}
          iconColor={'green'}
          title={t('common:information')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('gameProvider:name')} </FormLabel>
              <>
                <InputText name='name' values={name} onChange={this._onTextChange.bind(this)} />
                {showValidation && !name && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

          </ColumnContainer>

          <GridContainer justify="center">
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button>
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button>
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.GameProvider)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ gameProvider: {...this.state.gameProvider, [name]: value }});    
  }

  private _onValidate() {
    let { gameProvider: { name } } = this.state;

    return name;
  }

  private _onSave(close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { gameProvider: { name } } = this.state;
    let gameProvider = new GameProviderDTO();
    gameProvider.id = parseInt(this.props.match.params.id);
    gameProvider.name = name;

    this.props.saveGameProvider(gameProvider).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        gameProvider.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close) {
          history.push(URL.GameProvider);
        } else if (parseInt(match.params.id) === 0) {
          history.push(URL.GameProviderDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(GameProviderDetail)));