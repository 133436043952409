import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL, Permission, TransferStatus, GlobalConst } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer, Autocomplete, Checkbox, CustomSelect } from '../../Components';
import { People, Save, KeyboardArrowLeft, Clear } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getReceiveViettelById, saveReceiveViettel, cancelReceiveViettel, approveReceiveViettel } from '../../Redux/ReceiveViettel/ReceiveViettelReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { ReceiveViettelDTO, CurrentUserDTO, SelectItemDTO } from '../../DTO';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';
import { getUserSelectItems } from '../../Redux/User/UserReducer';
import { getPaymentAccountSelectItems } from '../../Redux/PaymentAccount/PaymentAccountReducer';
let moment = require('moment');

interface IReceiveViettelDetailProps extends WithTranslation {
  classes?: any;
  match: any;

  currentUser: CurrentUserDTO;
  receiveViettel: ReceiveViettelDTO;
  branchSelectItems: SelectItemDTO[];
  userSelectItems: SelectItemDTO[];
  paymentAccountSelectItems: SelectItemDTO[];

  setActiveRoute: ActionCreator<any>;
  getReceiveViettelById: ActionCreator<any>;
  saveReceiveViettel: ActionCreator<any>;
  approveReceiveViettel: ActionCreator<any>;
  cancelReceiveViettel: ActionCreator<any>;
  getBranchSelectItems: ActionCreator<any>;
  getUserSelectItems: ActionCreator<any>;
  getPaymentAccountSelectItems: ActionCreator<any>;
}

interface IReceiveViettelDetailStates {
  isSaving?: boolean;

  receiveViettel: ReceiveViettelDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    currentUser: state.user.currentUser,
    receiveViettel: state.receiveViettel.editingReceiveViettel,
    branchSelectItems: state.branch.branchSelectItems,
    userSelectItems: state.user.userSelectItems,
    paymentAccountSelectItems: state.paymentAccount.paymentAccountSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getReceiveViettelById: (id: number) => dispatch(getReceiveViettelById(id)),
    saveReceiveViettel: (receiveViettel: ReceiveViettelDTO, submit: boolean) => dispatch(saveReceiveViettel(receiveViettel, submit)),
    approveReceiveViettel: (id: number) => dispatch(approveReceiveViettel(id)),
    cancelReceiveViettel: (id: number) => dispatch(cancelReceiveViettel(id)),
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
    getUserSelectItems: () => dispatch(getUserSelectItems()),
    getPaymentAccountSelectItems: () => dispatch(getPaymentAccountSelectItems()),
  })
}

class ReceiveViettelDetail extends React.Component<IReceiveViettelDetailProps, IReceiveViettelDetailStates> {

  constructor(props: IReceiveViettelDetailProps) {
    super(props);
    this.state = {
      receiveViettel: { ...new ReceiveViettelDTO(), ...props.receiveViettel, branchId: props.currentUser.branchId, commissionUserId: props.currentUser.id }
    }
  }

  componentDidMount() {
    !this.props.branchSelectItems?.length && this.props.getBranchSelectItems();
    !this.props.userSelectItems?.length && this.props.getUserSelectItems();
    !this.props.paymentAccountSelectItems?.length && this.props.getPaymentAccountSelectItems();
    
    const { setActiveRoute, match, getReceiveViettelById, t } = this.props;

    setActiveRoute([{ path: URL.ReceiveViettel, name: this.props.t('menu:receiveViettel') }, { name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getReceiveViettelById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.ReceiveViettel);
          } else {
            this.setState({ receiveViettel: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes, currentUser, branchSelectItems, userSelectItems, paymentAccountSelectItems } = this.props;
    let { showValidation, receiveViettel: { id, number, status, time, createdUser, receiverName, identityNumber, phone, secretCode, amount, fee, discount, commission, branchId, commissionUserId, payByCash, receiveAccountId } } = this.state;

    const statusText = [
      { value: 0, text: t('transfer:status_draft')},
      { value: 1, text: t('transfer:status_pending_approve')},
      { value: 2, text: t('transfer:status_approved')},
      { value: 3, text: t('transfer:status_cancel')},
    ]

    let statusStr = statusText.find(s => s.value === status)?.text || '';
    let tDate = time ? moment(time, GlobalConst.DATE_TIME_FORMAT) : moment(new Date());
    let now = moment(new Date());
    let readOnly = (status === TransferStatus.Approved || status === TransferStatus.Canceled) && (tDate.year() * 12 + tDate.month() < now.year() * 12 + now.month());

    return (
      <ColumnContainer columnWidth={[{ sm: 12 }]}>
        <IconCard
          icon={People}
          iconColor={'green'}
          title={t('common:information')}>

          {id && currentUser.functionIds.includes(Permission.ApproveReceiveViettel) && status === TransferStatus.PendingApprove ? 
            <ColumnContainer columnWidth={[{xs:12, sm:12}]}>
              <div className={classes.right}>
                <Button color='primary' customClass={classes.marginRight} round onClick={this._onApprove.bind(this)} disabled={this.state.isSaving}>
                  <Save className={classes.icons} /> {t('button:approve')}
                </Button>
                <Button color='danger' customClass={classes.marginRight} round onClick={this._onCancel.bind(this)} disabled={this.state.isSaving}>
                  <Clear className={classes.icons} /> {t('button:cancel')}
                </Button>
              </div>
            </ColumnContainer>
          : null }

          <ColumnContainer columnWidth={[{ xs: 12, sm: 6 }]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('receiveViettel:number')} </FormLabel>
              <InputText values={number} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('receiveViettel:status')} </FormLabel>
              <InputText values={statusStr} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('receiveViettel:time')} </FormLabel>
              {!readOnly ? 
                <Datetime
                  dateFormat={GlobalConst.DATE_FORMAT}
                  timeFormat={"HH:mm"}
                  inputProps={{ placeholder: t('receiveViettel:time') }}
                  onChange={this._onDateChange.bind(this)}
                  value={time}
                /> : <InputText disabled values={time} /> }
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('receiveViettel:user')} </FormLabel>
              <InputText disabled values={id === 0 ? currentUser.name : createdUser} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('receiveViettel:receiver_name')} </FormLabel>
              <>
                <InputText 
                  name="receiverName" 
                  values={receiverName} 
                  onChange={this._onTextChange.bind(this)} 
                  disabled={readOnly} 
                />
                {showValidation && !receiverName && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('receiveViettel:identity_number')} </FormLabel>
              <>
                <InputText 
                  name="identityNumber" 
                  values={identityNumber} 
                  onChange={this._onTextChange.bind(this)} 
                  disabled={readOnly} 
                />
                {showValidation && !identityNumber && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('receiveViettel:phone')} </FormLabel>
              <>
                <InputText 
                  name="phone" 
                  values={phone} 
                  onChange={this._onTextChange.bind(this)} 
                  disabled={readOnly} 
                />
                {showValidation && !phone && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('receiveViettel:secret_code')} </FormLabel>
              <>
                <InputText 
                  name="secretCode" 
                  values={secretCode} 
                  onChange={this._onTextChange.bind(this)} 
                  disabled={readOnly} 
                />
                {showValidation && !secretCode && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('receiveViettel:amount')} </FormLabel>
              <>
                <InputText 
                  name="amount"
                  numberOnly
                  thousandSeparator
                  values={amount} 
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly} />
                {showValidation && !amount && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('receiveViettel:fee')} </FormLabel>
              <>
                <InputText 
                  name="fee"
                  numberOnly
                  thousandSeparator
                  values={fee} 
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly} />
                {showValidation && !fee && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('receiveViettel:discount')} </FormLabel>
              <>
                <InputText 
                  name="discount" 
                  numberOnly
                  thousandSeparator
                  values={discount} 
                  onChange={this._onNumberChange.bind(this)} 
                  disabled={readOnly} 
                />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('receiveViettel:commission')} </FormLabel>
              <>
                <InputText 
                  name="commission" 
                  numberOnly
                  thousandSeparator
                  values={commission} 
                  onChange={this._onNumberChange.bind(this)} 
                  disabled={readOnly} 
                />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('receiveViettel:total')} </FormLabel>
              <InputText 
                disabled 
                numberOnly 
                thousandSeparator 
                values={parseFloat('0' + amount.toString()) - parseFloat('0' + fee.toString()) - parseFloat('0' + discount.toString())} />
            </ColumnContainer>

            <> </>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('receiveViettel:branch')} </FormLabel>
              <>
                <Autocomplete
                  name='branchId'
                  value={branchId}
                  options={branchSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !branchId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('receiveViettel:commission_user')} </FormLabel>
              <>
                <Autocomplete
                  name='commissionUserId'
                  value={commissionUserId}
                  options={userSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !commissionUserId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('receiveViettel:pay_by_cash')} </FormLabel>
              <Checkbox
                checked={payByCash}
                name='payByCash'
                onClick={this._onCheckboxChange.bind(this)}
              />
            </ColumnContainer>

            {payByCash ? null : <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('receiveViettel:receive_account')} </FormLabel>
              <>
                <CustomSelect 
                  name={'receiveAccountId'}
                  value={receiveAccountId}
                  onChange={this._onNumberChange.bind(this)}
                  items={paymentAccountSelectItems}
                  label={t('receiveViettel:receive_account')}
                  disabled={readOnly} />
                {showValidation && !receiveAccountId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>}

          </ColumnContainer>

          <GridContainer justify="center">
          {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button> : null}
            {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button> : null}
            {status === TransferStatus.Draft ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_submit')}
            </Button> : null}
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.ReceiveViettel)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onCheckboxChange(event: any) {
    event.persist && event.persist();
    const { target: { name, checked } } = event;
    let receiveViettel = this.state.receiveViettel;
    this.setState({ receiveViettel: {...receiveViettel, [name]: checked }});
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ receiveViettel: { ...this.state.receiveViettel, [name]: value } });
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ receiveViettel: { ...this.state.receiveViettel, [name]: value } });
  }

  private _onDateChange(value: string | Moment) {
    let receiveViettel = this.state.receiveViettel;
    receiveViettel.time = moment(value).format(GlobalConst.DATE_TIME_FORMAT);
    this.setState({ receiveViettel: receiveViettel });
  }

  private _onValidate() {
    let { receiveViettel: { receiverName, identityNumber, phone, secretCode, amount, fee, branchId, commissionUserId, payByCash, receiveAccountId } } = this.state;

    return receiverName && identityNumber && phone && secretCode && amount && fee && branchId && commissionUserId && (payByCash || receiveAccountId);
  }

  private _onSave(submit: boolean = false, close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true });
    const { t, match } = this.props;
    let { receiveViettel: { time, receiverName, identityNumber, phone, secretCode, amount, fee, discount, commission, commissionUserId, branchId, payByCash, receiveAccountId } } = this.state;
    let receiveViettel = new ReceiveViettelDTO();
    receiveViettel.id = parseInt(this.props.match.params.id);
    receiveViettel.time = time;
    receiveViettel.receiverName = receiverName;
    receiveViettel.identityNumber = identityNumber;
    receiveViettel.phone = phone;
    receiveViettel.secretCode = secretCode;
    receiveViettel.amount = amount;
    receiveViettel.fee = fee;
    receiveViettel.discount = discount;
    receiveViettel.commission = commission;
    receiveViettel.branchId = branchId;
    receiveViettel.commissionUserId = commissionUserId;
    receiveViettel.payByCash = payByCash;
    receiveViettel.receiveAccountId = payByCash ? null : receiveAccountId;

    this.props.saveReceiveViettel(receiveViettel, submit).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        receiveViettel.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close || submit) {
          history.push(URL.ReceiveViettel);
        } else if (parseInt(match.params.id) === 0) {
          this.setState({ receiveViettel: response.data });
          history.push(URL.ReceiveViettelDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

  private _onApprove() {
    const { t } = this.props;
    this.setState({ isSaving: true });

    this.props.approveReceiveViettel(this.state.receiveViettel.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.ReceiveViettel);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

  private _onCancel() {
    const { t } = this.props;
    this.setState({ isSaving: true });
    
    this.props.cancelReceiveViettel(this.state.receiveViettel.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.ReceiveViettel);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(ReceiveViettelDetail)));