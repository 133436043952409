import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { GlobalConst, PaySupplierStatus, URL, WorkflowStatus } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer, StateButtons, Autocomplete, CustomSelect } from '../../Components';
import { People, Save, KeyboardArrowLeft } from '@material-ui/icons';
import { Checkbox, FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getPaySupplierById, savePaySupplier } from '../../Redux/PaySupplier/PaySupplierReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { PaySupplierDTO, SelectItemDTO } from '../../DTO';
import { getSupplierSelectItems } from '../../Redux/Supplier/SupplierReducer';
import { getPaymentAccountSelectItems } from '../../Redux/PaymentAccount/PaymentAccountReducer';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
let moment = require('moment');

interface IPaySupplierDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  paySupplier: PaySupplierDTO;
  supplierSelectItems: SelectItemDTO[];
  paymentAccountSelectItems: SelectItemDTO[];
  branchSelectItems: SelectItemDTO[];

  setActiveRoute: ActionCreator<any>,
  getPaySupplierById: ActionCreator<any>,
  savePaySupplier: ActionCreator<any>,
  getSupplierSelectItems: ActionCreator<any>,
  getPaymentAccountSelectItems: ActionCreator<any>,
  getBranchSelectItems: ActionCreator<any>,
}

interface IPaySupplierDetailStates {
  isSaving?: boolean;

  paySupplier: PaySupplierDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    paySupplier: state.paySupplier.editingPaySupplier,
    supplierSelectItems: state.supplier.supplierSelectItems,
    paymentAccountSelectItems: state.paymentAccount.paymentAccountSelectItems,
    branchSelectItems: state.branch.branchSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getPaySupplierById: (id: number) => dispatch(getPaySupplierById(id)),
    savePaySupplier: (paySupplier: PaySupplierDTO, action: string) => dispatch(savePaySupplier(paySupplier, action)),
    getSupplierSelectItems: () => dispatch(getSupplierSelectItems()),
    getPaymentAccountSelectItems: () => dispatch(getPaymentAccountSelectItems()),
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
  })
}

class PaySupplierDetail extends React.Component<IPaySupplierDetailProps, IPaySupplierDetailStates> {

  constructor(props: IPaySupplierDetailProps) {
    super(props);
    this.state = {
      paySupplier: {...new PaySupplierDTO(), ...props.paySupplier}
    }
  }

  componentDidMount() {
    const { setActiveRoute, match, getPaySupplierById, t } = this.props;

    !this.props.supplierSelectItems?.length && this.props.getSupplierSelectItems();
    !this.props.paymentAccountSelectItems?.length && this.props.getPaymentAccountSelectItems();
    !this.props.branchSelectItems?.length && this.props.getBranchSelectItems();

    setActiveRoute([{path: URL.PaySupplier, name: this.props.t('menu:paySupplier')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getPaySupplierById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.PaySupplier);
          } else {
            this.setState({ paySupplier: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes, supplierSelectItems, paymentAccountSelectItems, branchSelectItems } = this.props;
    let { showValidation, paySupplier: { id, number, supplierId, date, amount, payByCash, paymentAccountId, workflow, status, branchId } } = this.state;

    let statusStr = WorkflowStatus.find(s => s.value === status)?.text ?? '';
    if (statusStr) {
      statusStr = t(`status:${statusStr}`);
    }

    let readOnly = (status === PaySupplierStatus.Approved || status === PaySupplierStatus.Canceled);

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={People}
          iconColor={'green'}
          title={t('common:information')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('paySupplier:number')} </FormLabel>
              <InputText values={number} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('paySupplier:status')} </FormLabel>
              <InputText values={statusStr} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('paySupplier:date')} </FormLabel>
              {!readOnly ? 
                <Datetime
                  dateFormat={GlobalConst.DATE_FORMAT}
                  timeFormat={"HH:mm"}
                  inputProps={{ placeholder: t('paySupplier:date') }}
                  onChange={this._onDateChange.bind(this)}
                  value={date}
                /> : <InputText disabled values={date} /> }
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('paySupplier:supplier')} </FormLabel>
              <>
                <Autocomplete
                  name='supplierId'
                  value={supplierId}
                  options={supplierSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  getOptionLabel={(option: SelectItemDTO) => option.text}
                  disabled={readOnly}
                />
                {showValidation && !supplierId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('paySupplier:amount')} </FormLabel>
              <>
                <InputText 
                  name="amount"
                  numberOnly
                  thousandSeparator
                  values={amount} 
                  disabled={readOnly}
                  onChange={this._onNumberChange.bind(this)} />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('paySupplier:pay_by_cash')} </FormLabel>
              <Checkbox
                checked={payByCash}
                name='payByCash'
                onClick={this._onCheckboxChange.bind(this)}
              />
            </ColumnContainer>

            {payByCash ? null : <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('paySupplier:payment_account')} </FormLabel>
              <>
                <CustomSelect 
                  name={'paymentAccountId'}
                  value={paymentAccountId}
                  onChange={this._onNumberChange.bind(this)}
                  items={paymentAccountSelectItems}
                  label={t('paySupplier:payment_account')}
                  disabled={readOnly} />
                {showValidation && !paymentAccountId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>}

            {!payByCash ? null : <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('paySupplier:branch')} </FormLabel>
              <>
                <CustomSelect 
                  name={'branchId'}
                  value={branchId}
                  onChange={this._onNumberChange.bind(this)}
                  items={branchSelectItems}
                  label={t('paySupplier:branch')}
                  disabled={readOnly} />
                {showValidation && !branchId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>}

          </ColumnContainer>

          <GridContainer justify="center">
            {id === 0 ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSaveWF.bind(this, 'save')} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button> : 
            <StateButtons workflowButton={workflow?.workflowStates.find(s => s.status === status)?.workflowButtons ?? []} onSave={this._onSaveWF.bind(this)} /> }
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.PaySupplier)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:back')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onCheckboxChange(event: any) {
    event.persist && event.persist();
    const { target: { name, checked } } = event;
    let paySupplier = this.state.paySupplier;
    this.setState({ paySupplier: {...paySupplier, [name]: checked }});
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ paySupplier: {...this.state.paySupplier, [name]: value }});
  }

  private _onDateChange(value: string | Moment) {
    let paySupplier = this.state.paySupplier;
    paySupplier.date = moment(value).format(GlobalConst.DATE_TIME_FORMAT);
    this.setState({ paySupplier: paySupplier });
  }

  private _onTextChange(event: any) {
    event.persist && event.persist();
    const { target: { name, value } } = event;
    let paySupplier = this.state.paySupplier;
    this.setState({ paySupplier: {...paySupplier, [name]: value }});    
  }

  private _onValidate() {
    let { paySupplier: { supplierId, date, amount, payByCash, paymentAccountId, branchId } } = this.state;

    return supplierId && date && amount && ((!payByCash && paymentAccountId) || (payByCash && branchId));
  }

  private _onSaveWF(action: string) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { paySupplier: { supplierId, date, amount, payByCash, paymentAccountId, branchId } } = this.state;
    let paySupplier = new PaySupplierDTO();
    paySupplier.id = parseInt(this.props.match.params.id);
    paySupplier.supplierId = supplierId;
    paySupplier.date = date;
    paySupplier.amount = amount;
    paySupplier.payByCash = payByCash;
    paySupplier.paymentAccountId = payByCash ? null : paymentAccountId;
    paySupplier.branchId = !payByCash ? null : branchId;

    this.props.savePaySupplier(paySupplier, action).then((response: any) => {
      this.setState({ isSaving: false });
      if (response.data && !response.data.errorCode) {
        paySupplier.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (parseInt(match.params.id) === 0) {
          history.push(URL.PaySupplierDetail.replace(':id', response.data.id));
        } else if (action !== 'save') { 
          history.push(URL.PaySupplier);
        }
      } else {
        showError(t, response.data.errorCode, response.data.errorMessage);        
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(PaySupplierDetail)));