import { WorkflowButtonDTO } from "./WorkflowButtonDTO";

export class WorkflowStateDTO {
  public id: number;
  public name: string;
  public status: number;
  public statusCode: string;
  public order: number;
  public workflowButtons: WorkflowButtonDTO[];

  constructor() {
    this.id = 0;
    this.name = '';
    this.workflowButtons = [];
    this.status = 0;
    this.statusCode = '';
    this.order = 0;
  }
}