import * as React from 'react';
import cx from "classnames";
import headerStyle from './HeaderStyle';
import { AppBar, Toolbar, Hidden, IconButton, withStyles, Breadcrumbs, Typography, Button } from '@material-ui/core';
import { CustomIconButton, HeaderLinks } from '../';
import { ViewList, MoreVert, Menu } from '@material-ui/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import history from '../../history'

interface IHeaderProps extends WithTranslation {
  match: any,
  location: any,
  history: any,

  classes: any,
  color: string,
  routes: any[],
  miniActive: boolean,
  sidebarMinimize: any,
  handleDrawerToggle: any;

  activeRoutes: any[];
}

interface IHeaderStates {}

function mapStateToProps(state: any) {
  return ({
    activeRoutes: state.common.activeRoutes,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({})
}

class Header extends React.Component<IHeaderProps, IHeaderStates> {

  private _goTo(path: string, event: any) {
    event.stopPropagation();
    event.preventDefault();
    history.push(path);
  }

  private _makeBreadcrumb() {
    return (
      this.props.activeRoutes.map((r, idx) => {
        if (idx !== this.props.activeRoutes.length - 1) {
          return <Button onClick={this._goTo.bind(this, r.path)} key={idx}>{r.name}</Button>
        } else {
          return <Typography color="textPrimary" key={idx}>{r.name}</Typography>
        }        
      })      
    )
  }

  render() {
    const { classes, color } = this.props;
    const appBarClasses = cx({
      [" " + classes[color]]: color
    });
    const sidebarMinimize =
      classes.sidebarMinimize +
      " " +
      cx({
        [classes.sidebarMinimizeRTL]: false
      });

    return (
      <AppBar className={classes.appBar + appBarClasses}>
        <Toolbar
          className={classes.container}>
          <Hidden smDown>
            <div className={sidebarMinimize}>
              {this.props.miniActive ? (
                <CustomIconButton color="white" onClick={this.props.sidebarMinimize}>
                  <ViewList className={classes.sidebarMiniIcon} />
                </CustomIconButton>
              ) : (
                <CustomIconButton color="white" onClick={this.props.sidebarMinimize}>
                  <MoreVert className={classes.sidebarMiniIcon} />
                </CustomIconButton>
              )}
            </div>
          </Hidden>
          <div className={classes.flex}>
            {/* Here we create navbar brand, based on route name */}
            <Breadcrumbs aria-label="breadcrumb">
              {this._makeBreadcrumb()}
            </Breadcrumbs>
          </div>
          <Hidden smDown implementation="css">
            <HeaderLinks />
          </Hidden>
          <Hidden mdUp>
            <IconButton
              className={classes.appResponsive}
              color="inherit"
              aria-label="open drawer"
              onClick={this.props.handleDrawerToggle}
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }

}

// function Header({ ...props }) {
//   const { t } = useTranslation();
  
//   function makeBrand() {
//     var name;
//     props.routes.map((prop: any, key: any) => {
//       if (prop.collapse) {
//         prop.views.map((prop: any, key: any) => {
//           if (prop.path === window.location.pathname) {
//             name = t(prop.name);
//           }
//           return null;
//         });
//       }
//       if (prop.path === window.location.pathname) {
//         name = t(prop.name);
//       }
//       return null;
//     });
//     return name;
//   }
//   const { classes, color } = props;
//   const appBarClasses = cx({
//     [" " + classes[color]]: color
//   });
//   const sidebarMinimize =
//     classes.sidebarMinimize +
//     " " +
//     cx({
//       [classes.sidebarMinimizeRTL]: false
//     });
//   return (
//     <AppBar className={classes.appBar + appBarClasses}>
//       <Toolbar
//        className={classes.container}>
//         <Hidden smDown>
//           <div className={sidebarMinimize}>
//             {props.miniActive ? (
//               <CustomIconButton color="white" onClick={props.sidebarMinimize}>
//                 <ViewList className={classes.sidebarMiniIcon} />
//               </CustomIconButton>
//             ) : (
//               <CustomIconButton color="white" onClick={props.sidebarMinimize}>
//                 <MoreVert className={classes.sidebarMiniIcon} />
//               </CustomIconButton>
//             )}
//           </div>
//         </Hidden>
//         <div className={classes.flex}>
//           {/* Here we create navbar brand, based on route name */}
//           <Button href="#" className={classes.title}>
//             {makeBrand()}
//           </Button>
//         </div>
//         <Hidden smDown implementation="css">
//           <HeaderLinks />
//         </Hidden>
//         <Hidden mdUp>
//           <IconButton
          
//             className={classes.appResponsive}
//             color="inherit"
//             aria-label="open drawer"
//             onClick={props.handleDrawerToggle}
//           >
//             <Menu />
//           </IconButton>
//         </Hidden>
//       </Toolbar>
//     </AppBar>
//   );
// }

// Header.propTypes = {
//   classes: PropTypes.object.isRequired,
//   color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
//   false: PropTypes.bool
// };

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(withStyles(headerStyle)(Header))));