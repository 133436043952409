import React from 'react';
import { GridContainer, GridItem, IconCard, DataList, Button, CustomInput } from '../../Components';
import { Assignment, Edit, Replay, Add, Delete, Search, OpenInNew } from '@material-ui/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ActionCreator } from 'redux';
import { connect } from 'react-redux';
import { getListOrder, deleteOrder, setEditingOrder } from '../../Redux/Order/OrderReducer';
import { IconButton, withStyles, InputAdornment } from '@material-ui/core';
import { URL, GlobalConst } from '../../Consts';
import history from '../../history';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { FilterDTO, OrderDTO } from '../../DTO';
import styles from '../../styles';
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from 'react-toastify';
import { showError } from '../../Utils';
import _ from 'lodash';

interface IOrderProps extends WithTranslation {
  classes: any;

  getOrderList: ActionCreator<any>,
  setActiveRoute: ActionCreator<any>,
  setEditingOrder: ActionCreator<any>,
  deleteOrder: ActionCreator<any>,
}

interface IOrderStates {
  data: OrderDTO[],
  page: number;
  pageSize: number;
  totalCount: number;

  searchString?: string;
  showDeleteConfirm?: boolean;
  deletingId?: number;
}

function mapStateToProps(state: any) {
  return ({})
}

function mapDispatchToProps(dispatch: any) {
  return ({
    getOrderList: (filterDTO: FilterDTO) => dispatch(getListOrder(filterDTO)),
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    setEditingOrder: (order: OrderDTO) => dispatch(setEditingOrder(order)),
    deleteOrder: (id: number) => dispatch(deleteOrder(id)),
  })
}

class Order extends React.Component<IOrderProps, IOrderStates> {

  constructor(props: IOrderProps){
    super(props);

    this.state = {
      data: [],
      page: 0,
      pageSize: 100,
      totalCount: 0,
    }

    this._getOrderWithDebounce = _.debounce(this._getOrderWithDebounce.bind(this), GlobalConst.DEBOUNCE_TIME);
  }

  componentDidMount() {
    this.props.setActiveRoute([{path: URL.Order, name: this.props.t('menu:order')}]);
  }

  render() {
    const { t, classes } = this.props;
    let { data, page, pageSize, totalCount } = this.state;
    
    return (
      <>
        <GridContainer>
          <GridItem xs={12}>
            <IconCard
              icon={Assignment}
              iconColor={'green'}
              title={t('order:order')}>

              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <CustomInput
                    labelText={t('common:search')}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Search className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      value: this.state.searchString,
                      name: 'searchString',
                      onChange: this._search.bind(this)
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <div className={classes.right}>
                    <Button color='primary' customClass={classes.marginRight} round onClick={this._onEdit.bind(this, new OrderDTO())}>
                      <Add className={classes.icons} /> {t('button:add')}
                    </Button>
                    <Button color='primary' customClass={classes.marginRight} round onClick={this._onRefresh.bind(this)}>
                      <Replay className={classes.icons} /> {t('button:refresh')}
                    </Button>
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <DataList 
                    currentPage={page}
                    pageSize={pageSize}
                    totalPage={Math.ceil(totalCount / pageSize)}
                    data={this._mapData(data)}
                    columns={this._getColumns()}
                    fetchData={this._onLoadData.bind(this)}
                    onChangePage={this._onChangePage.bind(this)}
                  />
                </GridItem>
              </GridContainer>

              {this.state.showDeleteConfirm && <SweetAlert
                warning
                //style={{ display: "block", marginTop: "-100px" }}
                title={t('message:confirm_delete')}
                onConfirm={this._onDelete.bind(this)}
                onCancel={() => this.setState({ showDeleteConfirm: false})}
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
                cancelBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.danger
                }
                confirmBtnText={t('button:delete')}
                cancelBtnText={t('button:cancel')}
                showCancel
              >
              </SweetAlert>}

            </IconCard>
          </GridItem>
        </GridContainer>
      </>
    )
  }

  _getOrderWithDebounce(filterDTO: FilterDTO) {
    this.props.getOrderList(filterDTO).then((response: any) => {
      if (response && !response.errorCode) {
        this.setState({ data: response.data, totalCount: response.totalCount });
      } else {
        showError(this.props.t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _search(event: any) {
    event.persist();
    const { target: { value } } = event;
    this.setState({ searchString: value, page: 0 });

    let filter: FilterDTO = new FilterDTO();
    filter.page = 0;
    filter.pageSize = this.state.pageSize;
    filter.searchString = value;

    this._getOrderWithDebounce(filter);    
  }

  private _getColumns() {
    const { t } = this.props;
    return [
      { Header: t('order:number'), accessor: "number", sortable: false, filterable: false },
      { Header: '', accessor: "actions", sortable: false, filterable: false }
    ]
  }

  private _onEdit(order: OrderDTO) {
    this.props.setEditingOrder(order);
    history.push(URL.OrderDetail.replace(':id', order.id.toString()));
  }

  private _onDelete() {
    const { t } = this.props;

    this.setState({ showDeleteConfirm: false });
    this.props.deleteOrder(this.state.deletingId).then((response: any) => {
      if (response && !response.errorCode) {
        toast.success(t('message:delete_success'));
        this._onRefresh();
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _mapData(data: OrderDTO[]) {
    if (!data) return [];

    return data.map((u: OrderDTO) => {
      return {
        ...u,
        actions: (
          <div className="actions-right">
            <IconButton className='small-action' onClick={this._onEdit.bind(this, u)}> <Edit /> </IconButton>
            <IconButton className='small-action' onClick={() => window.open(URL.OrderDetail.replace(':id', u.id.toString()), '_blank')}> <OpenInNew /> </IconButton>
            <IconButton className='small-action' onClick={() => this.setState({ showDeleteConfirm: true, deletingId: u.id })}> <Delete color='error' /> </IconButton>
          </div>
        )
      }
    });
  }

  private _onLoadData(tableState: any, instance: any) {
    let filter: FilterDTO = new FilterDTO(tableState);

    this._getOrderWithDebounce(filter);
  }

  private _onRefresh() {
    let filter: FilterDTO = new FilterDTO();
    filter.page = this.state.page;
    filter.pageSize = this.state.pageSize;
    filter.searchString = this.state.searchString || '';

    this._getOrderWithDebounce(filter);
  }

  private _onChangePage(pageIndex: any) {
    this.setState({ page: pageIndex});
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(Order)));