import { FilterDTO, TransferViettelDTO, transferViettelToFormData } from '../../DTO';

const TRANSFER_VIETTEL_REQUEST: string = 'TRANSFER_VIETTEL_REQUEST';

const TRANSFER_VIETTEL_GET_LIST_SUCCESS: string = 'TRANSFER_VIETTEL_GET_LIST_SUCCESS';
const TRANSFER_VIETTEL_GET_LIST_FAIL: string = 'TRANSFER_VIETTEL_GET_LIST_FAIL';

const TRANSFER_VIETTEL_SET_EDITING_TRANSFER_VIETTEL: string = 'TRANSFER_VIETTEL_SET_EDITING_TRANSFER_VIETTEL';

const TRANSFER_VIETTEL_GET_BY_ID_SUCCESS: string = 'TRANSFER_VIETTEL_GET_BY_ID_SUCCESS';
const TRANSFER_VIETTEL_GET_BY_ID_FAIL: string = 'TRANSFER_VIETTEL_GET_BY_ID_FAIL';

const TRANSFER_VIETTEL_SAVE_SUCCESS: string = 'TRANSFER_VIETTEL_SAVE_SUCCESS';
const TRANSFER_VIETTEL_SAVE_FAIL: string = 'TRANSFER_VIETTEL_SAVE_FAIL';

const TRANSFER_VIETTEL_DELETE_SUCCESS: string = 'TRANSFER_VIETTEL_DELETE_SUCCESS';
const TRANSFER_VIETTEL_DELETE_FAIL: string = 'TRANSFER_VIETTEL_DELETE_FAIL';

const TRANSFER_VIETTEL_APPROVE_SUCCESS: string = 'TRANSFER_VIETTEL_APPROVE_SUCCESS';
const TRANSFER_VIETTEL_APPROVE_FAIL: string = 'TRANSFER_VIETTEL_APPROVE_FAIL';

const TRANSFER_VIETTEL_CANCEL_SUCCESS: string = 'TRANSFER_VIETTEL_CANCEL_SUCCESS';
const TRANSFER_VIETTEL_CANCEL_FAIL: string = 'TRANSFER_VIETTEL_CANCEL_FAIL';

const TRANSFER_VIETTEL_UPDATE_CONFIRM_SUCCESS: string = 'TRANSFER_VIETTEL_UPDATE_CONFIRM_SUCCESS';
const TRANSFER_VIETTEL_UPDATE_CONFIRM_FAIL: string = 'TRANSFER_VIETTEL_UPDATE_CONFIRM_FAIL';

const TRANSFER_VIETTEL_EXPORT_LIST_SUCCESS: string = 'TRANSFER_VIETTEL_EXPORT_LIST_SUCCESS';
const TRANSFER_VIETTEL_EXPORT_LIST_FAIL: string = 'TRANSFER_VIETTEL_EXPORT_LIST_FAIL';

const TRANSFER_VIETTEL_PRINT_SUCCESS: string = 'TRANSFER_VIETTEL_PRINT_SUCCESS';
const TRANSFER_VIETTEL_PRINT_FAIL: string = 'TRANSFER_VIETTEL_PRINT_FAIL';

const initialState = {
  editingTransferViettel: {},
}

export function transferViettelReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case TRANSFER_VIETTEL_SET_EDITING_TRANSFER_VIETTEL:
      return Object.assign({}, state, {
        editingTransferViettel: action.payload
      });

    default:
      return state;

  }

}

export function getListTransferViettel(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_VIETTEL_REQUEST, TRANSFER_VIETTEL_GET_LIST_SUCCESS, TRANSFER_VIETTEL_GET_LIST_FAIL],
      EndPoint: `TransferViettels${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingTransferViettel(transferViettel: TransferViettelDTO) {
  return {
    type: TRANSFER_VIETTEL_SET_EDITING_TRANSFER_VIETTEL,
    payload: transferViettel
  }
}

export function getTransferViettelById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_VIETTEL_REQUEST, TRANSFER_VIETTEL_GET_BY_ID_SUCCESS, TRANSFER_VIETTEL_GET_BY_ID_FAIL],
      EndPoint: `TransferViettels\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveTransferViettel(transferViettel: TransferViettelDTO, submit: boolean, reference: any, reference2: any, confirmation: any) {
  let formData = transferViettelToFormData(transferViettel);
  if (reference) {
    formData.append('reference', reference);
  }
  if (reference2) {
    formData.append('reference2', reference2);
  }
  if (confirmation) {
    formData.append('confirmation', confirmation);
  }

  let initRequest: RequestInit = {
    method: transferViettel.id ? 'PATCH' : 'POST',
    body: formData,
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_VIETTEL_REQUEST, TRANSFER_VIETTEL_SAVE_SUCCESS, TRANSFER_VIETTEL_SAVE_FAIL],
      EndPoint: `TransferViettels\\${submit}`,
      AuthenticationRequire: true,
      Config: initRequest,
      IsUpload: true
    }
  }
}

export function deleteTransferViettel(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_VIETTEL_REQUEST, TRANSFER_VIETTEL_DELETE_SUCCESS, TRANSFER_VIETTEL_DELETE_FAIL],
      EndPoint: 'TransferViettels',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function approveTransferViettel(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_VIETTEL_REQUEST, TRANSFER_VIETTEL_APPROVE_SUCCESS, TRANSFER_VIETTEL_APPROVE_FAIL],
      EndPoint: 'TransferViettels\\Approve',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function cancelTransferViettel(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_VIETTEL_REQUEST, TRANSFER_VIETTEL_CANCEL_SUCCESS, TRANSFER_VIETTEL_CANCEL_FAIL],
      EndPoint: 'TransferViettels\\Cancel',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function updateViettelConfirmation(id: number, confirmation: any) {
  let formData = new FormData();
  formData.append("confirmation", confirmation);

  let initRequest: RequestInit = {
    method: 'POST',
    body: formData,
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_VIETTEL_REQUEST, TRANSFER_VIETTEL_UPDATE_CONFIRM_SUCCESS, TRANSFER_VIETTEL_UPDATE_CONFIRM_FAIL],
      EndPoint: `TransferViettels\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest,
      IsUpload: true
    }
  }
}

export function exportTransferViettel(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_VIETTEL_REQUEST, TRANSFER_VIETTEL_EXPORT_LIST_SUCCESS, TRANSFER_VIETTEL_EXPORT_LIST_FAIL],
      EndPoint: `TransferViettels\\export${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function printTransferViettel(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_VIETTEL_REQUEST, TRANSFER_VIETTEL_PRINT_SUCCESS, TRANSFER_VIETTEL_PRINT_FAIL],
      EndPoint: `TransferViettels\\print\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest,
      ResponseHtml : true
    }
  }
}