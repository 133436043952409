import React from 'react';
import { IconCard, DataList, Button, CustomInput, ColumnContainer } from '../../Components';
import { Assignment, Edit, Replay, Add, Delete, Search } from '@material-ui/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ActionCreator } from 'redux';
import { connect } from 'react-redux';
import { getListTransferSettingMoMo, deleteTransferSettingMoMo, setEditingTransferSettingMoMo } from '../../Redux/TransferSettingMoMo/TransferSettingMoMoReducer';
import { IconButton, withStyles, InputAdornment } from '@material-ui/core';
import { URL, GlobalConst } from '../../Consts';
import history from '../../history';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { FilterDTO, TransferSettingMoMoDTO } from '../../DTO';
import styles from '../../styles';
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from 'react-toastify';
import { showError, currency } from '../../Utils';
import _ from 'lodash';

interface ITransferSettingMoMoProps extends WithTranslation {
  classes: any;

  getTransferSettingMoMoList: ActionCreator<any>,
  setActiveRoute: ActionCreator<any>,
  setEditingTransferSettingMoMo: ActionCreator<any>,
  deleteTransferSettingMoMo: ActionCreator<any>,
}

interface ITransferSettingMoMoStates {
  data: TransferSettingMoMoDTO[],
  page: number;
  pageSize: number;
  totalCount: number;

  searchString?: string;
  showDeleteConfirm?: boolean;
  deletingId?: number;
}

function mapStateToProps(state: any) {
  return ({})
}

function mapDispatchToProps(dispatch: any) {
  return ({
    getTransferSettingMoMoList: (filterDTO: FilterDTO) => dispatch(getListTransferSettingMoMo(filterDTO)),
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    setEditingTransferSettingMoMo: (transferSettingMoMo: TransferSettingMoMoDTO) => dispatch(setEditingTransferSettingMoMo(transferSettingMoMo)),
    deleteTransferSettingMoMo: (id: number) => dispatch(deleteTransferSettingMoMo(id)),
  })
}

class TransferSettingMoMo extends React.Component<ITransferSettingMoMoProps, ITransferSettingMoMoStates> {

  constructor(props: ITransferSettingMoMoProps){
    super(props);

    this.state = {
      data: [],
      page: 0,
      pageSize: 100,
      totalCount: 0,
    }

    this._getTransferSettingMoMoWithDebounce = _.debounce(this._getTransferSettingMoMoWithDebounce.bind(this), GlobalConst.DEBOUNCE_TIME);
  }

  componentDidMount() {
    this.props.setActiveRoute([{path: URL.TransferSettingMoMo, name: this.props.t('menu:transferSettingMoMo')}]);
  }

  render() {
    const { t, classes } = this.props;
    let { data, page, pageSize, totalCount } = this.state;
    
    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={Assignment}
          iconColor={'green'}
          title={t('transferSettingMoMo:transferSettingMoMo')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>
            <CustomInput
              labelText={t('common:search')}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                value: this.state.searchString,
                name: 'searchString',
                onChange: this._search.bind(this)
              }}
            />

            <div className={classes.right}>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onEdit.bind(this, new TransferSettingMoMoDTO())}>
                <Add className={classes.icons} /> {t('button:add')}
              </Button>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onRefresh.bind(this)}>
                <Replay className={classes.icons} /> {t('button:refresh')}
              </Button>
            </div>
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs:12}]}>
            <DataList 
              currentPage={page}
              pageSize={pageSize}
              totalPage={Math.ceil(totalCount / pageSize)}
              data={this._mapData(data)}
              columns={this._getColumns()}
              fetchData={this._onLoadData.bind(this)}
              onPageSizeChange={(ps: number) => {this.setState({ pageSize: 100, page: 0 })}}
              onChangePage={this._onChangePage.bind(this)}
            />
          </ColumnContainer>
          
          {this.state.showDeleteConfirm && <SweetAlert
            warning
            //style={{ display: "block", marginTop: "-100px" }}
            title={t('message:confirm_delete')}
            onConfirm={this._onDelete.bind(this)}
            onCancel={() => this.setState({ showDeleteConfirm: false})}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={t('button:delete')}
            cancelBtnText={t('button:cancel')}
            showCancel
          >
          </SweetAlert>}

        </IconCard>
      </ColumnContainer>
    )
  }

  _getTransferSettingMoMoWithDebounce(filterDTO: FilterDTO) {
    this.props.getTransferSettingMoMoList(filterDTO).then((response: any) => {
      if (response && !response.errorCode) {
        this.setState({ data: response.data, totalCount: response.totalCount });
      } else {
        showError(this.props.t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _search(event: any) {
    event.persist();
    const { target: { value } } = event;
    this.setState({ searchString: value, page: 0 });

    let filter: FilterDTO = new FilterDTO();
    filter.page = 0;
    filter.pageSize = this.state.pageSize;
    filter.searchString = value;

    this._getTransferSettingMoMoWithDebounce(filter);    
  }

  private _getColumns() {
    const { t } = this.props;
    return [
      { Header: t('transferSettingMoMo:name'), accessor: "name", sortable: false, filterable: false },
      { Header: t('transferSettingMoMo:number'), accessor: "number", sortable: false, filterable: false },
      { Header: t('transferSettingMoMo:from_amount'), accessor: "fromAmount", sortable: false, filterable: false },
      { Header: t('transferSettingMoMo:to_amount'), accessor: "toAmount", sortable: false, filterable: false },
      { Header: t('transferSettingMoMo:sale_price'), accessor: "salePrice", sortable: false, filterable: false },
      { Header: t('transferSettingMoMo:multiple_sale_price'), accessor: "multipleSalePrice", sortable: false, filterable: false },
      { Header: t('transferSettingMoMo:commission'), accessor: "commission", sortable: false, filterable: false },
      { Header: '', accessor: "actions", sortable: false, filterable: false }
    ]
  }

  private _onEdit(transferSettingMoMo: TransferSettingMoMoDTO) {
    this.props.setEditingTransferSettingMoMo(transferSettingMoMo);
    history.push(URL.TransferSettingMoMoDetail.replace(':id', transferSettingMoMo.id.toString()));
  }

  private _onDelete() {
    const { t } = this.props;

    this.setState({ showDeleteConfirm: false });
    this.props.deleteTransferSettingMoMo(this.state.deletingId).then((response: any) => {
      if (response && !response.errorCode) {
        toast.success(t('message:delete_success'));
        this._onRefresh();
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _mapData(data: TransferSettingMoMoDTO[]) {
    if (!data) return [];

    return data.map((u: TransferSettingMoMoDTO) => {
      return {
        ...u,
        fromAmount: currency(u.fromAmount),
        toAmount: currency(u.toAmount),
        salePrice: currency(u.salePrice),
        multipleSalePrice: currency(u.multipleSalePrice),
        commission: currency(u.commission),
        actions: (
          <div className="actions-right">
            <IconButton className='small-action' onClick={this._onEdit.bind(this, u)}> <Edit /> </IconButton>
            <IconButton className='small-action' onClick={() => this.setState({ showDeleteConfirm: true, deletingId: u.id })}> <Delete color='error' /> </IconButton>
          </div>
        )
      }
    });
  }

  private _onLoadData(tableState: any, instance: any) {
    let filter: FilterDTO = new FilterDTO(tableState);

    this._getTransferSettingMoMoWithDebounce(filter);
  }

  private _onRefresh() {
    let filter: FilterDTO = new FilterDTO();
    filter.page = this.state.page;
    filter.pageSize = this.state.pageSize;
    filter.searchString = this.state.searchString || '';

    this._getTransferSettingMoMoWithDebounce(filter);
  }

  private _onChangePage(pageIndex: any) {
    this.setState({ page: pageIndex});
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(TransferSettingMoMo)));