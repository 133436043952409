export class PaymentAccountDTO {
  public id: number;
  public name: string;
  public bankId: number;
  public accountNumber: string;
  public amount: number;

  constructor() {
    this.id = 0;
    this.name = '';
    this.bankId = 0;
    this.accountNumber = '';
    this.amount = 0;
  }
}