import { FilterDTO, IncomeDTO } from '../../DTO';

const INCOME_REQUEST: string = 'INCOME_REQUEST';

const INCOME_GET_LIST_SUCCESS: string = 'INCOME_GET_LIST_SUCCESS';
const INCOME_GET_LIST_FAIL: string = 'INCOME_GET_LIST_FAIL';

const INCOME_SET_EDITING_INCOME: string = 'INCOME_SET_EDITING_INCOME';

const INCOME_GET_BY_ID_SUCCESS: string = 'INCOME_GET_BY_ID_SUCCESS';
const INCOME_GET_BY_ID_FAIL: string = 'INCOME_GET_BY_ID_FAIL';

const INCOME_SAVE_SUCCESS: string = 'INCOME_SAVE_SUCCESS';
const INCOME_SAVE_FAIL: string = 'INCOME_SAVE_FAIL';

const INCOME_DELETE_SUCCESS: string = 'INCOME_DELETE_SUCCESS';
const INCOME_DELETE_FAIL: string = 'INCOME_DELETE_FAIL';

const INCOME_APPROVE_SUCCESS: string = 'INCOME_APPROVE_SUCCESS';
const INCOME_APPROVE_FAIL: string = 'INCOME_APPROVE_FAIL';

const INCOME_CANCEL_SUCCESS: string = 'INCOME_CANCEL_SUCCESS';
const INCOME_CANCEL_FAIL: string = 'INCOME_CANCEL_FAIL';

const INCOME_EXPORT_LIST_SUCCESS: string = 'INCOME_EXPORT_LIST_SUCCESS';
const INCOME_EXPORT_LIST_FAIL: string = 'INCOME_EXPORT_LIST_FAIL';

const initialState = {
  editingIncome: {},
}

export function incomeReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case INCOME_SET_EDITING_INCOME:
      return Object.assign({}, state, {
        editingIncome: action.payload
      });

    default:
      return state;

  }

}

export function getListIncome(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [INCOME_REQUEST, INCOME_GET_LIST_SUCCESS, INCOME_GET_LIST_FAIL],
      EndPoint: `Incomes${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingIncome(income: IncomeDTO) {
  return {
    type: INCOME_SET_EDITING_INCOME,
    payload: income
  }
}

export function getIncomeById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [INCOME_REQUEST, INCOME_GET_BY_ID_SUCCESS, INCOME_GET_BY_ID_FAIL],
      EndPoint: `Incomes\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveIncome(income: IncomeDTO, submit: boolean) {
  let initRequest: RequestInit = {
    method: income.id ? 'PATCH' : 'POST',
    body: JSON.stringify(income),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [INCOME_REQUEST, INCOME_SAVE_SUCCESS, INCOME_SAVE_FAIL],
      EndPoint: `Incomes\\${submit}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteIncome(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [INCOME_REQUEST, INCOME_DELETE_SUCCESS, INCOME_DELETE_FAIL],
      EndPoint: 'Incomes',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function approveIncome(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [INCOME_REQUEST, INCOME_APPROVE_SUCCESS, INCOME_APPROVE_FAIL],
      EndPoint: 'Incomes\\Approve',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function cancelIncome(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [INCOME_REQUEST, INCOME_CANCEL_SUCCESS, INCOME_CANCEL_FAIL],
      EndPoint: 'Incomes\\Cancel',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function exportIncome(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [INCOME_REQUEST, INCOME_EXPORT_LIST_SUCCESS, INCOME_EXPORT_LIST_FAIL],
      EndPoint: `Incomes\\export${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}