import * as React from 'react';
import cx from "classnames";
import sideBarStyle from './SideBarStyle';
import { withStyles, Hidden, Drawer, List, ListItem, ListItemText, Collapse, ListItemIcon } from '@material-ui/core';
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import { HeaderLinks } from '../';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';

interface ISideBarWrapperProps {
  className: string;
  user?: any;
  headerLinks?: any;
  links: any;
}

interface ISideBarWrapperStates {}

export class SideBarWrapper extends React.Component<ISideBarWrapperProps, ISideBarWrapperStates> {
  private _ps: any;

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1 && this.refs.sidebarWrapper) {
      this._ps = new PerfectScrollbar(this.refs.sidebarWrapper as Element, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1 && this._ps) {
      this._ps.destroy();
    }
  }

  render() {
    const { className, user, headerLinks, links } = this.props;
    return (
      <div className={className} ref="sidebarWrapper">
        {user}
        {headerLinks}
        {links}
      </div>
    );
  }

}

interface ISideBarProps extends WithTranslation {
  classes: any;

  open: boolean;
  miniActive: boolean;
  bgColor: string;
  logo: any;
  logoText: string;
  image: any;
  color: string;
  routes: any[];

  handleDrawerToggle?: any;

  activeRoutes: any[];
}

interface ISideBarStates {
  openAvatar: boolean;
  miniActive: boolean;
}

function mapStateToProps(state: any) {
  return ({
    activeRoutes: state.common.activeRoutes
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({})
}

class SideBar extends React.Component<ISideBarProps, ISideBarStates> {

  constructor(props: ISideBarProps) {
    super(props);
    this.state = {
      openAvatar: false,
      miniActive: true
    }
  }

  private _activeMenu(path: string, paths: string[]) {
    const { activeRoutes } = this.props;
    return activeRoutes && activeRoutes[0] && (path === activeRoutes[0].path || (paths && paths.some(p => p === activeRoutes[0].path)));
  }

  private _openCollapse(collapse: string) {
    this.setState({ [collapse]: !(this.state[collapse as (keyof ISideBarStates)]) } as Pick<ISideBarStates, keyof ISideBarStates>);
  }

  render() {
    const { classes, color, bgColor, logo, logoText, routes, image, t } = this.props;

    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: false
      });

    const logoNormal =
      classes.logoNormal +
      " " +
      cx({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.logoNormalSidebarMiniRTL]:
          false && this.props.miniActive && this.state.miniActive,
        [classes.logoNormalRTL]: false
      });

    const logoMini =
      classes.logoMini +
      " " +
      cx({
        [classes.logoMiniRTL]: false
      });

    const logoClasses =
      classes.logo +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white"
      });

    var brand = (
      <div className={logoClasses}>
        <a href="/" className={logoMini}>
          <img src={logo} alt="logo" className={classes.img} />
        </a>
        <a href="/" className={logoNormal}>
          {logoText}
        </a>
      </div>
    );

    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    
      var links = (
        <List className={classes.list}>
          {routes.map((prop, key) => {
            if (prop.redirect) {
              return null;
            }
            if (prop.collapse) {
              const navLinkClasses =
                classes.itemLink +
                " " +
                cx({
                  [" " + classes.collapseActive]: this._activeMenu(prop.path, prop.paths)
                });
              const itemText =
                classes.itemText +
                " " +
                cx({
                  [classes.itemTextMini]:
                    this.props.miniActive && this.state.miniActive,
                  [classes.itemTextMiniRTL]:
                    false && this.props.miniActive && this.state.miniActive,
                  [classes.itemTextRTL]: false
                });
              const collapseItemText =
                classes.collapseItemText +
                " " +
                cx({
                  [classes.collapseItemTextMini]:
                    this.props.miniActive && this.state.miniActive,
                  [classes.collapseItemTextMiniRTL]:
                    false && this.props.miniActive && this.state.miniActive,
                  [classes.collapseItemTextRTL]: false
                });
              const itemIcon =
                classes.itemIcon +
                " " +
                cx({
                  [classes.itemIconRTL]: false
                });
              const caret =
                classes.caret +
                " " +
                cx({
                  [classes.caretRTL]: false
                });
              return (
                <ListItem key={key} className={classes.item}>
                  <NavLink
                    to={'#'}
                    className={navLinkClasses}
                    onClick={() => this._openCollapse(prop.state)}
                  >
                    <ListItemIcon className={itemIcon}>
                      <prop.icon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t(prop.name)}
                      secondary={
                        <b
                          className={
                            caret +
                            " " +
                            (this.state[prop.state] ? classes.caretActive : "")
                          }
                        />
                      }
                      disableTypography={true}
                      className={itemText}
                    />
                  </NavLink>
                  <Collapse in={this.state[prop.state]} unmountOnExit>
                    <List className={classes.list + " " + classes.collapseList}>
                      {prop.children.map((prop: any, key: any) => {
                        if (prop.redirect) {
                          return null;
                        }
                        const navLinkClasses =
                          classes.collapseItemLink +
                          " " +
                          cx({
                            [" " + classes[color]]: this._activeMenu(prop.path, prop.paths)
                          });
                        return (
                          <ListItem key={key} className={classes.collapseItem}>
                            <NavLink to={prop.path} className={navLinkClasses}>
                              <ListItemIcon className={itemIcon}>
                                <prop.icon />
                              </ListItemIcon>
                              <ListItemText
                                primary={t(prop.name)}
                                disableTypography={true}
                                className={collapseItemText}
                              />
                            </NavLink>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </ListItem>
              );
            }
            const navLinkClasses =
              classes.itemLink +
              " " +
              cx({
                [" " + classes[color]]: this._activeMenu(prop.path, prop.paths)
              });
            const itemText =
              classes.itemText +
              " " +
              cx({
                [classes.itemTextMini]:
                  this.props.miniActive && this.state.miniActive,
                [classes.itemTextMiniRTL]:
                  false && this.props.miniActive && this.state.miniActive,
                [classes.itemTextRTL]: false
              });
            const itemIcon =
              classes.itemIcon +
              " " +
              cx({
                [classes.itemIconRTL]: false
              });
            return (
              <ListItem key={key} className={classes.item}>
                <NavLink to={prop.path} className={navLinkClasses}>
                  <ListItemIcon className={itemIcon}>
                    <prop.icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={t(prop.name)}
                    disableTypography={true}
                    className={itemText}
                  />
                </NavLink>
              </ListItem>
            );
          })}
        </List>
      );
      
    return (
      <div ref="mainPanel">
        <Hidden mdUp>
          <Drawer
            variant='temporary'
            anchor='left'
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}>
            {brand}
            <SideBarWrapper
              className={sidebarWrapper}
              // user={user}
              headerLinks={<HeaderLinks />}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={"left"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}
          >
            {brand}
            <SideBarWrapper
              className={sidebarWrapper}
              // user={user}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    )
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(sideBarStyle)(SideBar)));