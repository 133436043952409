import { withStyles } from '@material-ui/core';
import { Clear, Save, Check, Send } from '@material-ui/icons';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button } from '..';
import { WorkflowButtonDTO } from '../../DTO';
import styles from '../../styles';

interface IStateButtonProps extends WithTranslation {
  classes: any;
  workflowButton: WorkflowButtonDTO[];
  onSave: any;
}

function StateButtons(props: IStateButtonProps) {
  return (
    <>
      {props.workflowButton ? 
        props.workflowButton.map((b: WorkflowButtonDTO, idx: number) => (
          <Button color={b.color} customClass={b.class} round onClick={() => props.onSave(b.actionName)} key={idx}>
            { b.icon === 'Save' ? <Save className={props.classes.icons} /> : null }
            { b.icon === 'Clear' ? <Clear className={props.classes.icons} /> : null }
            { b.icon === 'Check' ? <Check className={props.classes.icons} /> : null }
            { b.icon === 'Send' ? <Send className={props.classes.icons} /> : null }
            {props.t(`button:${b.textCode}`)}
            {/* <Icon className={props.classes.icons}>{b.icon}</Icon> {props.t(`button:${b.textCode}`)} */}
          </Button>
        ))
      : null}
    </>
  )
}

export default withStyles(styles)(withTranslation()(StateButtons));