import { FilterDTO, MessageDTO } from '../../DTO';

const MESSAGE_REQUEST: string = 'MESSAGE_REQUEST';

const MESSAGE_GET_LIST_SUCCESS: string = 'MESSAGE_GET_LIST_SUCCESS';
const MESSAGE_GET_LIST_FAIL: string = 'MESSAGE_GET_LIST_FAIL';

const MESSAGE_SET_EDITING_MESSAGE: string = 'MESSAGE_SET_EDITING_MESSAGE';

const MESSAGE_GET_BY_ID_SUCCESS: string = 'MESSAGE_GET_BY_ID_SUCCESS';
const MESSAGE_GET_BY_ID_FAIL: string = 'MESSAGE_GET_BY_ID_FAIL';

const MESSAGE_SAVE_SUCCESS: string = 'MESSAGE_SAVE_SUCCESS';
const MESSAGE_SAVE_FAIL: string = 'MESSAGE_SAVE_FAIL';

const MESSAGE_DELETE_SUCCESS: string = 'MESSAGE_DELETE_SUCCESS';
const MESSAGE_DELETE_FAIL: string = 'MESSAGE_DELETE_FAIL';

const initialState = {
  editingMessage: {},
}

export function messageReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case MESSAGE_SET_EDITING_MESSAGE:
      return Object.assign({}, state, {
        editingMessage: action.payload
      });

    default:
      return state;

  }

}

export function getListMessage(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [MESSAGE_REQUEST, MESSAGE_GET_LIST_SUCCESS, MESSAGE_GET_LIST_FAIL],
      EndPoint: `Messages${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingMessage(message: MessageDTO) {
  return {
    type: MESSAGE_SET_EDITING_MESSAGE,
    payload: message
  }
}

export function getMessageById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [MESSAGE_REQUEST, MESSAGE_GET_BY_ID_SUCCESS, MESSAGE_GET_BY_ID_FAIL],
      EndPoint: `Messages\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveMessage(message: MessageDTO) {
  let initRequest: RequestInit = {
    method: message.id ? 'PATCH' : 'POST',
    body: JSON.stringify(message),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [MESSAGE_REQUEST, MESSAGE_SAVE_SUCCESS, MESSAGE_SAVE_FAIL],
      EndPoint: 'Messages',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteMessage(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [MESSAGE_REQUEST, MESSAGE_DELETE_SUCCESS, MESSAGE_DELETE_FAIL],
      EndPoint: 'Messages',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}