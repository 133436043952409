import { FilterDTO, BuySimDTO } from '../../DTO';

const BUY_SIM_REQUEST: string = 'BUY_SIM_REQUEST';

const BUY_SIM_GET_LIST_SUCCESS: string = 'BUY_SIM_GET_LIST_SUCCESS';
const BUY_SIM_GET_LIST_FAIL: string = 'BUY_SIM_GET_LIST_FAIL';

const BUY_SIM_SET_EDITING_BUY_SIM: string = 'BUY_SIM_SET_EDITING_BUY_SIM';

const BUY_SIM_GET_BY_ID_SUCCESS: string = 'BUY_SIM_GET_BY_ID_SUCCESS';
const BUY_SIM_GET_BY_ID_FAIL: string = 'BUY_SIM_GET_BY_ID_FAIL';

const BUY_SIM_SAVE_SUCCESS: string = 'BUY_SIM_SAVE_SUCCESS';
const BUY_SIM_SAVE_FAIL: string = 'BUY_SIM_SAVE_FAIL';

const BUY_SIM_DELETE_SUCCESS: string = 'BUY_SIM_DELETE_SUCCESS';
const BUY_SIM_DELETE_FAIL: string = 'BUY_SIM_DELETE_FAIL';

const BUY_SIM_APPROVE_SUCCESS: string = 'BUY_SIM_APPROVE_SUCCESS';
const BUY_SIM_APPROVE_FAIL: string = 'BUY_SIM_APPROVE_FAIL';

const BUY_SIM_CANCEL_SUCCESS: string = 'BUY_SIM_CANCEL_SUCCESS';
const BUY_SIM_CANCEL_FAIL: string = 'BUY_SIM_CANCEL_FAIL';

const BUY_SIM_EXPORT_LIST_SUCCESS: string = 'BUY_SIM_EXPORT_LIST_SUCCESS';
const BUY_SIM_EXPORT_LIST_FAIL: string = 'BUY_SIM_EXPORT_LIST_FAIL';

const initialState = {
  editingBuySim: {},
}

export function buySimReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case BUY_SIM_SET_EDITING_BUY_SIM:
      return Object.assign({}, state, {
        editingBuySim: action.payload
      });

    default:
      return state;

  }

}

export function getListBuySim(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [BUY_SIM_REQUEST, BUY_SIM_GET_LIST_SUCCESS, BUY_SIM_GET_LIST_FAIL],
      EndPoint: `BuySims${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingBuySim(buySim: BuySimDTO) {
  return {
    type: BUY_SIM_SET_EDITING_BUY_SIM,
    payload: buySim
  }
}

export function getBuySimById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [BUY_SIM_REQUEST, BUY_SIM_GET_BY_ID_SUCCESS, BUY_SIM_GET_BY_ID_FAIL],
      EndPoint: `BuySims\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveBuySim(buySim: BuySimDTO, submit: boolean) {
  let initRequest: RequestInit = {
    method: buySim.id ? 'PATCH' : 'POST',
    body: JSON.stringify(buySim),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [BUY_SIM_REQUEST, BUY_SIM_SAVE_SUCCESS, BUY_SIM_SAVE_FAIL],
      EndPoint: `BuySims\\${submit}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteBuySim(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [BUY_SIM_REQUEST, BUY_SIM_DELETE_SUCCESS, BUY_SIM_DELETE_FAIL],
      EndPoint: 'BuySims',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function approveBuySim(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [BUY_SIM_REQUEST, BUY_SIM_APPROVE_SUCCESS, BUY_SIM_APPROVE_FAIL],
      EndPoint: 'BuySims\\Approve',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function cancelBuySim(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [BUY_SIM_REQUEST, BUY_SIM_CANCEL_SUCCESS, BUY_SIM_CANCEL_FAIL],
      EndPoint: 'BuySims\\Cancel',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function exportBuySim(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [BUY_SIM_REQUEST, BUY_SIM_EXPORT_LIST_SUCCESS, BUY_SIM_EXPORT_LIST_FAIL],
      EndPoint: `BuySims\\export${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}