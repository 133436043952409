import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL, AutoNumberResetType } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer, CustomSelect, Checkbox } from '../../Components';
import { Code, Save, KeyboardArrowLeft } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getAutoNumberById, saveAutoNumber } from '../../Redux/AutoNumber/AutoNumberReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { AutoNumberDTO, SelectItemDTO } from '../../DTO';

interface IAutoNumberDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  autoNumber: AutoNumberDTO;

  setActiveRoute: ActionCreator<any>,
  getAutoNumberById: ActionCreator<any>,
  saveAutoNumber: ActionCreator<any>,
}

interface IAutoNumberDetailStates {
  isSaving?: boolean;

  autoNumber: AutoNumberDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    autoNumber: state.autoNumber.editingAutoNumber,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getAutoNumberById: (id: number) => dispatch(getAutoNumberById(id)),
    saveAutoNumber: (autoNumber: AutoNumberDTO) => dispatch(saveAutoNumber(autoNumber)),
  })
}

class AutoNumberDetail extends React.Component<IAutoNumberDetailProps, IAutoNumberDetailStates> {

  constructor(props: IAutoNumberDetailProps) {
    super(props);
    this.state = {
      autoNumber: {...new AutoNumberDTO(), ...props.autoNumber}
    }
  }

  componentDidMount() {
    const { setActiveRoute, match, getAutoNumberById, t } = this.props;

    setActiveRoute([{path: URL.AutoNumber, name: this.props.t('menu:autoNumber')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getAutoNumberById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.AutoNumber);
          } else {
            this.setState({ autoNumber: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes } = this.props;
    let { showValidation, autoNumber: { number, name, resetType, isDisabled } } = this.state;

    let resetTypeSelectItems = [
      { value: AutoNumberResetType.None, text: t('autoNumber:reset_type_none') } as SelectItemDTO,
      { value: AutoNumberResetType.Daily, text: t('autoNumber:reset_type_daily') } as SelectItemDTO,
      { value: AutoNumberResetType.Monthly, text: t('autoNumber:reset_type_monthly') } as SelectItemDTO,
      { value: AutoNumberResetType.Yearly, text: t('autoNumber:reset_type_yearly') } as SelectItemDTO,
    ]

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={Code}
          iconColor={'green'}
          title={t('common:information')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('autoNumber:number')} </FormLabel>
              <>
                <InputText name='number' values={number} onChange={this._onTextChange.bind(this)} />
                {showValidation && !number && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('autoNumber:name')} </FormLabel>
              <>
                <InputText name='name' values={name} onChange={this._onTextChange.bind(this)} />
                {showValidation && !name && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('autoNumber:reset_type')} </FormLabel>
              <CustomSelect 
                name={'resetType'}
                value={resetType}
                onChange={this._onTextChange.bind(this)}
                items={resetTypeSelectItems}
                label={t('autoNumber:reset_type')} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('autoNumber:is_disabled')} </FormLabel>
              <Checkbox
                checked={isDisabled}
                name='isDisabled'
                onClick={this._onCheckboxChange.bind(this)} />
            </ColumnContainer>

          </ColumnContainer>

          <GridContainer justify="center">
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button>
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button>
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.AutoNumber)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ autoNumber: {...this.state.autoNumber, [name]: value }});    
  }

  private _onCheckboxChange(event: any) {
    event.persist && event.persist();
    const { target: { name, checked } } = event;
    let autoNumber = this.state.autoNumber;
    this.setState({ autoNumber: {...autoNumber, [name]: checked }});
  }

  private _onValidate() {
    let { autoNumber: { number, name } } = this.state;

    return name && number;
  }

  private _onSave(close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { autoNumber: { number, name, resetType, isDisabled } } = this.state;
    let autoNumber = new AutoNumberDTO();
    autoNumber.id = parseInt(this.props.match.params.id);
    autoNumber.number = number;
    autoNumber.name = name;
    autoNumber.resetType = resetType;
    autoNumber.isDisabled = isDisabled;

    this.props.saveAutoNumber(autoNumber).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        autoNumber.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close) {
          history.push(URL.AutoNumber);
        } else if (parseInt(match.params.id) === 0) {
          history.push(URL.AutoNumberDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(AutoNumberDetail)));