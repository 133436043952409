import { FilterDTO, TopUpDTO } from '../../DTO';

const TOP_UP_REQUEST: string = 'TOP_UP_REQUEST';

const TOP_UP_GET_LIST_SUCCESS: string = 'TOP_UP_GET_LIST_SUCCESS';
const TOP_UP_GET_LIST_FAIL: string = 'TOP_UP_GET_LIST_FAIL';

const TOP_UP_SET_EDITING_TOP_UP: string = 'TOP_UP_SET_EDITING_TOP_UP';

const TOP_UP_GET_BY_ID_SUCCESS: string = 'TOP_UP_GET_BY_ID_SUCCESS';
const TOP_UP_GET_BY_ID_FAIL: string = 'TOP_UP_GET_BY_ID_FAIL';

const TOP_UP_SAVE_SUCCESS: string = 'TOP_UP_SAVE_SUCCESS';
const TOP_UP_SAVE_FAIL: string = 'TOP_UP_SAVE_FAIL';

const TOP_UP_DELETE_SUCCESS: string = 'TOP_UP_DELETE_SUCCESS';
const TOP_UP_DELETE_FAIL: string = 'TOP_UP_DELETE_FAIL';

const TOP_UP_APPROVE_SUCCESS: string = 'TOP_UP_APPROVE_SUCCESS';
const TOP_UP_APPROVE_FAIL: string = 'TOP_UP_APPROVE_FAIL';

const TOP_UP_CANCEL_SUCCESS: string = 'TOP_UP_CANCEL_SUCCESS';
const TOP_UP_CANCEL_FAIL: string = 'TOP_UP_CANCEL_FAIL';

const TOP_UP_EXPORT_LIST_SUCCESS: string = 'TOP_UP_EXPORT_LIST_SUCCESS';
const TOP_UP_EXPORT_LIST_FAIL: string = 'TOP_UP_EXPORT_LIST_FAIL';

const initialState = {
  editingTopUp: {},
}

export function topUpReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case TOP_UP_SET_EDITING_TOP_UP:
      return Object.assign({}, state, {
        editingTopUp: action.payload
      });

    default:
      return state;

  }

}

export function getListTopUp(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TOP_UP_REQUEST, TOP_UP_GET_LIST_SUCCESS, TOP_UP_GET_LIST_FAIL],
      EndPoint: `TopUps${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingTopUp(topUp: TopUpDTO) {
  return {
    type: TOP_UP_SET_EDITING_TOP_UP,
    payload: topUp
  }
}

export function getTopUpById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TOP_UP_REQUEST, TOP_UP_GET_BY_ID_SUCCESS, TOP_UP_GET_BY_ID_FAIL],
      EndPoint: `TopUps\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveTopUp(topUp: TopUpDTO, submit: boolean) {
  let initRequest: RequestInit = {
    method: topUp.id ? 'PATCH' : 'POST',
    body: JSON.stringify(topUp),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TOP_UP_REQUEST, TOP_UP_SAVE_SUCCESS, TOP_UP_SAVE_FAIL],
      EndPoint: `TopUps\\${submit}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteTopUp(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TOP_UP_REQUEST, TOP_UP_DELETE_SUCCESS, TOP_UP_DELETE_FAIL],
      EndPoint: 'TopUps',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function approveTopUp(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TOP_UP_REQUEST, TOP_UP_APPROVE_SUCCESS, TOP_UP_APPROVE_FAIL],
      EndPoint: 'TopUps\\Approve',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function cancelTopUp(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TOP_UP_REQUEST, TOP_UP_CANCEL_SUCCESS, TOP_UP_CANCEL_FAIL],
      EndPoint: 'TopUps\\Cancel',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function exportTopUp(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TOP_UP_REQUEST, TOP_UP_EXPORT_LIST_SUCCESS, TOP_UP_EXPORT_LIST_FAIL],
      EndPoint: `TopUps\\export${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}