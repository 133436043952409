import { FilterDTO, TransferDTO, transferToFormData } from '../../DTO';

const TRANSFER_REQUEST: string = 'TRANSFER_REQUEST';

const TRANSFER_GET_LIST_SUCCESS: string = 'TRANSFER_GET_LIST_SUCCESS';
const TRANSFER_GET_LIST_FAIL: string = 'TRANSFER_GET_LIST_FAIL';

const TRANSFER_SET_EDITING_TRANSFER: string = 'TRANSFER_SET_EDITING_TRANSFER';

const TRANSFER_GET_BY_ID_SUCCESS: string = 'TRANSFER_GET_BY_ID_SUCCESS';
const TRANSFER_GET_BY_ID_FAIL: string = 'TRANSFER_GET_BY_ID_FAIL';

const TRANSFER_SAVE_SUCCESS: string = 'TRANSFER_SAVE_SUCCESS';
const TRANSFER_SAVE_FAIL: string = 'TRANSFER_SAVE_FAIL';

const TRANSFER_DELETE_SUCCESS: string = 'TRANSFER_DELETE_SUCCESS';
const TRANSFER_DELETE_FAIL: string = 'TRANSFER_DELETE_FAIL';

const TRANSFER_APPROVE_SUCCESS: string = 'TRANSFER_APPROVE_SUCCESS';
const TRANSFER_APPROVE_FAIL: string = 'TRANSFER_APPROVE_FAIL';

const TRANSFER_CANCEL_SUCCESS: string = 'TRANSFER_CANCEL_SUCCESS';
const TRANSFER_CANCEL_FAIL: string = 'TRANSFER_CANCEL_FAIL';

const TRANSFER_UPDATE_CONFIRM_SUCCESS: string = 'TRANSFER_UPDATE_CONFIRM_SUCCESS';
const TRANSFER_UPDATE_CONFIRM_FAIL: string = 'TRANSFER_UPDATE_CONFIRM_FAIL';

const TRANSFER_EXPORT_LIST_SUCCESS: string = 'TRANSFER_EXPORT_LIST_SUCCESS';
const TRANSFER_EXPORT_LIST_FAIL: string = 'TRANSFER_EXPORT_LIST_FAIL';

const TRANSFER_PRINT_SUCCESS: string = 'TRANSFER_PRINT_SUCCESS';
const TRANSFER_PRINT_FAIL: string = 'TRANSFER_PRINT_FAIL';

const initialState = {
  editingTransfer: {},
}

export function transferReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case TRANSFER_SET_EDITING_TRANSFER:
      return Object.assign({}, state, {
        editingTransfer: action.payload
      });

    default:
      return state;

  }

}

export function getListTransfer(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_REQUEST, TRANSFER_GET_LIST_SUCCESS, TRANSFER_GET_LIST_FAIL],
      EndPoint: `Transfers${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingTransfer(transfer: TransferDTO) {
  return {
    type: TRANSFER_SET_EDITING_TRANSFER,
    payload: transfer
  }
}

export function getTransferById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_REQUEST, TRANSFER_GET_BY_ID_SUCCESS, TRANSFER_GET_BY_ID_FAIL],
      EndPoint: `Transfers\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveTransfer(transfer: TransferDTO, submit: boolean, reference: any, confirmation: any) {
  let formData = transferToFormData(transfer);
  if (reference) {
    formData.append('reference', reference);
  }
  if (confirmation) {
    formData.append('confirmation', confirmation);
  }

  let initRequest: RequestInit = {
    method: transfer.id ? 'PATCH' : 'POST',
    body: formData,
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_REQUEST, TRANSFER_SAVE_SUCCESS, TRANSFER_SAVE_FAIL],
      EndPoint: `Transfers\\${submit}`,
      AuthenticationRequire: true,
      Config: initRequest,
      IsUpload: true
    }
  }
}

export function deleteTransfer(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_REQUEST, TRANSFER_DELETE_SUCCESS, TRANSFER_DELETE_FAIL],
      EndPoint: 'Transfers',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function approveTransfer(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_REQUEST, TRANSFER_APPROVE_SUCCESS, TRANSFER_APPROVE_FAIL],
      EndPoint: 'Transfers\\Approve',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function cancelTransfer(id: number) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_REQUEST, TRANSFER_CANCEL_SUCCESS, TRANSFER_CANCEL_FAIL],
      EndPoint: 'Transfers\\Cancel',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function updateConfirmation(id: number, confirmation: any) {
  let formData = new FormData();
  formData.append("confirmation", confirmation);

  let initRequest: RequestInit = {
    method: 'POST',
    body: formData,
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_REQUEST, TRANSFER_UPDATE_CONFIRM_SUCCESS, TRANSFER_UPDATE_CONFIRM_FAIL],
      EndPoint: `Transfers\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest,
      IsUpload: true
    }
  }
}

export function exportTransfer(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_REQUEST, TRANSFER_EXPORT_LIST_SUCCESS, TRANSFER_EXPORT_LIST_FAIL],
      EndPoint: `Transfers\\export${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function printTransfer(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TRANSFER_REQUEST, TRANSFER_PRINT_SUCCESS, TRANSFER_PRINT_FAIL],
      EndPoint: `Transfers\\print\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest,
      ResponseHtml : true
    }
  }
}