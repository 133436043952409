import { GlobalConst } from "../Consts";

let moment = require('moment');

export class TransferMoMoDTO {
  public id: number;
  public number: string;
  public clientId: number;
  public toAccountName: string;
  public toAccountNumber: string;
  public detail: string;
  public amount: number;
  public fee: number;
  public commission: number;
  public discount: number;
  public paymentAccountId: number;
  public note: string;
  public referenceFileName: string;
  public confirmationFileName: string;
  public status: number;
  public transferDate: string;
  public createdUser: string;
  public approvedById: number | null;
  public approvedDate: string;
  public branchId: number;
  public commissionUserId: number;
  public payByCash: boolean;
  public receiveAccountId: number | null;

  constructor() {
    this.id = 0;
    this.number = '';
    this.clientId = 0;
    this.toAccountName = '';
    this.toAccountNumber = '';
    this.detail = '';
    this.amount = 0;
    this.fee = 0;
    this.commission = 0;
    this.discount = 0;
    this.paymentAccountId = 0;
    this.note = '';
    this.referenceFileName = '';
    this.confirmationFileName = '';
    this.status = 0;
    this.transferDate = moment().format(GlobalConst.DATE_TIME_FORMAT);
    this.createdUser = '';
    this.branchId = 0;
    this.commissionUserId = 0;
    this.approvedById = null;
    this.approvedDate = '';
    this.payByCash = true;
    this.receiveAccountId = null;
  }
}

export function transferMoMoToFormData(transferMoMo: TransferMoMoDTO) {
  let formData = new FormData();
  formData.append('id', transferMoMo.id.toString());
  formData.append('clientId', transferMoMo.clientId.toString());
  formData.append('toAccountName', transferMoMo.toAccountName ?? '');
  formData.append('toAccountNumber', transferMoMo.toAccountNumber ?? '');
  formData.append('detail', transferMoMo.detail ?? '');
  formData.append('amount', transferMoMo.amount.toString());
  formData.append('fee', transferMoMo.fee.toString());
  formData.append('commission', transferMoMo.commission.toString());
  formData.append('discount', transferMoMo.discount.toString());
  formData.append('paymentAccountId', transferMoMo.paymentAccountId.toString());
  formData.append('note', transferMoMo.note ?? '');
  formData.append('transferDate', transferMoMo.transferDate ?? '');
  formData.append('branchId', transferMoMo.branchId.toString());
  formData.append('commissionUserId', transferMoMo.commissionUserId.toString());
  formData.append('referenceFileName', transferMoMo.referenceFileName ?? '');
  formData.append('confirmationFileName', transferMoMo.confirmationFileName ?? '');
  formData.append('payByCash', transferMoMo.payByCash ? 'true' : 'false');
  formData.append('receiveAccountId', transferMoMo.receiveAccountId?.toString() ?? '');

  return formData;
}