import React from 'react';
import { IconCard, DataList, Button, CustomInput, ColumnContainer } from '../../Components';
import { People, Edit, Replay, Add, Delete, Search } from '@material-ui/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ActionCreator } from 'redux';
import { connect } from 'react-redux';
import { getListUser, deleteUser } from '../../Redux/User/UserReducer';
import { IconButton, withStyles, InputAdornment } from '@material-ui/core';
import { URL, GlobalConst } from '../../Consts';
import history from '../../history';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { FilterDTO, UserDTO, SelectItemDTO } from '../../DTO';
import styles from '../../styles';
import SweetAlert from "react-bootstrap-sweetalert";
import { setEditingUser } from '../../Redux/User/UserReducer';
import { toast } from 'react-toastify';
import { showError } from '../../Utils';
import _ from 'lodash';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';

interface IUserProps extends WithTranslation {
  classes: any;

  branchSelectItems: SelectItemDTO[],

  getUserList: ActionCreator<any>,
  setActiveRoute: ActionCreator<any>,
  setEditingUser: ActionCreator<any>,
  deleteUser: ActionCreator<any>,
  getBranchSelectItems: ActionCreator<any>,
}

interface IUserStates {
  data: UserDTO[],
  page: number;
  pageSize: number;
  totalCount: number;

  searchString?: string;
  showDeleteConfirm?: boolean;
  deletingId?: number;
}

function mapStateToProps(state: any) {
  return ({
    branchSelectItems: state.branch.branchSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    getUserList: (filterDTO: FilterDTO) => dispatch(getListUser(filterDTO)),
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    setEditingUser: (user: UserDTO) => dispatch(setEditingUser(user)),
    deleteUser: (id: number) => dispatch(deleteUser(id)),
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
  })
}

class User extends React.Component<IUserProps, IUserStates> {

  constructor(props: IUserProps){
    super(props);

    this.state = {
      data: [],
      page: 0,
      pageSize: 20,
      totalCount: 0,
    }

    this._getUserWithDebounce = _.debounce(this._getUserWithDebounce.bind(this), GlobalConst.DEBOUNCE_TIME);
  }

  componentDidMount() {
    !this.props.branchSelectItems?.length && this.props.getBranchSelectItems();
    
    this.props.setActiveRoute([{path: URL.User, name: this.props.t('menu:user')}]);
  }

  render() {
    const { t, classes } = this.props;
    let { data, page, pageSize, totalCount } = this.state;
    
    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={People}
          iconColor={'green'}
          title={t('user:user')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>
            <CustomInput
              labelText={t('common:search')}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                value: this.state.searchString,
                name: 'searchString',
                onChange: this._searchChange.bind(this)
              }}
            />

            <div className={classes.right}>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onEdit.bind(this, new UserDTO())}>
                <Add className={classes.icons} /> {t('button:add')}
              </Button>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onRefresh.bind(this)}>
                <Replay className={classes.icons} /> {t('button:refresh')}
              </Button>
            </div>
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs:12}]}>
            <DataList 
              currentPage={page}
              pageSize={pageSize}
              totalPage={Math.ceil(totalCount / pageSize)}
              data={this._mapData(data)}
              columns={this._getColumns()}
              fetchData={this._onLoadData.bind(this)}
              onChangePage={this._onChangePage.bind(this)}
              onPageSizeChange={(ps: number) => { this.setState({ pageSize: ps, page: 0 }, () => this._search()) }}
            />
          </ColumnContainer>

          {this.state.showDeleteConfirm && <SweetAlert
            warning
            //style={{ display: "block", marginTop: "-100px" }}
            title={t('message:confirm_delete')}
            onConfirm={this._onDelete.bind(this)}
            onCancel={() => this.setState({ showDeleteConfirm: false})}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={t('button:delete')}
            cancelBtnText={t('button:cancel')}
            showCancel
          >
          </SweetAlert>}

        </IconCard>
      </ColumnContainer>
    )
  }

  _getUserWithDebounce(filterDTO: FilterDTO) {
    this.props.getUserList(filterDTO).then((response: any) => {
      if (response && !response.errorCode) {
        this.setState({ data: response.data, totalCount: response.totalCount });
      } else {
        showError(this.props.t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _searchChange(event: any) {
    event.persist();
    const { target: { value } } = event;
    this.setState({ searchString: value, page: 0 }, () => this._search());
  }

  private _search() {
    let filter: FilterDTO = new FilterDTO();
    filter.page = this.state.page;
    filter.pageSize = this.state.pageSize;
    filter.searchString = this.state.searchString ?? '';

    this._getUserWithDebounce(filter);    
  }

  private _getColumns() {
    const { t } = this.props;
    return [
      { Header: t('user:number'), accessor: "number", sortable: false, filterable: false },
      { Header: t('user:last_name'), accessor: "lastName", sortable: false, filterable: false },
      { Header: t('user:first_name'), accessor: "firstName", sortable: false, filterable: false },
      { Header: t('user:display_name'), accessor: "name", sortable: false, filterable: false },
      { Header: t('user:login_name'), accessor: "loginName", sortable: false, filterable: false },
      { Header: t('user:phone'), accessor: "phone", sortable: false, filterable: false },
      { Header: t('branch:name'), accessor: "branchName", sortable: false, filterable: false },
      { Header: '', accessor: "actions", sortable: false, filterable: false }
    ]
  }

  private _onEdit(user: UserDTO) {
    this.props.setEditingUser(user);
    history.push(URL.UserDetail.replace(':id', user.id.toString()));
  }

  private _onDelete() {
    const { t } = this.props;

    this.setState({ showDeleteConfirm: false });
    this.props.deleteUser(this.state.deletingId).then((response: any) => {
      if (response && !response.errorCode) {
        toast.success(t('message:delete_success'));
        this._onRefresh();
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    });
  }

  private _mapData(data: UserDTO[]) {
    if (!data) return [];

    return data.map((u: UserDTO) => {
      return {
        ...u,
        branchName: this.props.branchSelectItems.find(b => b.value === u.branchId)?.text ?? '',
        actions: (
          <div className="actions-right">
            <IconButton className='small-action' onClick={this._onEdit.bind(this, u)}> <Edit /> </IconButton>
            <IconButton className='small-action' onClick={() => this.setState({ showDeleteConfirm: true, deletingId: u.id })}> <Delete color='error' /> </IconButton>
          </div>
        )
      }
    });
  }

  private _onLoadData(tableState: any, instance: any) {
    let filter: FilterDTO = new FilterDTO(tableState);

    this._getUserWithDebounce(filter);
  }

  private _onRefresh() {
    let filter: FilterDTO = new FilterDTO();
    filter.page = this.state.page;
    filter.pageSize = this.state.pageSize;    
    filter.searchString = this.state.searchString || '';

    this._getUserWithDebounce(filter);
  }

  private _onChangePage(pageIndex: any) {
    this.setState({ page: pageIndex});
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(User)));