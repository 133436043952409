import React from "react";
import GridContainer from './GridContainer';
import GridItem from './GridItem';

export default function ColumnContainer(props: any) {
  let children = React.Children.toArray(props.children);
  return (
    <GridContainer>
      {children?.map((item: any, idx: number) => {
        return (
          <GridItem {...props.columnWidth[idx % props.columnWidth.length]} key={idx}>
            {item}
          </GridItem>
        )
      })}
    </GridContainer>
  )
}