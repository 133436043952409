import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from '../../styles';
import { withStyles, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { FindInPage, CloudUpload, Close, GetApp } from '@material-ui/icons';
import { CustomRadio, Button } from '../../Components';
import { SimUpdateType } from '../../Consts';
import { InputText, ColumnContainer } from '../../Components';
import { toast } from 'react-toastify';
import { showError } from '../../Utils';
import { ActionCreator } from 'redux';
import { uploadSims } from '../../Redux/Sim/SimReducer';

interface IUploadSimProps extends WithTranslation {
  classes: any;
  
  handleClose?: any;

  uploadSims: ActionCreator<any>,
}

interface IUploadSimStates {
  fileName: string;
  updateType: number;
  uploading: boolean;
}

function mapStateToProps(state: any) {
  return ({})
}

function mapDispatchToProps(dispatch: any) {
  return ({
    uploadSims: (formData: any) => dispatch(uploadSims(formData)),
  })
}

class UploadSim extends React.Component<IUploadSimProps, IUploadSimStates> {

  private _fileSelector: any;

  constructor(props: IUploadSimProps) {
    super(props);

    this.state = {
      updateType: 1,
      uploading: false,
      fileName: '',
    }
    
    this._fileSelector = React.createRef();
  }

  render() {
    const { t, classes } = this.props;
    let { updateType, uploading, fileName } = this.state;
    
    return (
      <Dialog 
        open 
        onClose={this._onClose.bind(this, false)} 
        aria-labelledby="form-dialog-title"
        scroll='body'>

        <DialogTitle>{t('sim:upload')}</DialogTitle>
        
        <DialogContent dividers>
          <ColumnContainer columnWidth={[{xs:11}]}>
            <input type='file' accept='.xlsx' onChange={this._onFileChange.bind(this)} hidden ref={this._fileSelector} />
            <ColumnContainer columnWidth={[{xs:12, sm:8}, {xs:12, sm:4}]}>
              <InputText values={fileName} disabled={true} />
              <Button round onClick={this._onSelectFile.bind(this)} color="primary" disabled={uploading}>
                <FindInPage className={classes.icons} /> {t('button:select_file')}
              </Button>
            </ColumnContainer>
            <CustomRadio 
              checked={updateType.toString() === SimUpdateType.ClearAll.toString()}
              onChange={this._updateTypeChange.bind(this)}
              value={SimUpdateType.ClearAll.toString()}
              label={t('sim:update_type_clear_all')} />
            <CustomRadio 
              checked={updateType.toString() === SimUpdateType.UpdateOnly.toString()}
              onChange={this._updateTypeChange.bind(this)}
              value={SimUpdateType.UpdateOnly.toString()}
              label={t('sim:update_type_update_only')} />
          </ColumnContainer>
        </DialogContent>

        <DialogActions>
          <Button round onClick={this._onUpload.bind(this)} color="primary" disabled={uploading || fileName === ''}>
            <CloudUpload className={classes.icons} /> {t('button:upload')}
          </Button>
          <Button round onClick={this._onDownloadTemplate.bind(this)} color="primary">
            <GetApp className={classes.icons} /> {t('button:down_template')}
          </Button>
          <Button round onClick={this._onClose.bind(this)} disabled={uploading}>
            <Close className={classes.icons} /> {t('button:cancel')}
          </Button>

        </DialogActions>

      </Dialog>
    )
  }

  private _onFileChange(event: any) {
    event.persist && event.persist();
    this.setState({ fileName: event.target.files[0].name })
  }

  private _onClose(success: boolean = false) {
    const { handleClose } = this.props;
    handleClose && handleClose(success);
  }

  private _updateTypeChange(event: any) {
    event.persist && event.persist();
    this.setState({ updateType: parseInt(event.target.value) });
  }

  private _onSelectFile() {
    this._fileSelector.current.click();
  }

  private _onDownloadTemplate() {
    window.open('/Upload/Sim/Sim.xlsx', '_blank');
  }

  private _onUpload() {
    const { t } = this.props;
    let { updateType } = this.state;

    this.setState({ uploading: true });
    let formData = new FormData();
    formData.append('file', this._fileSelector.current.files[0]);
    formData.append('type', updateType.toString());

    this.props.uploadSims(formData).then((response: any) => {
      this.setState({ uploading: false });
      if (response && !response.errorCode) {
        toast.success(t('message:upload_success'));
        this._onClose(true);
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    });
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(UploadSim)));