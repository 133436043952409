import React from "react";
import NumberFormat from 'react-number-format';
import { TextField, FormControl, withStyles } from "@material-ui/core";
import inputStyle from "./CustomInputStyle";

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (value: number, name: string) => void;
  name: string;
  isInt: boolean;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange(props.isInt ? parseInt(values.value ?? '0') : parseFloat(values.value ?? '0'), props.name);
      }}
    />
  );
}

function InputText(props: any) {
  const { classes, labelProps, values, onChange, name, numberOnly, disabled, type, multiline } = props;
  return (
    <FormControl fullWidth>
      {numberOnly ? 
        (<TextField
          className={classes.inputNoLabel}
          label={labelProps}
          value={values}
          onChange={onChange}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
            inputProps: props
          }}
          disabled={disabled}
        />) : 
        (<TextField
          className={classes.inputNoLabel}
          label={labelProps}
          value={values}
          disabled={disabled}
          type={type}
          multiline={multiline}
          onChange={(event) => {
            event && event.persist && event.persist();
            onChange(event?.target?.value ?? '', name)
          }}
        />)}
    </FormControl>
  )
}

export default withStyles(inputStyle)(InputText);