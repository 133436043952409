import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, CustomSelect, InputText, ColumnContainer } from '../../Components';
import { People, Save, KeyboardArrowLeft } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { RoleDTO, SelectItemDTO } from '../../DTO';
import { getRoleById, saveRole } from '../../Redux/Role/RoleReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { getFunctionSelectItems } from '../../Redux/Function/FunctionReducer';

interface IRoleDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  role: RoleDTO;
  functionSelectItems: SelectItemDTO[],

  setActiveRoute: ActionCreator<any>,
  getRoleById: ActionCreator<any>,
  saveRole: ActionCreator<any>,
  getFunctionSelectItems: ActionCreator<any>,
}

interface IRoleDetailStates {
  isSaving?: boolean;

  role: RoleDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    role: state.role.editingRole,
    functionSelectItems: state.function.functionSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getRoleById: (id: number) => dispatch(getRoleById(id)),
    saveRole: (role: RoleDTO) => dispatch(saveRole(role)),
    getFunctionSelectItems: () => dispatch(getFunctionSelectItems()),
  })
}

class RoleDetail extends React.Component<IRoleDetailProps, IRoleDetailStates> {

  constructor(props: IRoleDetailProps) {
    super(props);
    this.state = {
      role: {...new RoleDTO(), ...props.role}
    }
  }

  componentDidMount() {
    const { setActiveRoute, match, getRoleById, t, functionSelectItems, getFunctionSelectItems } = this.props;

    !functionSelectItems?.length && getFunctionSelectItems();

    setActiveRoute([{path: URL.Role, name: this.props.t('menu:role')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getRoleById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.Role);
          } else {
            this.setState({ role: response.data });
          }
        } else {
          showError(t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes, functionSelectItems } = this.props;
    let { showValidation, role: { name } } = this.state;

    let functionSorted = functionSelectItems?.map(i => { return { ...i, text: t(`function:${i.text}`)} }) ?? [];
    functionSorted.sort((a: SelectItemDTO, b: SelectItemDTO) => { return a.text > b.text ? 1 : -1 });

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={People}
          iconColor={'green'}
          title={t('common:information')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('role:name')} </FormLabel>
              <>
                <InputText name='name' values={name} onChange={this._onTextChange.bind(this)} />
                {showValidation && !name && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('function:name')} </FormLabel>
              <CustomSelect 
                name={'functionId'}
                multiple
                value={this.state.role.functionIds ?? []}
                onChange={this._onFunctionChange.bind(this)}
                items={functionSorted}
                label={t('function:name')} />
            </ColumnContainer>
          </ColumnContainer>

          <GridContainer justify="center">
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button>
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button>
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.Role)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onFunctionChange(value: number[]) {
    this.setState({ role: { ...this.state.role, functionIds: value } });
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ role: {...this.state.role, [name]: value }});    
  }

  private _onValidate() {
    let { role: { name } } = this.state;

    return name;
  }

  private _onSave(close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { role: { name, functionIds } } = this.state;
    let role = new RoleDTO();
    role.id = parseInt(this.props.match.params.id);
    role.name = name;
    role.functionIds = functionIds;

    this.props.saveRole(role).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        role.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close) {
          history.push(URL.Role);
        } else if (parseInt(match.params.id) === 0) {
          history.push(URL.RoleDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(RoleDetail)));