import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { GlobalConst, InventoryExportStatus, Permission, URL, WorkflowStatus } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, InputText, ColumnContainer, StateButtons, DataList, CustomSelect, Autocomplete } from '../../Components';
import { People, Save, KeyboardArrowLeft, Add, Clear } from '@material-ui/icons';
import { Dialog, DialogTitle, DialogContent, DialogActions, FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { approveInventoryExport, cancelInventoryExport, getInventoryExportById, saveInventoryExport } from '../../Redux/InventoryExport/InventoryExportReducer';
import { currency, showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { CurrentUserDTO, InventoryExportDTO, InventoryExportItemDTO, SelectItemDTO } from '../../DTO';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
import { getProductSelectItems } from '../../Redux/Product/ProductReducer';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';
let moment = require('moment');

interface IInventoryExportDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  currentUser: CurrentUserDTO;
  inventoryExport: InventoryExportDTO;
  productSelectItems: SelectItemDTO[];
  branchSelectItems: SelectItemDTO[];

  setActiveRoute: ActionCreator<any>,
  getInventoryExportById: ActionCreator<any>,
  saveInventoryExport: ActionCreator<any>,
  getProductSelectItems: ActionCreator<any>,
  getBranchSelectItems: ActionCreator<any>,
  approveInventoryExport: ActionCreator<any>,
  cancelInventoryExport: ActionCreator<any>,
}

interface IInventoryExportDetailStates {
  isSaving?: boolean;

  inventoryExport: InventoryExportDTO;
  showValidation?: boolean;

  showEditDialog?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    inventoryExport: state.inventoryExport.editingInventoryExport,
    productSelectItems: state.product.productSelectItems,
    branchSelectItems: state.branch.branchSelectItems,
    currentUser: state.user.currentUser,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getInventoryExportById: (id: number) => dispatch(getInventoryExportById(id)),
    saveInventoryExport: (inventoryExport: InventoryExportDTO, action: string) => dispatch(saveInventoryExport(inventoryExport, action)),
    getProductSelectItems: () => dispatch(getProductSelectItems()),
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
    approveInventoryExport: (id: number) => dispatch(approveInventoryExport(id)),
    cancelInventoryExport: (id: number) => dispatch(cancelInventoryExport(id)),
  })
}

class InventoryExportDetail extends React.Component<IInventoryExportDetailProps, IInventoryExportDetailStates> {

  constructor(props: IInventoryExportDetailProps) {
    super(props);
    this.state = {
      inventoryExport: {...new InventoryExportDTO(), ...props.inventoryExport}
    }
  }

  componentDidMount() {
    const { setActiveRoute, match, getInventoryExportById, t } = this.props;

    setActiveRoute([{path: URL.InventoryExport, name: this.props.t('menu:inventoryExport')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    !this.props.productSelectItems?.length && this.props.getProductSelectItems();
    !this.props.branchSelectItems?.length && this.props.getBranchSelectItems();

    if (match.params.id > 0) {
      getInventoryExportById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.InventoryExport);
          } else {
            this.setState({ inventoryExport: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes, currentUser, branchSelectItems } = this.props;
    let { inventoryExport: { id, number, status, dateExport, fromBranchId, toBranchId, items, workflow } } = this.state;

    let statusStr = WorkflowStatus.find(s => s.value === status)?.text ?? '';
    if (statusStr) {
      statusStr = t(`status:${statusStr}`);
    }
    let tDate = dateExport ? moment(dateExport, GlobalConst.DATE_TIME_FORMAT) : moment(new Date());
    let now = moment(new Date());
    let readOnly = (status === InventoryExportStatus.Approved || status === InventoryExportStatus.Canceled);

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={People}
          iconColor={'green'}
          title={t('common:information')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('inventoryExport:number')} </FormLabel>
              <InputText values={number} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('inventoryExport:status')} </FormLabel>
              <InputText values={statusStr} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('inventoryExport:date')} </FormLabel>
              {!readOnly ? 
                <Datetime
                  dateFormat={GlobalConst.DATE_FORMAT}
                  timeFormat={"HH:mm"}
                  inputProps={{ placeholder: t('inventoryExport:date') }}
                  onChange={this._onDateChange.bind(this)}
                  value={dateExport}
                /> : <InputText disabled values={dateExport} /> }
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('inventoryExport:from_branch')} </FormLabel>
              <Autocomplete
                value={fromBranchId}
                disabled={readOnly}
                options={branchSelectItems}
                onChange={(value: number) => this.setState({ inventoryExport: { ...this.state.inventoryExport, fromBranchId: value } }) }
              />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('inventoryExport:to_branch')} </FormLabel>
              <Autocomplete
                value={toBranchId}
                disabled={readOnly}
                options={branchSelectItems}
                onChange={(value: number) => this.setState({ inventoryExport: { ...this.state.inventoryExport, toBranchId: value } }) }
              />
            </ColumnContainer>
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs:12}]}>
            {!readOnly ? <ColumnContainer columnWidth={[{xs:12}]}>
              <div className={classes.right}>
                <Button color='primary' customClass={classes.marginRight} round onClick={this._onAddItem.bind(this)}>
                  <Add className={classes.icons} /> {t('button:add')}
                </Button>
              </div>
            </ColumnContainer> : null}

            <ColumnContainer columnWidth={[{xs:12}]}>
              <DataList 
                currentPage={0}
                pageSize={1000}
                totalPage={1}
                data={this._mapData(items, readOnly)}
                columns={this._getColumns()}
                // fetchData={this._onLoadData.bind(this)}
                // onChangePage={this._onChangePage.bind(this)}
                hidePaging
              />
            </ColumnContainer>
          </ColumnContainer>

          <GridContainer justify="center">
            { id === 0 ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSaveWF.bind(this, 'save')} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button> : 
            <StateButtons workflowButton={workflow?.workflowStates.find(s => s.status === status)?.workflowButtons ?? []} onSave={this._onSaveWF.bind(this)} /> }
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.InventoryExport)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:back')}
            </Button>
          </GridContainer>

        </IconCard>

      </ColumnContainer>
    )
  }

  private _onApprove() {
    const { t } = this.props;
    this.setState({ isSaving: true });

    this.props.approveInventoryExport(this.state.inventoryExport.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.InventoryExport);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

  private _onCancel() {
    const { t } = this.props;
    this.setState({ isSaving: true });
    
    this.props.cancelInventoryExport(this.state.inventoryExport.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.InventoryExport);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

  private _onAddItem() {
    this.setState({ inventoryExport: {...this.state.inventoryExport, items: [...this.state.inventoryExport.items, {id: 0, productId: 0, productName: '', quantity: 0}]} })
  }

  private _getColumns() {
    const { t } = this.props;
    return [
      { Header: t('inventoryExport:product'), accessor: "productId", sortable: false, filterable: false },
      { Header: t('inventoryExport:quantity'), accessor: "quantity", sortable: false, filterable: false },
    ]
  }

  private _mapData(data: InventoryExportItemDTO[], readOnly: boolean) {
    const { t, productSelectItems } = this.props;
    if (!data) return [];

    return data.map((i: InventoryExportItemDTO, idx: number) => {
      return {
        ...i,
        productId: <CustomSelect name='productId' disabled={readOnly} value={i.productId} onChange={this._onChange.bind(this, idx)} items={productSelectItems} />,
        quantity: <InputText name='quantity' disabled={readOnly} value={i.quantity} numberOnly thousandSeparator onChange={this._onChange.bind(this, idx)} />,
      }
    });
  }

  private _onChange(idx: number, value: number, name: string) {
    let exports = [...this.state.inventoryExport.items];
    exports[idx][name] = value;
    this.setState({ inventoryExport: {...this.state.inventoryExport, items: exports} })
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ inventoryExport: {...this.state.inventoryExport, [name]: value }});    
  }

  private _onDateChange(value: string | Moment) {
    let inventoryExport = this.state.inventoryExport;
    inventoryExport.dateExport = moment(value).format(GlobalConst.DATE_TIME_FORMAT);
    this.setState({ inventoryExport: inventoryExport });
  }

  private _onValidate() {
    let { inventoryExport: { dateExport, fromBranchId, toBranchId, items } } = this.state;

    return dateExport && items && items.length && fromBranchId && toBranchId;
  }

  private _onSaveWF(action: string) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false, isSaving: true });

    const { t, match } = this.props;
    let { inventoryExport: { dateExport, fromBranchId, toBranchId, items } } = this.state;
    let inventoryExport = new InventoryExportDTO();
    inventoryExport.id = parseInt(this.props.match.params.id);
    inventoryExport.dateExport = dateExport;
    inventoryExport.fromBranchId = fromBranchId;
    inventoryExport.toBranchId = toBranchId;
    inventoryExport.items = items

    this.props.saveInventoryExport(inventoryExport, action).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        inventoryExport.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (parseInt(match.params.id) === 0) {
          history.push(URL.InventoryExportDetail.replace(':id', response.data.id));
        } else if (action !== 'save') { 
          history.push(URL.InventoryExport);
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

  // private _onSave(close: boolean = true) {
  //   if (!this._onValidate()) {
  //     this.setState({ showValidation: true });
  //     return;
  //   }
  //   this.setState({ showValidation: false });

  //   this.setState({ isSaving: true});
  //   const { t, match } = this.props;
  //   let { inventoryExport: { dateExport } } = this.state;
  //   let inventoryExport = new InventoryExportDTO();
  //   inventoryExport.id = parseInt(this.props.match.params.id);
  //   inventoryExport.dateExport = dateExport;

  //   this.props.saveInventoryExport(inventoryExport).then((response: any) => {
  //     this.setState({ isSaving: false });
  //     if (response && !response.errorCode) {
  //       inventoryExport.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
  //       if (close) {
  //         history.push(URL.InventoryExport);
  //       } else if (parseInt(match.params.id) === 0) {
  //         history.push(URL.InventoryExportDetail.replace(':id', response.data.id));
  //       }
  //     } else {
  //       showError(t, response.errorCode, response.errorMessage);        
  //     }
  //   })
  // }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(InventoryExportDetail)));