import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer } from '../../Components';
import { Web, Save, KeyboardArrowLeft } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { FunctionDTO } from '../../DTO';
import { getFunctionById, saveFunction } from '../../Redux/Function/FunctionReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';

interface IFunctionDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  function: FunctionDTO;

  setActiveRoute: ActionCreator<any>,
  getFunctionById: ActionCreator<any>,
  saveFunction: ActionCreator<any>,
}

interface IFunctionDetailStates {
  isSaving?: boolean;

  function: FunctionDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    function: state.function.editingFunction,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getFunctionById: (id: number) => dispatch(getFunctionById(id)),
    saveFunction: (func: FunctionDTO) => dispatch(saveFunction(func)),
  })
}

class FunctionDetail extends React.Component<IFunctionDetailProps, IFunctionDetailStates> {

  constructor(props: IFunctionDetailProps) {
    super(props);
    this.state = {
      function: {...new FunctionDTO(), ...props.function}
    }
  }

  componentDidMount() {
    const { setActiveRoute, match, getFunctionById, t } = this.props;

    setActiveRoute([{path: URL.Function, name: this.props.t('menu:function')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getFunctionById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.Function);
          } else {
            this.setState({ function: response.data });
          }
        } else {
          showError(t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes } = this.props;
    let { showValidation, function: { name } } = this.state;

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={Web}
          iconColor={'green'}
          title={t('common:information')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('function:name')} </FormLabel>
              <>
                <InputText name='name' values={name} onChange={this._onTextChange.bind(this)} />
                {showValidation && !name && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

          </ColumnContainer>

          <GridContainer justify="center">
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button>
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button>
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.Function)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ function: {...this.state.function, [name]: value }});    
  }

  private _onValidate() {
    let { function: { name } } = this.state;

    return name;
  }

  private _onSave(close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { function: { name } } = this.state;
    let func = new FunctionDTO();
    func.id = parseInt(this.props.match.params.id);
    func.name = name;    

    this.props.saveFunction(func).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        func.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close) {
          history.push(URL.Function);
        } else if (parseInt(match.params.id) === 0) {
          history.push(URL.FunctionDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(FunctionDetail)));