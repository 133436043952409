import { FilterDTO, LoanDTO } from '../../DTO';

const LOAN_REQUEST: string = 'LOAN_REQUEST';

const LOAN_GET_LIST_SUCCESS: string = 'LOAN_GET_LIST_SUCCESS';
const LOAN_GET_LIST_FAIL: string = 'LOAN_GET_LIST_FAIL';

const LOAN_SET_EDITING_LOAN: string = 'LOAN_SET_EDITING_LOAN';

const LOAN_GET_BY_ID_SUCCESS: string = 'LOAN_GET_BY_ID_SUCCESS';
const LOAN_GET_BY_ID_FAIL: string = 'LOAN_GET_BY_ID_FAIL';

const LOAN_SAVE_SUCCESS: string = 'LOAN_SAVE_SUCCESS';
const LOAN_SAVE_FAIL: string = 'LOAN_SAVE_FAIL';

const LOAN_DELETE_SUCCESS: string = 'LOAN_DELETE_SUCCESS';
const LOAN_DELETE_FAIL: string = 'LOAN_DELETE_FAIL';

const initialState = {
  editingLoan: {},
}

export function loanReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case LOAN_SET_EDITING_LOAN:
      return Object.assign({}, state, {
        editingLoan: action.payload
      });

    default:
      return state;

  }

}

export function getListLoan(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [LOAN_REQUEST, LOAN_GET_LIST_SUCCESS, LOAN_GET_LIST_FAIL],
      EndPoint: `Loans${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingLoan(loan: LoanDTO) {
  return {
    type: LOAN_SET_EDITING_LOAN,
    payload: loan
  }
}

export function getLoanById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [LOAN_REQUEST, LOAN_GET_BY_ID_SUCCESS, LOAN_GET_BY_ID_FAIL],
      EndPoint: `Loans\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveLoan(loan: LoanDTO, action: string) {
  let initRequest: RequestInit = {
    method: 'POST',
    body: JSON.stringify(loan),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [LOAN_REQUEST, LOAN_SAVE_SUCCESS, LOAN_SAVE_FAIL],
      EndPoint: `Loans?action=${action}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteLoan(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [LOAN_REQUEST, LOAN_DELETE_SUCCESS, LOAN_DELETE_FAIL],
      EndPoint: 'Loans',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}