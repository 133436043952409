import { ProductStatisticDTO } from "./ProductStatisticDTO";

export class ProductDTO {
  public id: number;
  public name: string;
  public multipleSalePrice: number;
  public salePrice: number;
  public note: string;
  public isService: boolean;
  public productStatistics: ProductStatisticDTO[];

  constructor() {
    this.id = 0;
    this.name = '';
    this.multipleSalePrice = 0;
    this.salePrice = 0;
    this.note = '';
    this.isService = false;
    this.productStatistics = [];
  }
}