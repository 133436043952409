import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, CustomInput, Button, Danger, InputText, ColumnContainer, Checkbox, DataList } from '../../Components';
import { People, Save, KeyboardArrowLeft, Assignment } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { ProductDTO, ProductStatisticDTO } from '../../DTO';
import { getProductById, saveProduct } from '../../Redux/Product/ProductReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';

interface IProductDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  product: ProductDTO;

  setActiveRoute: ActionCreator<any>,
  getProductById: ActionCreator<any>,
  saveProduct: ActionCreator<any>,
}

interface IProductDetailStates {
  isSaving?: boolean;

  product: ProductDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    product: state.product.editingProduct,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getProductById: (id: number) => dispatch(getProductById(id)),
    saveProduct: (product: ProductDTO) => dispatch(saveProduct(product)),
  })
}

class ProductDetail extends React.Component<IProductDetailProps, IProductDetailStates> {

  constructor(props: IProductDetailProps) {
    super(props);
    this.state = {
      product: {...new ProductDTO(), ...props.product},
    }
  }

  componentDidMount() {
    const { setActiveRoute, match, getProductById, t } = this.props;

    setActiveRoute([{path: URL.Product, name: this.props.t('menu:product')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getProductById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.Product);
          } else {
            this.setState({ product: response.data });
          }
        } else {
          showError(t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes } = this.props;
    let { showValidation, product: { name, isService, salePrice, multipleSalePrice, note, productStatistics } } = this.state;

    return (
      <>
        <ColumnContainer columnWidth={[{xs:12, sm:8}, {xs:12, sm:4}]}>
          <IconCard
            icon={People}
            iconColor={'green'}
            title={t('common:information')}>

            <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

              <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
                <FormLabel className={classes.labelHorizontal} required> {t('product:name')} </FormLabel>
                <InputText name='name' values={name} onChange={this._onTextChange.bind(this)} />
                {showValidation && !name && <Danger>{t('message:required')}</Danger>}
              </ColumnContainer>

              <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
                <FormLabel className={classes.labelHorizontal}> {t('product:note')} </FormLabel>
                <InputText name='note' values={note} onChange={this._onTextChange.bind(this)} />
              </ColumnContainer>

              <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
                <FormLabel className={classes.labelHorizontal} required> {t('product:sale_price')} </FormLabel>
                <InputText name='multipleSalePrice' values={multipleSalePrice} numberOnly thousandSeparator onChange={this._onNumberChange.bind(this)} />
              </ColumnContainer>

              <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
                <FormLabel className={classes.labelHorizontal} required> {t('product:multiple_sale_price')} </FormLabel>
                <InputText name='salePrice' values={salePrice} numberOnly thousandSeparator onChange={this._onNumberChange.bind(this)} />
              </ColumnContainer>

              <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
                <FormLabel className={classes.labelHorizontal}> {t('product:is_service')} </FormLabel>
                <Checkbox
                  checked={isService}
                  name='isService'
                  onClick={this._onCheckboxChange.bind(this)}
                />
              </ColumnContainer>
            </ColumnContainer>

            <GridContainer justify="center">
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false)} disabled={this.state.isSaving}>
                <Save className={classes.icons} /> {t('button:save')}
              </Button>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true)} disabled={this.state.isSaving}>
                <Save className={classes.icons} /> {t('button:save_close')}
              </Button>
              <Button customClass={classes.marginRight} round onClick={() => history.push(URL.Product)}>
                <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
              </Button>
            </GridContainer>

          </IconCard>

          <IconCard
            icon={Assignment}
            iconColor={'green'}
            title={t('product:statistic')}>
            <ColumnContainer columnWidth={[{xs:12}]}>
              <DataList 
                currentPage={0}
                pageSize={1000}
                totalPage={1}
                data={this._mapData(productStatistics)}
                columns={this._getColumns()}
                hidePaging
                // fetchData={this._onLoadData.bind(this)}
                // onChangePage={this._onChangePage.bind(this)}
              />
            </ColumnContainer>
          </IconCard>
        </ColumnContainer>
      </>
    )
  }

  private _getColumns() {
    const { t } = this.props;
    return [
      { Header: t('product:branch'), accessor: "branchName", sortable: false, filterable: false },
      { Header: t('product:quantity'), accessor: "quantity", sortable: false, filterable: false },
    ]
  }

  private _mapData(data: ProductStatisticDTO[]) {
    const { t } = this.props;
    if (!data) return [];

    return data.map((p: ProductStatisticDTO) => {
      return {
        ...p
      }
    });
  }

  private _onCheckboxChange(event: any) {
    event.persist && event.persist();
    const { target: { name, checked } } = event;
    let product = this.state.product;
    this.setState({ product: {...product, [name]: checked }});
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ product: {...this.state.product, [name]: value }});
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ product: {...this.state.product, [name]: value }});
  }

  private _onValidate() {
    let { product: { name } } = this.state;

    return name;
  }

  private _onSave(close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { product: { name, note, multipleSalePrice, salePrice, isService } } = this.state;
    let product = new ProductDTO();
    product.id = parseInt(this.props.match.params.id);
    product.name = name;
    product.note = note;
    product.multipleSalePrice = multipleSalePrice;
    product.salePrice = salePrice;
    product.isService = isService;
    
    this.props.saveProduct(product).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        product.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close) {
          history.push(URL.Product);
        } else if (parseInt(match.params.id) === 0) {
          history.push(URL.ProductDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(ProductDetail)));