import React from 'react';
import { IconCard, DataList, Button, CustomInput, ColumnContainer } from '../../Components';
import { Assignment, Edit, Replay, Add, Delete, Search, OpenInNew } from '@material-ui/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ActionCreator } from 'redux';
import { connect } from 'react-redux';
import { getListTelecomService, deleteTelecomService, setEditingTelecomService } from '../../Redux/TelecomService/TelecomServiceReducer';
import { IconButton, withStyles, InputAdornment } from '@material-ui/core';
import { URL, GlobalConst } from '../../Consts';
import history from '../../history';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { FilterDTO, TelecomServiceDTO, SelectItemDTO } from '../../DTO';
import styles from '../../styles';
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from 'react-toastify';
import { showError, currency } from '../../Utils';
import _ from 'lodash';
import { getNetworkProviderSelectItems } from '../../Redux/NetworkProvider/NetworkProviderReducer';

interface ITelecomServiceProps extends WithTranslation {
  classes: any;

  networkProviderSelectItems: SelectItemDTO[];

  getTelecomServiceList: ActionCreator<any>,
  setActiveRoute: ActionCreator<any>,
  setEditingTelecomService: ActionCreator<any>,
  deleteTelecomService: ActionCreator<any>,
  getNetworkProviderSelectItems: ActionCreator<any>,
}

interface ITelecomServiceStates {
  data: TelecomServiceDTO[],
  page: number;
  pageSize: number;
  totalCount: number;

  searchString: string;
  showDeleteConfirm?: boolean;
  deletingId?: number;
}

function mapStateToProps(state: any) {
  return ({
    networkProviderSelectItems: state.networkProvider.networkProviderSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    getTelecomServiceList: (filterDTO: FilterDTO) => dispatch(getListTelecomService(filterDTO)),
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    setEditingTelecomService: (telecomService: TelecomServiceDTO) => dispatch(setEditingTelecomService(telecomService)),
    deleteTelecomService: (id: number) => dispatch(deleteTelecomService(id)),
    getNetworkProviderSelectItems: () => dispatch(getNetworkProviderSelectItems()),
  })
}

class TelecomService extends React.Component<ITelecomServiceProps, ITelecomServiceStates> {

  constructor(props: ITelecomServiceProps){
    super(props);

    this.state = {
      data: [],
      page: 0,
      pageSize: 100,
      totalCount: 0,
      searchString: '',
    }

    this._getTelecomServiceWithDebounce = _.debounce(this._getTelecomServiceWithDebounce.bind(this), GlobalConst.DEBOUNCE_TIME);
  }

  componentDidMount() {
    !this.props.networkProviderSelectItems?.length && this.props.getNetworkProviderSelectItems();
    
    this.props.setActiveRoute([{path: URL.TelecomService, name: this.props.t('menu:telecomService')}]);
  }

  render() {
    const { t, classes } = this.props;
    let { data, page, pageSize, totalCount } = this.state;
    
    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={Assignment}
          iconColor={'green'}
          title={t('telecomService:telecomService')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>
            <CustomInput
              labelText={t('common:search')}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                value: this.state.searchString,
                name: 'searchString',
                onChange: this._onSearchStringChange.bind(this)
              }}
            />

            <div className={classes.right}>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onEdit.bind(this, new TelecomServiceDTO())}>
                <Add className={classes.icons} /> {t('button:add')}
              </Button>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onRefresh.bind(this)}>
                <Replay className={classes.icons} /> {t('button:refresh')}
              </Button>
            </div>
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs:12}]}>
            <DataList 
              currentPage={page}
              pageSize={pageSize}
              totalPage={Math.ceil(totalCount / pageSize)}
              data={this._mapData(data)}
              columns={this._getColumns()}
              fetchData={this._onLoadData.bind(this)}
              onChangePage={this._onChangePage.bind(this)}
            />
          </ColumnContainer>
          
          {this.state.showDeleteConfirm && <SweetAlert
            warning
            //style={{ display: "block", marginTop: "-100px" }}
            title={t('message:confirm_delete')}
            onConfirm={this._onDelete.bind(this)}
            onCancel={() => this.setState({ showDeleteConfirm: false})}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={t('button:delete')}
            cancelBtnText={t('button:cancel')}
            showCancel
          >
          </SweetAlert>}

        </IconCard>
      </ColumnContainer>
    )
  }

  _getTelecomServiceWithDebounce(filterDTO: FilterDTO) {
    this.props.getTelecomServiceList(filterDTO).then((response: any) => {
      if (response && !response.errorCode) {
        this.setState({ data: response.data, totalCount: response.totalCount });
      } else {
        showError(this.props.t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _onSearchStringChange(event: any) {
    event.persist && event.persist();
    const { target: { value } } = event;
    this.setState({ searchString: value || '', page: 0 }, () => this._search());
  }

  private _search() {
    let { pageSize, searchString } = this.state;

    let filter: FilterDTO = new FilterDTO();
    filter.page = 0;
    filter.pageSize = pageSize;
    filter.searchString = searchString;

    this._getTelecomServiceWithDebounce(filter);    
  }

  private _getColumns() {
    const { t } = this.props;
    return [
      { Header: t('telecomService:number'), accessor: "number", sortable: false, filterable: false },
      { Header: t('telecomService:name'), accessor: "name", sortable: false, filterable: false },
      // { Header: t('telecomService:sale_price'), accessor: "salePrice", sortable: false, filterable: false },
      // { Header: t('telecomService:multiple_sale_price'), accessor: "multipleSalePrice", sortable: false, filterable: false },
      // { Header: t('telecomService:price'), accessor: "price", sortable: false, filterable: false },
      { Header: t('telecomService:network_provider'), accessor: "networkProvider", sortable: false, filterable: false },
      { Header: '', accessor: "actions", sortable: false, filterable: false }
    ]
  }

  private _onEdit(telecomService: TelecomServiceDTO) {
    this.props.setEditingTelecomService(telecomService);
    history.push(URL.TelecomServiceDetail.replace(':id', telecomService.id.toString()));
  }

  private _onDelete() {
    const { t } = this.props;

    this.setState({ showDeleteConfirm: false });
    this.props.deleteTelecomService(this.state.deletingId).then((response: any) => {
      if (response && !response.errorCode) {
        toast.success(t('message:delete_success'));
        this._onRefresh();
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _mapData(data: TelecomServiceDTO[]) {
    if (!data) return [];

    return data.map((u: TelecomServiceDTO) => {
      return {
        ...u,
        salePrice: currency(u.salePrice),
        multipleSalePrice: currency(u.multipleSalePrice),
        price: currency(u.price),
        networkProvider: this.props.networkProviderSelectItems.find(p => p.value === u.networkProviderId)?.text ?? '',
        actions: (
          <div className="actions-right">
            <IconButton className='small-action' onClick={this._onEdit.bind(this, u)}> <Edit /> </IconButton>
            <IconButton className='small-action' onClick={() => window.open(URL.TelecomServiceDetail.replace(':id', u.id.toString()), '_blank')}> <OpenInNew /> </IconButton>
            <IconButton className='small-action' onClick={() => this.setState({ showDeleteConfirm: true, deletingId: u.id })}> <Delete color='error' /> </IconButton>
          </div>
        )
      }
    });
  }

  private _onLoadData(tableState: any, instance: any) {
    let filter: FilterDTO = new FilterDTO(tableState);

    this._getTelecomServiceWithDebounce(filter);
  }

  private _onRefresh() {
    let filter: FilterDTO = new FilterDTO();
    filter.page = this.state.page;
    filter.pageSize = this.state.pageSize;
    filter.searchString = this.state.searchString || '';

    this._getTelecomServiceWithDebounce(filter);
  }

  private _onChangePage(pageIndex: any) {
    this.setState({ page: pageIndex});
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(TelecomService)));