import React from 'react';
import { IconCard, DataList, Button, CustomInput, ColumnContainer } from '../../Components';
import { Assignment, Edit, Replay, Add, Delete, Search } from '@material-ui/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ActionCreator } from 'redux';
import { connect } from 'react-redux';
import { getListRole, deleteRole, setEditingRole } from '../../Redux/Role/RoleReducer';
import { IconButton, withStyles, InputAdornment } from '@material-ui/core';
import { URL, GlobalConst } from '../../Consts';
import history from '../../history';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { FilterDTO, RoleDTO } from '../../DTO';
import styles from '../../styles';
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from 'react-toastify';
import { showError } from '../../Utils';
import _ from 'lodash';

interface IRoleProps extends WithTranslation {
  classes: any;

  getRoleList: ActionCreator<any>,
  setActiveRoute: ActionCreator<any>,
  setEditingRole: ActionCreator<any>,
  deleteRole: ActionCreator<any>,
}

interface IRoleStates {
  data: RoleDTO[],
  page: number;
  pageSize: number;
  totalCount: number;

  searchString?: string;
  showDeleteConfirm?: boolean;
  deletingId?: number;
}

function mapStateToProps(state: any) {
  return ({})
}

function mapDispatchToProps(dispatch: any) {
  return ({
    getRoleList: (filterDTO: FilterDTO) => dispatch(getListRole(filterDTO)),
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    setEditingRole: (role: RoleDTO) => dispatch(setEditingRole(role)),
    deleteRole: (id: number) => dispatch(deleteRole(id)),
  })
}

class Role extends React.Component<IRoleProps, IRoleStates> {

  constructor(props: IRoleProps){
    super(props);

    this.state = {
      data: [],
      page: 0,
      pageSize: 100,
      totalCount: 0,
    }

    this._getRoleWithDebounce = _.debounce(this._getRoleWithDebounce.bind(this), GlobalConst.DEBOUNCE_TIME);
  }

  componentDidMount() {
    this.props.setActiveRoute([{path: URL.Role, name: this.props.t('menu:role')}]);
  }

  render() {
    const { t, classes } = this.props;
    let { data, page, pageSize, totalCount } = this.state;
    
    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={Assignment}
          iconColor={'green'}
          title={t('role:role')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>
            <CustomInput
              labelText={t('common:search')}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                value: this.state.searchString,
                name: 'searchString',
                onChange: this._search.bind(this)
              }}
            />

            <div className={classes.right}>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onEdit.bind(this, new RoleDTO())}>
                <Add className={classes.icons} /> {t('button:add')}
              </Button>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onRefresh.bind(this)}>
                <Replay className={classes.icons} /> {t('button:refresh')}
              </Button>
            </div>
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs:12}]}>
            <DataList 
              currentPage={page}
              pageSize={pageSize}
              totalPage={Math.ceil(totalCount / pageSize)}
              data={this._mapData(data)}
              columns={this._getColumns()}
              fetchData={this._onLoadData.bind(this)}
              onChangePage={this._onChangePage.bind(this)}
            />
          </ColumnContainer>

          {this.state.showDeleteConfirm && <SweetAlert
            warning
            //style={{ display: "block", marginTop: "-100px" }}
            title={t('message:confirm_delete')}
            onConfirm={this._onDelete.bind(this)}
            onCancel={() => this.setState({ showDeleteConfirm: false})}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={t('button:delete')}
            cancelBtnText={t('button:cancel')}
            showCancel
          >
          </SweetAlert>}

        </IconCard>
      </ColumnContainer>
    )
  }

  _getRoleWithDebounce(filterDTO: FilterDTO) {
    this.props.getRoleList(filterDTO).then((response: any) => {
      if (response && !response.errorCode) {
        this.setState({ data: response.data, totalCount: response.totalCount });
      } else {
        showError(this.props.t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _search(event: any) {
    event.persist();
    const { target: { value } } = event;
    this.setState({ searchString: value, page: 0 });

    let filter: FilterDTO = new FilterDTO();
    filter.page = 0;
    filter.pageSize = this.state.pageSize;
    filter.searchString = value;

    this._getRoleWithDebounce(filter);    
  }

  private _getColumns() {
    const { t } = this.props;
    return [
      { Header: t('role:name'), accessor: "name", sortable: false, filterable: false },
      { Header: '', accessor: "actions", sortable: false, filterable: false }
    ]
  }

  private _onEdit(role: RoleDTO) {
    this.props.setEditingRole(role);
    history.push(URL.RoleDetail.replace(':id', role.id.toString()));
  }

  private _onDelete() {
    const { t } = this.props;

    this.setState({ showDeleteConfirm: false });
    this.props.deleteRole(this.state.deletingId).then((response: any) => {
      if (response && !response.errorCode) {
        toast.success(t('message:delete_success'));
        this._onRefresh();
      } else {
        showError(this.props.t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _mapData(data: RoleDTO[]) {
    if (!data) return [];

    return data.map((u: RoleDTO) => {
      return {
        ...u,
        actions: (
          <div className="actions-right">
            <IconButton className='small-action' onClick={this._onEdit.bind(this, u)}> <Edit /> </IconButton>
            <IconButton className='small-action' onClick={() => this.setState({ showDeleteConfirm: true, deletingId: u.id })}> <Delete color='error' /> </IconButton>
          </div>
        )
      }
    });
  }

  private _onLoadData(tableState: any, instance: any) {
    let filter: FilterDTO = new FilterDTO(tableState);

    this._getRoleWithDebounce(filter);
  }

  private _onRefresh() {
    let filter: FilterDTO = new FilterDTO();
    filter.page = this.state.page;
    filter.pageSize = this.state.pageSize;
    filter.searchString = this.state.searchString || '';

    this._getRoleWithDebounce(filter);
  }

  private _onChangePage(pageIndex: any) {
    this.setState({ page: pageIndex});
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(Role)));