import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// material-ui components
import loginFooterStyle from './LoginFooterStyle';
import { withStyles } from "@material-ui/core";

function LoginFooter({ ...props }) {
  const { classes, fluid, white } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  });
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white
    });
  return (
    <footer className={classes.footer}>
      <div className={container}>
        <p className={classes.right}>
          &copy; {new Date().getFullYear()}{" "}
          <a href="tel:0786363636" target='_blank' rel="noopener noreferrer" className={anchor}>
            VŨ GIA GROUP - <b>0786.36.36.36</b>
          </a>          
        </p>
      </div>
    </footer>
  );
}

LoginFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};

export default withStyles(loginFooterStyle)(LoginFooter);
