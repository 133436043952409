import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL, GlobalConst, TransferStatus, Permission } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer, Autocomplete, Checkbox, CustomSelect } from '../../Components';
import { Money, Save, KeyboardArrowLeft, Clear } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getTopUpById, saveTopUp, approveTopUp, cancelTopUp } from '../../Redux/TopUp/TopUpReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { TopUpDTO, SelectItemDTO, CurrentUserDTO } from '../../DTO';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
import { getClientSelectItems } from '../../Redux/Client/ClientReducer';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';
import { getUserSelectItems } from '../../Redux/User/UserReducer';
import { getTopUpTypeSelectItems } from '../../Redux/TopUpType/TopUpTypeReducer';
import { getNetworkProviderSelectItems } from '../../Redux/NetworkProvider/NetworkProviderReducer';
import { getPaymentAccountSelectItems } from '../../Redux/PaymentAccount/PaymentAccountReducer';
let moment = require('moment');

interface ITopUpDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  currentUser: CurrentUserDTO;
  topUp: TopUpDTO;
  clientSelectItems: SelectItemDTO[];
  branchSelectItems: SelectItemDTO[];
  userSelectItems: SelectItemDTO[];
  topUpTypeSelectItems: SelectItemDTO[];
  networkProviderSelectItems: SelectItemDTO[];
  paymentAccountSelectItems: SelectItemDTO[];

  setActiveRoute: ActionCreator<any>,
  getTopUpById: ActionCreator<any>,
  saveTopUp: ActionCreator<any>,
  getClientSelectItems: ActionCreator<any>,
  getBranchSelectItems: ActionCreator<any>,
  getUserSelectItems: ActionCreator<any>,
  getTopUpTypeSelectItems: ActionCreator<any>,
  getNetworkProviderSelectItems: ActionCreator<any>,
  approveTopUp: ActionCreator<any>,
  cancelTopUp: ActionCreator<any>,
  getPaymentAccountSelectItems: ActionCreator<any>,
}

interface ITopUpDetailStates {
  isSaving?: boolean;

  topUp: TopUpDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    topUp: state.topUp.editingTopUp,
    currentUser: state.user.currentUser,
    clientSelectItems: state.client.clientSelectItems,
    branchSelectItems: state.branch.branchSelectItems,
    userSelectItems: state.user.userSelectItems,
    topUpTypeSelectItems: state.topUpType.topUpTypeSelectItems,
    networkProviderSelectItems: state.networkProvider.networkProviderSelectItems,
    paymentAccountSelectItems: state.paymentAccount.paymentAccountSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getTopUpById: (id: number) => dispatch(getTopUpById(id)),
    saveTopUp: (topUp: TopUpDTO, submit: boolean) => dispatch(saveTopUp(topUp, submit)),
    getClientSelectItems: () => dispatch(getClientSelectItems()),
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
    getUserSelectItems: () => dispatch(getUserSelectItems()),
    getTopUpTypeSelectItems: () => dispatch(getTopUpTypeSelectItems()),
    getNetworkProviderSelectItems: () => dispatch(getNetworkProviderSelectItems()),
    approveTopUp: (id: number) => dispatch(approveTopUp(id)),
    cancelTopUp: (id: number) => dispatch(cancelTopUp(id)),
    getPaymentAccountSelectItems: () => dispatch(getPaymentAccountSelectItems()),
  })
}

class TopUpDetail extends React.Component<ITopUpDetailProps, ITopUpDetailStates> {

  constructor(props: ITopUpDetailProps) {
    super(props);
    this.state = {
      topUp: {...new TopUpDTO(), ...props.topUp}
    }
  }

  componentDidMount() {
    !this.props.clientSelectItems?.length && this.props.getClientSelectItems();
    !this.props.branchSelectItems?.length && this.props.getBranchSelectItems();
    !this.props.userSelectItems?.length && this.props.getUserSelectItems();
    !this.props.topUpTypeSelectItems?.length && this.props.getTopUpTypeSelectItems();
    !this.props.networkProviderSelectItems?.length && this.props.getNetworkProviderSelectItems();
    !this.props.paymentAccountSelectItems?.length && this.props.getPaymentAccountSelectItems();

    const { setActiveRoute, match, getTopUpById, t } = this.props;

    setActiveRoute([{path: URL.TopUp, name: this.props.t('menu:topUp')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getTopUpById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.TopUp);
          } else {
            this.setState({ topUp: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes, currentUser, clientSelectItems, branchSelectItems, userSelectItems, topUpTypeSelectItems, networkProviderSelectItems, paymentAccountSelectItems } = this.props;
    let { showValidation, topUp: { id, number, phone, networkProviderId, date, clientId, topUpTypeId, amount, discount, price, branchId, commissionUserId, status, payByCash, receiveAccountId } } = this.state;

    const statusText = [
      { value: TransferStatus.Draft, text: t('topUp:status_draft')},
      { value: TransferStatus.PendingApprove, text: t('topUp:status_pending_approve')},
      { value: TransferStatus.Approved, text: t('topUp:status_approved')},
      { value: TransferStatus.Canceled, text: t('topUp:status_cancel')},
    ]

    let statusStr = statusText.find(s => s.value === status)?.text || '';
    let tDate = date ? moment(date, GlobalConst.DATE_TIME_FORMAT) : moment(new Date());
    let now = moment(new Date());
    let readOnly = (status === TransferStatus.Approved || status === TransferStatus.Canceled) && (tDate.year() * 12 + tDate.month() < now.year() * 12 + now.month());

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={Money}
          iconColor={'green'}
          title={t('common:information')}>

          {id && currentUser.functionIds.includes(Permission.ApproveTopUps) && status === TransferStatus.PendingApprove ? 
            <ColumnContainer columnWidth={[{xs:12, sm:12}]}>
              <div className={classes.right}>
                <Button color='primary' customClass={classes.marginRight} round onClick={this._onApprove.bind(this)} disabled={this.state.isSaving}>
                  <Save className={classes.icons} /> {t('button:approve')}
                </Button>
                <Button color='danger' customClass={classes.marginRight} round onClick={this._onCancel.bind(this)} disabled={this.state.isSaving}>
                  <Clear className={classes.icons} /> {t('button:cancel')}
                </Button>
              </div>
            </ColumnContainer>
          : null }

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('topUp:number')} </FormLabel>
              <InputText values={number} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('topUp:status')} </FormLabel>
              <InputText values={statusStr} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('topUp:date')} </FormLabel>
              <Datetime
                dateFormat={GlobalConst.DATE_FORMAT}
                timeFormat={"HH:mm"}
                inputProps={{ placeholder: t('topUp:date') }}
                onChange={this._onDateChange.bind(this)}
                value={date}
              />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('topUp:phone')} </FormLabel>
              <>
                <InputText name='phone' values={phone} onChange={this._onTextChange.bind(this)} />
                {showValidation && !phone && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('topUp:network_provider')} </FormLabel>
              <>
                <Autocomplete
                  name='networkProviderId'
                  value={networkProviderId}
                  options={networkProviderSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                />
                {showValidation && !networkProviderId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('topUp:client')} </FormLabel>
              <>
                <Autocomplete
                  name='clientId'
                  value={clientId}
                  options={clientSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  getOptionLabel={(option: SelectItemDTO) => `${option.text} - ${option.details.phone}`}
                />
                {showValidation && !clientId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('topUp:top_up_type')} </FormLabel>
              <>
                <Autocomplete
                  name='topUpTypeId'
                  value={topUpTypeId}
                  options={topUpTypeSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                />
                {showValidation && !topUpTypeId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('topUp:amount')} </FormLabel>
              <>
                <InputText 
                  name="amount"
                  numberOnly
                  thousandSeparator
                  values={amount} 
                  onChange={this._onNumberChange.bind(this)} />
                {showValidation && !amount && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('topUp:discount')} </FormLabel>
              <>
                <InputText 
                  name="discount"
                  numberOnly
                  thousandSeparator
                  values={discount} 
                  onChange={this._onNumberChange.bind(this)} />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('topUp:total')} </FormLabel>
              <>
                <InputText 
                  numberOnly
                  thousandSeparator
                  values={parseFloat(amount.toString()) - parseFloat(discount.toString())} 
                  disabled />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('topUp:price')} </FormLabel>
              <>
                <InputText 
                  name="price"
                  numberOnly
                  thousandSeparator
                  values={price} 
                  onChange={this._onNumberChange.bind(this)} />
                {showValidation && !price && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <> </>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('topUp:branch')} </FormLabel>
              <>
                <Autocomplete
                  name='branchId'
                  value={branchId}
                  options={branchSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                />
                {showValidation && !branchId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('topUp:commission_user')} </FormLabel>
              <>
                <Autocomplete
                  name='commissionUserId'
                  value={commissionUserId}
                  options={userSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                />
                {showValidation && !commissionUserId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('topUp:pay_by_cash')} </FormLabel>
              <Checkbox
                checked={payByCash}
                name='payByCash'
                onClick={this._onCheckboxChange.bind(this)}
              />
            </ColumnContainer>

            {payByCash ? null : <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('topUp:receive_account')} </FormLabel>
              <>
                <CustomSelect 
                  name={'receiveAccountId'}
                  value={receiveAccountId}
                  onChange={this._onNumberChange.bind(this)}
                  items={paymentAccountSelectItems}
                  label={t('topUp:receive_account')}
                  disabled={readOnly} />
                {showValidation && !receiveAccountId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>}

          </ColumnContainer>

          <GridContainer justify="center">
          {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button> : null}
            {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button> : null}
            {status === TransferStatus.Draft ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_submit')}
            </Button> : null}
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.TopUp)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onCheckboxChange(event: any) {
    event.persist && event.persist();
    const { target: { name, checked } } = event;
    let topUp = this.state.topUp;
    this.setState({ topUp: {...topUp, [name]: checked }});
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ topUp: {...this.state.topUp, [name]: value }});    
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ topUp: {...this.state.topUp, [name]: value }});    
  }

  private _onDateChange(value: string | Moment) {
    let topUp = this.state.topUp;
    topUp.date = moment(value).format(GlobalConst.DATE_TIME_FORMAT);
    this.setState({ topUp: topUp });
  }

  private _onValidate() {
    let { topUp: { phone, amount, price, branchId, commissionUserId, payByCash, receiveAccountId } } = this.state;

    return phone && amount && price && branchId && commissionUserId && (payByCash || receiveAccountId);
  }

  private _onSave(submit: boolean = false, close: boolean = false) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { topUp: { phone, date, networkProviderId, clientId, topUpTypeId, amount, price, discount, branchId, commissionUserId, payByCash, receiveAccountId } } = this.state;
    let topUp = new TopUpDTO();
    topUp.id = parseInt(this.props.match.params.id);
    topUp.date = date;
    topUp.phone = phone;
    topUp.clientId = clientId;
    topUp.networkProviderId = networkProviderId;
    topUp.topUpTypeId = topUpTypeId;
    topUp.amount = amount;
    topUp.discount = discount;
    topUp.price = price;
    topUp.branchId = branchId;
    topUp.commissionUserId = commissionUserId;
    topUp.payByCash = payByCash;
    topUp.receiveAccountId = payByCash ? null : receiveAccountId;

    this.props.saveTopUp(topUp, submit).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        topUp.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close || submit) {
          history.push(URL.TopUp);
        } else if (parseInt(match.params.id) === 0) {
          this.setState({ topUp: response.data });
          history.push(URL.TopUpDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

  private _onApprove() {
    const { t } = this.props;
    this.setState({ isSaving: true });

    this.props.approveTopUp(this.state.topUp.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.TopUp);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

  private _onCancel() {
    const { t } = this.props;
    this.setState({ isSaving: true });
    
    this.props.cancelTopUp(this.state.topUp.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.TopUp);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(TopUpDetail)));