export class BranchDTO {
  public id: number;
  public name: string;
  public higherBranchId: number;
  public cashAmount: number;

  constructor() {
    this.id = 0;
    this.name = '';
    this.higherBranchId = 0;
    this.cashAmount = 0;
  }
}