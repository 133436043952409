import React from 'react';
import { IconCard, DataList, Button, CustomInput, ColumnContainer, Accordion } from '../../Components';
import { Assignment, Edit, Replay, Add, Delete, Search, Visibility, GetApp, Print, OpenInNew } from '@material-ui/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ActionCreator } from 'redux';
import { connect } from 'react-redux';
import { getListReceiveViettel, deleteReceiveViettel, setEditingReceiveViettel, exportReceiveViettel, printReceiveViettel } from '../../Redux/ReceiveViettel/ReceiveViettelReducer';
import { IconButton, withStyles, InputAdornment, FormLabel } from '@material-ui/core';
import { URL, GlobalConst, TransferStatus } from '../../Consts';
import history from '../../history';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { FilterDTO, ReceiveViettelDTO, FilterItemDTO, SelectItemDTO } from '../../DTO';
import styles from '../../styles';
import SweetAlert from "react-bootstrap-sweetalert";
import { toast } from 'react-toastify';
import { showError, currency } from '../../Utils';
import _ from 'lodash';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
import { getUserSelectItems } from '../../Redux/User/UserReducer';
let moment = require('moment');

interface IReceiveViettelProps extends WithTranslation {
  classes: any;

  userSelectItems: SelectItemDTO[];

  getReceiveViettelList: ActionCreator<any>,
  setActiveRoute: ActionCreator<any>,
  setEditingReceiveViettel: ActionCreator<any>,
  deleteReceiveViettel: ActionCreator<any>,
  getUserSelectItems: ActionCreator<any>,
  exportReceiveViettel: ActionCreator<any>,
  printReceiveViettel: ActionCreator<any>,
}

interface IReceiveViettelStates {
  data: ReceiveViettelDTO[],
  page: number;
  pageSize: number;
  totalCount: number;

  searchString: string;
  showDeleteConfirm?: boolean;
  deletingId?: number;

  dateFrom?: string;
  dateTo?: string;

  isExporting: boolean;
}

function mapStateToProps(state: any) {
  return ({
    userSelectItems: state.user.userSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    getReceiveViettelList: (filterDTO: FilterDTO) => dispatch(getListReceiveViettel(filterDTO)),
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    setEditingReceiveViettel: (receiveViettel: ReceiveViettelDTO) => dispatch(setEditingReceiveViettel(receiveViettel)),
    deleteReceiveViettel: (id: number) => dispatch(deleteReceiveViettel(id)),
    getUserSelectItems: () => dispatch(getUserSelectItems()),
    exportReceiveViettel: (filterDTO: FilterDTO) => dispatch(exportReceiveViettel(filterDTO)),
    printReceiveViettel: (id: number) => dispatch(printReceiveViettel(id)),
  })
}

class ReceiveViettel extends React.Component<IReceiveViettelProps, IReceiveViettelStates> {

  constructor(props: IReceiveViettelProps){
    super(props);

    this.state = {
      data: [],
      page: 0,
      pageSize: 20,
      totalCount: 0,
      searchString: '',
      dateFrom: moment().format(GlobalConst.DATE_FORMAT),
      dateTo: moment().format(GlobalConst.DATE_FORMAT),
      isExporting: false,
    }

    this._getReceiveViettelWithDebounce = _.debounce(this._getReceiveViettelWithDebounce.bind(this), GlobalConst.DEBOUNCE_TIME);
  }

  componentDidMount() {
    !this.props.userSelectItems?.length && this.props.getUserSelectItems();

    this.props.setActiveRoute([{path: URL.ReceiveViettel, name: this.props.t('menu:receiveViettel')}]);
  }

  render() {
    const { t, classes } = this.props;
    let { data, page, pageSize, totalCount, isExporting } = this.state;
    
    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={Assignment}
          iconColor={'green'}
          title={t('receiveViettel:receiveViettel')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>
            <CustomInput
              labelText={t('common:search')}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                value: this.state.searchString,
                name: 'searchString',
                onChange: this._onSearchStringChange.bind(this)
              }}
            />

            <div className={classes.right}>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onEdit.bind(this, new ReceiveViettelDTO())}>
                <Add className={classes.icons} /> {t('button:add')}
              </Button>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onRefresh.bind(this)}>
                <Replay className={classes.icons} /> {t('button:refresh')}
              </Button>
              <Button color='primary' customClass={classes.marginRight} disabled={isExporting} round onClick={this._onExport.bind(this)}>
                <GetApp className={classes.icons} /> {t('button:excel')}
              </Button>
            </div>
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs: 12}]}>
            <Accordion
              active={-1}
              collapses={[
                {
                  title: t('common:advance_search'),
                  content: this._renderAdvancedSearch()
                }
              ]}
            />
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs:12}]}>
            <DataList 
              currentPage={page}
              pageSize={pageSize}
              totalPage={Math.ceil(totalCount / pageSize)}
              data={this._mapData(data)}
              columns={this._getColumns()}
              fetchData={this._onLoadData.bind(this)}
              onPageSizeChange={(ps: number) => {this.setState({ pageSize: ps, page: 0 }, () => this._search())}}
              onChangePage={this._onChangePage.bind(this)}
            />
          </ColumnContainer>
          
          {this.state.showDeleteConfirm && <SweetAlert
            warning
            //style={{ display: "block", marginTop: "-100px" }}
            title={t('message:confirm_delete')}
            onConfirm={this._onDelete.bind(this)}
            onCancel={() => this.setState({ showDeleteConfirm: false})}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={t('button:delete')}
            cancelBtnText={t('button:cancel')}
            showCancel
          >
          </SweetAlert>}

        </IconCard>
      </ColumnContainer>
    )
  }

  private _renderAdvancedSearch() {
    const { t, classes } = this.props;
    let { dateFrom, dateTo } = this.state;

    return (
      <ColumnContainer columnWidth={[{xs:12, sm:6, md:4}]}>

        <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
          <FormLabel className={classes.labelHorizontal}> {t('transfer:date_from')} </FormLabel>
          <Datetime
            dateFormat={GlobalConst.DATE_FORMAT}
            timeFormat={false}
            inputProps={{ placeholder: t('transfer:date_from') }}
            onChange={(value: string | Moment) => this.setState({ dateFrom: value ? moment(value).format(GlobalConst.DATE_FORMAT) : '', page: 0 }, () => this._search())}
            value={dateFrom}
          />
        </ColumnContainer>

        <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
          <FormLabel className={classes.labelHorizontal}> {t('transfer:date_to')} </FormLabel>
          <Datetime
            dateFormat={GlobalConst.DATE_FORMAT}
            timeFormat={false}
            inputProps={{ placeholder: t('transfer:date_to') }}
            onChange={(value: string | Moment) => this.setState({ dateTo: value ? moment(value).format(GlobalConst.DATE_FORMAT) : '', page: 0 }, () => this._search())}
            value={dateTo}
          />
        </ColumnContainer>

      </ColumnContainer>
    )
  }

  private _getFilter(tableState: any = null): FilterDTO {
    let { pageSize, searchString, dateFrom, dateTo} = this.state;

    let filter: FilterDTO = new FilterDTO(tableState);
    if (!tableState) {
      filter.page = 0;
    }
    filter.pageSize = pageSize;
    filter.searchString = searchString;
    filter.filters = [
      { key: 'dateFrom', value: dateFrom } as FilterItemDTO,
      { key: 'dateTo', value: dateTo } as FilterItemDTO
    ];

    return filter;
  }

  _getReceiveViettelWithDebounce(filterDTO: FilterDTO) {
    this.props.getReceiveViettelList(filterDTO).then((response: any) => {
      if (response && !response.errorCode) {
        this.setState({ data: response.data, totalCount: response.totalCount });
      } else {
        showError(this.props.t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _onSearchStringChange(event: any) {
    event.persist && event.persist();
    const { target: { value } } = event;
    this.setState({ searchString: value || '', page: 0 }, () => this._search());
  }

  private _search() {
    this._getReceiveViettelWithDebounce(this._getFilter());    
  }

  private _getColumns() {
    const { t } = this.props;
    return [
      { Header: t('receiveViettel:number'), accessor: "number", sortable: false, filterable: false },
      { Header: t('receiveViettel:receiver_name'), accessor: "receiverName", sortable: false, filterable: false },
      { Header: t('receiveViettel:phone'), accessor: "phone", sortable: false, filterable: false },
      { Header: t('receiveViettel:amount'), accessor: "amount", sortable: false, filterable: false },
      { Header: t('receiveViettel:fee'), accessor: "fee", sortable: false, filterable: false },
      { Header: t('receiveViettel:user'), accessor: "createdUser", sortable: false, filterable: false },
      { Header: t('receiveViettel:status'), accessor: "status", sortable: false, filterable: false },
      { Header: t('receiveViettel:approved_by'), accessor: "approvedBy", sortable: false, filterable: false },
      { Header: t('receiveViettel:approved_date'), accessor: "approvedDate", sortable: false, filterable: false },
      { Header: '', accessor: "actions", sortable: false, filterable: false }
    ]
  }

  private _onEdit(receiveViettel: ReceiveViettelDTO) {
    this.props.setEditingReceiveViettel(receiveViettel);
    history.push(URL.ReceiveViettelDetail.replace(':id', receiveViettel.id.toString()));
  }

  private _onPrint(receiveViettel: ReceiveViettelDTO) {
    this.props.printReceiveViettel(receiveViettel.id).then((response: any) => {
      var newTab = window.open("about:blank", "", "_blank");
      newTab?.document.write(response);
    })
  }

  private _onDelete() {
    const { t } = this.props;

    this.setState({ showDeleteConfirm: false });
    this.props.deleteReceiveViettel(this.state.deletingId).then((response: any) => {
      if (response && !response.errorCode) {
        toast.success(t('message:delete_success'));
        this._onRefresh();
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _mapData(data: ReceiveViettelDTO[]) {
    if (!data) return [];

    const { t, userSelectItems } = this.props;

    const statusText = [
      { value: TransferStatus.Draft, text: t('receiveViettel:status_draft')},
      { value: TransferStatus.PendingApprove, text: t('receiveViettel:status_pending_approve')},
      { value: TransferStatus.Approved, text: t('receiveViettel:status_approved')},
      { value: TransferStatus.Canceled, text: t('receiveViettel:status_cancel')},
    ]

    return data.map((u: ReceiveViettelDTO) => {
      return {
        ...u,
        status: statusText.find(s => s.value === u.status)?.text,
        amount: currency(u.amount),
        fee: currency(u.fee),
        approvedBy: userSelectItems.find(s => s.value === u.approvedById)?.text ?? '',
        approvedDate: u.approvedById != null ? u.approvedDate : '',
        actions: (u.status === TransferStatus.Draft || u.status === TransferStatus.PendingApprove) ? (
          <div className="actions-right">
            <IconButton className='small-action' onClick={this._onPrint.bind(this, u)}> <Print /> </IconButton>
            <IconButton className='small-action' onClick={this._onEdit.bind(this, u)}> <Edit /> </IconButton>
            <IconButton className='small-action' onClick={() => window.open(URL.ReceiveViettelDetail.replace(':id', u.id.toString()), '_blank')}> <OpenInNew /> </IconButton>
            <IconButton className='small-action' onClick={() => this.setState({ showDeleteConfirm: true, deletingId: u.id })}> <Delete color='error' /> </IconButton>
          </div>) :
          (<div className="actions-right">
            <IconButton className='small-action' onClick={this._onPrint.bind(this, u)}> <Print /> </IconButton>
            <IconButton className='small-action' onClick={this._onEdit.bind(this, u)}> <Visibility /> </IconButton>
            <IconButton className='small-action' onClick={() => window.open(URL.ReceiveViettelDetail.replace(':id', u.id.toString()), '_blank')}> <OpenInNew /> </IconButton>
          </div>
          )
      }
    });
  }

  private _onLoadData(tableState: any, instance: any) {
    this._getReceiveViettelWithDebounce(this._getFilter());
  }

  private _onRefresh() {
    this._getReceiveViettelWithDebounce(this._getFilter());
  }

  private _onExport() {
    this.setState({ isExporting: true });
    this.props.exportReceiveViettel(this._getFilter()).then((response: any) => {
      this.setState({ isExporting: false });
      if (response && !response.errorCode) {
        window.open(response.data, '_blank');
      } else {
        showError(this.props.t, response.errorCode, response.errorMessage);
      }
    });
  }

  private _onChangePage(pageIndex: any) {
    this.setState({ page: pageIndex});
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(ReceiveViettel)));