import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ActionCreator } from 'redux';
import { CurrentUserDTO } from '../../DTO';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { showError, validEmail } from '../../Utils';
import styles from '../../styles';
import { withStyles, FormLabel } from '@material-ui/core';
import { connect } from 'react-redux';
import { GridContainer, IconCard, Button, Danger, ImageUpload, InputText, ColumnContainer } from '../../Components';
import { Person, Save } from '@material-ui/icons';
import { saveUserInfo } from '../../Redux/User/UserReducer';
import { toast } from 'react-toastify';

interface IUserInfoProps extends WithTranslation {
  classes?: any;

  currentUser: CurrentUserDTO;

  saveUser: ActionCreator<any>;
}

interface IUserInfoStates {
  isSaving?: boolean;

  user: CurrentUserDTO;
  showValidation?: boolean;
  avatarFile?: any;
}

function mapStateToProps(state: any) {
  return ({
    currentUser: state.user.currentUser,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    saveUser: (user: CurrentUserDTO, avatarFile: any) => dispatch(saveUserInfo(user, avatarFile)),
  })
}

class UserInfo extends React.Component<IUserInfoProps, IUserInfoStates> {

  constructor(props: IUserInfoProps) {
    super(props);
    this.state = {
      user: { ...new CurrentUserDTO(), ...props.currentUser }
    }
  }

  componentDidMount() {
    setActiveRoute([]);
  }

  UNSAFE_componentWillReceiveProps(props: IUserInfoProps) {
    if (!this.props.currentUser.loginName && props.currentUser.loginName) {
      this.setState({ user: props.currentUser });
    }
  }

  render() {
    const { t, classes } = this.props;
    let { showValidation, user: { loginName, name, firstName, lastName, email, phone, password, repeatPassword, avatar } } = this.state;

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={Person}
          iconColor={'green'}
          title={t('common:information')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('user:login_name')} </FormLabel>
              <>
                <InputText name='loginName' values={loginName} disabled onChange={this._onTextChange.bind(this)} />
                {showValidation && !name && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('user:display_name')} </FormLabel>
              <>
                <InputText name='name' values={name} onChange={this._onTextChange.bind(this)} />
                {showValidation && !name && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('user:first_name')} </FormLabel>
              <>
                <InputText name='firstName' values={firstName} onChange={this._onTextChange.bind(this)} />
                {showValidation && !firstName && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('user:last_name')} </FormLabel>
              <>
                <InputText name='lastName' values={lastName} onChange={this._onTextChange.bind(this)} />
                {showValidation && !lastName && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('user:email')} </FormLabel>
              <>
              <InputText name='email' values={email} onChange={this._onTextChange.bind(this)} />
              {showValidation && email && !validEmail(email) && <Danger>{t('message:invalid_email')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('user:phone')} </FormLabel>
              <>
                <InputText name='phone' values={phone} onChange={this._onTextChange.bind(this)} />
                {showValidation && !phone && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('user:password')} </FormLabel>
              <InputText name='password' values={password} type='password' onChange={this._onTextChange.bind(this)} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required={!!password}> {t('user:repeat_password')} </FormLabel>
              <>
                <InputText name='repeatPassword' values={repeatPassword} type='password' onChange={this._onTextChange.bind(this)} />
                {showValidation && password && password !== repeatPassword && <Danger>{t('message:repeat_password_not_match')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('user:avatar')} </FormLabel>
              <ImageUpload 
                image={avatar ? `/Upload/UserAvatar/${avatar}` : null}
                onImageChange={(file: any) => this.setState({ avatarFile: file })}/>
            </ColumnContainer>
          </ColumnContainer>

          <GridContainer justify="center">
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ user: {...this.state.user, [name]: value }});    
  }

  private _onValidate() {
    let { user: { name, phone, firstName, lastName, email, password, repeatPassword } } = this.state;

    return name && firstName && lastName && phone && (!email || validEmail(email)) && (!password || password === repeatPassword);
  }

  private _onSave() {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    const { t } = this.props;
    let { avatarFile, user: { name, firstName, lastName, email, phone, password } } = this.state;
    let user = new CurrentUserDTO();
    user.name = name;
    user.firstName = firstName;
    user.lastName = lastName;
    user.email = email;
    user.phone = phone;
    user.password = password;

    this.props.saveUser(user, avatarFile).then((response: any) => {
      this.setState({ isSaving: false });

      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(UserInfo)));