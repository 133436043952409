import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withStyles, FormLabel } from '@material-ui/core';
import styles from '../../styles';
import { connect } from 'react-redux';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { ActionCreator } from 'redux';
import { URL } from '../../Consts';
import { GridContainer, GridItem, IconCard, CustomSelect, DataList, Button, InputText, ColumnContainer } from '../../Components';
import { Assignment, Save } from '@material-ui/icons';
import { SelectItemDTO, BranchCommissionDTO, CommissionDTO } from '../../DTO';
import { showError } from '../../Utils';
import { getCommissions, saveCommissions } from '../../Redux/Commission/CommissionReducer';
import { toast } from 'react-toastify';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';

interface ICommissionProps extends WithTranslation {
  classes: any;

  branchSelectItems: SelectItemDTO[],

  setActiveRoute: ActionCreator<any>,
  getCommissions: ActionCreator<any>,
  saveCommissions: ActionCreator<any>,
  getBranchSelectItems: ActionCreator<any>,
}

interface ICommissionStates {
  currentBranchId: number;
  branches: BranchCommissionDTO[];
  isSaving: boolean;
}

function mapStateToProps(state: any) {
  return ({
    branchSelectItems: state.branch.branchSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getCommissions: () => dispatch(getCommissions()),
    saveCommissions: (branches: BranchCommissionDTO[]) => dispatch(saveCommissions(branches)),
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
  })
}

class Commission extends React.Component<ICommissionProps, ICommissionStates> {

  constructor(props: ICommissionProps) {
    super(props);
    
    this.state = {
      currentBranchId: 0,
      branches: [],
      isSaving: false,
    }
  }

  componentDidMount() {
    this.props.setActiveRoute([{path: URL.Commission, name: this.props.t('menu:commission')}]);
    !this.props.branchSelectItems?.length && this.props.getBranchSelectItems();
    this.props.getCommissions().then((response: any) => {
      if (response && !response.errorCode) {
        this.setState({ branches: response.data })
      } else {
        showError(this.props.t, response.errorCode, response.errorMessage);
      }
    });
  }

  render() {
    const { t, classes, branchSelectItems } = this.props;
    let currentBranchId = this.state.currentBranchId;
    if (currentBranchId === 0 && branchSelectItems && branchSelectItems.length) {
      currentBranchId = parseInt(branchSelectItems[0].value.toString());
    }

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={Assignment}
          iconColor={'green'}
          title={t('commission:commission')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('branch:name')} </FormLabel>
              <CustomSelect 
                name={'branchId'}
                value={currentBranchId}
                onChange={this._onBranchChange.bind(this)}
                items={branchSelectItems}
                label={t('branch:name')} />
            </ColumnContainer>
          </ColumnContainer>

          <ColumnContainer columnWidth={[{xs:12}]}>
            <DataList
              currentPage={0}
              pageSize={1000}
              totalPage={1}
              data={this._mapData(this.state.branches)}
              onPageSizeChange={() => {}}
              columns={this._getColumns()}
            />
          </ColumnContainer>

          <GridContainer justify="center">
            <GridItem xs={2}>
              <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this)} disabled={this.state.isSaving}>
                <Save className={classes.icons} /> {t('button:save')}
              </Button>
            </GridItem>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onSave() {
    this.props.saveCommissions(this.state.branches).then((response: any) => {
      if (response.data.errorCode) {
        showError(this.props.t, response.errorCode, response.errorMessage);
      } else {
        toast.success(this.props.t('message:save_success'))
      }
    })
  }

  private _mapData(data: BranchCommissionDTO[]) {
    const { branchSelectItems } = this.props;
    let currentBranchId = this.state.currentBranchId;
    if (currentBranchId === 0 && branchSelectItems && branchSelectItems.length) {
      currentBranchId = parseInt(branchSelectItems[0].value.toString());
    }

    return (data.find(b => b.id === currentBranchId)?.commissions ?? []).map((i: CommissionDTO, idx: number) => {
      return {
        ...i,
        amount: <InputText name='amount' value={i.amount} numberOnly thousandSeparator onChange={this._onChange.bind(this, currentBranchId, i.productId)} />,
        percent: <InputText name='percent' suffix=' %' value={i.percent} numberOnly thousandSeparator onChange={this._onChange.bind(this, currentBranchId, i.productId)} />,
      }
    });
  }

  private _onChange(branchId: number, productId: number, value: string, name: string, ) {
    let data = this.state.branches;
    let branchIdx = data.findIndex(b => b.id === branchId);
    let productIdx = data[branchIdx].commissions.findIndex(p => p.productId === productId);
    data[branchIdx].commissions[productIdx][name] = parseFloat(value);
    
    this.setState({ branches: data });
  }

  private _getColumns() {
    const { t } = this.props;
    return [
      { Header: t('product:name'), accessor: "productName", sortable: false, filterable: false },
      { Header: t('commission:amount'), accessor: "amount", sortable: false, filterable: false },
      { Header: t('commission:percent'), accessor: "percent", sortable: false, filterable: false },
    ]
  }

  private _onBranchChange(value: number) {
    this.setState({ currentBranchId: value });
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(Commission)));