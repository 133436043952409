import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer } from '../../Components';
import { People, Save, KeyboardArrowLeft } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getVersionById, saveVersion } from '../../Redux/Version/VersionReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { VersionDTO } from '../../DTO';

interface IVersionDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  version: VersionDTO;

  setActiveRoute: ActionCreator<any>,
  getVersionById: ActionCreator<any>,
  saveVersion: ActionCreator<any>,
}

interface IVersionDetailStates {
  isSaving?: boolean;

  version: VersionDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    version: state.version.editingVersion,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getVersionById: (id: number) => dispatch(getVersionById(id)),
    saveVersion: (version: VersionDTO) => dispatch(saveVersion(version)),
  })
}

class VersionDetail extends React.Component<IVersionDetailProps, IVersionDetailStates> {

  constructor(props: IVersionDetailProps) {
    super(props);
    this.state = {
      version: {...new VersionDTO(), ...props.version}
    }
  }

  componentDidMount() {
    const { setActiveRoute, match, getVersionById, t } = this.props;

    setActiveRoute([{path: URL.Version, name: this.props.t('menu:version')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getVersionById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.Version);
          } else {
            this.setState({ version: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes } = this.props;
    let { showValidation, version: { number, detail } } = this.state;

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={People}
          iconColor={'green'}
          title={t('common:information')}>

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('version:number')} </FormLabel>
              <>
                <InputText name='number' values={number} onChange={this._onTextChange.bind(this)} />
                {showValidation && !number && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('version:detail')} </FormLabel>
              <>
                <InputText name='detail' values={detail} multiline onChange={this._onTextChange.bind(this)} />
                {showValidation && !detail && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

          </ColumnContainer>

          <GridContainer justify="center">
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button>
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button>
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.Version)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ version: {...this.state.version, [name]: value }});    
  }

  private _onValidate() {
    let { version: { number, detail } } = this.state;

    return number && detail;
  }

  private _onSave(close: boolean = true) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { version: { number, detail } } = this.state;
    let version = new VersionDTO();
    version.id = parseInt(this.props.match.params.id);
    version.number = number;
    version.detail = detail;

    this.props.saveVersion(version).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        version.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close) {
          history.push(URL.Version);
        } else if (parseInt(match.params.id) === 0) {
          history.push(URL.VersionDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(VersionDetail)));