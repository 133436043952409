import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';
import { URL, GlobalConst, TransferStatus, Permission } from '../../Consts';
import { setActiveRoute } from '../../Redux/Common/CommonReducer';
import { GridContainer, IconCard, Button, Danger, InputText, ColumnContainer, Autocomplete, Checkbox } from '../../Components';
import { People, Save, KeyboardArrowLeft, Clear } from '@material-ui/icons';
import { FormLabel, withStyles } from '@material-ui/core';
import styles from '../../styles';
import { getExpenseById, saveExpense, approveExpense, cancelExpense } from '../../Redux/Expense/ExpenseReducer';
import { showError } from '../../Utils';
import { toast } from 'react-toastify';
import history from '../../history';
import { ExpenseDTO, CurrentUserDTO, SelectItemDTO } from '../../DTO';
import Datetime from 'react-datetime';
import { Moment } from 'moment';
import { getBranchSelectItems } from '../../Redux/Branch/BranchReducer';
import { getUserSelectItems } from '../../Redux/User/UserReducer';
import { getBankSelectItems } from '../../Redux/Bank/BankReducer';
import { getPaymentAccountSelectItems } from '../../Redux/PaymentAccount/PaymentAccountReducer';
import { getClientSelectItems } from '../../Redux/Client/ClientReducer';
let moment = require('moment');

interface IExpenseDetailProps extends WithTranslation {
  classes?: any;
  match: any;
  
  currentUser: CurrentUserDTO;
  expense: ExpenseDTO;
  branchSelectItems: SelectItemDTO[];
  userSelectItems: SelectItemDTO[];
  bankSelectItems: SelectItemDTO[];
  paymentAccountSelectItems: SelectItemDTO[];
  clientSelectItems: SelectItemDTO[];

  setActiveRoute: ActionCreator<any>,
  getExpenseById: ActionCreator<any>,
  saveExpense: ActionCreator<any>,
  approveExpense: ActionCreator<any>,
  cancelExpense: ActionCreator<any>,
  getBranchSelectItems: ActionCreator<any>,
  getUserSelectItems: ActionCreator<any>,
  getBankSelectItems: ActionCreator<any>,
  getPaymentAccountSelectItems: ActionCreator<any>,
  getClientSelectItems: ActionCreator<any>,
}

interface IExpenseDetailStates {
  isSaving?: boolean;

  expense: ExpenseDTO;
  showValidation?: boolean;
}

function mapStateToProps(state: any) {
  return ({
    expense: state.expense.editingExpense,
    currentUser: state.user.currentUser,
    branchSelectItems: state.branch.branchSelectItems,
    userSelectItems: state.user.userSelectItems,
    bankSelectItems: state.bank.bankSelectItems,
    paymentAccountSelectItems: state.paymentAccount.paymentAccountSelectItems,
    clientSelectItems: state.client.clientSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    setActiveRoute: (routes: any[]) => dispatch(setActiveRoute(routes)),
    getExpenseById: (id: number) => dispatch(getExpenseById(id)),
    saveExpense: (expense: ExpenseDTO, submit: boolean) => dispatch(saveExpense(expense, submit)),
    approveExpense: (id: number) => dispatch(approveExpense(id)),
    cancelExpense: (id: number) => dispatch(cancelExpense(id)),
    getBranchSelectItems: () => dispatch(getBranchSelectItems()),
    getUserSelectItems: () => dispatch(getUserSelectItems()),
    getBankSelectItems: () => dispatch(getBankSelectItems()),
    getPaymentAccountSelectItems: () => dispatch(getPaymentAccountSelectItems()),
    getClientSelectItems: () => dispatch(getClientSelectItems()),
  })
}

class ExpenseDetail extends React.Component<IExpenseDetailProps, IExpenseDetailStates> {

  constructor(props: IExpenseDetailProps) {
    super(props);
    this.state = {
      expense: {...new ExpenseDTO(), ...props.expense}
    }
  }

  componentDidMount() {
    !this.props.branchSelectItems?.length && this.props.getBranchSelectItems();
    !this.props.userSelectItems?.length && this.props.getUserSelectItems();
    !this.props.bankSelectItems?.length && this.props.getBankSelectItems();
    !this.props.paymentAccountSelectItems?.length && this.props.getPaymentAccountSelectItems();
    !this.props.clientSelectItems?.length && this.props.getClientSelectItems();

    const { setActiveRoute, match, getExpenseById, t } = this.props;

    setActiveRoute([{path: URL.Expense, name: this.props.t('menu:expense')}, {name: match.params.id > 0 ? t('button:edit') : t('button:add') }]);

    if (match.params.id > 0) {
      getExpenseById(match.params.id).then((response: any) => {
        if (response && !response.errorCode) {
          if (response.data === null) {
            toast.error(t('not_found:not_found'));
            history.push(URL.Expense);
          } else {
            this.setState({ expense: response.data });
          }
        } else {
          showError(this.props.t, response.errorCode, response.errorMessage);
        }
      });
    }
  }

  render() {
    const { t, classes, currentUser, branchSelectItems, userSelectItems, bankSelectItems, paymentAccountSelectItems, clientSelectItems } = this.props;
    let { showValidation, expense: { id, number, clientId, isCash, receiveBankId, receiveBankNumber, paymentAccountId, status, date, createdUser, amount, detail, branchId, commissionUserId } } = this.state;

    // const types = [
    //   { value: 1, text: t('client:type_single')},
    //   { value: 2, text: t('client:type_multiple')},
    //   { value: 3, text: t('client:type_branch')},
    // ];

    const statusText = [
      { value: 0, text: t('expense:status_draft')},
      { value: 1, text: t('expense:status_pending_approve')},
      { value: 2, text: t('expense:status_approved')},
      { value: 3, text: t('expense:status_cancel')},
    ]

    let statusStr = statusText.find(s => s.value === status)?.text || '';
    let tDate = date ? moment(date, GlobalConst.DATE_TIME_FORMAT) : moment(new Date());
    let now = moment(new Date());
    let readOnly = (status === TransferStatus.Approved || status === TransferStatus.Canceled) && (tDate.year() * 12 + tDate.month() < now.year() * 12 + now.month());

    return (
      <ColumnContainer columnWidth={[{sm:12}]}>
        <IconCard
          icon={People}
          iconColor={'green'}
          title={t('common:information')}>

          {id && currentUser.functionIds.includes(Permission.ApproveExpense) && status === TransferStatus.PendingApprove ? 
            <ColumnContainer columnWidth={[{xs:12, sm:12}]}>
              <div className={classes.right}>
                <Button color='primary' customClass={classes.marginRight} round onClick={this._onApprove.bind(this)} disabled={this.state.isSaving}>
                  <Save className={classes.icons} /> {t('button:approve')}
                </Button>
                <Button color='danger' customClass={classes.marginRight} round onClick={this._onCancel.bind(this)} disabled={this.state.isSaving}>
                  <Clear className={classes.icons} /> {t('button:cancel')}
                </Button>
              </div>
            </ColumnContainer>
          : null }

          <ColumnContainer columnWidth={[{xs:12, sm:6}]}>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('expense:number')} </FormLabel>
              <InputText values={number} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('expense:status')} </FormLabel>
              <InputText values={statusStr} disabled={true} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('expense:date')} </FormLabel>
              {!readOnly ? 
                <Datetime
                  dateFormat={GlobalConst.DATE_FORMAT}
                  timeFormat={"HH:mm"}
                  inputProps={{ placeholder: t('expense:date') }}
                  onChange={this._onDateChange.bind(this)}
                  value={date}
                /> : <InputText disabled values={date} /> }
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('expense:user')} </FormLabel>
              <InputText disabled values={id === 0 ? currentUser.name : createdUser} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('expense:client')} </FormLabel>
              <>
                <Autocomplete
                  name='clientId'
                  value={clientId}
                  options={clientSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                />
                {showValidation && !clientId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('expense:client_phone')} </FormLabel>
              <InputText disabled values={clientSelectItems?.find(c => c.value === clientId)?.details.phone || ''} />
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('expense:is_cash')} </FormLabel>
              <Checkbox
                checked={isCash}
                name='isCash'
                onClick={this._onCheckboxChange.bind(this)}
                disabled={readOnly}
              />
            </ColumnContainer>

            {!isCash ? <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required={!isCash}> {t('expense:payment_account')} </FormLabel>
              <>
                <Autocomplete
                  name='paymentAccountId'
                  value={paymentAccountId}
                  options={paymentAccountSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !isCash && !paymentAccountId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer> : null}

            {!isCash ? <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required={!isCash}> {t('expense:receive_bank')} </FormLabel>
              <>
                <Autocomplete
                  name='receiveBankId'
                  value={receiveBankId}
                  options={bankSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                />
                {showValidation && !isCash && !receiveBankId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer> : null}

            {!isCash ? <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('expense:receive_bank_number')} </FormLabel>
              <>
                <InputText name="receiveBankNumber" values={receiveBankNumber} onChange={this._onTextChange.bind(this)} disabled={readOnly} />
                {showValidation && !isCash && !receiveBankNumber && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer> : null}

            {isCash ? <> </> : null}

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('expense:amount')} </FormLabel>
              <>
                <InputText  name="amount" numberOnly thousandSeparator values={amount} onChange={this._onNumberChange.bind(this)} disabled={readOnly} />
                {showValidation && !amount && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal}> {t('expense:detail')} </FormLabel>
              <>
                <InputText name="detail" values={detail} onChange={this._onTextChange.bind(this)} disabled={readOnly} />
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('expense:branch')} </FormLabel>
              <>
                <Autocomplete
                  name='branchId'
                  value={branchId}
                  options={branchSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !branchId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

            <ColumnContainer columnWidth={[{xs:12, sm:3}, {xs:12, sm:9}]}>
              <FormLabel className={classes.labelHorizontal} required> {t('expense:commission_user')} </FormLabel>
              <>
                <Autocomplete
                  name='commissionUserId'
                  value={commissionUserId}
                  options={userSelectItems}
                  onChange={this._onNumberChange.bind(this)}
                  disabled={readOnly}
                />
                {showValidation && !commissionUserId && <Danger>{t('message:required')}</Danger>}
              </>
            </ColumnContainer>

          </ColumnContainer>

          <GridContainer justify="center">
            {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save')}
            </Button> : null}
            {!readOnly ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, false, true)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_close')}
            </Button> : null}
            {status === TransferStatus.Draft ? 
            <Button color='primary' customClass={classes.marginRight} round onClick={this._onSave.bind(this, true, false)} disabled={this.state.isSaving}>
              <Save className={classes.icons} /> {t('button:save_submit')}
            </Button> : null}
            <Button customClass={classes.marginRight} round onClick={() => history.push(URL.Expense)}>
              <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
            </Button>
          </GridContainer>

        </IconCard>
      </ColumnContainer>
    )
  }

  private _onTextChange(value: string, name: string) {
    this.setState({ expense: {...this.state.expense, [name]: value }});
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ expense: {...this.state.expense, [name]: value }});
  }

  private _onDateChange(value: string | Moment) {
    let expense = this.state.expense;
    expense.date = moment(value).format(GlobalConst.DATE_TIME_FORMAT);
    this.setState({ expense: expense });
  }

  private _onCheckboxChange(event: any) {
    event.persist && event.persist();
    const { target: { name, checked } } = event;
    let expense = this.state.expense;
    this.setState({ expense: {...expense, [name]: checked }});
  }

  private _onValidate() {
    let { expense: { amount, branchId, commissionUserId, isCash, receiveBankId, receiveBankNumber, paymentAccountId, clientId } } = this.state;

    return amount && branchId && commissionUserId && (isCash || (receiveBankId && receiveBankNumber && paymentAccountId)) && clientId;
  }

  private _onSave(submit: boolean = false, close: boolean = false) {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });

    this.setState({ isSaving: true});
    const { t, match } = this.props;
    let { expense: { date, clientId, isCash, receiveBankId, receiveBankNumber, paymentAccountId, amount, detail, branchId, commissionUserId } } = this.state;
    let expense = new ExpenseDTO();
    expense.id = parseInt(this.props.match.params.id);
    expense.date = date;
    expense.clientId = clientId;
    expense.isCash = isCash;
    if (!isCash) {
      expense.receiveBankId = receiveBankId;
      expense.receiveBankNumber = receiveBankNumber;
      expense.paymentAccountId = paymentAccountId;
    }
    expense.amount = amount;
    expense.detail = detail;
    expense.branchId = branchId;
    expense.commissionUserId = commissionUserId;

    this.props.saveExpense(expense, submit).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        expense.id > 0 ? toast.success(t('message:save_success')) : toast.success(t('message:create_success'));
        if (close || submit) {
          history.push(URL.Expense);
        } else if (parseInt(match.params.id) === 0) {
          this.setState({ expense: response.data });
          history.push(URL.ExpenseDetail.replace(':id', response.data.id));
        }
      } else {
        showError(t, response.errorCode, response.errorMessage);        
      }
    })
  }

  private _onApprove() {
    const { t } = this.props;
    this.setState({ isSaving: true });

    this.props.approveExpense(this.state.expense.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.Expense);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

  private _onCancel() {
    const { t } = this.props;
    this.setState({ isSaving: true });
    
    this.props.cancelExpense(this.state.expense.id).then((response: any) => {
      this.setState({ isSaving: false });
      if (response && !response.errorCode) {
        toast.success(t('message:save_success'));
        history.push(URL.Expense);
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    })
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(ExpenseDetail)));