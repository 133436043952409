import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { SelectItemDTO } from '../../DTO';
import { GlobalConst } from '../../Consts';

interface ICustomAutocompleteProps {
  name?: string,
  options: SelectItemDTO[] | null,
  value: any,
  onChange: any,
  getOptionLabel?: any,
  disableClearable?: boolean,
  disabled?: boolean,
  emptyText?: string,
  emptyValue?: number | string,
  includeEmptyOption?: boolean;
}

export default function CustomAutocomplete(props: ICustomAutocompleteProps) {
  let options = (props.options ?? []).slice();
  if (props.includeEmptyOption) {
    options.unshift({ value: props.emptyValue ?? 0, text: props.emptyText ?? GlobalConst.NONE_SELECTED_TEXT, details: [] } as SelectItemDTO);
  }

  return (
    <Autocomplete 
      options={options}
      value={options.find((o: SelectItemDTO) => o.value === props.value) ?? null}
      onChange={(event: any, values: SelectItemDTO | null) => props.onChange(values?.value ?? (props.emptyValue ?? 0), props.name)}
      getOptionLabel={props.getOptionLabel ?? ((option) => option.text)}
      getOptionSelected={(option, value) => option.value === value.value}
      renderInput={(params) => <TextField {...params} margin="normal" />}
      disableClearable={!!props.disableClearable}
      disabled={!!props.disabled}
    />
  )
}