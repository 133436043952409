import { FilterDTO, ClientDTO } from '../../DTO';

const CLIENT_REQUEST: string = 'CLIENT_REQUEST';

const CLIENT_GET_LIST_SUCCESS: string = 'CLIENT_GET_LIST_SUCCESS';
const CLIENT_GET_LIST_FAIL: string = 'CLIENT_GET_LIST_FAIL';

const CLIENT_SET_EDITING_CLIENT: string = 'CLIENT_SET_EDITING_CLIENT';

const CLIENT_GET_BY_ID_SUCCESS: string = 'CLIENT_GET_BY_ID_SUCCESS';
const CLIENT_GET_BY_ID_FAIL: string = 'CLIENT_GET_BY_ID_FAIL';

const CLIENT_SAVE_SUCCESS: string = 'CLIENT_SAVE_SUCCESS';
const CLIENT_SAVE_FAIL: string = 'CLIENT_SAVE_FAIL';

const CLIENT_DELETE_SUCCESS: string = 'CLIENT_DELETE_SUCCESS';
const CLIENT_DELETE_FAIL: string = 'CLIENT_DELETE_FAIL';

const CLIENT_GET_NAMES_SUCCESS: string = 'CLIENT_GET_NAMES_SUCCESS';
const CLIENT_GET_NAMES_FAIL: string = 'CLIENT_GET_NAMES_FAIL';

const CLIENT_EXPORT_LIST_SUCCESS: string = 'CLIENT_EXPORT_LIST_SUCCESS';
const CLIENT_EXPORT_LIST_FAIL: string = 'CLIENT_EXPORT_LIST_FAIL';

const initialState = {
  editingClient: {},

  clientSelectItems: [],
}

export function clientReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case CLIENT_SET_EDITING_CLIENT:
      return Object.assign({}, state, {
        editingClient: action.payload
      });

    case CLIENT_GET_NAMES_SUCCESS:
      return Object.assign({}, state, {
        clientSelectItems: action.response?.data ?? []
      });

    default:
      return state;

  }

}

export function getListClient(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CLIENT_REQUEST, CLIENT_GET_LIST_SUCCESS, CLIENT_GET_LIST_FAIL],
      EndPoint: `Clients${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingClient(client: ClientDTO) {
  return {
    type: CLIENT_SET_EDITING_CLIENT,
    payload: client
  }
}

export function getClientById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CLIENT_REQUEST, CLIENT_GET_BY_ID_SUCCESS, CLIENT_GET_BY_ID_FAIL],
      EndPoint: `Clients\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveClient(client: ClientDTO) {
  let initRequest: RequestInit = {
    method: client.id ? 'PATCH' : 'POST',
    body: JSON.stringify(client),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CLIENT_REQUEST, CLIENT_SAVE_SUCCESS, CLIENT_SAVE_FAIL],
      EndPoint: 'Clients',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteClient(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CLIENT_REQUEST, CLIENT_DELETE_SUCCESS, CLIENT_DELETE_FAIL],
      EndPoint: 'Clients',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function getClientSelectItems() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CLIENT_REQUEST, CLIENT_GET_NAMES_SUCCESS, CLIENT_GET_NAMES_FAIL],
      EndPoint: 'Clients/selectList',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function exportClient(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [CLIENT_REQUEST, CLIENT_EXPORT_LIST_SUCCESS, CLIENT_EXPORT_LIST_FAIL],
      EndPoint: `Clients\\export${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}