import { FilterDTO, TopUpTypeDTO } from '../../DTO';

const TOP_UP_TYPE_REQUEST: string = 'TOP_UP_TYPE_REQUEST';

const TOP_UP_TYPE_GET_LIST_SUCCESS: string = 'TOP_UP_TYPE_GET_LIST_SUCCESS';
const TOP_UP_TYPE_GET_LIST_FAIL: string = 'TOP_UP_TYPE_GET_LIST_FAIL';

const TOP_UP_TYPE_SET_EDITING_TOP_UP_TYPE: string = 'TOP_UP_TYPE_SET_EDITING_TOP_UP_TYPE';

const TOP_UP_TYPE_GET_BY_ID_SUCCESS: string = 'TOP_UP_TYPE_GET_BY_ID_SUCCESS';
const TOP_UP_TYPE_GET_BY_ID_FAIL: string = 'TOP_UP_TYPE_GET_BY_ID_FAIL';

const TOP_UP_TYPE_SAVE_SUCCESS: string = 'TOP_UP_TYPE_SAVE_SUCCESS';
const TOP_UP_TYPE_SAVE_FAIL: string = 'TOP_UP_TYPE_SAVE_FAIL';

const TOP_UP_TYPE_DELETE_SUCCESS: string = 'TOP_UP_TYPE_DELETE_SUCCESS';
const TOP_UP_TYPE_DELETE_FAIL: string = 'TOP_UP_TYPE_DELETE_FAIL';

const TOP_UP_TYPE_GET_NAMES_SUCCESS: string = 'TOP_UP_TYPE_GET_NAMES_SUCCESS';
const TOP_UP_TYPE_GET_NAMES_FAIL: string = 'TOP_UP_TYPE_GET_NAMES_SUCCESS';

const initialState = {
  editingTopUpType: {},
  topUpTypeSelectItems: [],
}

export function topUpTypeReducer(state = initialState, action: any) {

  switch (action.type) {
    
    case TOP_UP_TYPE_SET_EDITING_TOP_UP_TYPE:
      return Object.assign({}, state, {
        editingTopUpType: action.payload
      });

    case TOP_UP_TYPE_GET_NAMES_SUCCESS:
      return Object.assign({}, state, {
        topUpTypeSelectItems: action.response?.data ?? []
      });

    default:
      return state;

  }

}

export function getListTopUpType(filterDTO: FilterDTO) {  
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TOP_UP_TYPE_REQUEST, TOP_UP_TYPE_GET_LIST_SUCCESS, TOP_UP_TYPE_GET_LIST_FAIL],
      EndPoint: `TopUpTypes${filterDTO.toQuery()}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function setEditingTopUpType(topUpType: TopUpTypeDTO) {
  return {
    type: TOP_UP_TYPE_SET_EDITING_TOP_UP_TYPE,
    payload: topUpType
  }
}

export function getTopUpTypeById(id: number) {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TOP_UP_TYPE_REQUEST, TOP_UP_TYPE_GET_BY_ID_SUCCESS, TOP_UP_TYPE_GET_BY_ID_FAIL],
      EndPoint: `TopUpTypes\\${id}`,
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function saveTopUpType(topUpType: TopUpTypeDTO) {
  let initRequest: RequestInit = {
    method: topUpType.id ? 'PATCH' : 'POST',
    body: JSON.stringify(topUpType),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TOP_UP_TYPE_REQUEST, TOP_UP_TYPE_SAVE_SUCCESS, TOP_UP_TYPE_SAVE_FAIL],
      EndPoint: 'TopUpTypes',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function deleteTopUpType(id: number) {
  let initRequest: RequestInit = {
    method: 'DELETE',
    body: id.toString(),
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TOP_UP_TYPE_REQUEST, TOP_UP_TYPE_DELETE_SUCCESS, TOP_UP_TYPE_DELETE_FAIL],
      EndPoint: 'TopUpTypes',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}

export function getTopUpTypeSelectItems() {
  let initRequest: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return {
    CallAPI: {
      Actions: [TOP_UP_TYPE_REQUEST, TOP_UP_TYPE_GET_NAMES_SUCCESS, TOP_UP_TYPE_GET_NAMES_FAIL],
      EndPoint: 'TopUpTypes/selectList',
      AuthenticationRequire: true,
      Config: initRequest
    }
  }
}