export class FilterDTO {

  public searchString: string;
  public pageSize: number;
  public page: number;
  public filters: FilterItemDTO[];

  constructor(tableState?: any) {
    this.searchString = '';
    this.pageSize = tableState?.pageSize || 20;
    this.page = tableState?.page || 0;
    this.filters = [];
  }

  public toQuery() {
    let str = `?page=${this.page}&pageSize=${this.pageSize}`;
    if (this.searchString) str += `&searchString=${this.searchString}`
    if (this.filters && this.filters.length) {
      for (let i = 0; i < this.filters.length; i++) {
        str += `&filters[${i}].key=${this.filters[i].key}&filters[${i}].value=${this.filters[i].value.toString()}`
      }
      this.filters.forEach(item => {
        
      });
    }
    return str;
  }

}

export class FilterItemDTO {
  public key: string;
  public value: any;

  constructor() {
    this.key = '';
    this.value = '';
  }
}