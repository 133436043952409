import { GlobalConst } from "../Consts";
import { WorkflowDTO } from "./WorkflowDTO";

let moment = require('moment');

export class TransferDTO {
  public id: number;
  public number: string;
  public clientId: number;
  public toAccountName: string;
  public toAccountNumber: string;
  public detail: string;
  public bankId: number;
  public amount: number;
  public fee: number;
  public commission: number;
  public discount: number;
  public paymentAccountId: number;
  public note: string;
  public referenceFileName: string;
  public confirmationFileName: string;
  public status: number;
  public commissionUserId: number;
  public branchId: number;
  public transferDate: string;
  public createdUser: string;
  public approvedById: number | null;
  public approvedDate: string;
  public Workflow: WorkflowDTO | null;
  public payByCash: boolean;
  public receiveAccountId: number | null;

  constructor() {
    this.id = 0;
    this.number = '';
    this.clientId = 0;
    this.toAccountName = '';
    this.toAccountNumber = '';
    this.detail = '';
    this.bankId = 0;
    this.amount = 0;
    this.fee = 0;
    this.commission = 0;
    this.discount = 0;
    this.paymentAccountId = 0;
    this.note = '';
    this.referenceFileName = '';
    this.confirmationFileName = '';
    this.status = 0;
    this.transferDate = moment().format(GlobalConst.DATE_TIME_FORMAT);
    this.createdUser = '';
    this.commissionUserId = 0;
    this.branchId = 0;
    this.approvedById = null;
    this.approvedDate = '';
    this.Workflow = null;
    this.payByCash = true;
    this.receiveAccountId = null;
  }
}

export function transferToFormData(transfer: TransferDTO) {
  let formData = new FormData();
  formData.append('id', transfer.id.toString());
  formData.append('clientId', transfer.clientId.toString());
  formData.append('toAccountName', transfer.toAccountName ?? '');
  formData.append('toAccountNumber', transfer.toAccountNumber ?? '');
  formData.append('detail', transfer.detail ?? '');
  formData.append('bankId', transfer.bankId.toString());
  formData.append('amount', transfer.amount.toString());
  formData.append('fee', transfer.fee.toString());
  formData.append('commission', transfer.commission.toString());
  formData.append('discount', transfer.discount.toString());
  formData.append('paymentAccountId', transfer.paymentAccountId.toString());
  formData.append('note', transfer.note ?? '');
  formData.append('transferDate', transfer.transferDate ?? '');
  formData.append('commissionUserId', transfer.commissionUserId.toString());
  formData.append('branchId', transfer.branchId.toString());
  formData.append('referenceFileName', transfer.referenceFileName ?? '');
  formData.append('confirmationFileName', transfer.confirmationFileName ?? '');
  formData.append('payByCash', transfer.payByCash ? 'true' : 'false');
  formData.append('receiveAccountId', transfer.receiveAccountId?.toString() ?? '');

  return formData;
}