import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import styles from '../../styles';
import { ClientDTO, SelectItemDTO } from '../../DTO';
import { Button, Danger, CustomSelect, Autocomplete } from '../../Components';
import { Save, KeyboardArrowLeft } from '@material-ui/icons';
import { ActionCreator } from 'redux';
import { saveClient, getClientSelectItems } from '../../Redux/Client/ClientReducer';
import { toast } from 'react-toastify';
import { showError } from '../../Utils';

interface IQuickClientProps extends WithTranslation {
  classes?: any;

  handleClose?: any;
  branchSelectItems: SelectItemDTO[];

  saveClient: ActionCreator<any>,
  getClientSelectItems: ActionCreator<any>,
}

interface IQuickClientState {
  showValidation: boolean;
  client: ClientDTO;
}

function mapStateToProps(state: any) {
  return ({
    branchSelectItems: state.branch.branchSelectItems,
  })
}

function mapDispatchToProps(dispatch: any) {
  return ({
    saveClient: (client: ClientDTO) => dispatch(saveClient(client)),
    getClientSelectItems: () => dispatch(getClientSelectItems()),
  })
}

class QuickClient extends React.Component<IQuickClientProps, IQuickClientState> {

  constructor(props: IQuickClientProps) {
    super(props);
    this.state = {
      client: new ClientDTO(),
      showValidation: false,
    }
  }

  render() {
    const { t, classes, branchSelectItems } = this.props;
    let { showValidation, client: { name, phone, type, branchId } } = this.state;

    const types = [
      { value: 1, text: t('client:type_single')},
      { value: 2, text: t('client:type_multiple')},
      { value: 3, text: t('client:type_branch')},
    ];

    return (
      <Dialog 
        open 
        onClose={this._onClose.bind(this)} 
        aria-labelledby="form-dialog-title"
        scroll='body'
        >
        <DialogTitle>{t('client:quick')}</DialogTitle>
        <DialogContent dividers>
          <TextField
            autoFocus
            margin="dense"
            label={t('client:name')}
            fullWidth
            name="name"
            onChange={this._onTextChange.bind(this)}
          />
          {showValidation && !name && <Danger>{t('message:required')}</Danger>}
          <TextField
            margin="dense"
            label={t('client:phone')}
            fullWidth
            name="phone"
            onChange={this._onTextChange.bind(this)}
          />
          {showValidation && !phone && <Danger>{t('message:required')}</Danger>}
          <CustomSelect 
            name={'type'}
            value={type}
            onChange={this._onNumberChange.bind(this)}
            items={types}
            label={t('client:type')} />
          {/* TODO auto pick */}
          <Autocomplete
            name='branchId'
            value={branchId}
            options={branchSelectItems}
            onChange={this._onNumberChange.bind(this)}
          />
        </DialogContent>
        <DialogActions>
          <Button round onClick={this._onSave.bind(this)} color="primary">
            <Save className={classes.icons} /> {t('button:save')}
          </Button>
          <Button round onClick={this._onClose.bind(this)}>
            <KeyboardArrowLeft className={classes.icons} /> {t('button:cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private _onClose() {
    const { handleClose } = this.props;
    handleClose && handleClose();
  }

  private _onTextChange(event: any) {
    event.persist();
    const { target: { name, value } } = event;
    let client = this.state.client;
    this.setState({ client: {...client, [name]: value} });
  }

  private _onNumberChange(value: number, name: string) {
    this.setState({ client: { ...this.state.client, [name]: value} });
  }

  private _onValidate() {
    let { client: { name, phone } } = this.state;

    return name && phone;
  }

  private _onSave() {
    if (!this._onValidate()) {
      this.setState({ showValidation: true });
      return;
    }
    this.setState({ showValidation: false });
    const { t, handleClose, getClientSelectItems, branchSelectItems } = this.props;
    let { client: { name, phone, type, branchId } } = this.state;
    let client = new ClientDTO();
    client.name = name;
    client.phone = phone;
    client.type = type;
    client.branchId = branchId === 0 ? parseInt(branchSelectItems[0].value.toString()) : branchId;

    this.props.saveClient(client).then((response: any) => {
      if (response && !response.errorCode) {
        handleClose && handleClose();
        toast.success(t('message:create_success'));
        getClientSelectItems();
      } else {
        showError(t, response.errorCode, response.errorMessage);
      }
    });

  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(QuickClient)))